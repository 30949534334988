// ProtectedImage.js
import React, { useState, useEffect } from 'react';

const ProtectedImage = ({ src, apiKey, alt, ...rest }) => {
  const [protectedSrc, setProtectedSrc] = useState(src);

  useEffect(() => {
    let isMounted = true;
    let objectUrl = null;

    const fetchImage = async () => {
      try {
        const response = await fetch(src, {
          method: 'GET',
          headers: { 'Api-Key': apiKey },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch image');
        }
        const blob = await response.blob();
        objectUrl = URL.createObjectURL(blob);
        if (isMounted) {
          setProtectedSrc(objectUrl);
        }
      } catch (error) {
        console.error('ProtectedImage error:', error);
        // fallback: use original src if fetching fails
        if (isMounted) {
          setProtectedSrc(src);
        }
      }
    };

    if (src) {
      fetchImage();
    }

    return () => {
      isMounted = false;
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [src, apiKey]);

  return <img src={protectedSrc} alt={alt} {...rest} />;
};

export default ProtectedImage;

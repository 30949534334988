import React, { useState, useEffect,useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation,Link } from 'react-router-dom';
import './App.css';
import { faker } from '@faker-js/faker';
import { uniqueNamesGenerator, Config } from 'unique-names-generator';
import { toPng } from '@dicebear/converter';
import Pica from 'pica';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import SessionLogViewer from './SessionLogViewer';
import UserInfo from './UserInfo';
import { toast, ToastContainer, toastId } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmbeddedSitePopup from './EmbeddedSitePopup';
import ReactConfetti from 'react-confetti';
import ProfileSection from './ProfileSection';
import SnakesGame from './SnakesGame';
import ProtectedImage from './ProtectedImage'; // Adjust the path as necessary

import Papa from 'papaparse';

const LandingPage = ({ setProfileName, setProfileImage }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toastId = useRef(null);
  const logoRef = useRef(null);


  useEffect(() => {
    const logoElement = logoRef.current;
    logoElement.classList.add('fun-bounce');
    const handleAnimationEnd = () => {
      logoElement.classList.remove('fun-bounce');
    };
    logoElement.addEventListener('animationend', handleAnimationEnd);

    return () => {
      logoElement.removeEventListener('animationend', handleAnimationEnd);
    };
  }, []);


  const CustomCloseButton = ({ closeToast }) => (
    <div className="landing-toast-close" onClick={closeToast}>
      ✖
    </div>
  );

  const handleGoogleSuccess = async (response) => {
    const accessToken = response.access_token;
    const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
      headers: { 'Authorization': `Bearer ${accessToken}` },
    });
    const userInfo = await userInfoResponse.json();
    let profileImage = userInfo.picture;
    if (!profileImage) {
      profileImage = `https://api.dicebear.com/8.x/initials/svg?seed=${encodeURIComponent(userInfo.name)}`;
    }
    
    if (userInfo.hd === 'dirtcube.xyz') {
      setProfileName(userInfo.name);
      setProfileImage(profileImage);
      localStorage.setItem('profileName', userInfo.name);
      localStorage.setItem('profileImage', profileImage);
      toast.update(toastId.current, {
        render: 'Google login successful!',
        type: 'success',
        className: 'landing-toast landing-toast-success',
        isLoading: false,
        autoClose: 2000,
        closeButton: <CustomCloseButton />,
      });
      setTimeout(() => {
        navigate('/member-login', { state: { dirtcubeAuthenticated: true } });
      }, 2000);
    } else {
      toast.update(toastId.current, {
        render: 'You must use a Dirtcube email to access developer features',
        type: 'error',
        className: 'landing-toast landing-toast-error',
        isLoading: false,
        autoClose: 5000,
        closeButton: <CustomCloseButton />,
      });
    }
  };


  const handleGoogleError = () => {
    toast.update(toastId.current, {
      render: 'Dirtcube login failed',
      type: 'error',
      className: 'landing-toast landing-toast-error',
      isLoading: false,
      autoClose: 5000,
      closeButton: <CustomCloseButton />,
    });
  };

  const dirtcubeLogin = useGoogleLogin({
    clientId: '8094591956-l1oj9gu9tm35nv03d8u86g20p1dphbta.apps.googleusercontent.com',
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleError,
  });

  const initiateDirtcubeLogin = () => {
    toastId.current = toast.loading('Initiating Dirtcube login...', {
      className: 'landing-toast landing-toast-info',
      closeButton: <CustomCloseButton />,
    });

    // Set a timeout to handle cases where the login might be stuck
    setTimeout(() => {
      if (toast.isActive(toastId.current)) {
        toast.update(toastId.current, {
          render: 'Dirtcube login taking too long, please try again',
          type: 'warning',
          className: 'landing-toast landing-toast-warning',
          isLoading: false,
          autoClose: 5000,
          closeButton: <CustomCloseButton />,
        });
      }
    }, 10000); // 10 seconds timeout

    dirtcubeLogin();
  };

  const generateRandomName = () => {
    const adjectives = ['Anonymous', 'Mystic', 'Mysterious', 'Enigmatic', 'Arcane'];
    const animals = ['Bear', 'Fox', 'Wolf', 'Owl', 'Panther'];
    const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const animal = animals[Math.floor(Math.random() * animals.length)];
    return `${adjective}${animal}`;
  };
  

  const continueAsGuest = () => {
    const randomName = generateRandomName();
    const profileImage = `https://api.dicebear.com/8.x/pixel-art/svg?seed=${randomName}`;
    setProfileName(randomName);
    setProfileImage(profileImage);
    localStorage.setItem('profileName', randomName);
    localStorage.setItem('profileImage', profileImage);
    toast.info('Setting up Specter playground...', {
      className: 'landing-toast landing-toast-info',
      autoClose: 3000,
      closeButton: <CustomCloseButton />,
      onClose: () => navigate('/member-login', { state: { dirtcubeAuthenticated: false } }),
    });
  };


  return (
    <div className="landing-page">
<div className="pattern-container">
  <div className="pattern-item" style={{ top: '5%', left: '10%', transform: 'scale(1.2)' }}>
    <img src={`${process.env.PUBLIC_URL}/assets/cross_filled.svg`} alt="Pattern" />
  </div>

  <div className="pattern-item" style={{ top: '15%', left: '90%', transform: 'scale(2.3)' }}>
    <img src={`${process.env.PUBLIC_URL}/assets/circle_filled.svg`} alt="Pattern" />
  </div>

  <div className="pattern-item" style={{ top: '85%', left: '15%', transform: 'scale(2.8)' }}>
    <img src={`${process.env.PUBLIC_URL}/assets/cross_stroke.svg`} alt="Pattern" />
  </div>
  <div className="pattern-item" style={{ top: '90%', left: '80%', transform: 'scale(1.6)' }}>
    <img src={`${process.env.PUBLIC_URL}/assets/circle_stroke.svg`} alt="Pattern" />
  </div>
</div>
      <div className="landing-page-background-animation"></div>
      <div className="landing-page-content">
      <div className="landing-page-title">
      <img
            ref={logoRef}
            src={`${process.env.PUBLIC_URL}/assets/specter_landing.svg`}
            alt="Specter Logo"
            className="landing-page-logo"
          
          />
          <h1 className="landing-page-main-title">Dive into the Specter Playground</h1>
        </div>
        <p className="landing-page-tagline">The ultimate platform for developers to test and experiment with Specter features.</p>
        <div className="landing-page-button-container">
        <div className="landing-page-login-option">
            <h2 className="landing-page-login-title">Experience Specter Now</h2>
            <button className="landing-page-styled-button" onClick={continueAsGuest}>🚀 Launch Playground</button>

          </div>
          <div className="landing-page-or-divider">or</div>
          <div className="landing-page-login-option">
            <h2 className="landing-page-login-title">Authorized Access for Dirtcube Team</h2>
            <button className="landing-page-styled-button" onClick={initiateDirtcubeLogin}>
              <img src={`${process.env.PUBLIC_URL}/assets/dirtcube-google.png`} alt="Google Icon" className="google-icon" />
              Sign in to Dirtcube Workspace
            </button>
            <div className="social-section">
      <h3 className="social-section-title">Follow Us On</h3>
          </div>
          <div className="social-button-container">
          <a href="https://discord.gg/w8RwmwH6Bk" target="_blank" rel="noopener noreferrer" className="social-button flex-center">
            <img src={`${process.env.PUBLIC_URL}/assets/discord.svg`} alt="Discord" className="btn-svg" />
          </a>
          <a href="https://www.facebook.com/specterapp" target="_blank" rel="noopener noreferrer" className="social-button flex-center">
            <img src={`${process.env.PUBLIC_URL}/assets/facebook.svg`} alt="Facebook" className="btn-svg" />
          </a>
          <a href="https://instagram.com/specterapp" target="_blank" rel="noopener noreferrer" className="social-button flex-center">
            <img src={`${process.env.PUBLIC_URL}/assets/instagram.svg`} alt="Instagram" className="btn-svg" />
          </a>
          <a href="https://twitter.com/specterapp" target="_blank" rel="noopener noreferrer" className="social-button flex-center">
            <img src={`${process.env.PUBLIC_URL}/assets/x.svg`} alt="X" className="btn-svg" />
          </a>
        </div>
   
        </div>
  
        </div>
      </div>
    </div>
  );
  }
  
  const App = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [profileName, setProfileName] = useState("John Doe");
    const [profileImage, setProfileImage] = useState(`${process.env.PUBLIC_URL}/assets/default_profile_image.png`);
    const location = useLocation(); // Use useLocation to get the current path
  

  

  useEffect(() => {
    // Retrieve theme from localStorage
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setIsDarkMode(savedTheme === 'dark');
    }

    // Retrieve profile information from localStorage
    const savedProfileName = localStorage.getItem('profileName');
    const savedProfileImage = localStorage.getItem('profileImage');
    if (savedProfileName && savedProfileImage) {
      setProfileName(savedProfileName);
      setProfileImage(savedProfileImage);
    }
  }, []);


  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem('theme', newMode ? 'dark' : 'light');
  };

  const handleWheel = (event) => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  };

  const removeLeadingZeros = (event) => {
    const value = event.target.value;
    if (value.length > 1 && value[0] === '0') {
      event.target.value = value.replace(/^0+/, '');
    }
  };

  useEffect(() => {
    document.addEventListener('wheel', handleWheel, { passive: true });

    // Add input event listeners to all number inputs
    const numberInputs = document.querySelectorAll('input[type="number"]');
    numberInputs.forEach(input => {
      input.addEventListener('input', removeLeadingZeros);
    });

    return () => {
      document.removeEventListener('wheel', handleWheel);

      numberInputs.forEach(input => {
        input.removeEventListener('input', removeLeadingZeros);
      });
    };
  }, []);

  const CustomCloseButton = ({ closeToast }) => (
    <div className="neu-toast-close" onClick={closeToast}>
      ✖
    </div>
  );
  
  const handleLogout = () => {
    console.log('User logged out');
    setProfileName("John Doe");
    setProfileImage(`${process.env.PUBLIC_URL}/assets/default_profile_image.png`);
  };

  return (
      <div className="App">
        {location.pathname !== '/' && ( // Conditionally render the header
          <header className="app-header">
          <div className="logo-container">
            <Link to="/member-login">
              <img
                src={`${process.env.PUBLIC_URL}/assets/${isDarkMode ? 'specter_textlogo_dark.svg' : 'specter_textlogo_light.svg'}`}
                alt="Specter Logo"
                className="specter-logo"
              />
            </Link>
          </div>
          <div className="theme-toggle">
            <div className={`toggle-option ${!isDarkMode ? 'active' : ''}`} onClick={() => { setIsDarkMode(false); localStorage.setItem('theme', 'light'); }}>
              <img src={`${process.env.PUBLIC_URL}/assets/sun_light.svg`} alt="Light Mode" />
              Light
            </div>
            <div className={`toggle-option ${isDarkMode ? 'active' : ''}`} onClick={() => { setIsDarkMode(true); localStorage.setItem('theme', 'dark'); }}>
              <img src={`${process.env.PUBLIC_URL}/assets/moon_dark.svg`} alt="Dark Mode" />
              Dark
            </div>
            <div className={`toggle-switch ${isDarkMode ? 'dark' : 'light'}`}></div>
          </div>
          <ProfileSection
              profileImage={profileImage}
              profileName={profileName}
              onLogout={handleLogout}
            />
        </header>
        )}
        <Routes>
        <Route path="/" element={<LandingPage setProfileName={setProfileName} setProfileImage={setProfileImage} />} />
          <Route path="/member-login" element={
            <GoogleOAuthProvider clientId="840194801711-8sn7dtio084t26sgn1l950dmgs39ok0o.apps.googleusercontent.com">
              <MemberLoginPage isDarkMode={isDarkMode} />
            </GoogleOAuthProvider>
          } />
          <Route path="/env-app-selection" element={
            <GoogleOAuthProvider clientId="840194801711-8sn7dtio084t26sgn1l950dmgs39ok0o.apps.googleusercontent.com">
              <EnvAppSelectionPage />
            </GoogleOAuthProvider>
          } />
          <Route path="/sign-up" element={
            <GoogleOAuthProvider clientId="840194801711-8sn7dtio084t26sgn1l950dmgs39ok0o.apps.googleusercontent.com">
              <SignUpPlayersPage />
            </GoogleOAuthProvider>
          } />
          <Route path="/log-in" element={
            <GoogleOAuthProvider clientId="840194801711-8sn7dtio084t26sgn1l950dmgs39ok0o.apps.googleusercontent.com">
              <LoginPlayersPage />
            </GoogleOAuthProvider>
          } />
          <Route path="/players" element={
            <GoogleOAuthProvider clientId="840194801711-8sn7dtio084t26sgn1l950dmgs39ok0o.apps.googleusercontent.com">
              <PlayerManagementPage />
            </GoogleOAuthProvider>
          } />
        </Routes>
        <ToastContainer
          pauseOnHover={false}
          closeOnClick
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeButton={<CustomCloseButton />}
        />
      </div>

);
}





function MemberLoginPage({ isDarkMode }) {
  // State variables
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedEnv, setSelectedEnv] = useState('staging');
  const [showPassword, setShowPassword] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [devIp, setDevIp] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Get authentication state from location
  const location = useLocation();
  const { dirtcubeAuthenticated } = location.state || { dirtcubeAuthenticated: false };

  // New state for organization selection
  const [showOrgPopup, setShowOrgPopup] = useState(false);
  const [availableOrganisations, setAvailableOrganisations] = useState([]);
  const [authToken, setAuthToken] = useState('');
  const [memberName, setMemberName] = useState('');
  const [memberIcon, setMemberIcon] = useState('');
  const [organisationIcon, setOrganisationIcon] = useState('');
  const [organisationName, setOrganisationName] = useState('');
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);

  // useEffect for initial loading and environment selection
  useEffect(() => {
    setGlobalLoading(true);

    // Simulate loading delay
    setTimeout(() => {
      setGlobalLoading(false);
    }, 1000);

    if (!dirtcubeAuthenticated) {
      setSelectedEnv('console');
    }
  }, [dirtcubeAuthenticated]);

  // Custom Close Button for Toast notifications
  const CustomCloseButton = ({ closeToast }) => (
    <div className="neu-toast-close" onClick={closeToast}>
      ✖
    </div>
  );

  // Handle Environment Selection
  const handleSelect = (option) => {
    setSelectedEnv(option.value);
    setShowOptions(false);
  };

  const navigate = useNavigate();

  // Toggle Password Visibility
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  // Handle Google Login Success
  const handleGoogleSuccess = async (tokenResponse) => {
    if (!tokenResponse || !tokenResponse.access_token) {
      toast.error('Google login failed: Invalid response from Google', { closeButton: <CustomCloseButton /> });
      return;
    }

    setIsLoading(true);
    const toastId = toast.loading('Logging in with Google...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
      closeButton: <CustomCloseButton />,
    });

    try {
      const accessToken = tokenResponse.access_token;
      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const userInfo = await userInfoResponse.json();
      const { email, id: googleId } = userInfo;

      const headers = {
        'Content-Type': 'application/json',
        env: selectedEnv,
        ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
      };

      const res = await fetch(
        'https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/member/sign-in',
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ email, password: null, googleId }),
        }
      );

      const data = await res.json();
      if (!res.ok) throw new Error(data.message || 'Google login failed');

      const memberDetails = data.data.memberDetails;
      const authToken = memberDetails?.authToken;
      const organisations = memberDetails?.organisations;
      const memberName = memberDetails?.name;
      const memberIcon = memberDetails?.profile_url;

      if (authToken) {
        setAuthToken(authToken);
        setMemberName(memberName);
        setMemberIcon(memberIcon);

        if (organisations && organisations.length === 1) {
          // Pass authToken directly
          handleSelectOrganisation(organisations[0], authToken);}

        else if (organisations && organisations.length > 1) {
          setIsLoading(false);
          setShowOrgPopup(true);
          setAvailableOrganisations(organisations);
          toast.update(toastId, {
            render: 'Login successful! Please select an organisation.',
            type: 'success',
            isLoading: false,
            autoClose: 2000,
            closeButton: <CustomCloseButton />,
          });
        } else {
          throw new Error('No organisations found for this member.');
        }
      } else {
        throw new Error('Invalid response structure: authToken not found');
      }
    } catch (error) {
      toast.update(toastId, {
        render: `Error logging in with Google: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeButton: <CustomCloseButton />,
      });
      setIsLoading(false);
    }
  };

  // Define the 'login' function using 'useGoogleLogin'
  const login = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: (error) => toast.error('Google login failed', { closeButton: <CustomCloseButton /> }),
  });

  // Handle Member Login
  const handleLoginMember = async () => {
    setIsLoading(true);
    const toastId = toast.loading('Logging in as member...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
      closeButton: <CustomCloseButton />,
    });

    try {
      const headers = {
        'Content-Type': 'application/json',
        env: selectedEnv,
        ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
      };

      const response = await fetch(
        'https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/member/sign-in',
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Member login failed');

      const memberDetails = data.data.memberDetails;
      const authToken = memberDetails?.authToken;
      const organisations = memberDetails?.organisations;
      const memberName = memberDetails?.name;
      const memberIcon = memberDetails?.profile_url;

      if (authToken) {
        setAuthToken(authToken);
        setMemberName(memberName);
        setMemberIcon(memberIcon);

        if (organisations && organisations.length === 1) {
          // Pass authToken directly
          handleSelectOrganisation(organisations[0], authToken);
        }
        
      
        else if (organisations && organisations.length > 1) {
          setIsLoading(false);
          setShowOrgPopup(true);
          setAvailableOrganisations(organisations);
          toast.update(toastId, {
            render: 'Login successful! Please select an organisation.',
            type: 'success',
            isLoading: false,
            autoClose: 2000,
            closeButton: <CustomCloseButton />,
          });
        } else {
          throw new Error('No organisations found for this member.');
        }
      } else {
        throw new Error('Invalid response structure: authToken not found');
      }
    } catch (error) {
      toast.update(toastId, {
        render: `Error logging in member: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeButton: <CustomCloseButton />,
      });
      setIsLoading(false);
    }
  };

  const handleSelectOrganisation = (organisation, token = authToken) => {
    const envOptions = {
      devAPIKey: organisation.api_keys.devAPIKey,
      stagingAPIKey: organisation.api_keys.stagingAPIKey,
      productionAPIKey: organisation.api_keys.productionAPIKey,
    };
    const organisationName = organisation.name;
    const organisationIcon = organisation.default_logo_url;
  
    navigate('/env-app-selection', {
      state: {
        memberToken: token,
        envOptions,
        selectedEnv,
        devIp,
        organisationId: organisation.id, // Include organisationId
        organisationName,
        memberName,
        memberIcon,
        organisationIcon,
        dirtcubeAuthenticated,
      },
    });
  };
  
  // JSX Rendering
  return (
    <div className="App">
      {/* Global Loading Overlay */}
      {globalLoading && (
        <div className="global-loading-overlay">
          <img
            src={`${process.env.PUBLIC_URL}/assets/specter_landing.svg`}
            alt="Loading..."
            className="loading-logo"
          />
        </div>
      )}

      {/* Main Card */}
      <div className="card">
        <h1>Welcome to the Specter Playground</h1>
        <h3>Streamline your Specter API & Project Testing</h3>

        {/* Environment Selection */}
        {dirtcubeAuthenticated ? (
          <>
            <label>
              <h4>Please select your Specter Environment</h4>
            </label>
            <div className="environment-dropdown-container">
              <div className="custom-dropdown">
                <div className="custom-dropdown-header" onClick={() => setShowOptions(!showOptions)}>
                  {selectedEnv === 'staging'
                    ? 'Staging'
                    : selectedEnv === 'console'
                    ? 'Console'
                    : selectedEnv === 'demo'
                    ? 'Demo'
                    : 'Development'}
                  <span className="custom-dropdown-arrow">&#9662;</span>
                </div>
                {showOptions && (
                  <ul className="custom-dropdown-list">
                    {[
                      { value: 'development', label: 'Development', className: 'development-option' },
                      { value: 'staging', label: 'Staging' },
                      { value: 'demo', label: 'Demo' },
                      { value: 'console', label: 'Console' },
                
                    ].map((option) => (
                      <li
                        key={option.value}
                        className={`custom-dropdown-option ${option.className || ''}`}
                        onClick={() => handleSelect(option)}
                      >
                        {option.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* Development Server URL Input */}
              <div className="dev-url-container">
                {selectedEnv === 'development' && (
                  <div>
                    <label>Enter your Development Server URL</label>
                    <div className="dev-url-wrapper">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/link.svg`}
                        alt="URL Icon"
                        className="dev-url-icon"
                      />
                      <input
                        type="text"
                        value={devIp}
                        onChange={(e) => setDevIp(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="then-divider">then</div>
          </>
        ) : (
          <input type="hidden" value="console" />
        )}

        {/* Login Form */}
        <h4>Log in to Specter to access your Apps</h4>

        <label className="profile-label">Enter your Email</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

        <label className="profile-label">Enter your Password</label>

        <div className="password-input-container">
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="password-input"
          />
          <div onClick={toggleShowPassword} className="password-toggle-button">
            <img
              src={showPassword ? '/assets/hide.svg' : '/assets/show.svg'}
              alt={showPassword ? 'Hide' : 'Show'}
            />
          </div>
        </div>
        <div className="test-divider"></div>

        {/* Login Button */}
        <button onClick={handleLoginMember} className="loading-button" disabled={isLoading}>
          {isLoading && <div className="button-spinner"></div>}
          {isLoading ? 'Logging In...' : 'Log in to Specter!'}
        </button>

        {/* Google Sign-In */}
        <div className="or-divider">or</div>
        <div className="google-signin-wrapper" id="googleSignInDiv">
          <button onClick={() => login()} className="google-default-button"></button>
          <img
            src={`${process.env.PUBLIC_URL}/assets/${
              isDarkMode ? 'google-light-icon.svg' : 'google-dark-icon.svg'
            }`}
            alt="Sign in with Google"
            onClick={() => login()}
          />
        </div>

        {/* Sign Up Link */}
        <div className="not-a-member">
          Not a Specter member?{' '}
          <a href="https://console.specterapp.xyz" target="_blank" rel="noopener noreferrer">
            Create an account here
          </a>
        </div>
      </div>

      {/* Organisation Selection Popup */}
      {showOrgPopup && (
        <div className="org-popup">
          <div className="org-popup-content">
            <h2>Select Organisation</h2>
            <div className="org-list">
              {availableOrganisations.map((org) => (
                <div
                  key={org.id}
                  className={`org-item ${selectedOrganisation?.id === org.id ? 'selected' : ''}`}
                  onClick={() => setSelectedOrganisation(org)}
                >
                  <h3>{org.name}</h3>
                  {/* Additional organisation details can be displayed here */}
                </div>
              ))}
            </div>
            <button
              onClick={() => {
                if (selectedOrganisation) {
                  handleSelectOrganisation(selectedOrganisation);
                } else {
                  toast.error('Please select an organisation.', { closeButton: <CustomCloseButton /> });
                }
              }}
              className="continue-button"
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
}




function EnvAppSelectionPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { memberToken, envOptions, selectedEnv,devIp,memberName,organisationName,memberIcon,organisationIcon,dirtcubeAuthenticated,organisationId,  } = location.state; // Get env from state
  const [envApiKey, setEnvApiKey] = useState('');
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState('');
  const [clientApiKey, setClientApiKey] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [showEnvOptions, setShowEnvOptions] = useState(false);
  const [showAppOptions, setShowAppOptions] = useState(false);
  const [selectedAppEnv, setSelectedAppEnv] = useState(''); // Renamed to avoid conflict
  const [fetchAppLoading, setFetchAppLoading] = useState(false); // New state for fetching apps
  const [selectedAppName, setSelectedAppName] = useState(''); // Add this line

  const CustomCloseButton = ({ closeToast }) => (
    <div className="neu-toast-close" onClick={closeToast}>
      ✖
    </div>
  );

  useEffect(() => {
    if (envApiKey) {
      const fetchApps = async () => {
        setFetchAppLoading(true);
        try {
          const headers = {
            'Authorization': `Bearer ${memberToken}`,
            'Api-Key': envApiKey,
            'Content-Type': 'application/json',
            'env': selectedEnv,
            ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }) // Add these lines
          };
          const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/app/get', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ organisationId }), // Include organisationId here
          });
      
        

          const data = await response.json();
          if (!response.ok) {
            throw new Error(data.message || 'Failed to fetch apps');
          }

          setApps(data.data.projectDetails);
        } catch (error) {
          console.error('Error fetching apps:', error);
          setStatus(`Error fetching apps: ${error.message}`);
        } finally {
          setFetchAppLoading(false); // Set fetch app loading state to false
        }
      };


      fetchApps();
    }
  }, [envApiKey, memberToken, selectedEnv]);

  const handleSelectEnv = (option) => {
    setEnvApiKey(option.value);
    setSelectedAppEnv(option.label); // Update selected environment label
    setShowEnvOptions(false); // Close the dropdown after selection
    setSelectedApp(''); // Reset selected app when environment changes
    setSelectedAppName('');
  };

  const handleSelectApp = (option) => {
    const appId = option.value;
    setSelectedApp(appId);
    const selectedAppDetails = apps.find(app => app.id === appId);
    setSelectedAppName(selectedAppDetails?.name || ''); // Set selected app name


    if (selectedAppDetails) {
      const selectedEnvironment = envApiKey === envOptions.devAPIKey
        ? 'clientDevAPIKey'
        : envApiKey === envOptions.stagingAPIKey
        ? 'clientStagingAPIKey'
        : 'clientProductionAPIKey';
      const apiKey = selectedAppDetails.apiKeys[selectedEnvironment][0]; // Use the first client API key for the selected environment
      setClientApiKey(apiKey);
    }
    setShowAppOptions(false); // Close the dropdown after selection
  };

  const handleNext = (mode) => {
    navigate(`/${mode}`, { state: { clientApiKey, selectedApp, envApiKey, memberToken, selectedEnv , selectedAppEnv,devIp,organisationName,memberName,selectedAppName,memberIcon,organisationIcon,dirtcubeAuthenticated} }); // Pass selectedEnv to the next page
  };

  return (
    <div className="App">
        <UserInfo memberName={memberName} organisationName={organisationName} selectedEnv={selectedEnv} />
    <div className="card">
      <h1>Configure Your Session</h1>
      <h3>Initialise your settings</h3>
      <label>Select your Application Environment</label>
      <div className="environment-dropdown-container">
        <div className="custom-dropdown">
          <div className="custom-dropdown-header" onClick={() => setShowEnvOptions(!showEnvOptions)}>
            {selectedAppEnv || 'Select an environment'}
            <span className="custom-dropdown-arrow">&#9662;</span>
          </div>
          {showEnvOptions && (
            <ul className="custom-dropdown-list">
              {[
                { value: envOptions.devAPIKey, label: 'Development' },
                { value: envOptions.stagingAPIKey, label: 'Quality Assurance' },
                { value: envOptions.productionAPIKey, label: 'Production' },
              ].map((option) => (
                <li
                  key={option.value}
                  className="custom-dropdown-option"
                  onClick={() => handleSelectEnv(option)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {fetchAppLoading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : apps.length > 0 ? (
        <>
          <label>Select your App</label>
          <div className="environment-dropdown-container">
            <div className="custom-dropdown">
              <div className="custom-dropdown-header" onClick={() => setShowAppOptions(!showAppOptions)}>
                {selectedApp ? apps.find(app => app.id === selectedApp)?.name : 'Select an app'}
                <span className="custom-dropdown-arrow">&#9662;</span>
              </div>
              {showAppOptions && (
                <ul className="custom-dropdown-list">
                  {apps.map((app) => (
                    <li
                      key={app.id}
                      className="custom-dropdown-option"
                      onClick={() => handleSelectApp({ value: app.id })}
                    >
                      {app.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          </>
        ) : null}

{selectedApp && (
  <>
   <div className="then-divider"></div>
    <button onClick={() => handleNext('sign-up')}>Sign Up Players</button>
    <div className="or-divider">or</div>
    <button onClick={() => handleNext('log-in')}>Log In Players</button>
  </>
)}

{status && <p>{status}</p>}
</div>
</div>
);
}



function SignUpPlayersPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { clientApiKey, selectedApp, envApiKey, memberToken, selectedEnv, selectedAppEnv,devIp,organisationName,memberName,selectedAppName,memberIcon,organisationIcon,dirtcubeAuthenticated } = location.state;
  const [numPlayers, setNumPlayers] = useState(1);
  const [players, setPlayers] = useState([]);
  const [status, setStatus] = useState('');
  const [signUpMethod, setSignUpMethod] = useState('');
  const [selectedAvatarStyle, setSelectedAvatarStyle] = useState('None');

  const [showGenerateSection, setShowGenerateSection] = useState(false);
  const [showAuthOptions, setShowAuthOptions] = useState(false);
  const [showAvatarOptions, setShowAvatarOptions] = useState(false);
  const [batchSize, setBatchSize] = useState(10); // New state for batch size
  const [customIdGenerationMethod, setCustomIdGenerationMethod] = useState('phone'); // default for custom method
const [showCustomMethodOptions, setShowCustomMethodOptions] = useState(false);

  const usedEmails = new Set();
  const usedUsernames = new Set();
  const usedPhoneNumbers = new Set();
  const [progress, setProgress] = useState('');


  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isBatchSigningUp, setIsBatchSigningUp] = useState(false);
  

  const [isSessionLogViewerOpen, setIsSessionLogViewerOpen] = useState(false);
  

  const handleOpenLogViewer = () => {
    setIsSessionLogViewerOpen(true);
  };
  
  const handleCloseLogViewer = () => {
    setIsSessionLogViewerOpen(false);
  };




// 2. Define options for custom id generation:
const customIdGenerationOptions = [
  { value: 'email', label: 'Fake Email' },
  { value: 'username', label: 'Fake Username' },
  { value: 'phone', label: 'Fake Phone Number' },
];




  const CustomCloseButton = ({ closeToast }) => (
    <div className="neu-toast-close" onClick={closeToast}>
      ✖
    </div>
  );





  const handleNumPlayersChange = (e) => {
    setNumPlayers(Number(e.target.value));
  };


  const handleBatchSizeChange = (e) => {
    setBatchSize(Number(e.target.value));
  };


  const handleGenerateFields = () => {
    const newPlayers = Array.from({ length: numPlayers }, () => ({ id: '', password: '' }));
    setPlayers(newPlayers);
    setBatchSize(numPlayers); 
    setShowGenerateSection(true);
  };


  const generateUniqueValue = (generateFunc, usedSet) => {
    let value;
    do {
      value = generateFunc();
    } while (usedSet.has(value));
    usedSet.add(value);
    return value;
  };


  const handleInputChange = (index, field, value) => {
    const newPlayers = [...players];
    newPlayers[index][field] = value;
    setPlayers(newPlayers);
  };


 

// const generateRandomSuffix = () => {
//   const randomNumber = faker.number.int({ min: 1000, max: 9999 }); // Generates a 4-digit random number
//   return `#${randomNumber}`;
// };


  

  const generateRandomEmail = () => generateUniqueValue(faker.internet.email, usedEmails);
  const generateIndianPhoneNumber = () => generateUniqueValue(() => `91-${faker.number.int({ min: 1000000000, max: 9999999999 })}`, usedPhoneNumbers);

  const generateRandomUsername = () => {
    const baseUsername = faker.internet.userName();
    return `${baseUsername}`;
  };


  const avatarStyles = [
    { value: 'None', label: 'None' },  // Add this line
    { value: 'GameStarz', label: 'GameStarz' }, // Add this line
    { value: 'avataaars', label: 'Cartoon' },
    { value: 'adventurer', label: 'Adventurer' },
    { value: 'adventurer-neutral', label: 'Adventurer Neutral' },
    { value: 'big-ears', label: 'Big Ears' },
    { value: 'big-ears-neutral', label: 'Big Ears Neutral' },
    { value: 'big-smile', label: 'Big Smile' },
    { value: 'bottts', label: 'Robots' },
    { value: 'croodles', label: 'Croodles' },
    { value: 'croodles-neutral', label: 'Croodles Neutral' },
    { value: 'fun-emoji', label: 'Fun Emoji' },
    { value: 'icons', label: 'Icons' },
    { value: 'identicon', label: 'Geometric' },
    { value: 'initials', label: 'Initials' },
    { value: 'micah', label: 'Modern' },
    { value: 'miniavs', label: 'Mini Avatars' },
    { value: 'open-peeps', label: 'Open Peeps' },
    { value: 'personas', label: 'Personas' },
    { value: 'pixel-art', label: 'Pixel Art' },
    { value: 'pixel-art-neutral', label: 'Pixel Art Neutral' },
  ];

  const handleGenerateCredentials = (index) => {
    const newPlayers = [...players];
  
    if (signUpMethod === 'email') {
      newPlayers[index].id = generateRandomEmail().toLowerCase();;
      newPlayers[index].password = '1234';
    } else if (signUpMethod === 'username') {
      newPlayers[index].id = generateRandomUsername();
      newPlayers[index].password = '1234';
    } else if (signUpMethod === 'custom') {
      if (customIdGenerationMethod === 'email') {
        newPlayers[index].id = generateRandomEmail().toLowerCase();;
      } else if (customIdGenerationMethod === 'username') {
        newPlayers[index].id = generateRandomUsername();
      } else if (customIdGenerationMethod === 'phone') {
        newPlayers[index].id = generateIndianPhoneNumber();
      }
      // For custom IDs, you can leave password empty or assign a default if you prefer
    }
    setPlayers(newPlayers);
  };
  
  

  // const retryOperation = async (operation, retries = 5, delay = 1000) => {
  //   for (let attempt = 1; attempt <= retries; attempt++) {
  //     try {
  //       return await operation();
  //     } catch (error) {
  //       if (attempt === retries) throw error;
  //       await new Promise((resolve) => setTimeout(resolve, delay));
  //     }
  //   }
  // };
  
  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsSigningUp(true);
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': clientApiKey,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    const totalPlayers = players.length;
    let signedUpPlayersCount = 0;
  
    const batchToastId = toast.loading(`Signing up players: 0/${totalPlayers}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
  
    const newPlayers = [];
    const signUpPromises = players.map(async (player, index) => {
      try {
        const response = await fetch(
          signUpMethod === 'email'
            ? 'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/auth/signup-email'
            : signUpMethod === 'username'
            ? 'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/auth/signup-username'
            : 'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/auth/signup-custom',
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
              signUpMethod === 'email'
                ? {
                    email: player.id,
                    password: player.password,
                    projectId: selectedApp,
                  }
                : signUpMethod === 'username'
                ? {
                    username: player.id,
                    password: player.password,
                    projectId: selectedApp,
                  }
                : {
                    customId: player.id,
                    projectId: selectedApp,
                  }
            ),
          }
        );
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Sign-up failed');
        }
  
        const newPlayer = {
          ...player,
          token: data.data.accessToken,
          uuid: data.data.user.uuid,
          id: data.data.user.id,
          customId: signUpMethod === 'custom' ? player.id : '',
          email: signUpMethod === 'email' ? player.id : '',
          username: signUpMethod === 'username' ? player.id : '',
        };
  
        newPlayers.push(newPlayer);
  
        if (signUpMethod !== 'username') {
          const generatedUsername = generateRandomUsername();
          await updateProfileWithUsername(newPlayer, generatedUsername);
        }
  
        signedUpPlayersCount++;
        toast.update(batchToastId, {
          render: `Signing up players: ${signedUpPlayersCount}/${totalPlayers}`,
          type: 'info',
          isLoading: true,
        });
  
      } catch (error) {
        toast.update(batchToastId, {
          render: `Error signing up Player ${index + 1}: ${player.id}: ${error.message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000
        });
        throw error; // Ensure the loop stops on error
      }
    });
  
    try {
      await Promise.all(signUpPromises);
      toast.update(batchToastId, {
        render: 'All players signed up successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
      navigate('/players', { state: { players: newPlayers, apiKey: clientApiKey, projectId: selectedApp, envApiKey, memberToken, avatarStyle: selectedAvatarStyle, selectedEnv, selectedAppEnv, devIp, memberName, organisationName, selectedAppName,memberIcon,organisationIcon,dirtcubeAuthenticated } });
    } catch (error) {
      // Errors are already handled by individual promises
    } finally {
      setIsSigningUp(false);
    }
  };
  const handleSignUpBatch = async (batchSize) => {
    setIsBatchSigningUp(true);
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': clientApiKey,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
    };
  
    const signUpUrl = signUpMethod === 'email'
      ? 'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/auth/signup-email'
      : signUpMethod === 'username'
      ? 'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/auth/signup-username'
      : 'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/auth/signup-custom';
  
    let allNewPlayers = [];
    const totalPlayers = players.length;
    let signedUpPlayersCount = 0;
  
    const batchToastId = toast.loading(`Signing up players: 0/${totalPlayers}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
  
    try {
      for (let start = 0; start < players.length; start += batchSize) {
        const batch = players.slice(start, start + batchSize);
  
        const signUpPromises = batch.map((player) => {
          const body = JSON.stringify(
            signUpMethod === 'email'
              ? { email: player.id, password: player.password, projectId: selectedApp }
              : signUpMethod === 'username'
              ? { username: player.id, password: player.password, projectId: selectedApp }
              : { customId: player.id, projectId: selectedApp }
          );
  
          return fetch(signUpUrl, { method: 'POST', headers, body })
            .then(response => response.json().then(data => ({ response, data })))
            .catch(error => ({ error }));
        });
  
        const results = await Promise.all(signUpPromises);
  
        const newPlayers = [];
        for (let i = 0; i < results.length; i++) {
          const { response, data, error } = results[i];
          const player = batch[i];
  
          if (error || !response.ok) {
            toast.update(batchToastId, {
              render: `Error signing up Player ${start + i + 1}: ${player.id}: ${data.message || 'Sign-up failed'}`,
              type: 'error',
              isLoading: false,
              autoClose: 5000
            });
            throw new Error(data.message || 'Sign-up failed');
          }
  
          const newPlayer = {
            ...player,
            token: data.data.accessToken,
            uuid: data.data.user.uuid,
            id: data.data.user.id,
            customId: signUpMethod === 'custom' ? player.id : '',
            email: signUpMethod === 'email' ? player.id : '',
            username: signUpMethod === 'username' ? player.id : '',
          };
  
          newPlayers.push(newPlayer);
  
          if (signUpMethod !== 'username') {
            const generatedUsername = generateRandomUsername();
            await updateProfileWithUsername(newPlayer, generatedUsername);
          }
  
          signedUpPlayersCount++;
          toast.update(batchToastId, {
            render: `Signing up players: ${signedUpPlayersCount}/${totalPlayers}`,
            type: 'info',
            isLoading: true,
          });
        }
  
        allNewPlayers = [...allNewPlayers, ...newPlayers];
      }
  
      toast.update(batchToastId, {
        render: 'All players signed up successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
      navigate('/players', {
        state: {
          players: allNewPlayers,
          apiKey: clientApiKey,
          projectId: selectedApp,
          envApiKey,
          memberToken,
          avatarStyle: selectedAvatarStyle,
          selectedEnv,
          selectedAppEnv,
          devIp,
          memberName,
          organisationName,
          selectedAppName,
          memberIcon,organisationIcon,
          dirtcubeAuthenticated
        }
      });
    } catch (error) {
      // Errors are already handled by individual promises
    } finally {
      setIsBatchSigningUp(false);
    }
  };
  

  const updateProfileWithUsername = async (player, username) => {
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': clientApiKey,
      'Authorization': `Bearer ${player.token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }) // Add these lines
    };

    try {
      const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/user/update-profile', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ username }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to update profile');
      }

      return data;
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error;
    }
  };

  
  const authMethods = [
    { value: 'email', label: 'Email' },
    { value: 'custom', label: 'Custom ID' },
    { value: 'username', label: 'Username' }
  ];

  const handleAuthMethodSelect = (option) => {
    setSignUpMethod(option.value);
    setShowAuthOptions(false);
  };

  const handleAvatarStyleSelect = (option) => {
    setSelectedAvatarStyle(option.value);
    setShowAvatarOptions(false);
  };

  useEffect(() => {
    setPlayers([]);
  }, [signUpMethod]);

  console.log('dirtcubeAuthenticated:', dirtcubeAuthenticated);
  console.log('players:', players);


  return (
    <div className="App">
     <UserInfo memberName={memberName} organisationName={organisationName} selectedEnv={selectedEnv} 
    appEnv={selectedAppEnv} appName={selectedAppName} />
    
      <div className="card">
        <h1>Sign Up Players</h1>
        <h3>Prepare Player Profiles for Testing</h3>
  
        <div>
          <label>Select your Preferred Auth Method</label>
          <div className="environment-dropdown-container">
            <div className="custom-dropdown">
              <div className="custom-dropdown-header" onClick={() => setShowAuthOptions(!showAuthOptions)}>
                {signUpMethod ? authMethods.find(method => method.value === signUpMethod)?.label : 'Select Auth Method'}
                <span className="custom-dropdown-arrow">&#9662;</span>
              </div>
              {showAuthOptions && (
                <ul className="custom-dropdown-list">
                  {authMethods.map((method) => (
                    <li
                      key={method.value}
                      className="custom-dropdown-option"
                      onClick={() => handleAuthMethodSelect(method)}
                    >
                      {method.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {signUpMethod === 'custom' && (
  <div>
    <label>Select Custom ID Generation Method</label>
    <div className="environment-dropdown-container">
      <div className="custom-dropdown">
        <div
          className="custom-dropdown-header"
          onClick={() => setShowCustomMethodOptions(!showCustomMethodOptions)}
        >
          {customIdGenerationMethod
            ? customIdGenerationOptions.find(
                (option) => option.value === customIdGenerationMethod
              )?.label
            : 'Select Generation Method'}
          <span className="custom-dropdown-arrow">&#9662;</span>
        </div>
        {showCustomMethodOptions && (
          <ul className="custom-dropdown-list">
            {customIdGenerationOptions.map((option) => (
              <li
                key={option.value}
                className="custom-dropdown-option"
                onClick={() => {
                  setCustomIdGenerationMethod(option.value);
                  setShowCustomMethodOptions(false);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  </div>
)}

        <div>
          <label>Select your Player Avatar Style</label>
          <div className="environment-dropdown-container">
            <div className="custom-dropdown">
              <div className="custom-dropdown-header" onClick={() => setShowAvatarOptions(!showAvatarOptions)}>
                {selectedAvatarStyle ? avatarStyles.find(style => style.value === selectedAvatarStyle)?.label : 'Select Avatar Style'}
                <span className="custom-dropdown-arrow">&#9662;</span>
              </div>
              {showAvatarOptions && (
                <ul className="custom-dropdown-list">
                  {avatarStyles.map((style) => (
                    <li
                      key={style.value}
                      className={`custom-dropdown-option ${style.value === 'None' ? 'development-option' : ''}`}
                      onClick={() => handleAvatarStyleSelect(style)}
                    >
                      {style.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        {signUpMethod && selectedAvatarStyle && (
          <>
            <label>Input Number of Players</label>
            <input type="number" value={numPlayers} onChange={handleNumPlayersChange} min="1" />
            <button onClick={handleGenerateFields}>Generate Players</button>
          </>
        )}
 {players.length > 0 && showGenerateSection && <div className="then-divider"></div>}
{players.length > 0 && showGenerateSection && (
  <>
  <button onClick={() => {
  const newPlayers = players.map((player) => {
    let id, password = '';
    if (signUpMethod === 'email') {
      id = generateRandomEmail().toLowerCase();
      password = '1234';
    } else if (signUpMethod === 'username') {
      id = generateRandomUsername();
      password = '1234';
    } else if (signUpMethod === 'custom') {
      if (customIdGenerationMethod === 'email') {
        id = generateRandomEmail().toLowerCase();
      } else if (customIdGenerationMethod === 'username') {
        id = generateRandomUsername();
      } else if (customIdGenerationMethod === 'phone') {
        id = generateIndianPhoneNumber();
      }
    }
    return { ...player, id, password };
  });
  setPlayers(newPlayers);
}}>
  Generate All Credentials
</button>


    <form onSubmit={handleSignUp}>
      {players.length > 0 && (
        <table className="players-table">
          <thead>
            <tr>
              <th>#</th>
              <th>
  {signUpMethod === 'custom'
    ? (customIdGenerationMethod === 'email'
        ? 'Fake Email'
        : customIdGenerationMethod === 'username'
        ? 'Fake Username'
        : customIdGenerationMethod === 'phone'
        ? 'Fake Phone Number'
        : 'Custom ID')
    : signUpMethod === 'email'
    ? 'Email'
    : signUpMethod === 'username'
    ? 'Username'
    : 'Custom ID'}
</th>

              {(signUpMethod === 'email' || signUpMethod === 'username') && <th>Password</th>}
              <th>Generate</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <input
                    type="text"
                    value={player.id}
                    onChange={(e) => handleInputChange(index, 'id', e.target.value)}
                    required
                  />
                </td>
                {(signUpMethod === 'email' || signUpMethod === 'username') && (
                  <td>
                    <input
                      type="password"
                      value={player.password}
                      onChange={(e) => handleInputChange(index, 'password', e.target.value)}
                      required
                    />
                  </td>
                )}
                <td>
                  <button type="button" onClick={() => handleGenerateCredentials(index)}>Generate</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {players.length > 0 && <div className="then-divider"></div>}
      {players.length > 0 && (
        <button type="submit" className="loading-button" disabled={isSigningUp}>
          {isSigningUp && <div className="button-spinner"></div>}
          {isSigningUp ? 'Signing Up...' : 'Sign Up Players'}
        </button>
      )}
{players.length > 0 && dirtcubeAuthenticated && <div className="or-divider">or</div>}
{players.length > 0 && dirtcubeAuthenticated && (
  <div className="batch-signup-container">
    <label>Input Batch Size & Sign Up Players (Only for Specter Devs)</label>
    <input
      type="number"
      value={batchSize}
      onChange={handleBatchSizeChange}
      min="1"
      max={players.length}
      placeholder="Batch Size"
      className="batch-size-input"
    />
    <button
      type="button"
      className="loading-button"
      onClick={() => handleSignUpBatch(batchSize)}
      disabled={isBatchSigningUp}
    >
      {isBatchSigningUp && <div className="button-spinner"></div>}
      {isBatchSigningUp ? 'Batch Signing Up...' : 'Batch Sign Up'}
    </button>
  </div>
)}

              
            </form>
          </>
        )}

        
        {status && <p>{status}</p>}
        {progress && <p>{progress}</p>}
     
      </div>
      <button className="view-session-logs-button-fixed" onClick={handleOpenLogViewer}>
        <img src="/assets/log.svg" alt="View Session Logs Icon" className="log-icon" />
        <span className="button-text">View Logs</span>
      </button>
  

      {isSessionLogViewerOpen && (
        <SessionLogViewer onClose={handleCloseLogViewer} />
      )}
    </div>
  );
}







function LoginPlayersPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { clientApiKey, selectedApp, envApiKey, memberToken, selectedEnv, selectedAppEnv,devIp,organisationName,memberName,selectedAppName,memberIcon,organisationIcon,dirtcubeAuthenticated } = location.state;
  const [appPlayers, setAppPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [playerCredentials, setPlayerCredentials] = useState({});
  const [loginMethods, setLoginMethods] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [toastId, setToastId] = useState(null);
  const [dropdownVisibility, setDropdownVisibility] = useState({});

  const [isSessionLogViewerOpen, setIsSessionLogViewerOpen] = useState(false);
  

  const handleOpenLogViewer = () => {
    setIsSessionLogViewerOpen(true);
  };
  
  const handleCloseLogViewer = () => {
    setIsSessionLogViewerOpen(false);
  };


  // Add this function to your component to generate and download a sample CSV
const downloadSampleCSV = () => {
  // Create sample data with header and one example row
  const csvContent = "identifier\nsample@email.com";
  
  // Create a blob and download link
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  
  // Set up the download
  link.setAttribute('href', url);
  link.setAttribute('download', 'player_import_sample.csv');
  link.style.visibility = 'hidden';
  
  // Add to document, trigger download, and clean up
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Updated handleCSVUpload to search all players, not just the current page
const handleCSVUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) return;
  
  // Show loading toast
  const loadingToastId = toast.loading("Preparing to process CSV...", {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
    closeButton: <CustomCloseButton />
  });

  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    trimHeaders: true,
    delimitersToGuess: [',', '\t', ';', '|'],
    complete: async function(results) {
      console.log("CSV Parse Results:", results);
      
      const relevantErrors = results.errors.filter(
        error => error.code !== "UndetectableDelimiter"
      );
      
      if (relevantErrors.length > 0) {
        console.error("CSV parsing errors:", relevantErrors);
        toast.update(loadingToastId, {
          render: `Error parsing CSV: ${relevantErrors[0].message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000
        });
        return;
      }
      
      // Find the identifier field
      let identifierField = "identifier";
      if (!results.meta.fields.includes("identifier")) {
        const possibleField = results.meta.fields.find(field => 
          field.toLowerCase().includes("identifier") || 
          field.toLowerCase().includes("id") ||
          field.toLowerCase().includes("email") ||
          field.toLowerCase().includes("user")
        );
        
        if (possibleField) {
          identifierField = possibleField;
          console.log(`Using "${identifierField}" as the identifier field`);
        } else if (results.meta.fields.length === 1) {
          identifierField = results.meta.fields[0];
          console.log(`Using only available column "${identifierField}" as identifier`);
        } else {
          toast.update(loadingToastId, {
            render: 'CSV file must contain an "identifier" column. Please download the sample for reference.',
            type: 'error',
            isLoading: false,
            autoClose: 5000
          });
          return;
        }
      }
      
      // Extract all identifiers from the CSV
      const identifiers = results.data
        .map(row => row[identifierField]?.trim())
        .filter(id => id); // Remove empty values
      
      if (identifiers.length === 0) {
        toast.update(loadingToastId, {
          render: 'No valid identifiers found in the CSV file.',
          type: 'error',
          isLoading: false,
          autoClose: 5000
        });
        return;
      }
      
      toast.update(loadingToastId, {
        render: `Found ${identifiers.length} identifiers. Searching all players in the system...`,
      });
      
      // We need to fetch and search through all players in the system
      let allPlayers = [];
      let currentFetchPage = 1;
      let hasMorePages = true;
      
      try {
        // Fetch all players across all pages
        while (hasMorePages) {
          toast.update(loadingToastId, {
            render: `Searching players (page ${currentFetchPage})...`,
          });
          
          const headers = {
            'Authorization': `Bearer ${memberToken}`,
            'Api-Key': envApiKey,
            'Content-Type': 'application/json',
            'env': selectedEnv,
            ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
          };
          
          const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/user/get', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
              projectId: selectedApp,
              offset: (currentFetchPage - 1) * 100, // Fetch more players per request
              limit: 100, // Increased limit for faster fetching
              search: '', // Empty search to get all players
            }),
          });
          
          const data = await response.json();
          if (!response.ok) {
            throw new Error(data.message || 'Failed to fetch players');
          }
          
          // Add the fetched players to our collection
          allPlayers = [...allPlayers, ...data.data.userDetails];
          
          // Check if we need to fetch more pages
          if (data.data.userDetails.length < 100 || currentFetchPage * 100 >= data.data.totalCount) {
            hasMorePages = false;
          } else {
            currentFetchPage++;
          }
        }
        
        console.log(`Fetched ${allPlayers.length} total players for matching`);
        
        // Now match the identifiers against the full player list
        let foundPlayers = [];
        let notFoundIdentifiers = [];
        
        toast.update(loadingToastId, {
          render: `Matching ${identifiers.length} identifiers against ${allPlayers.length} players...`,
        });
        
        // For each identifier in the CSV
        identifiers.forEach(identifier => {
          if (!identifier) return;
          
          console.log(`Looking for identifier: "${identifier}"`);
          const normalizedIdentifier = identifier.toLowerCase();
          
          // Find matching player in the full list
          const foundPlayer = allPlayers.find((player) => {
            const playerEmail = (player.email || "").toLowerCase();
            const playerCustomId = (player.customId || "").toLowerCase();
            const playerUsername = (player.username || "").toLowerCase();
            
            return playerEmail === normalizedIdentifier || 
                   playerCustomId === normalizedIdentifier || 
                   playerUsername === normalizedIdentifier;
          });
          
          if (foundPlayer) {
            console.log(`Found matching player:`, foundPlayer);
            
            // Only add if not already selected
            if (!selectedPlayers.some(p => p.id === foundPlayer.id)) {
              foundPlayers.push(foundPlayer);
            } else {
              console.log(`Player ${identifier} already selected, skipping`);
            }
          } else {
            console.log(`No player found matching identifier: ${identifier}`);
            notFoundIdentifiers.push(identifier);
          }
        });
        
        console.log(`Found ${foundPlayers.length} new players to add`);
        
        if (foundPlayers.length > 0) {
          // Create new collections for all state that needs to be updated
          const newSelectedPlayers = [...selectedPlayers, ...foundPlayers];
          const newPlayerCredentials = { ...playerCredentials };
          const newLoginMethods = { ...loginMethods };
          
          // Set up credentials and login methods for all new players
          foundPlayers.forEach(player => {
            const initialLoginMethod = player.email ? 'email' : player.customId ? 'customId' : 'username';
            
            newPlayerCredentials[player.id] = {
              email: player.email || '',
              password: '',
              customId: player.customId || '',
              username: player.username || '',
            };
            
            newLoginMethods[player.id] = initialLoginMethod;
          });
          
          // Update all state at once
          setSelectedPlayers(newSelectedPlayers);
          setPlayerCredentials(newPlayerCredentials);
          setLoginMethods(newLoginMethods);
          
          toast.update(loadingToastId, {
            render: `Successfully matched and selected ${foundPlayers.length} player(s).`,
            type: 'success',
            isLoading: false,
            autoClose: 3000
          });
        } else {
          toast.update(loadingToastId, {
            render: 'No new players were found to select.',
            type: 'info',
            isLoading: false,
            autoClose: 3000
          });
        }
        
        if (notFoundIdentifiers.length > 0) {
          toast.warning(`${notFoundIdentifiers.length} identifier(s) could not be matched.`, {
            className: 'neu-toast neu-toast-warning',
            progressClassName: 'neu-toast-progress',
            closeButton: <CustomCloseButton />
          });
        }
        
      } catch (error) {
        console.error('Error searching players:', error);
        toast.update(loadingToastId, {
          render: `Error searching players: ${error.message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000
        });
      }
    },
    error: function(err) {
      console.error("Fatal CSV parsing error:", err);
      toast.update(loadingToastId, {
        render: `Error parsing CSV: ${err.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
    }
  });
  
  // Clear the file input to allow re-uploading the same file
  event.target.value = null;
};
// Improved download all players function with matching format
const downloadAllPlayers = async () => {
  // Show loading toast
  const toastId = toast.loading("Preparing players data for download...", {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
    closeButton: <CustomCloseButton />
  });
  
  try {
    // We need to fetch all players, not just the current page
    let allPlayers = [];
    let currentFetchPage = 1;
    let hasMorePages = true;
    
    // Show that we're fetching data
    setLoading(true);
    
    while (hasMorePages) {
      const headers = {
        'Authorization': `Bearer ${memberToken}`,
        'Api-Key': envApiKey,
        'Content-Type': 'application/json',
        'env': selectedEnv,
        ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
      };
      
      // Update toast with current progress
      toast.update(toastId, { 
        render: `Fetching players data (page ${currentFetchPage})...`
      });
      
      const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/user/get', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          projectId: selectedApp,
          offset: (currentFetchPage - 1) * 100, // Fetch more players per request
          limit: 100, // Increased limit for faster fetching
          search: '', // Empty search to get all players
        }),
      });
      
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch players');
      }
      
      // Add the fetched players to our collection
      allPlayers = [...allPlayers, ...data.data.userDetails];
      
      // Check if we need to fetch more pages
      if (data.data.userDetails.length < 100 || currentFetchPage * 100 >= data.data.totalCount) {
        hasMorePages = false;
      } else {
        currentFetchPage++;
      }
    }
    
    // Update toast once all fetching is done
    toast.update(toastId, { 
      render: `Creating CSV file with ${allPlayers.length} players...`
    });
    
    // Format the data for CSV - USING THE SAME FORMAT AS UPLOAD
    // Only include the identifier column to match the upload format
    
    // Start with the header
    let csvContent = "identifier\r\n";
    
    // Add one line per player with their primary identifier
    allPlayers.forEach(player => {
      const identifier = (player.email || player.customId || player.username || "").trim();
      if (identifier) {
        // Properly escape the identifier in case it contains quotes
        csvContent += `${identifier}\r\n`;
      }
    });
    
    // Create a blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    
    // Set up the download with a descriptive filename
    const date = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
    const filename = `players_${date}.csv`;
    
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    
    // Add to document, trigger download, and clean up
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    // Show success message
    toast.update(toastId, { 
      render: `Successfully downloaded ${allPlayers.length} players! You can now upload this CSV to select these players.`,
      type: 'success',
      isLoading: false,
      autoClose: 4000
    });
    
  } catch (error) {
    console.error('Error downloading players:', error);
    toast.update(toastId, { 
      render: `Error downloading players: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setLoading(false);
  }
};
  const toggleDropdownVisibility = (playerId) => {
    setDropdownVisibility((prevVisibility) => ({
      ...prevVisibility,
      [playerId]: !prevVisibility[playerId],
    }));
  };



  const CustomCloseButton = ({ closeToast }) => (
    <div className="neu-toast-close" onClick={closeToast}>
      ✖
    </div>
  );

  const fetchPlayers = async (page = 1, query = '') => {
    setLoading(true);
    try {
      const headers = {
        'Authorization': `Bearer ${memberToken}`,
        'Api-Key': envApiKey,
        'Content-Type': 'application/json',
        'env': selectedEnv,
        ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }) // Add these lines
      };
  
      const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/user/get', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          projectId: selectedApp,
          offset: (page - 1) * 10,
          limit: 10,
          search: query,
        }),
      });
  

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch players');
      }

      setAppPlayers(data.data.userDetails);
      setCurrentPage(page);
      setTotalPages(Math.ceil(data.data.totalCount / 10));
    } catch (error) {
      console.error('Error fetching players:', error);
      setStatus(`Error fetching players: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedApp) {
      fetchPlayers(currentPage);
    }
  }, [selectedApp, currentPage]);

  const handlePlayerSelect = (player, isSelected) => {
    const selected = selectedPlayers.some(p => p.id === player.id);
    let newSelectedPlayers = [...selectedPlayers];

    if (isSelected) {
      if (!selected) {
        newSelectedPlayers.push(player);
      }
    } else {
      newSelectedPlayers = newSelectedPlayers.filter(p => p.id !== player.id);
    }

    setSelectedPlayers(newSelectedPlayers);

    const initialLoginMethod = player.email ? 'email' : player.customId ? 'customId' : 'username';
    setPlayerCredentials((prevCredentials) => ({
      ...prevCredentials,
      [player.id]: {
        email: player.email || '',
        password: '',
        customId: player.customId || '',
        username: player.username || '',
      },
    }));

    setLoginMethods((prevMethods) => ({
      ...prevMethods,
      [player.id]: initialLoginMethod,
    }));
  };

  const handlePlayerCredentialChange = (playerId, field, value) => {
    setPlayerCredentials((prevCredentials) => ({
      ...prevCredentials,
      [playerId]: { ...prevCredentials[playerId], [field]: value },
    }));
  };


  const handleLoginPlayers = async () => {
    setIsLoggingIn(true);
  
    const totalPlayers = selectedPlayers.length;
    let loggedInPlayersCount = 0;
  
    const batchToastId = toast.loading(`Logging in players: 0/${totalPlayers}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
  
    let validPlayers = [];
    const errorMessages = [];
  
    for (const player of selectedPlayers) {
      const apiEndpoint =
        loginMethods[player.id] === 'email'
          ? 'login-email'
          : loginMethods[player.id] === 'customId'
          ? 'login-custom'
          : 'login-username';
  
      const loginPayload =
        loginMethods[player.id] === 'email'
          ? {
              email: playerCredentials[player.id]?.email,
              password: playerCredentials[player.id]?.password || '1234', // Default to '1234' if no password is provided
              projectId: selectedApp,
              createAccount: false,
            }
          : loginMethods[player.id] === 'customId'
          ? {
              customId: playerCredentials[player.id]?.customId,
              projectId: selectedApp,
              createAccount: false,
            }
          : {
              username: playerCredentials[player.id]?.username,
              password: playerCredentials[player.id]?.password || '1234', // Default to '1234' if no password is provided
              projectId: selectedApp,
              createAccount: false,
            };
  
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Api-Key': clientApiKey,
          'env': selectedEnv,
          ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }) // Add these lines
        };
  
        const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/auth/${apiEndpoint}`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(loginPayload),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Player login failed');
        }
  
        validPlayers.push({
          id: player.id,
          email: player.email,
          customId: player.customId,
          username: player.username,
          token: data.data.accessToken,
          uuid: data.data.user.uuid,
        });
  
        loggedInPlayersCount++;
        toast.update(batchToastId, {
          render: `Logging in players: ${loggedInPlayersCount}/${totalPlayers}`,
          type: 'info',
          isLoading: true,
        });
  
      } catch (error) {
        errorMessages.push(`Error logging in player ${player.email || player.customId || player.username}: ${error.message}`);
      }
    }
  
    setIsLoggingIn(false);
  
    if (errorMessages.length > 0) {
      toast.update(batchToastId, {
        render: errorMessages.join('\n'),
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
    } else {
      toast.update(batchToastId, {
        render: 'All players logged in successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
      navigate('/players', { state: { players: validPlayers, apiKey: clientApiKey, projectId: selectedApp, envApiKey, memberToken, selectedEnv, selectedAppEnv, devIp, selectedAppName, memberName, organisationName,memberIcon,organisationIcon,dirtcubeAuthenticated } });
    }
  };
  

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    fetchPlayers(1, e.target.value);
  };

  const handleLoginMethodChange = (playerId, method) => {
    setLoginMethods((prevMethods) => ({
      ...prevMethods,
      [playerId]: method,
    }));
  };

  const getPlayerLabel = (player) => {
    return player.email || player.customId || player.username;
  };

  return (
    <div className="App">
      <UserInfo memberName={memberName} organisationName={organisationName} selectedEnv={selectedEnv} 
    appEnv={selectedAppEnv} appName={selectedAppName} />
     
      <div className="card"> 
        <h1>Log In Players</h1>
        <h3>Access Existing Player Profiles</h3>
        <label>
        Search Players by Email/ Custom ID/ Username
          </label>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search Players"
          />
 <div className="csv-upload-section">
  <label>Player CSV Upload</label>
  <div className="csv-controls">
    <label className="csv-upload-label" htmlFor="csvUpload">
      Upload CSV
    </label>
    <input 
      type="file" 
      id="csvUpload" 
      accept=".csv" 
      onChange={handleCSVUpload} 
    />
    <button 
      className="download-sample-btn" 
      onClick={downloadSampleCSV}
    >
      Download Sample
    </button>
    <button 
      className="download-all-btn" 
      onClick={downloadAllPlayers}
    >
      Download All Players
    </button>
  </div>
  <div className="csv-format-info">
    CSV should contain an "identifier" column with email, customId, or username values
  </div>
</div>
        {loading ? (
           <div style={{ minHeight: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <div className="spinner-container"> 
    <div className="player-spinner"></div>
  </div>
  </div>
) : (


          <div>
           <h3>Select Players</h3>
<table className="new-select-table">
  <thead>
    <tr>
      <th>Select</th>
      <th>Player</th>
    </tr>
  </thead>
  <tbody>
    {appPlayers.map((player) => (
      <tr key={player.id}>
        <td>
          <input
            type="checkbox"
            checked={selectedPlayers.some(p => p.id === player.id)}
            onChange={(e) => handlePlayerSelect(player, e.target.checked)}
          />
        </td>
        <td>
          {getPlayerLabel(player)}
        </td>
      </tr>
    ))}
  </tbody>
</table>
<div>
  <button
    disabled={currentPage === 1}
    onClick={() => {
      setCurrentPage(currentPage - 1);
      fetchPlayers(currentPage - 1, searchQuery);
    }}
  >
    Previous Page
  </button>
  <button
    disabled={currentPage === totalPages}
    onClick={() => {
      setCurrentPage(currentPage + 1);
      fetchPlayers(currentPage + 1, searchQuery);
    }}
  >
    Next Page
  </button>
</div>

         
          </div>
        )}
      </div>
      {selectedPlayers.length > 0 && (
        <div className="card">
     <div className="info-tip">
          <span>If the password field is left blank, a default password of '1234' will be used!</span>
        </div>


          <table className="players-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Login Method</th>
                <th>Credentials</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {selectedPlayers.map((player, index) => {
                const loginMethod = loginMethods[player.id] || (player.email ? 'email' : player.customId ? 'customId' : 'username');

                return (
                  <tr key={player.id}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="custom-dropdown">
                      <div
                        className="custom-dropdown-header"
                        onClick={() => toggleDropdownVisibility(player.id)}
                      >
                        {loginMethod === 'email'
                          ? 'Email'
                          : loginMethod === 'customId'
                          ? 'Custom ID'
                          : 'Username'}
                        <span className="custom-dropdown-arrow">&#9662;</span>
                      </div>
                      {dropdownVisibility[player.id] && (
                        <ul className="custom-dropdown-list">
                          {[
                            { value: 'email', label: 'Email' },
                            { value: 'customId', label: 'Custom ID' },
                            { value: 'username', label: 'Username' },
                          ].map((option) => (
                            <li
                              key={option.value}
                              className="custom-dropdown-option"
                              onClick={() => handleLoginMethodChange(player.id, option.value)}
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </td>
                  <td>
                      {loginMethod === 'email' ? (
                        <>
                          <label>
                            Email:
                            <input
                              type="email"
                              value={playerCredentials[player.id]?.email || player.email || ''}
                              onChange={(e) => handlePlayerCredentialChange(player.id, 'email', e.target.value)}
                            />
                          </label>
                          <label>
                            Password:
                            <input
                              type="password"
                              value={playerCredentials[player.id]?.password || ''}
                              onChange={(e) => handlePlayerCredentialChange(player.id, 'password', e.target.value)}
                            />
                          </label>
                        </>
                      ) : loginMethod === 'customId' ? (
                        <label>
                          Custom ID:
                          <input
                            type="text"
                            value={playerCredentials[player.id]?.customId || player.customId || ''}
                            onChange={(e) => handlePlayerCredentialChange(player.id, 'customId', e.target.value)}
                          />
                        </label>
                      ) : (
                        <>
                          <label>
                            Username:
                            <input
                              type="text"
                              value={playerCredentials[player.id]?.username || player.username || ''}
                              onChange={(e) => handlePlayerCredentialChange(player.id, 'username', e.target.value)}
                            />
                          </label>
                          <label>
                            Password:
                            <input
                              type="password"
                              value={playerCredentials[player.id]?.password || ''}
                              onChange={(e) => handlePlayerCredentialChange(player.id, 'password', e.target.value)}
                            />
                          </label>
                        </>
                      )}
                    </td>
                    <td>
                      <button onClick={() => handlePlayerSelect(player, false)}>Delete</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="then-divider"></div>
          <button className="loading-button" onClick={handleLoginPlayers} disabled={isLoggingIn}>
      {isLoggingIn && <div className="button-spinner"></div>}
      {isLoggingIn ? 'Logging In...' : 'Log In Players'}
    </button>
        
        </div>
        
      )}
       <button className="view-session-logs-button-fixed" onClick={handleOpenLogViewer}>
        <img src="/assets/log.svg" alt="View Session Logs Icon" className="log-icon" />
        <span className="button-text">View Logs</span>
      </button>
  

      {isSessionLogViewerOpen && (
        <SessionLogViewer onClose={handleCloseLogViewer} />
      )}
    </div>
    
  );
}









function PlayerManagementPage() {
  const location = useLocation();
  const { avatarStyle,players, apiKey, projectId, envApiKey, memberToken,selectedEnv, selectedAppEnv,devIp,organisationName,memberName,selectedAppName,memberIcon,organisationIcon,dirtcubeAuthenticated } = location.state;
  const [activeTab, setActiveTab] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [matches, setMatches] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [items, setItems] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [matchSessionId, setMatchSessionId] = useState('');
  const [competitionSessionId, setCompetitionSessionId] = useState('');
  const [showMatchPopup, setShowMatchPopup] = useState(false);
  const [showCompetitionPopup, setShowCompetitionPopup] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState('');
  const [selectedCompetition, setSelectedCompetition] = useState('');
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [masterPlayer, setMasterPlayer] = useState('');
  const [matchStatus, setMatchStatus] = useState('');
  const [competitionStatus, setCompetitionStatus] = useState('');
  const [showMatchCustomParamsPopup, setShowMatchCustomParamsPopup] = useState(false);
  const [showAddInventoryCustomParamsPopup, setShowAddInventoryCustomParamsPopup] = useState(false);
  const [showConsumeInventoryCustomParamsPopup, setShowConsumeInventoryCustomParamsPopup] = useState(false);
  const [showEquipInventoryCustomParamsPopup, setShowEquipInventoryCustomParamsPopup] = useState(false);
  const [matchCustomParams, setMatchCustomParams] = useState({});
  const [addInventoryCustomParams, setAddInventoryCustomParams] = useState({});
  const [consumeInventoryCustomParams, setConsumeInventoryCustomParams] = useState({});
  const [equipInventoryCustomParams, setEquipInventoryCustomParams] = useState({});
  const [competitionCustomParams, setCompetitionCustomParams] = useState({});
  const [outcomes, setOutcomes] = useState({});
  const [showEndMatchPopup, setShowEndMatchPopup] = useState(false);
  const [showEndCompetitionPopup, setShowEndCompetitionPopup] = useState(false);
  const [showInventoryPopup, setShowInventoryPopup] = useState(false);
  const [inventoryStatus, setInventoryStatus] = useState('');
  const [customParamsMode, setCustomParamsMode] = useState('');
  const [entries, setEntries] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [selectedInventoryItems, setSelectedInventoryItems] = useState([]);
  const [selectedConsumeItems, setSelectedConsumeItems] = useState([]);
  
  const [selectedEquipItems, setSelectedEquipItems] = useState([]);
  


  const [consumeItemAmount, setConsumeItemAmount] = useState(0);
  const [consumeItemStackId, setConsumeItemStackId] = useState('');
  const [consumeItemCollectionId, setConsumeItemCollectionId] = useState('');
  const [equipItemStackId, setEquipItemStackId] = useState('');
  const [equipItemCollectionId, setEquipItemCollectionId] = useState('');
  const [equipItemAction, setEquipItemAction] = useState('equip');
  const [customEvents, setCustomEvents] = useState([]); //customevents
  const [selectedEvent, setSelectedEvent] = useState(null);//customevents2
  const [eventParams, setEventParams] = useState([]);//customevents2
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLeaderboardPopup, setShowLeaderboardPopup] = useState(false);
  const [leaderboards, setLeaderboards] = useState([]);
  const [selectedLeaderboard, setSelectedLeaderboard] = useState('');
  const [selectedLeaderboardPlayers, setSelectedLeaderboardPlayers] = useState([]);
  const [playerScores, setPlayerScores] = useState({});
  const [minOutcome, setMinOutcome] = useState(0);
const [maxOutcome, setMaxOutcome] = useState(100);
const [outcomeMode, setOutcomeMode] = useState('unique'); // 'unique' or 'ties'
const [numTied, setNumTied] = useState(0);
const [tieAll, setTieAll] = useState(false);
const [showCustomTournamentPopup, setShowCustomTournamentPopup] = useState(false);
const [selectedTournament, setSelectedTournament] = useState('');
const [customTournaments, setCustomTournaments] = useState([]);
const [showSubmitScoresPopup, setShowSubmitScoresPopup] = useState(false);
const [scores, setScores] = useState({});
const [tournamentStatus, setTournamentStatus] = useState('');
const [customTournamentEntries, setCustomTournamentEntries] = useState([]);
const [selectedCustomTournamentPlayers, setSelectedCustomTournamentPlayers] = useState([]);
const [selectedCustomTournamentEntries, setSelectedCustomTournamentEntries] = useState([]);
const [selectAllMatchPlayers, setSelectAllMatchPlayers] = useState(false);
const [selectAllCompetitionPlayers, setSelectAllCompetitionPlayers] = useState(false);
const [selectAllCustomTournamentPlayers, setSelectAllCustomTournamentPlayers] = useState(false);
const [selectAllLeaderboardPlayers, setSelectAllLeaderboardPlayers] = useState(false);
const [selectAllEntries, setSelectAllEntries] = useState(false);
const [selectAllCustomTournamentEntries, setSelectAllCustomTournamentEntries] = useState(false);
const [appInfo, setAppInfo] = useState(null);
const [selectedOption, setSelectedOption] = useState('Global Actions');
const [showOptions, setShowOptions] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [globalLoading, setGlobalLoading] = useState(false); // Renamed loading state

const [playerSearchInput, setPlayerSearchInput] = useState('');
const [filteredPlayers, setFilteredPlayers] = useState(players);

const [matchPopupStage, setMatchPopupStage] = useState('match'); // Stage can be 'match', 'create', 'start', 'end'

const CustomCloseButton = ({ closeToast }) => (
  <div className="neu-toast-close" onClick={closeToast}>
    ✖
  </div>
);

const handleNextStage = (stage) => {
  setMatchPopupStage(stage);
};

const changeMatchStage = (stage) => {
  setMatchPopupStage(stage);
  setMatchStatus('');
  setIsCreateMatchSuccess(false);
  setIsStartMatchSuccess(false);
};


const [competitionPopupStage, setCompetitionPopupStage] = useState('competition');


const handleNextCompetitionStage = (stage) => {
  setCompetitionStatus(''); // Clear the status
  setCompetitionPopupStage(stage);
  // Reset success states
  setIsEnterCompetitionSuccess(false);
  setIsStartCompetitionSuccess(false);
  setIsEndCompetitionSuccess(false);
};

const [customTournamentPopupStage, setCustomTournamentPopupStage] = useState('setup');


const handleNextCustomTournamentStage = (stage) => {
  setCustomTournamentPopupStage(stage);
  setTournamentStatus('');
};



const [leaderboardPopupStage, setLeaderboardPopupStage] = useState('setup');


// Function to handle the next stage in the leaderboard popup
const handleNextLeaderboardStage = (stage) => {
  setLeaderboardPopupStage(stage);
  setSuccessMessage('');
  setErrorMessage('');
};




const [isCreatingMatch, setIsCreatingMatch] = useState(false);
const [isStartingMatch, setIsStartingMatch] = useState(false);
const [isEndingMatch, setIsEndingMatch] = useState(false);

const [isCreateMatchSuccess, setIsCreateMatchSuccess] = useState(false);
const [isStartMatchSuccess, setIsStartMatchSuccess] = useState(false);
const [isEndMatchSuccess, setIsEndMatchSuccess] = useState(false);



// State variables for loading and success states

const [isStartingCompetition, setIsStartingCompetition] = useState(false);
const [isEndingCompetition, setIsEndingCompetition] = useState(false);


const [isStartCompetitionSuccess, setIsStartCompetitionSuccess] = useState(false);
const [isEndCompetitionSuccess, setIsEndCompetitionSuccess] = useState(false);

const [isEnteringCompetition, setIsEnteringCompetition] = useState(false);
const [isEnteringCompetitionBatch, setIsEnteringCompetitionBatch] = useState(false);
const [isEnterCompetitionSuccess, setIsEnterCompetitionSuccess] = useState(false);
const [isEnterCompetitionBatchSuccess, setIsEnterCompetitionBatchSuccess] = useState(false);


const [isEnteringCustomCompetition, setIsEnteringCustomCompetition] = useState(false);
const [isEnteringCustomCompetitionBatch, setIsEnteringCustomCompetitionBatch] = useState(false);
const [isEnterCustomCompetitionSuccess, setIsEnterCustomCompetitionSuccess] = useState(false);
const [isEnterCustomCompetitionBatchSuccess, setIsEnterCustomCompetitionBatchSuccess] = useState(false);

const [isSubmittingScores, setIsSubmittingScores] = useState(false);
const [isSubmittingScoresBatch, setIsSubmittingScoresBatch] = useState(false);
const [isSubmitScoresSuccess, setIsSubmitScoresSuccess] = useState(false);
const [isSubmitScoresBatchSuccess, setIsSubmitScoresBatchSuccess] = useState(false);




const [isPostingScore, setIsPostingScore] = useState(false);
const [isPostingScoreBatch, setIsPostingScoreBatch] = useState(false);
const [isPostScoreSuccess, setIsPostScoreSuccess] = useState(false);
const [isPostScoreBatchSuccess, setIsPostScoreBatchSuccess] = useState(false);


const [isUpdatingWallets, setIsUpdatingWallets] = useState(false);
const [isUpdatingWalletsBatch, setIsUpdatingWalletsBatch] = useState(false);


// State variables for loading and success states
const [isUpdatingProgress, setIsUpdatingProgress] = useState(false);
const [isUpdatingProgressBatch, setIsUpdatingProgressBatch] = useState(false);





useEffect(() => {
  setFilteredPlayers(
    players.filter(player =>
      (player.email || player.username || player.customId).toLowerCase().includes(playerSearchInput.toLowerCase())
    )
  );
}, [playerSearchInput, players]);





const resetDropdown = () => {
  setSelectedOption('Global Actions');
};
const [loading, setLoading] = useState(true);

const resetInventoryState = () => {
  setSelectedItems([]);
  setSelectedBundles([]);
  setAddInventoryCustomParams({});
  setConsumeInventoryCustomParams({});
  setEquipInventoryCustomParams({});
  setSelectedInventoryItems([]);
  setSelectedConsumeItems([]);
  setSelectedEquipItems([]);
  setInventoryStatus('');
  setShowAddInventoryCustomParamsPopup(false);
  setShowConsumeInventoryCustomParamsPopup(false);
  setShowEquipInventoryCustomParamsPopup(false);
};



// State variables
const [showUpdateProgressPopup, setShowUpdateProgressPopup] = useState(false);
const [selectedProgressPlayers, setSelectedProgressPlayers] = useState([]);
const [selectedProgressMarker, setSelectedProgressMarker] = useState('');
const [progressAmount, setProgressAmount] = useState(0);
const [progressOperator, setProgressOperator] = useState('add');
const [selectAllProgressPlayers, setSelectAllProgressPlayers] = useState(false);
const [progressMarkers, setProgressMarkers] = useState([]);
const [updatedProgress, setUpdatedProgress] = useState({});

// Fetch all progress for players
const fetchAllProgress = async () => {
  try {
    const progress = {};
    const markers = new Map();
    for (const player of players) {
      const progressData = await handleFetchProgress(player.token, player.uuid);
      const playerProgress = progressData.reduce((acc, curr) => {
        acc[curr.id] = curr.progressionMarkerAmount;
        markers.set(curr.id, curr.name);
        return acc;
      }, {});
      progress[player.id] = playerProgress;
    }
    setUpdatedProgress(progress);
    setProgressMarkers(Array.from(markers.entries()).map(([id, name]) => ({ id, name })));
  } catch (error) {
    console.error('Error fetching all progress:', error);
  }
};


// Update progress for all selected players
const [progressUpdateStatusMessages, setProgressUpdateStatusMessages] = useState([]);
const [progressUpdateProgress, setProgressUpdateProgress] = useState('');
const [progressBatchSize, setProgressBatchSize] = useState(10);
const [progressBatchUpdateStatusMessages, setProgressBatchUpdateStatusMessages] = useState([]);
const [progressBatchUpdateProgress, setProgressBatchUpdateProgress] = useState('');


const handleUpdateProgress = async () => {
  setIsUpdatingProgress(true);
  const batchToastId = toast.loading(`Updating progress for players: 0/${selectedProgressPlayers.length}`, {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  let updatedPlayersCount = 0;

  try {
    for (const playerId of selectedProgressPlayers) {
      const player = players.find(p => p.id === playerId);
      if (!player || !player.token) continue;

      try {
        await handleUpdateMarker(player.token, selectedProgressMarker, progressOperator, progressAmount);
        updatedPlayersCount++;
        toast.update(batchToastId, {
          render: `Progress: ${updatedPlayersCount}/${selectedProgressPlayers.length} players updated.`,
          type: 'info',
          isLoading: true
        });
      } catch (error) {
        const errorMessage = `Error updating progress for player: ${player.email || player.customId || player.username} - ${error.message}`;
        toast.update(batchToastId, {
          render: errorMessage,
          type: 'error',
          isLoading: false
        });
      }
    }

    await fetchAllProgress();
    toast.update(batchToastId, {
      render: 'Progress updated successfully!',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
  } catch (error) {
    console.error('Error updating progress:', error);
    toast.update(batchToastId, {
      render: `Error updating progress: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setIsUpdatingProgress(false);
  }
};

const handleUpdateProgressBatch = async (batchSize) => {
  setIsUpdatingProgressBatch(true);
  const batchToastId = toast.loading(`Updating progress for players: 0/${selectedProgressPlayers.length}`, {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  let updatedPlayersCount = 0;

  try {
    for (let start = 0; start < selectedProgressPlayers.length; start += batchSize) {
      const batch = selectedProgressPlayers.slice(start, start + batchSize);

      const updatePromises = batch.map(async (playerId) => {
        const player = players.find(p => p.id === playerId);
        if (!player || !player.token) return;

        try {
          await handleUpdateMarker(player.token, selectedProgressMarker, progressOperator, progressAmount);
          updatedPlayersCount++;
          toast.update(batchToastId, {
            render: `Progress: ${updatedPlayersCount}/${selectedProgressPlayers.length} players updated.`,
            type: 'info',
            isLoading: true
          });
        } catch (error) {
          const errorMessage = `Error updating progress for player: ${player.email || player.customId || player.username} - ${error.message}`;
          toast.update(batchToastId, {
            render: errorMessage,
            type: 'error',
            isLoading: false
          });
        }
      });

      await Promise.all(updatePromises);
    }

    await fetchAllProgress();
    toast.update(batchToastId, {
      render: 'Progress updated successfully!',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
  } catch (error) {
    console.error('Error updating progress:', error);
    toast.update(batchToastId, {
      render: `Error updating progress: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setIsUpdatingProgressBatch(false);
  }
};


useEffect(() => {
  setProgressBatchSize(selectedProgressPlayers.length);
}, [selectedProgressPlayers]);














// Trigger update progress popup
const triggerUpdateProgressPopup = async () => {
  setGlobalLoading(true);
  try {
    if (!showUpdateProgressPopup) {
      await fetchAllProgress();
      document.body.classList.add('modal-open');
    } else {
      handleCloseUpdateProgressPopup();
    }
    setShowUpdateProgressPopup(!showUpdateProgressPopup);
  } catch (error) {
    console.error('Error handling update progress popup:', error);
  } finally {
    setGlobalLoading(false);
  }
};





const handleCloseUpdateProgressPopup = () => {
  document.body.classList.remove('modal-open'); // Remove the modal-open class
  setShowUpdateProgressPopup(false);
  setSelectedProgressPlayers([]);
  setSelectedProgressMarker('');
  setProgressAmount(0);
  setProgressOperator('add');
  setUpdatedProgress({});
  resetDropdown();
  setGlobalLoading(true);
  window.location.reload();
};


























const [showUpdateWalletPopup, setShowUpdateWalletPopup] = useState(false);
const [selectedCurrency, setSelectedCurrency] = useState('');
const [updatedWalletBalances, setUpdatedWalletBalances] = useState({});
const [selectAllWalletPlayers, setSelectAllWalletPlayers] = useState(false);
const [selectedWalletPlayers, setSelectedWalletPlayers] = useState([]);


const [selectedOperator, setSelectedOperator] = useState('add');
const [updateAmount, setUpdateAmount] = useState(0);



const [batchSize, setBatchSize] = useState(10);

const triggerUpdateWalletPopup = async () => {
  setGlobalLoading(true); // Start loading
  try {
    if (!showUpdateWalletPopup) {
      await fetchCurrencies(); // Ensure currencies are fetched
      await fetchAllWalletBalances(); // Fetch all wallet balances
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
      setSelectedWalletPlayers([]); // Reset selected players
      setSelectedCurrency(''); // Reset selected currency
      setUpdatedWalletBalances({}); // Reset updated wallet balances
    }
    setShowUpdateWalletPopup(!showUpdateWalletPopup);
  } catch (error) {
    console.error('Error handling update wallet popup:', error);
  } finally {
    setGlobalLoading(false); // End loading
  }
};



const fetchAllWalletBalances = async () => {
  try {
    const balances = {};
    for (const player of players) {
      const walletData = await handleFetchWallet(player.token, currencies.map(currency => currency.id));
      // Transforming the wallet data to the required format
      const playerBalances = walletData.reduce((acc, curr) => {
        acc[curr.id] = curr.balance;
        return acc;
      }, {});
      balances[player.id] = playerBalances;
    }
    setUpdatedWalletBalances(balances);
  } catch (error) {
    console.error('Error fetching all wallet balances:', error);
  }
};

const [walletUpdateStatusMessages, setWalletUpdateStatusMessages] = useState([]);
const [walletUpdateProgress, setWalletUpdateProgress] = useState('');
const handleUpdateWallets = async () => {
  setIsUpdatingWallets(true);
  const batchToastId = toast.loading(`Updating wallets for players: 0/${selectedWalletPlayers.length}`, {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  let updatedPlayersCount = 0;

  try {
    for (const playerId of selectedWalletPlayers) {
      const player = players.find(p => p.id === playerId);
      if (!player || !player.token) continue;

      try {
        await handleUpdateWallet(player.token, selectedCurrency, selectedOperator, updateAmount);
        updatedPlayersCount++;
        toast.update(batchToastId, {
          render: `Progress: ${updatedPlayersCount}/${selectedWalletPlayers.length} players updated.`,
          type: 'info',
          isLoading: true
        });
      } catch (error) {
        const errorMessage = `Error updating wallet for player: ${player.email || player.customId || player.username} - ${error.message}`;
        toast.update(batchToastId, {
          render: errorMessage,
          type: 'error',
          isLoading: false
        });
      }
    }

    await fetchAllWalletBalances();
    toast.update(batchToastId, {
      render: 'Wallets updated successfully!',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
  } catch (error) {
    console.error('Error updating wallets:', error);
    toast.update(batchToastId, {
      render: `Error updating wallets: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setIsUpdatingWallets(false);
  }
};

const handleUpdateWalletsBatch = async (batchSize) => {
  setIsUpdatingWalletsBatch(true);
  const batchToastId = toast.loading(`Updating wallets for players: 0/${selectedWalletPlayers.length}`, {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  let updatedPlayersCount = 0;

  try {
    for (let start = 0; start < selectedWalletPlayers.length; start += batchSize) {
      const batch = selectedWalletPlayers.slice(start, start + batchSize);

      const updatePromises = batch.map(async (playerId) => {
        const player = players.find(p => p.id === playerId);
        if (!player || !player.token) return;

        try {
          await handleUpdateWallet(player.token, selectedCurrency, selectedOperator, updateAmount);
          updatedPlayersCount++;
          toast.update(batchToastId, {
            render: `Progress: ${updatedPlayersCount}/${selectedWalletPlayers.length} players updated.`,
            type: 'info',
            isLoading: true
          });
        } catch (error) {
          const errorMessage = `Error updating wallet for player: ${player.email || player.customId || player.username} - ${error.message}`;
          toast.update(batchToastId, {
            render: errorMessage,
            type: 'error',
            isLoading: false
          });
        }
      });

      await Promise.all(updatePromises);
    }

    await fetchAllWalletBalances();
    toast.update(batchToastId, {
      render: 'Wallets updated successfully!',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
  } catch (error) {
    console.error('Error updating wallets:', error);
    toast.update(batchToastId, {
      render: `Error updating wallets: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setIsUpdatingWalletsBatch(false);
  }
};



useEffect(() => {
  setBatchSize(selectedWalletPlayers.length);
}, [selectedWalletPlayers]);



const handleCloseUpdateWalletPopup = () => {
  document.body.classList.remove('modal-open'); // Remove the modal-open class
  setShowUpdateWalletPopup(false);
  setSelectedWalletPlayers([]);
  setSelectedCurrency('');
  setUpdatedWalletBalances({});
  resetDropdown(); 
  setGlobalLoading(true);
  window.location.reload();
};









useEffect(() => {
  if (globalLoading) {
    document.body.classList.add('global-loading-active');
  } else {
    document.body.classList.remove('global-loading-active');
  }
}, [globalLoading]);









const fetchAppInfo = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': envApiKey,
    'Authorization': `Bearer ${memberToken}`,
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  try {
    const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/app/overview', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ projectId }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to fetch app info');
    }

    setAppInfo(data.data.appInfo);
  } catch (error) {
    console.error('Error fetching app info:', error);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  fetchAppInfo();
}, [envApiKey, memberToken, projectId]);
 
const [copySuccess, setCopySuccess] = useState(''); // 1. Added copySuccess state

  const handleCopy = () => {
    navigator.clipboard.writeText(appInfo.id).then(() => {
      setCopySuccess('Copied!'); // 2. Updated handleCopy function
      setTimeout(() => {
        setCopySuccess('');
        // Reset the copy image or perform any other required reset actions
      }, 2000); // Clear the message after 2 seconds
    });
  };



  const navigate = useNavigate();

  useEffect(() => {
    if (players.length > 0) {
      setActiveTab(0); // Set the first tab as active when players are signed up
    }
  }, [players]);



  useEffect(() => {
    const tabElement = document.querySelector('.tabs');
    if (tabElement) {
      if (players.length > 12) {
        tabElement.classList.remove('center');
      } else {
        tabElement.classList.add('center');
      }
    }
  }, [players]);

  

  useEffect(() => {
    const fetchCustomEvents = async () => {
      const headers = {
        'Content-Type': 'application/json',
        'Api-Key': envApiKey,
        'Authorization': `Bearer ${memberToken}`,
        'env': selectedEnv,
        ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
      };
      
  
      let allEvents = [];
      let offset = 0;
      const limit = 10; // Set the limit to the number of events you want to fetch per request
      let hasMoreEvents = true;
  
      try {
        while (hasMoreEvents) {
          const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/app-event/get/custom`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
              projectId,
              limit: limit,
              offset: offset,
            }),
          });
  
          const data = await response.json();
          if (!response.ok) {
            throw new Error(data.message || 'Failed to fetch custom events');
          }
  
          allEvents = [...allEvents, ...data.data.appEventDetails];
  
          if (data.data.appEventDetails.length < limit) {
            hasMoreEvents = false;
          } else {
            offset += limit;
          }
        }
  
        setCustomEvents(allEvents);
      } catch (error) {
        console.error('Error fetching custom events:', error);
      }
    };
  
    fetchCustomEvents();
  }, [envApiKey, memberToken, projectId, selectedEnv]);
  

const handleSelectEvent = (eventId) => {
  const selectedEvent = customEvents.find(event => event.eventId === eventId);
  if (selectedEvent) {
    const parameters = [
      {
        name: 'eventId',
        value: selectedEvent.eventId,
        type: 'state',
        dataType: 'string',
        editable: false,
        category: 'default'
      },
      ...(selectedEvent.specterParameterDetails ? selectedEvent.specterParameterDetails.map(param => ({
        name: param.name,
        value: '',
        type: param.type,
        dataType: param.dataType.dataTypeName,
        editable: true,
        category: 'specter'
      })) : []),
      ...(selectedEvent.customParameterDetails ? selectedEvent.customParameterDetails.map(param => ({
        name: param.name,
        value: '',
        type: param.type,
        dataType: param.dataType.dataTypeName,
        editable: true,
        category: 'custom'
      })) : [])
    ];
    setSelectedEvent(selectedEvent);
    setEventParams(parameters);
  }
};


const handleParamChange = (index, value) => {
  const newParams = [...eventParams];
  const param = newParams[index];
  if (param.dataType === 'integer') {
    param.value = parseInt(value, 10);
  } else if (param.dataType === 'float') {
    param.value = parseFloat(value);
  } else if (param.dataType === 'boolean') {
    param.value = value === "" ? "" : (value === "true");
  } else {
    param.value = value;
  }
  setEventParams(newParams);
};

const [isFiringEvent, setIsFiringEvent] = useState(false);


const handleFireEvent = async () => {
  const specterParams = {};
  const customParams = {};

  eventParams.forEach(param => {
    if (param.value !== '') {
      const formattedValue = param.dataType === 'integer' ? parseInt(param.value, 10) :
                             param.dataType === 'boolean' ? (param.value === 'true') :
                             param.value;

      if (param.category === 'specter') {
        specterParams[param.name] = formattedValue;
      } else if (param.category === 'custom') {
        customParams[param.name] = formattedValue;
      }
    }
  });

  const payload = {
    eventId: selectedEvent.eventId,
    specterParams: Object.keys(specterParams).length > 0 ? specterParams : null,
    customParams: Object.keys(customParams).length > 0 ? customParams : null,
    projectId: projectId
  };

  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': apiKey,
    'Authorization': `Bearer ${players[activeTab].token}`,
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  setIsFiringEvent(true);
  const toastId = toast.loading('Firing custom event...', {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  try {
    const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/events/send-custom`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to fire custom event');
    }
    toast.update(toastId, {
      render: 'Custom Event Fired Successfully',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
  } catch (error) {
    console.error('Error firing custom event:', error);
    toast.update(toastId, {
      render: `Error: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setIsFiringEvent(false);
  }
};

const handleClearFields = () => {
  const clearedParams = eventParams.map(param => {
    if (param.name === 'eventId') {
      return param; // Don't clear the eventId field
    } else {
      return { ...param, value: '' }; // Clear other fields
    }
  });
  setEventParams(clearedParams);
};


// Place this function inside the PlayerManagementPage component, but outside of useEffect
const fetchCurrencies = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': apiKey,
    'Authorization': `Bearer ${players[0].token}`, // Use the token from the first player for authorization
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  let allCurrencies = [];
  let offset = 0;
  const limit = 10; // Set the limit to the number of currencies you want to fetch per request
  let hasMoreCurrencies = true;

  try {
    while (hasMoreCurrencies) {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-currencies`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
         projectId,
          limit: limit,
          offset: offset,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch currencies');
      }

      allCurrencies = [...allCurrencies, ...data.data.currencies];

      if (data.data.currencies.length < limit) {
        hasMoreCurrencies = false;
      } else {
        offset += limit;
      }
    }

    setCurrencies(allCurrencies);
  } catch (error) {
    console.error('Error fetching currencies:', error);
  }
};




useEffect(() => {
  const fetchInitialData = async () => {
    try {
      await fetchCurrencies(); // Ensure this function is in the correct scope
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  fetchInitialData();
}, [apiKey, projectId, players]);

  const handleFetchProgress = async (token, uuid) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Api-Key': apiKey,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
    

    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/progression/get-progress`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ uuid }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch progress');
      }

      return data.data;
    } catch (error) {
      console.error('Error fetching progress:', error);
    }
  };

  const handleUpdateMarker = async (token, progressionMarkerId, operation, amount, customParams) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Api-Key': apiKey,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
    

    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/progression/update-marker`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          progressionMarkerId,
          operation,
          amount,
          customParams, // Include custom parameters
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to update marker');
      }

      return data.data;
    } catch (error) {
      console.error('Error updating marker:', error);
    }
  };

  const handleFetchWallet = async (token, currencyIds) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Api-Key': apiKey,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
    

    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/wallet/get-balance`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ currencyIds }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch wallet balances');
      }

      return data.data;
    } catch (error) {
      console.error('Error fetching wallet:', error);
    }
  };

  const handleUpdateWallet = async (token, currencyId, operation, amount, customParams) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Api-Key': apiKey,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
    

    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/wallet/update-balance`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          currencyId,
          operation,
          amount,
          customParams, // Include custom parameters
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to update wallet');
      }

      return data.data;
    } catch (error) {
      console.error('Error updating wallet:', error);
    }
  };

  const handleFetchMatches = async () => {
    let playerToken;
  
    if (masterPlayer) {
      const masterPlayerObject = players.find(player => player.id === masterPlayer);
      if (masterPlayerObject) {
        playerToken = masterPlayerObject.token;
      }
    }
  
    // If no master player is selected or found, use the token of the first player
    if (!playerToken) {
      playerToken = players[0].token;
    }
  
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${playerToken}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
    
    let allMatches = [];
    let offset = 0;
    const limit = 10; // Set the limit to the number of matches you want to fetch per request
    let hasMoreMatches = true;
  
    try {
      while (hasMoreMatches) {
        const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-matches`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ projectId, limit, offset }),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch matches');
        }
  
        allMatches = [...allMatches, ...data.data.matches];
  
        if (data.data.matches.length < limit) {
          hasMoreMatches = false; // If fewer matches are returned than the limit, there are no more matches
        } else {
          offset += limit; // Increase the offset for the next batch
        }
      }
  
      setMatches(allMatches);
    } catch (error) {
      console.error('Error fetching matches:', error);
    }
  };
  
  const handleFetchCompetitions = async () => {
    const playerToken = players[0].token;
  
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${playerToken}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
    
  
    let allCompetitions = [];
    let offset = 0;
    const limit = 10; // Set the limit to the number of competitions you want to fetch per request
    let hasMoreCompetitions = true;
  
    try {
      while (hasMoreCompetitions) {
        const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-competitions`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
           // projectId,
            limit: limit,
            offset: offset,
          }),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch competitions');
        }
  
        const competitions = data.data.competitions.filter(comp => comp.match && comp.match.name);
        allCompetitions = [...allCompetitions, ...competitions];
  
        if (data.data.competitions.length < limit) {
          hasMoreCompetitions = false;
        } else {
          offset += limit;
        }
      }
  
      setCompetitions(allCompetitions);
    } catch (error) {
      console.error('Error fetching competitions:', error);
    }
  };
  const handleEnterCompetition = async () => {
    setIsEnteringCompetition(true);
    const totalPlayers = selectedPlayers.length;
    let enteredPlayersCount = 0;
  
    const competitionToastId = toast.loading(`Entering competition for players: 0/${totalPlayers}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
      closeButton: <CustomCloseButton />
    });
  
    try {
      for (const playerId of selectedPlayers) {
        const player = players.find(p => p.id === playerId);
        if (!player || !player.token) {
          console.error(`Player or token not found for player ID ${playerId}`);
          continue;
        }
  
        const headers = {
          'Content-Type': 'application/json',
          'Api-Key': apiKey,
          'Authorization': `Bearer ${player.token}`,
          'env': selectedEnv,
          ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
        };
  
        const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/competitions/enter`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            competitionId: selectedCompetition,
            projectId,
          }),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to enter competition');
        }
  
        const newEntry = {
          entryId: data.data.entryId,
          playerId: player.id,
          player: player.email || player.customId || player.username,
          competitionName: competitions.find(comp => comp.id === selectedCompetition).name,
          competitionInstanceId: data.data.competitionInstanceId,
        };
        console.log('New Entry:', newEntry);
  
        setEntries(prevEntries => [...prevEntries, newEntry]);
  
        enteredPlayersCount++;
        toast.update(competitionToastId, {
          render: `Entering competition for players: ${enteredPlayersCount}/${totalPlayers}`,
          type: 'info',
          isLoading: true,
        });
      }
      toast.update(competitionToastId, {
        render: 'Entries created successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      });
      setIsEnterCompetitionSuccess(true);
    } catch (error) {
      console.error('Error entering competition:', error);
      toast.update(competitionToastId, {
        render: `Error entering competition: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      setIsEnterCompetitionSuccess(false);
    } finally {
      setIsEnteringCompetition(false);
    }
  };
  

  const [enterCompetitionBatchSize, setEnterCompetitionBatchSize] = useState(0);
  const [enterCompetitionBatchUpdateStatusMessages, setEnterCompetitionBatchUpdateStatusMessages] = useState([]);
  const [enterCompetitionBatchUpdateProgress, setEnterCompetitionBatchUpdateProgress] = useState('');

  
  useEffect(() => {
    setEnterCompetitionBatchSize(selectedPlayers.length);
  }, [selectedPlayers]);

 


  const handleEnterCompetitionBatch = async () => {
    setIsEnteringCompetitionBatch(true);
  
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    let updatedPlayersCount = 0;
  
    const batchToastId = toast.loading(`Entering competition for players: 0/${selectedPlayers.length}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
  
    try {
      for (let start = 0; start < selectedPlayers.length; start += enterCompetitionBatchSize) {
        const batch = selectedPlayers.slice(start, start + enterCompetitionBatchSize);
  
        const updatePromises = batch.map(async (playerId) => {
          const player = players.find(p => p.id === playerId);
          if (!player || !player.token) {
            const errorMessage = `Player or token not found for player ID ${playerId}`;
            toast.update(batchToastId, {
              render: errorMessage,
              type: 'error',
              isLoading: false
            });
            return;
          }
  
          try {
            const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/competitions/enter`, {
              method: 'POST',
              headers: {
                ...headers,
                'Authorization': `Bearer ${player.token}`,
              },
              body: JSON.stringify({
                competitionId: selectedCompetition,
                projectId,
              }),
            });
  
            const data = await response.json();
            if (!response.ok) {
              throw new Error(data.message || 'Failed to enter competition');
            }
  
            const newEntry = {
              entryId: data.data.entryId,
              playerId: player.id,
              player: player.email || player.customId || player.username,
              competitionName: competitions.find(comp => comp.id === selectedCompetition).name,
              competitionInstanceId: data.data.competitionInstanceId,
            };
            console.log('New Entry:', newEntry);
  
            setEntries(prevEntries => [...prevEntries, newEntry]);
  
            updatedPlayersCount++;
            const successMessage = `Progress: ${updatedPlayersCount}/${selectedPlayers.length} players entered`;
            toast.update(batchToastId, {
              render: successMessage,
              type: 'info',
              isLoading: true
            });
            
          } catch (error) {
            const errorMessage = `Error entering competition for player: ${player.email || player.customId || player.username} - ${error.message}`;
            toast.update(batchToastId, {
              render: errorMessage,
              type: 'error',
              isLoading: false
            });
          }
        });
  
        await Promise.all(updatePromises);
      }
  
      toast.update(batchToastId, {
        render: 'Entry created successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
      setCompetitionStatus('Entries created successfully!');
      setIsEnterCompetitionBatchSuccess(true);
      handleNextCompetitionStage('entries');
    } catch (error) {
      const errorMessage = `Error entering competition: ${error.message}`;
      toast.update(batchToastId, {
        render: errorMessage,
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
      setCompetitionStatus(errorMessage);
      setIsEnterCompetitionBatchSuccess(false);
  } finally {
    setIsEnteringCompetitionBatch(false);
    }
  };
  
  const [enterCustomCompetitionBatchSize, setEnterCustomCompetitionBatchSize] = useState(0);
const [enterCustomCompetitionBatchUpdateStatusMessages, setEnterCustomCompetitionBatchUpdateStatusMessages] = useState([]);
const [enterCustomCompetitionBatchUpdateProgress, setEnterCustomCompetitionBatchUpdateProgress] = useState('');

useEffect(() => {
  setEnterCustomCompetitionBatchSize(selectedCustomTournamentPlayers.length);
}, [selectedCustomTournamentPlayers]);
const handleEnterCustomCompetitionBatch = async () => {
  setIsEnteringCustomCompetitionBatch(true);

  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': apiKey,
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  let updatedPlayersCount = 0;

  const batchToastId = toast.loading(`Entering competition for players: 0/${selectedCustomTournamentPlayers.length}`, {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  try {
    for (let start = 0; start < selectedCustomTournamentPlayers.length; start += enterCustomCompetitionBatchSize) {
      const batch = selectedCustomTournamentPlayers.slice(start, start + enterCustomCompetitionBatchSize);

      const updatePromises = batch.map(async (playerId) => {
        const player = players.find(p => p.id === playerId);
        if (!player || !player.token) {
          const errorMessage = `Player or token not found for player ID ${playerId}`;
          toast.update(batchToastId, {
            render: errorMessage,
            type: 'error',
            isLoading: false
          });
          return;
        }

        try {
          const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/competitions/enter`, {
            method: 'POST',
            headers: {
              ...headers,
              'Authorization': `Bearer ${player.token}`,
            },
            body: JSON.stringify({
              competitionId: selectedTournament,
              projectId,
            }),
          });

          const data = await response.json();
          if (!response.ok) {
            throw new Error(data.message || 'Failed to enter competition');
          }

          const newEntry = {
            entryId: data.data.entryId,
            playerId: player.id,
            player: player.email || player.customId || player.username,
            competitionName: customTournaments.find(tournament => tournament.id === selectedTournament).name,
            competitionInstanceId: data.data.competitionInstanceId,
          };

          setCustomTournamentEntries(prevEntries => [...prevEntries, newEntry]);

          updatedPlayersCount++;
          const successMessage = `Progress: ${updatedPlayersCount}/${selectedCustomTournamentPlayers.length} players entered`;
          toast.update(batchToastId, {
            render: successMessage,
            type: 'info',
            isLoading: true
          });

        } catch (error) {
          const errorMessage = `Error entering competition for player: ${player.email || player.customId || player.username} - ${error.message}`;
          toast.update(batchToastId, {
            render: errorMessage,
            type: 'error',
            isLoading: false
          });
        }
      });

      await Promise.all(updatePromises);
    }

    toast.update(batchToastId, {
      render: 'Entry created successfully!',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
    setTournamentStatus('Entries created successfully!');
    setIsEnterCustomCompetitionBatchSuccess(true);
    handleNextCustomTournamentStage('entries');
  } catch (error) {
    const errorMessage = `Error entering competition: ${error.message}`;
    toast.update(batchToastId, {
      render: errorMessage,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
    setTournamentStatus(errorMessage);
    setIsEnterCustomCompetitionBatchSuccess(false);
  } finally {
    setIsEnteringCustomCompetitionBatch(false);
  }
};











const handleEnterCustomCompetition = async () => {
  setIsEnteringCustomCompetition(true);
  const totalPlayers = selectedCustomTournamentPlayers.length;
  let enteredPlayersCount = 0;

  const customCompetitionToastId = toast.loading(`Entering competition for players: 0/${totalPlayers}`, {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
    closeButton: <CustomCloseButton />
  });

  try {
    for (const playerId of selectedCustomTournamentPlayers) {
      const player = players.find(p => p.id === playerId);
      if (!player || !player.token) {
        console.error(`Player or token not found for player ID ${playerId}`);
        continue;
      }

      const headers = {
        'Content-Type': 'application/json',
        'Api-Key': apiKey,
        'Authorization': `Bearer ${player.token}`,
        'env': selectedEnv,
        ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
      };
      
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/competitions/enter`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          competitionId: selectedTournament,
          projectId,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to enter competition');
      }

      const newEntry = {
        entryId: data.data.entryId,
        playerId: player.id,
        player: player.email || player.customId || player.username,
        competitionName: customTournaments.find(tournament => tournament.id === selectedTournament).name,
        competitionInstanceId: data.data.competitionInstanceId,
      };

      setCustomTournamentEntries(prevEntries => [...prevEntries, newEntry]);

      enteredPlayersCount++;
      toast.update(customCompetitionToastId, {
        render: `Entering competition for players: ${enteredPlayersCount}/${totalPlayers}`,
        type: 'info',
        isLoading: true,
      });
    }
    toast.update(customCompetitionToastId, {
      render: 'Entries created successfully!',
      type: 'success',
      isLoading: false,
      autoClose: 5000,
    });
    setIsEnterCustomCompetitionSuccess(true);
  } catch (error) {
    console.error('Error entering competition:', error);
    toast.update(customCompetitionToastId, {
      render: `Error entering competition: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000,
    });
    setIsEnterCustomCompetitionSuccess(false);
  } finally {
    setIsEnteringCustomCompetition(false);
  }
};

  

  const formatCustomParams = (params) => {
    const formattedParams = {};
    params.forEach(param => {
      if (param.name && param.value) {
        const key = param.name; // Ensure the parameter name is treated as a string
        const value = param.type === 'number' ? Number(param.value) : param.type === 'boolean' ? param.value === 'true' : param.value;
        formattedParams[`"${key}"`] = value; // Wrap the key in double quotes
      }
    });
    return formattedParams;
  };

  const handleCreateMatchSession = async () => {
    setCustomParamsMode('create');
    setShowMatchCustomParamsPopup(true);
  };

  const handleSubmitCreateMatchSession = async () => {
    setIsCreatingMatch(true);
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${players.find(player => player.id === masterPlayer).token}`, // Use the token from the master player for authorization
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };

    const userInfo = selectedPlayers.map(playerId => ({
      id: playerId,
      customParams: formatCustomParams(matchCustomParams[playerId] || [])
    }));

    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/matches/create-session`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          matchId: selectedMatch,
          userInfo,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to create match session');
      }

      setMatchSessionId(data.data.matchSessionId);
      toast.success('Match created successfully!', {
        className: 'neu-toast neu-toast-success',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setIsCreateMatchSuccess(true); // Set success state
      setShowMatchCustomParamsPopup(false);
      setMatchCustomParams({}); // Clear custom parameters
    } catch (error) {
      console.error('Error creating match session:', error);
      toast.error('Error creating match session.', {
        className: 'neu-toast neu-toast-error',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setIsCreateMatchSuccess(false); // Ensure success state is false
    } finally {
      setIsCreatingMatch(false);
    }
  };


  const handleStartMatchSession = async () => {
    setCustomParamsMode('start');
    setShowMatchCustomParamsPopup(true);
  };

  const handleSubmitStartMatchSession = async () => {
    setIsStartingMatch(true);
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${players.find(player => player.id === masterPlayer).token}`, // Use the token from the master player for authorization
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
};

    const userInfo = selectedPlayers.map(playerId => ({
      id: playerId,
      customParams: formatCustomParams(matchCustomParams[playerId] || [])
    }));

    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/matches/start-session`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          matchSessionId,
          userInfo,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to start match session');
      }

      toast.success('Match started successfully!', {
        className: 'neu-toast neu-toast-success',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setIsStartMatchSuccess(true); // Set success state
      setShowMatchCustomParamsPopup(false);
      setMatchCustomParams({}); // Clear custom parameters
    } catch (error) {
      console.error('Error starting match session:', error);
      toast.error('Error starting match session.', {
        className: 'neu-toast neu-toast-error',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setIsStartMatchSuccess(false); // Ensure success state is false
    } finally {
      setIsStartingMatch(false);
    }
  };
  
  const handleEndMatchSession = async () => {
    setShowEndMatchPopup(true);
  };

  const handleSubmitEndMatchSession = async () => {
    setIsEndingMatch(true);
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${players.find(player => player.id === masterPlayer).token}`, // Use the token from the master player for authorization
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };

    const userInfo = selectedPlayers.map(playerId => ({
      id: playerId,
      outcome: outcomes[playerId] || 0,
      customParams: formatCustomParams(matchCustomParams[playerId] || [])
    }));

    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/matches/end-session`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          matchSessionId,
          userInfo,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to end match session');
      }

      toast.success('Match ended successfully!', {
        className: 'neu-toast neu-toast-success',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setIsEndMatchSuccess(true); // Set success state
      setShowEndMatchPopup(false);
      setMatchCustomParams({}); // Clear custom parameters
     
    } catch (error) {
      console.error('Error ending match session:', error);
      toast.error('Error ending match session.', {
        className: 'neu-toast neu-toast-error',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setIsEndMatchSuccess(false); // Ensure success state is false
    } finally {
      setIsEndingMatch(false);
    }
  };


  const handleFetchMatchResults = async () => {
    setIsFetchingResults(true);
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${players.find(player => player.id === masterPlayer).token}`, // Use the token from the master player for authorization
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/matches/get-session-details`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          matchSessionIds: [matchSessionId],
          offset: 0,
          limit: 10,
        }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch match results');
      }
  
      setMatchResults(data.data);
      toast.success('Match results fetched successfully!', {
        className: 'neu-toast neu-toast-success',
        progressClassName: 'neu-toast-progress',
        closeButton: <CustomCloseButton />
      });
      setMatchPopupStage('results'); // Proceed to results stage
    } catch (error) {
      console.error('Error fetching match results:', error);
      toast.error('Error fetching match results.', {
        className: 'neu-toast neu-toast-error',
        progressClassName: 'neu-toast-progress',
        closeButton: <CustomCloseButton />
      });
    } finally {
      setIsFetchingResults(false);
    }
  };
  

  const [showConfetti, setShowConfetti] = useState(false);


useEffect(() => {
  if (matchPopupStage === 'results') {
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
  }
}, [matchPopupStage]);



// Function to get window size
const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { width: innerWidth, height: innerHeight };
};

const [windowSize, setWindowSize] = useState(getWindowSize());

useEffect(() => {
  const handleResize = () => {
    setWindowSize(getWindowSize());
  };

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);


  const [isFetchingResults, setIsFetchingResults] = useState(false);
  const [matchResults, setMatchResults] = useState([]);





  const handleSubmitStartCompetition = async () => {
    setIsStartingCompetition(true);
    const masterPlayerObject = players.find(player => player.id === masterPlayer);

   
  if (!masterPlayerObject || !masterPlayerObject.token) {
    toast.error('Master player token not found.', {
      className: 'neu-toast neu-toast-error',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
    setCompetitionStatus('Master player token not found.');
    setIsStartingCompetition(false);
    return;
  }
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${masterPlayerObject.token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };

    const selectedCompetitionObject = competitions.find(comp => comp.id === selectedCompetition);

    if (!selectedCompetitionObject) {
      toast.error('Selected competition not found.', {
        className: 'neu-toast neu-toast-error',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setCompetitionStatus('Selected competition not found.');
      setIsStartingCompetition(false);
      return;
    }
  

    const matchId = selectedCompetitionObject.match && selectedCompetitionObject.match.id ? selectedCompetitionObject.match.id : '';

    const userInfo = selectedEntries.map(entryId => {
      const entry = entries.find(e => e.entryId === entryId);
      if (!entry) {
        toast.error(`Entry with ID ${entryId} not found.`, {
          className: 'neu-toast neu-toast-error',
          progressClassName: 'neu-toast-progress',
         closeButton: <CustomCloseButton /> 
        });
        setCompetitionStatus(`Entry with ID ${entryId} not found`);
        setIsStartingCompetition(false);
        return null;
      }
      const player = players.find(p => p.id === entry.playerId);
      if (!player) {
        toast.error(`Player with ID ${entry.playerId} not found.`, {
          className: 'neu-toast neu-toast-error',
          progressClassName: 'neu-toast-progress',
         closeButton: <CustomCloseButton /> 
        });
        setCompetitionStatus(`Player with ID ${entry.playerId} not found`);
        setIsStartingCompetition(false);
        return null;
      }
  

      return {
        entryId: entry.entryId,
        id: player.id,
        customParams: formatCustomParams(competitionCustomParams[entryId] || [])
      };
    }).filter(info => info !== null);

    if (userInfo.length === 0) {
      toast.error('No valid entries or players found.', {
        className: 'neu-toast neu-toast-error',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setCompetitionStatus('No valid entries or players found');
      setIsStartingCompetition(false);
      return;
    }
  
    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/matches/start-session`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          competitionId: selectedCompetition,
          matchId,
          userInfo,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to start this match of the competition');
      }

      setCompetitionSessionId(data.data.matchSessionId); // Store the matchSessionId
    
      toast.success('This match of the competition started successfully!', {
        className: 'neu-toast neu-toast-success',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setIsStartCompetitionSuccess(true);
      setCompetitionCustomParams({});
    } catch (error) {
      console.error('Error starting this match of the competition:', error);
      toast.error(`Error starting this match of the competition: ${error.message}`, {
        className: 'neu-toast neu-toast-error',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
    
      setIsStartCompetitionSuccess(false);
    } finally {
      setIsStartingCompetition(false);
    }
  };

  const handleEndCompetition = async () => {
    setShowEndCompetitionPopup(true);
  };

  const handleSubmitEndCompetition = async () => {
    setIsEndingCompetition(true);
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${players.find(player => player.id === masterPlayer).token}`, // Use the token from the master player for authorization
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    const userInfo = selectedEntries.map(entryId => {
      const entry = entries.find(e => e.entryId === entryId);
      return {
        entryId: entry.entryId,
        id: entry.playerId, // Ensure the playerId is included in the userInfo object
        outcome: outcomes[entry.entryId] || 0, // Include the outcome
        customParams: formatCustomParams(competitionCustomParams[entry.entryId] || [])
      };
    });
  
    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/matches/end-session`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          matchSessionId: competitionSessionId, // Use the matchSessionId from the start competition
          userInfo,
        }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to end the match of the competition');
      }
  
   
      toast.success('This match of the competition ended successfully!', {
        className: 'neu-toast neu-toast-success',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      setIsEndCompetitionSuccess(true);
      setShowEndCompetitionPopup(false);
      setCompetitionCustomParams({}); // Clear custom parameters
    
    } catch (error) {
      console.error('Error ending the match of the competition:', error);
      toast.error(`Error ending the match of the competition: ${error.message}`, {
        className: 'neu-toast neu-toast-error',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
     
      setIsEndCompetitionSuccess(false);
    } finally {
      setIsEndingCompetition(false);
    }
  };


  const [competitionResults, setCompetitionResults] = useState([]);
  
  
  // Trigger the confetti animation when the results stage is reached
  useEffect(() => {
    if (competitionPopupStage === 'results') {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
    }
  }, [competitionPopupStage]);
  

  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

// Fetch competition results
const handleFetchCompetitionResults = async () => {
  setIsFetchingResults(true);
  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': apiKey,
    'Authorization': `Bearer ${players.find(player => player.id === masterPlayer).token}`, // Use the token from the master player for authorization
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  try {
    const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/matches/get-session-details`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        matchSessionIds: [competitionSessionId],
        offset: 0,
        limit: 10,
      }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to fetch competition results');
    }

    setCompetitionResults(data.data);
    toast.success('Competition results fetched successfully!', {
      className: 'neu-toast neu-toast-success',
      progressClassName: 'neu-toast-progress',
      closeButton: <CustomCloseButton />
    });
    setCompetitionPopupStage('results'); // Proceed to results stage
  } catch (error) {
    console.error('Error fetching competition results:', error);
    toast.error('Error fetching competition results.', {
      className: 'neu-toast neu-toast-error',
      progressClassName: 'neu-toast-progress',
      closeButton: <CustomCloseButton />
    });
  } finally {
    setIsFetchingResults(false);
  }
};










  //hi
  // Function to reset competition states
const resetCompetitionState = () => {
  setCompetitionCustomParams({});
  setSelectedEntries([]);
};

  const handleAddCustomParam = (type, entryId) => {
    const setters = {
      match: setMatchCustomParams,
      addInventory: setAddInventoryCustomParams,
      consumeInventory: setConsumeInventoryCustomParams,
      equipInventory: setEquipInventoryCustomParams,
      competition: setCompetitionCustomParams,
    };

    const currentParams = {
      match: matchCustomParams,
      addInventory: addInventoryCustomParams,
      consumeInventory: consumeInventoryCustomParams,
      equipInventory: equipInventoryCustomParams,
      competition: competitionCustomParams,
    };

    const newParams = [...(currentParams[type][entryId] || []), { name: '', value: '', type: 'string' }];
    setters[type]({
      ...currentParams[type],
      [entryId]: newParams,
    });
  };

  const handleRemoveCustomParam = (type, entryId, index) => {
    const setters = {
      match: setMatchCustomParams,
      addInventory: setAddInventoryCustomParams,
      consumeInventory: setConsumeInventoryCustomParams,
      equipInventory: setEquipInventoryCustomParams,
      competition: setCompetitionCustomParams,
    };

    const currentParams = {
      match: matchCustomParams,
      addInventory: addInventoryCustomParams,
      consumeInventory: consumeInventoryCustomParams,
      equipInventory: equipInventoryCustomParams,
      competition: competitionCustomParams,
    };

    const newParams = currentParams[type][entryId].filter((_, idx) => idx !== index);
    setters[type]({
      ...currentParams[type],
      [entryId]: newParams,
    });
  };

  const handleCustomParamChange = (type, entryId, index, field, value) => {
    const setters = {
      match: setMatchCustomParams,
      addInventory: setAddInventoryCustomParams,
      consumeInventory: setConsumeInventoryCustomParams,
      equipInventory: setEquipInventoryCustomParams,
      competition: setCompetitionCustomParams,
    };

    const currentParams = {
      match: matchCustomParams,
      addInventory: addInventoryCustomParams,
      consumeInventory: consumeInventoryCustomParams,
      equipInventory: equipInventoryCustomParams,
      competition: competitionCustomParams,
    };

    const newParams = [...currentParams[type][entryId]];
    newParams[index][field] = value;
    setters[type]({
      ...currentParams[type],
      [entryId]: newParams,
    });
  };

  const [attemptsLeft, setAttemptsLeft] = useState({});

  const handleCheckAttempts = async (entryId) => {
    const entry = entries.find(e => e.entryId === entryId);
    if (!entry) {
      console.error(`Entry with ID ${entryId} not found`);
      setCompetitionStatus(`Entry with ID ${entryId} not found`);
      return;
    }
  
    const player = players.find(p => p.id === entry.playerId);
    if (!player || !player.token) {
      console.error(`Player or token not found for player ID ${entry.playerId}`);
      setCompetitionStatus(`Player or token not found for player ID ${entry.playerId}`);
      return;
    }
  
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${player.token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/competitions/check-attempts`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          entryId,
        }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to check attempts');
      }
  
      setAttemptsLeft(prev => ({
        ...prev,
        [entryId]: data.data.numberOfAttemptsLeft
      }));
     // Clear the attempts left after 5 seconds
    setTimeout(() => {
      setAttemptsLeft(prev => {
        const newAttemptsLeft = { ...prev };
        delete newAttemptsLeft[entryId];
        return newAttemptsLeft;
      });
    }, 5000);
  } catch (error) {
    console.error('Error checking attempts:', error);
  }
};

  const handleEndSession = () => {
    navigate('/member-login');
  };

  const handleAddInventoryRow = (type) => {
    if (type === 'item') {
      setSelectedItems([...selectedItems, { id: '', amount: 1, stackId: '', collectionId: '' }]);
    } else if (type === 'bundle') {
      setSelectedBundles([...selectedBundles, { id: '', amount: 1, stackId: '', collectionId: '' }]);
    }
  };

  const handleRemoveInventoryRow = (type, index) => {
    if (type === 'item') {
      const newItems = selectedItems.filter((_, idx) => idx !== index);
      setSelectedItems(newItems);
    } else if (type === 'bundle') {
      const newBundles = selectedBundles.filter((_, idx) => idx !== index);
      setSelectedBundles(newBundles);
    }
  };

  const handleInventoryInputChange = (type, index, field, value) => {
    if (type === 'item') {
      const newItems = [...selectedItems];
      newItems[index][field] = value;
      setSelectedItems(newItems);
    } else if (type === 'bundle') {
      const newBundles = [...selectedBundles];
      newBundles[index][field] = value;
      setSelectedBundles(newBundles);
    }
  };

  const handleAddInventoryItem = async () => {
    setCustomParamsMode('addInventory');
    setShowAddInventoryCustomParamsPopup(true);
  };


  const handleSubmitAddInventoryItem = async () => {
    setIsLoading(true);
    const toastId = toast.loading('Processing add to inventory...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
      closeButton: <CustomCloseButton />
    });
  
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${players[activeTab].token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    try {
      const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/inventory/add', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          items: selectedItems.map(item => ({
            ...item,
            customParams: formatCustomParams(addInventoryCustomParams[item.id] || [])
          })),
          bundles: selectedBundles.map(bundle => ({
            ...bundle,
            customParams: formatCustomParams(addInventoryCustomParams[bundle.id] || [])
          })),
        }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to add inventory items');
      }
  
     
      toast.update(toastId, {
        render: 'Successfully added to User Inventory',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeButton: <CustomCloseButton />
      });
      setSelectedItems([]);
      setSelectedBundles([]);
      setShowInventoryPopup(false);
      setShowAddInventoryCustomParamsPopup(false);
      setAddInventoryCustomParams({});
    } catch (error) {
      
      toast.update(toastId, {
        render: `Error adding inventory items: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeButton: <CustomCloseButton />
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleFetchInventory = async (token) => {
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    let allItems = [];
    let offset = 0;
    const limit = 10; // Set the limit to the number of items you want to fetch per request
    let hasMoreItems = true;
  
    try {
      while (hasMoreItems) {
        const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/inventory/get-inventory`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            projectId,
            limit: limit,
            offset: offset,
          }),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch inventory');
        }
  
        allItems = [...allItems, ...data.data.items];
  
        if (data.data.items.length < limit) {
          hasMoreItems = false;
        } else {
          offset += limit;
        }
      }
  
      setInventoryItems(allItems);
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  };
  
 // Handlers for Consume Item
 const handleAddConsumeItemRow = () => {
  setSelectedConsumeItems([...selectedConsumeItems, { instanceId: '', id: '', amount: 1, stackId: '', collectionId: '' }]);
};

const handleRemoveConsumeItemRow = (index) => {
  const newItems = selectedConsumeItems.filter((_, idx) => idx !== index);
  setSelectedConsumeItems(newItems);
};

const handleConsumeItemInputChange = (index, field, value) => {
  const newItems = [...selectedConsumeItems];
  newItems[index][field] = value;

  if (field === 'instanceId') {
    const selectedItem = inventoryItems.find(item => item.instanceId === value);
    newItems[index]['id'] = selectedItem ? selectedItem.id : '';
  }

  setSelectedConsumeItems(newItems);
};

const handleConsumeItem = async () => {
  setCustomParamsMode('consume');
  setShowConsumeInventoryCustomParamsPopup(true);
};
const handleSubmitConsumeItem = async () => {
  setIsLoading(true);
  const toastId = toast.loading('Processing consume item...', {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
    closeButton: <CustomCloseButton />
  });

  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': apiKey,
    'Authorization': `Bearer ${players[activeTab].token}`,
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  try {
    const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/inventory/consume-item', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        items: selectedConsumeItems.map(item => ({
          instanceId: item.instanceId,
          id: item.id,
          amount: item.amount,
          stackId: item.stackId,
          collectionId: item.collectionId,
          customParams: formatCustomParams(consumeInventoryCustomParams[item.instanceId] || [])
        })),
      }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to consume item');
    }

   
    toast.update(toastId, {
      render: 'Item consumed successfully',
      type: 'success',
      isLoading: false,
      autoClose: 5000,
      closeButton: <CustomCloseButton />
    });
    setSelectedConsumeItems([]);
    setShowInventoryPopup(false);
    setShowConsumeInventoryCustomParamsPopup(false);
    setConsumeInventoryCustomParams({});
  } catch (error) {
    
    toast.update(toastId, {
      render: `Error consuming item: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000,
      closeButton: <CustomCloseButton />
    });
  } finally {
    setIsLoading(false);
  }
};


// Handlers for Equip/Unequip Item
const handleAddEquipItemRow = () => {
  setSelectedEquipItems([...selectedEquipItems, { instanceId: '', id: '', stackId: '', collectionId: '', shouldEquip: true }]);
};

const handleRemoveEquipItemRow = (index) => {
  const newItems = selectedEquipItems.filter((_, idx) => idx !== index);
  setSelectedEquipItems(newItems);
};

const handleEquipItemInputChange = (index, field, value) => {
  const newItems = [...selectedEquipItems];
  newItems[index][field] = (field === 'shouldEquip') ? (value === 'Equip') : value;

  if (field === 'instanceId') {
    const selectedItem = inventoryItems.find(item => item.instanceId === value);
    newItems[index]['id'] = selectedItem ? selectedItem.id : '';
  }

  setSelectedEquipItems(newItems);
};

const handleEquipItem = async () => {
  setCustomParamsMode('equip');
  setShowEquipInventoryCustomParamsPopup(true);
};


const handleSubmitEquipItem = async () => {
  setIsLoading(true);
  const toastId = toast.loading('Processing equip/unequip item...', {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
    closeButton: <CustomCloseButton />
  });

  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': apiKey,
    'Authorization': `Bearer ${players[activeTab].token}`,
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  try {
    const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/inventory/equip-unequip', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        items: selectedEquipItems.map(item => ({
          instanceId: item.instanceId,
          id: item.id,
          stackId: item.stackId,
          collectionId: item.collectionId,
          shouldEquip: item.shouldEquip,
          customParams: formatCustomParams(equipInventoryCustomParams[item.instanceId] || [])
        })),
      }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to equip/unequip item');
    }

    
    toast.update(toastId, {
      render: 'Item equipped/unequipped successfully',
      type: 'success',
      isLoading: false,
      autoClose: 5000,
      closeButton: <CustomCloseButton />
    });
    setSelectedEquipItems([]);
    setShowInventoryPopup(false);
    setShowEquipInventoryCustomParamsPopup(false);
    setEquipInventoryCustomParams({});
  } catch (error) {
  
    toast.update(toastId, {
      render: `Error equipping/unequipping item: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000,
      closeButton: <CustomCloseButton />
    });
  } finally {
    setIsLoading(false);
  }
};



  const handleFetchLeaderboards = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${players[activeTab].token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    let allLeaderboards = [];
    let offset = 0;
    const limit = 10; // Set the limit to the number of leaderboards you want to fetch per request
    let hasMoreLeaderboards = true;
  
    try {
      while (hasMoreLeaderboards) {
        const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-leaderboards', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            limit: limit,
            offset: offset,
         projectId,
          }),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch leaderboards');
        }
  
        // Filter custom leaderboards
        const customLeaderboards = data.data.leaderboards.filter(leaderboard => leaderboard.sourceType.name === 'custom');
        allLeaderboards = [...allLeaderboards, ...customLeaderboards];
  
        if (data.data.leaderboards.length < limit) {
          hasMoreLeaderboards = false;
        } else {
          offset += limit;
        }
      }
  
      setLeaderboards(allLeaderboards);
    } catch (error) {
      console.error('Error fetching leaderboards:', error);
    }
  };
  
  
  // Call this function when opening the popup
  useEffect(() => {
    if (showLeaderboardPopup) {
      handleFetchLeaderboards();
    }
  }, [showLeaderboardPopup]);
  
  const handlePostScoreToLeaderboard = async () => {
    setIsPostingScore(true);
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    let updatedPlayersCount = 0;
  
    const batchToastId = toast.loading(`Posting scores for players: 0/${selectedLeaderboardPlayers.length}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
  
    try {
      for (const playerId of selectedLeaderboardPlayers) {
        const player = players.find(p => p.id === playerId);
        if (!player) continue;
  
        try {
          const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/leaderboards/post-score', {
            method: 'POST',
            headers: {
              ...headers,
              'Authorization': `Bearer ${player.token}`,
            },
            body: JSON.stringify({
              leaderboardIds: [selectedLeaderboard],
              score: playerScores[playerId],
            }),
          });
  
          const data = await response.json();
          if (!response.ok) {
            throw new Error(data.message || 'Failed to post score');
          }
  
          const successMessage = `Player ${player.email || player.customId || player.username}: Score ${playerScores[playerId]} posted successfully!`;
          updatedPlayersCount++;
          toast.update(batchToastId, {
            render: `Posting scores for players: ${updatedPlayersCount}/${selectedLeaderboardPlayers.length}\n${successMessage}`,
            type: 'info',
            isLoading: true
          });
          setIsPostScoreSuccess(true);
        } catch (error) {
          const errorMessage = `Player ${player.email || player.customId || player.username}: Error - ${error.message}`;
          toast.update(batchToastId, {
            render: `Posting scores for players: ${updatedPlayersCount}/${selectedLeaderboardPlayers.length}\n${errorMessage}`,
            type: 'error',
            isLoading: true
          });
          setIsPostScoreSuccess(false);
        }
      }
  
      toast.update(batchToastId, {
        render: 'Scores submitted successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
    } catch (error) {
      const errorMessage = `Error posting scores: ${error.message}`;
      toast.update(batchToastId, {
        render: errorMessage,
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
    } finally {
      setIsPostingScore(false);
    }
  };
  

  const [leaderboardBatchSize, setLeaderboardBatchSize] = useState(0);
  const [leaderboardBatchUpdateStatusMessages, setLeaderboardBatchUpdateStatusMessages] = useState([]);
  const [leaderboardBatchUpdateProgress, setLeaderboardBatchUpdateProgress] = useState('');

  useEffect(() => {
    setLeaderboardBatchSize(selectedLeaderboardPlayers.length);
  }, [selectedLeaderboardPlayers]);

  const handlePostScoreToLeaderboardBatch = async (batchSize) => {
    setIsPostingScoreBatch(true);
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    const batchToastId = toast.loading(`Submitting scores for players: 0/${selectedLeaderboardPlayers.length}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });

    let updatedPlayersCount = 0;
  
    try {
      for (let start = 0; start < selectedLeaderboardPlayers.length; start += batchSize) {
        const batch = selectedLeaderboardPlayers.slice(start, start + batchSize);
  
        const updatePromises = batch.map(async (playerId) => {
          const player = players.find(p => p.id === playerId);
          if (!player) return;
  
          try {
            const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/leaderboards/post-score', {
              method: 'POST',
              headers: {
                ...headers,
                'Authorization': `Bearer ${player.token}`,
              },
              body: JSON.stringify({
                leaderboardIds: [selectedLeaderboard],
                score: playerScores[playerId],
              }),
            });
  
            const data = await response.json();
            if (!response.ok) {
              throw new Error(data.message || 'Failed to post score');
            }
  
            const successMessage = `Player ${player.email || player.customId || player.username}: Score ${playerScores[playerId]} posted successfully!`;
            toast.update(batchToastId, {
              render: `Progress: ${++updatedPlayersCount}/${selectedLeaderboardPlayers.length} - ${successMessage}`,
              type: 'info',
              isLoading: true
            });
          } catch (error) {
            const errorMessage = `Player ${player.email || player.customId || player.username}: Error - ${error.message}`;
            toast.update(batchToastId, {
              render: errorMessage,
              type: 'error',
              isLoading: false
            });
          }
        });
  
        await Promise.all(updatePromises);
      }
  
      toast.update(batchToastId, {
        render: 'Scores submitted successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
      setIsPostScoreBatchSuccess(true);
      setSelectedLeaderboardPlayers([]);
      setPlayerScores({});
    } catch (error) {
      const errorMessage = `Error posting scores: ${error.message}`;
      toast.update(batchToastId, {
        render: errorMessage,
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
      setIsPostScoreBatchSuccess(false);
    } finally {
      setIsPostingScoreBatch(false);
    }
  };
  





  const generateOutcomes = (isCompetition = false) => {
    const range = maxOutcome - minOutcome + 1;
    const generateRandomOutcome = () => Math.floor(Math.random() * range) + minOutcome;
    let outcomesMap = {};
    const selectedList = isCompetition ? selectedEntries : selectedPlayers;
  
    if (outcomeMode === 'unique') {
      const uniqueSet = new Set();
      while (uniqueSet.size < selectedList.length) {
        uniqueSet.add(generateRandomOutcome());
      }
      const uniqueOutcomesArray = Array.from(uniqueSet);
      selectedList.forEach((id, index) => {
        outcomesMap[id] = uniqueOutcomesArray[index];
      });
    } else if (outcomeMode === 'ties') {
      const tiedOutcome = generateRandomOutcome();
      if (tieAll) {
        selectedList.forEach(id => {
          outcomesMap[id] = tiedOutcome;
        });
      } else {
        const tiedIds = new Set();
        while (tiedIds.size < numTied) {
          const randomId = selectedList[Math.floor(Math.random() * selectedList.length)];
          tiedIds.add(randomId);
        }
        tiedIds.forEach(id => {
          outcomesMap[id] = tiedOutcome;
        });
        selectedList.forEach(id => {
          if (!outcomesMap[id]) {
            outcomesMap[id] = generateRandomOutcome();
          }
        });
      }
    }
  
    setOutcomes(outcomesMap);
  };
  
  const generateLeaderboardOutcomes = () => {
    const range = maxOutcome - minOutcome + 1;
    const generateRandomOutcome = () => Math.floor(Math.random() * range) + minOutcome;
    let outcomesMap = {};
  
    if (outcomeMode === 'unique') {
      const uniqueSet = new Set();
      while (uniqueSet.size < selectedLeaderboardPlayers.length) {
        uniqueSet.add(generateRandomOutcome());
      }
      const uniqueOutcomesArray = Array.from(uniqueSet);
      selectedLeaderboardPlayers.forEach((id, index) => {
        outcomesMap[id] = uniqueOutcomesArray[index];
      });
    } else if (outcomeMode === 'ties') {
      const tiedOutcome = generateRandomOutcome();
      if (tieAll) {
        selectedLeaderboardPlayers.forEach(id => {
          outcomesMap[id] = tiedOutcome;
        });
      } else {
        const tiedIds = new Set();
        while (tiedIds.size < numTied) {
          const randomId = selectedLeaderboardPlayers[Math.floor(Math.random() * selectedLeaderboardPlayers.length)];
          tiedIds.add(randomId);
        }
        tiedIds.forEach(id => {
          outcomesMap[id] = tiedOutcome;
        });
        selectedLeaderboardPlayers.forEach(id => {
          if (!outcomesMap[id]) {
            outcomesMap[id] = generateRandomOutcome();
          }
        });
      }
    }
  
    setPlayerScores(outcomesMap);
  };


  
  const handleShowSubmitScores = () => {
    setShowSubmitScoresPopup(true);
  };


  const [scoreBatchSize, setScoreBatchSize] = useState(0);
  const [scoreBatchUpdateStatusMessages, setScoreBatchUpdateStatusMessages] = useState([]);
  const [scoreBatchUpdateProgress, setScoreBatchUpdateProgress] = useState('');

  useEffect(() => {
    setScoreBatchSize(selectedCustomTournamentEntries.length);
  }, [selectedCustomTournamentEntries]);
  const handleSubmitScoresBatch = async (batchSize) => {
    setIsSubmittingScoresBatch(true);
    const successMessages = [];
    const errorMessages = [];
    let updatedEntriesCount = 0;
  
    const batchToastId = toast.loading(`Submitting scores for entries: 0/${selectedCustomTournamentEntries.length}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
  
    try {
      for (let start = 0; start < selectedCustomTournamentEntries.length; start += batchSize) {
        const batch = selectedCustomTournamentEntries.slice(start, start + batchSize);
  
        const updatePromises = batch.map(async (entryId) => {
          const playerEntry = customTournamentEntries.find(entry => entry.entryId === entryId);
          if (!playerEntry) {
            const errorMessage = `Entry not found for entry ID ${entryId}`;
            toast.update(batchToastId, {
              render: errorMessage,
              type: 'error',
              isLoading: false
            });
            return;
          }
  
          const player = players.find(p => p.id === playerEntry.playerId);
          if (!player || !player.token) {
            const errorMessage = `Player or token not found for player ID ${playerEntry.playerId}`;
            toast.update(batchToastId, {
              render: errorMessage,
              type: 'error',
              isLoading: false
            });
            return;
          }
  
          const score = scores[entryId];
          if (score === undefined || score === null) {
            const errorMessage = `Score not found for entry ID ${entryId}`;
            toast.update(batchToastId, {
              render: errorMessage,
              type: 'error',
              isLoading: false
            });
            return;
          }
  
          const headers = {
            'Content-Type': 'application/json',
            'Api-Key': apiKey,
            'Authorization': `Bearer ${player.token}`,
            'env': selectedEnv,
            ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
          };
  
          const payload = {
            competitionId: selectedTournament,
            entryId: entryId,
            score: score,
          };
  
          const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/competitions/post-score-to-tournament', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload),
          });
  
          const data = await response.json();
          if (!response.ok) {
            throw new Error(data.message || 'Failed to post score to tournament');
          }
  
          updatedEntriesCount++;
          const successMessage = `Progress: ${updatedEntriesCount}/${selectedCustomTournamentEntries.length} entries updated`;
          toast.update(batchToastId, {
            render: successMessage,
            type: 'info',
            isLoading: true
          });
        });
  
        await Promise.all(updatePromises);
      }
  
      toast.update(batchToastId, {
        render: 'Scores submitted successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
      setTournamentStatus('Scores submitted successfully!');
      setShowSubmitScoresPopup(false);
      setSelectedCustomTournamentEntries([]);
      setScores({});
      setIsSubmitScoresBatchSuccess(true);
    } catch (error) {
      const errorMessage = `Error submitting scores: ${error.message}`;
      toast.update(batchToastId, {
        render: errorMessage,
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
      setTournamentStatus(`Error submitting scores: ${error.message}`);
      setIsSubmitScoresBatchSuccess(false);
    } finally {
      setIsSubmittingScoresBatch(false);
    }
  
    setTimeout(() => {
      setScoreBatchUpdateStatusMessages([]);
      setScoreBatchUpdateProgress('');
    }, 3000);
  };
  

  const handleSubmitScores = async () => {
    setIsSubmittingScores(true);
    const totalEntries = selectedCustomTournamentEntries.length;
    let submittedScoresCount = 0;
  
    const submitScoresToastId = toast.loading(`Submitting scores for entries: 0/${totalEntries}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
      closeButton: <CustomCloseButton />
    });
  
    try {
      for (const entryId of selectedCustomTournamentEntries) {
        const playerEntry = customTournamentEntries.find(entry => entry.entryId === entryId);
        if (!playerEntry) {
          console.error(`Entry not found for entry ID ${entryId}`);
          continue;
        }
  
        const player = players.find(p => p.id === playerEntry.playerId);
        if (!player || !player.token) {
          console.error(`Player or token not found for player ID ${playerEntry.playerId}`);
          continue;
        }
  
        const score = scores[entryId];
        if (score === undefined || score === null) {
          console.error(`Score not found for entry ID ${entryId}`);
          continue;
        }
  
        const headers = {
          'Content-Type': 'application/json',
          'Api-Key': apiKey,
          'Authorization': `Bearer ${player.token}`,
          'env': selectedEnv,
          ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
        };
  
        const payload = {
          competitionId: selectedTournament,
          entryId: entryId,
          score: score,
        };
  
        const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/competitions/post-score-to-tournament', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to post score to tournament');
        }
  
        console.log('Success:', data);
  
        submittedScoresCount++;
        toast.update(submitScoresToastId, {
          render: `Submitting scores for entries: ${submittedScoresCount}/${totalEntries}`,
          type: 'info',
          isLoading: true,
        });
      }
      toast.update(submitScoresToastId, {
        render: 'Scores submitted successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      });
      setTournamentStatus('Scores submitted successfully!');
      setShowSubmitScoresPopup(false);
      setSelectedCustomTournamentEntries([]);
      setScores({});
      setIsSubmitScoresSuccess(true);
    } catch (error) {
      console.error('Error submitting scores:', error);
      toast.update(submitScoresToastId, {
        render: `Error submitting scores: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      setTournamentStatus(`Error submitting scores: ${error.message}`);
      setIsSubmitScoresSuccess(false);
    } finally {
      setIsSubmittingScores(false);
    }
  
    // Clear messages after 3 seconds
    setTimeout(() => {
      setTournamentStatus('');
    }, 3000);
  };
  



  const handleFetchCustomTournaments = async () => {
    const playerToken = players[0].token;
  
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${playerToken}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-competitions`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ projectId }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch competitions');
      }
  
      // Filter custom competitions by checking if the match object is empty
      const customCompetitions = data.data.competitions.filter(comp => Object.keys(comp.match).length === 0);
  
      setCustomTournaments(customCompetitions);
    } catch (error) {
      console.error('Error fetching custom tournaments:', error);
    }
  };


  const generateOutcomesForCustomTournament = () => {
    const range = maxOutcome - minOutcome + 1;
    const generateRandomOutcome = () => Math.floor(Math.random() * range) + minOutcome;
    let outcomesMap = {};
  
    if (outcomeMode === 'unique') {
      const uniqueSet = new Set();
      while (uniqueSet.size < selectedCustomTournamentEntries.length) {
        uniqueSet.add(generateRandomOutcome());
      }
      const uniqueOutcomesArray = Array.from(uniqueSet);
      selectedCustomTournamentEntries.forEach((id, index) => {
        outcomesMap[id] = uniqueOutcomesArray[index];
      });
    } else if (outcomeMode === 'ties') {
      const tiedOutcome = generateRandomOutcome();
      if (tieAll) {
        selectedCustomTournamentEntries.forEach(id => {
          outcomesMap[id] = tiedOutcome;
        });
      } else {
        const tiedIds = new Set();
        while (tiedIds.size < numTied) {
          const randomId = selectedCustomTournamentEntries[Math.floor(Math.random() * selectedCustomTournamentEntries.length)];
          tiedIds.add(randomId);
        }
        tiedIds.forEach(id => {
          outcomesMap[id] = tiedOutcome;
        });
        selectedCustomTournamentEntries.forEach(id => {
          if (!outcomesMap[id]) {
            outcomesMap[id] = generateRandomOutcome();
          }
        });
      }
    }
  
    setScores(outcomesMap);
  };
  
  const [tournamentAttemptsLeft, setTournamentAttemptsLeft] = useState({});
  const handleCheckAttemptsForCustomTournament = async (entryId) => {
    const entry = customTournamentEntries.find(e => e.entryId === entryId);
    if (!entry) {
      console.error(`Entry with ID ${entryId} not found`);
      return;
    }
  
    const player = players.find(p => p.id === entry.playerId);
    if (!player || !player.token) {
      console.error(`Player or token not found for player ID ${entry.playerId}`);
      return;
    }
  
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${player.token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    try {
      const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/competitions/check-attempts`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ entryId }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to check attempts');
      }
  
      setTournamentAttemptsLeft(prev => ({
        ...prev,
        [entryId]: data.data.numberOfAttemptsLeft
      }));
  
      // Clear the attempts left after 5 seconds
      setTimeout(() => {
        setTournamentAttemptsLeft(prev => {
          const newAttemptsLeft = { ...prev };
          delete newAttemptsLeft[entryId];
          return newAttemptsLeft;
        });
      }, 5000);
    } catch (error) {
      console.error('Error checking attempts:', error);
    }
  };
  
  const triggerMatchPopup = async () => {
    setGlobalLoading(true); // Start loading
    try {
      if (!showMatchPopup) {
        await handleFetchMatches();
        document.body.classList.add('modal-open');
        setMatchPopupStage('match');
        setMatchStatus(''); // Reset status
      } else {
        document.body.classList.remove('modal-open');
        setSelectedPlayers([]);
        setMasterPlayer('');
        setSelectedMatch('');
        setMatchSessionId('');
        setShowMatchCustomParamsPopup(false);
        setMatchCustomParams({});
        setMatchStatus('');
        setMatchResults([]); // Clear match results
        
      }
      setShowMatchPopup(!showMatchPopup);
    } catch (error) {
      console.error('Error handling match popup:', error);
    } finally {
      setGlobalLoading(false); // End loading
    }
  };
  

  const triggerCompetitionPopup = async () => {
    setGlobalLoading(true); // Start loading
    try {
      if (!showCompetitionPopup) {
        await handleFetchCompetitions();
        document.body.classList.add('modal-open');
        setCompetitionPopupStage('competition'); // Set initial stage to 'Setup Simulation'
        setCompetitionStatus(''); // Reset status
      } else {
        document.body.classList.remove('modal-open');
        setSelectedPlayers([]);
        setSelectedEntries([]);
        setCompetitionCustomParams({});
        setSelectedCompetition('');
        setMasterPlayer('');
        setOutcomes({});
        setCompetitionSessionId('');
        setShowEndCompetitionPopup(false);
        setCompetitionStatus('');
        setEntries([]);
        setCompetitionResults([]); // Clear competition results
      }
      setShowCompetitionPopup(!showCompetitionPopup);
    } catch (error) {
      console.error('Error handling competition popup:', error);
    } finally {
      setGlobalLoading(false); // End loading
    }
  };
  
  const triggerCustomTournamentPopup = async () => {
    setGlobalLoading(true); // Start loading
    try {
      if (!showCustomTournamentPopup) {
        await handleFetchCustomTournaments();
        document.body.classList.add('modal-open');
        setCustomTournamentPopupStage('setup')
        setTournamentStatus('');
      } else {
        document.body.classList.remove('modal-open');
        setSelectedCustomTournamentPlayers([]);
        setSelectedCustomTournamentEntries([]);
        setScores({});
        setSelectedTournament('');
        setTournamentStatus('');
        setMinOutcome(0);
        setMaxOutcome(100);
        setOutcomeMode('unique');
        setNumTied(0);
        setTieAll(false);
        setCustomTournamentEntries([]);
      }
      setShowCustomTournamentPopup(!showCustomTournamentPopup);
    } catch (error) {
      console.error('Error handling custom tournament popup:', error);
    } finally {
      setGlobalLoading(false); // End loading
    }
  };
  
  const triggerLeaderboardPopup = async () => {
    setGlobalLoading(true); // Start loading
    try {
      if (!showLeaderboardPopup) {
        await handleFetchLeaderboards();
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
        setSelectedLeaderboardPlayers([]);
        setSelectedLeaderboard('');
        setPlayerScores({});
        setSuccessMessage('');
        setErrorMessage('');
      }
      setShowLeaderboardPopup(!showLeaderboardPopup);
    } catch (error) {
      console.error('Error handling leaderboard popup:', error);
    } finally {
      setGlobalLoading(false); // End loading
    }
  };
  

  const handleActionSelect = (action) => {
    switch (action) {
      case 'end-session':
        handleEndSession();
        break;
      case 'play-match':
        triggerMatchPopup();
        break;
      case 'play-competition':
        triggerCompetitionPopup();
        break;
      case 'play-custom-tournament':
        triggerCustomTournamentPopup();
        break;
      case 'post-leaderboard':
        triggerLeaderboardPopup();
        
        break;
        case 'update-wallets':
  triggerUpdateWalletPopup();
  break;
  case 'update-progress':
    triggerUpdateProgressPopup();
    break;

      default:
        break;
    }
  };


  const handleSelect = (option) => {
    setSelectedOption(option.label);
    setShowOptions(false);
    handleActionSelect(option.value);
  };


  const getPlayerLabel = (player, index) => {
    return `Player ${index + 1} - ${player.email || player.username || player.customId}`;
  };


  const [showPlayerDropdown, setShowPlayerDropdown] = useState(false);

   const handlePlayerSelect = (index) => {
    setActiveTab(index);
    setShowPlayerDropdown(false);
  };

  const getAppIconUrl = (appName) => {
    return `https://api.dicebear.com/8.x/initials/svg?seed=${encodeURIComponent(appName)}`;
  };
  

  const getEnvIcon = () => {
    let env = selectedAppEnv.toLowerCase();
    let icon = '';

    if (env === 'development') icon = '/assets/dev.svg';
    if (env === 'quality assurance') icon = '/assets/qa.svg';
    if (env === 'production') icon = '/assets/prod.svg';

    return icon;
  };

  const getInitialsIcon = (name) => {
    return `https://api.dicebear.com/8.x/initials/svg?seed=${encodeURIComponent(name)}`;
  };
  
  const [isEmbeddedSiteOpen, setisEmbeddedSiteOpen] = useState(false);
  const handleOpenEmbeddedSite = () => {
    setisEmbeddedSiteOpen(true);
  };
  
  const handleCloseEmbeddedSite = () => {
    setisEmbeddedSiteOpen(false);
  };
  


 
  const [matchSelectDropdownVisibility, setMatchSelectDropdownVisibility] = useState(false);
  
  const toggleMatchSelectDropdownVisibility = () => {
    setMatchSelectDropdownVisibility(!matchSelectDropdownVisibility);
  };
  
  const handleMatchSelect = (matchId) => {
    setSelectedMatch(matchId);
    setMatchSelectDropdownVisibility(false);
  };
  




  const [competitionSelectDropdownVisibility, setCompetitionSelectDropdownVisibility] = useState(false);
  
  const toggleCompetitionSelectDropdownVisibility = () => {
    setCompetitionSelectDropdownVisibility(!competitionSelectDropdownVisibility);
  };
  
  const handleCompetitionSelect = (competitionId) => {
    setSelectedCompetition(competitionId);
    setCompetitionSelectDropdownVisibility(false);
  };




  const [masterPlayerDropdownVisibility, setMasterPlayerDropdownVisibility] = useState(false);
  
  const toggleMasterPlayerDropdownVisibility = () => {
    setMasterPlayerDropdownVisibility(!masterPlayerDropdownVisibility);
  };
  
  const handleMasterPlayerSelect = (playerId) => {
    setMasterPlayer(playerId);
    setMasterPlayerDropdownVisibility(false);
  };
  

  const [tournamentDropdownVisibility, setTournamentDropdownVisibility] = useState(false);

  const toggleTournamentDropdownVisibility = () => {
    setTournamentDropdownVisibility(!tournamentDropdownVisibility);
  };

  const handleTournamentSelect = (tournamentId) => {
    setSelectedTournament(tournamentId);
    setTournamentDropdownVisibility(false);
  };

  const [leaderboardDropdownVisibility, setLeaderboardDropdownVisibility] = useState(false);

  const toggleLeaderboardDropdownVisibility = () => {
    setLeaderboardDropdownVisibility(!leaderboardDropdownVisibility);
  };
  
  const handleLeaderboardSelect = (leaderboardId) => {
    setSelectedLeaderboard(leaderboardId);
    setLeaderboardDropdownVisibility(false);
  };
  


  const [currencyDropdownVisibility, setCurrencyDropdownVisibility] = useState(false);

  const toggleCurrencyDropdownVisibility = () => {
    setCurrencyDropdownVisibility(!currencyDropdownVisibility);
  };
  
  const handleCurrencySelect = (currencyId) => {
    setSelectedCurrency(currencyId);
    setCurrencyDropdownVisibility(false);
  };


  const [operatorDropdownVisibility, setOperatorDropdownVisibility] = useState(false);
  
  const toggleOperatorDropdownVisibility = () => {
    setOperatorDropdownVisibility(!operatorDropdownVisibility);
  };
  
  const handleOperatorSelect = (operator) => {
    setSelectedOperator(operator);
    setOperatorDropdownVisibility(false);
  };

  const [progressMarkerDropdownVisibility, setProgressMarkerDropdownVisibility] = useState(false);

const toggleProgressMarkerDropdownVisibility = () => {
  setProgressMarkerDropdownVisibility(!progressMarkerDropdownVisibility);
};

const handleProgressMarkerSelect = (markerId) => {
  setSelectedProgressMarker(markerId);
  setProgressMarkerDropdownVisibility(false);
};
  

const [progressOperatorDropdownVisibility, setProgressOperatorDropdownVisibility] = useState(false);

const toggleProgressOperatorDropdownVisibility = () => {
  setProgressOperatorDropdownVisibility(!progressOperatorDropdownVisibility);
};

const handleProgressOperatorSelect = (operator) => {
  setProgressOperator(operator);
  setProgressOperatorDropdownVisibility(false);
};

const [paramDropdownVisibility, setParamDropdownVisibility] = useState({});



const toggleParamDropdownVisibility = (playerId, index) => {
  setParamDropdownVisibility((prevVisibility) => ({
    ...prevVisibility,
    [playerId]: {
      ...prevVisibility[playerId],
      [index]: !prevVisibility[playerId]?.[index],
    },
  }));
};

const handleParamSelect = (paramType, playerId, index, value) => {
  handleCustomParamChange(paramType, playerId, index, 'type', value);
  setParamDropdownVisibility((prevVisibility) => ({
    ...prevVisibility,
    [playerId]: {
      ...prevVisibility[playerId],
      [index]: false,
    },
  }));
};

const [competitionParamDropdownVisibility, setCompetitionParamDropdownVisibility] = useState({});

const toggleCompetitionParamDropdownVisibility = (entryId, index) => {
  setCompetitionParamDropdownVisibility((prevVisibility) => ({
    ...prevVisibility,
    [entryId]: {
      ...prevVisibility[entryId],
      [index]: !prevVisibility[entryId]?.[index],
    },
  }));
};

const handleCompetitionParamSelect = (paramType, entryId, index, value) => {
  handleCustomParamChange(paramType, entryId, index, 'type', value);
  setCompetitionParamDropdownVisibility((prevVisibility) => ({
    ...prevVisibility,
    [entryId]: {
      ...prevVisibility[entryId],
      [index]: false,
    },
  }));
};



  return (
    <div className="App">
   {globalLoading && (
  <div className="global-loading-overlay">
    <img src={`${process.env.PUBLIC_URL}/assets/specter_landing.svg`} alt="Loading..." className="loading-logo" />
  </div>
)}


<div className="top-bar">
<div className="env-label-container">
  <img src={getEnvIcon()} alt={`${selectedAppEnv} icon`} className="env-icon" />
  <div className="env-label">
    {selectedEnv} | {selectedAppEnv}
  </div>
  <button className="embedded-site-button" onClick={handleOpenEmbeddedSite}>
    <img src="/assets/specter_og.svg" alt="Embedded Site" className="embedded-site-icon" />
  </button>
</div>

{isEmbeddedSiteOpen && (
  <EmbeddedSitePopup onClose={handleCloseEmbeddedSite} />
)}
  
    <div className="member-org-info">
    <ProtectedImage
  
    src={memberIcon || getInitialsIcon(memberName)} 
    alt="Member Icon" 
    className="member-icon"
    apiKey={apiKey} 
  />
  <div className="member-name">{memberName}</div>
  <div className="separator"></div>
  <ProtectedImage
    src={organisationIcon || getInitialsIcon(organisationName)} 
    alt="Organisation Icon" 
    className="org-icon"
    apiKey={apiKey} 
  />
  <div className="org-name">{organisationName}</div>
  <button className="end-session-button" onClick={handleEndSession}>
    <img src="/assets/home.svg" alt="End Session Icon" className="end-session-icon" />
  </button>
</div>
    </div>



      <div className="header-container">
        {!loading && appInfo && (
          <div className="app-card">
            <div className="app-icon">
            <ProtectedImage
            src={appInfo.iconUrl || getAppIconUrl(appInfo.name)}
            alt={appInfo.name}
            apiKey={apiKey}
          />
            </div>
            <div className="app-details">
              <div className="app-name">{appInfo.name}</div>
              <div className="app-id">
                {copySuccess ? (
                  <span className="copy-success">Copied!</span>
                ) : (
                  <button className="copy-button" onClick={handleCopy}>
                    <img src={`${process.env.PUBLIC_URL}/assets/copy.svg`} alt="Copy" />
                  </button>
                )}
                <span>{appInfo.id}</span>
              </div>
            </div>
          </div>
        )}
        <div className="actions-dropdown-container">
          <div className="custom-dropdown">
            <div className="custom-dropdown-header" onClick={() => setShowOptions(!showOptions)}>
              {selectedOption}
              <span className="custom-dropdown-arrow">&#9662;</span>
            </div>
            {showOptions && (
              <ul className="custom-dropdown-list">
                {[
                  // { value: '', label: 'Esports Actions' },
                  { value: 'update-wallets', label: 'Bulk Update Wallets' },
                  { value: 'update-progress', label: 'Bulk Update Progress' },
                  { value: 'play-match', label: 'Match Simulator' },
                  { value: 'post-leaderboard', label: 'Custom Leaderboard Simulator' },
                  { value: 'play-competition', label: 'Competition (Match) Simulator' },
                  { value: 'play-custom-tournament', label: 'Custom Tournament Simulator' },
                 
                
              
                ].map((option) => (
                  <li
            key={option.value}
            className={`custom-dropdown-option ${option.value === 'end-session' ? 'end-session-option' : ''}`}
            onClick={() => handleSelect(option)}
          >
            {option.label}
          </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="player-dropdown-container">
  <div className="player-custom-dropdown">
    {!showPlayerDropdown ? (
      <div className="player-custom-dropdown-header" onClick={() => setShowPlayerDropdown(!showPlayerDropdown)}>
        {getPlayerLabel(players[activeTab], activeTab)}
        <span className="player-custom-dropdown-arrow">&#9662;</span>
      </div>
    ) : (
      <input
        type="text"
        value={playerSearchInput}
        onChange={(e) => setPlayerSearchInput(e.target.value)}
        placeholder="Search for players"
        className="player-search-input"
      />
    )}
    {showPlayerDropdown && (
      <div className="player-dropdown-content">
        <ul className="player-custom-dropdown-list">
          <li
            className="player-custom-dropdown-option active-player-option"
            onClick={() => handlePlayerSelect(activeTab)}
          >
            {getPlayerLabel(players[activeTab], activeTab)}
          </li>
          <div className="scrollable-players">
            {[...filteredPlayers.filter((_, index) => index !== activeTab)].map((player, index) => (
              <li
                key={index}
                className="player-custom-dropdown-option"
                onClick={() => handlePlayerSelect(filteredPlayers.findIndex(p => p === player))}
              >
                {getPlayerLabel(player, filteredPlayers.findIndex(p => p === player))}
              </li>
            ))}
          </div>
        </ul>
      </div>
    )}
  </div>
</div>


      {players[activeTab] && (
        <PlayerTab
        dirtcubeAuthenticated={dirtcubeAuthenticated}
          player={players[activeTab]}
          apiKey={apiKey}
          projectId={projectId}
          selectedEnv={selectedEnv}
          devIp={devIp}
          memberName={memberName}
          selectedAppName={selectedAppName}
          organisationName={organisationName}
          fetchProgress={handleFetchProgress}
          updateMarker={handleUpdateMarker}
          fetchWallet={handleFetchWallet}
          updateWallet={handleUpdateWallet}
          currencies={currencies}
          items={items}
          bundles={bundles}
          customEvents={customEvents}  // Pass custom events to PlayerTab
          selectedEvent={selectedEvent} // Pass selectedEvent
          eventParams={eventParams} // Pass eventParams
          isFiringEvent = {isFiringEvent}
          handleSelectEvent={handleSelectEvent} // Pass handleSelectEvent
          handleParamChange={handleParamChange} // Pass handleParamChange
          handleFireEvent={handleFireEvent} // Pass handleFireEvent
          handleClearFields={handleClearFields}  // Pass handleClearFields to PlayerTab
          successMessage={successMessage}
          errorMessage={errorMessage}
          avatarStyle={avatarStyle} 
          players={players}
          activeTab={activeTab}
          handleFetchInventory={handleFetchInventory}
        selectedConsumeItems ={selectedConsumeItems}
       isLoading = {isLoading}
  selectedEquipItems={selectedEquipItems}
        inventoryItems={inventoryItems}
        selectedItems={selectedItems}
        selectedBundles={selectedBundles}
        handleAddInventoryRow={handleAddInventoryRow}
        handleRemoveInventoryRow={handleRemoveInventoryRow}
        handleInventoryInputChange={handleInventoryInputChange}
        handleAddInventoryItem={handleAddInventoryItem}
        showAddInventoryCustomParamsPopup={showAddInventoryCustomParamsPopup}
        addInventoryCustomParams={addInventoryCustomParams}
        handleCustomParamChange={handleCustomParamChange}
        handleRemoveCustomParam={handleRemoveCustomParam}
        handleAddCustomParam={handleAddCustomParam}
        handleSubmitAddInventoryItem={handleSubmitAddInventoryItem}
        setShowAddInventoryCustomParamsPopup={setShowAddInventoryCustomParamsPopup}
        selectedInventoryItems={selectedInventoryItems}
        handleAddConsumeItemRow={handleAddConsumeItemRow}
        handleConsumeItemInputChange={handleConsumeItemInputChange}
        handleRemoveConsumeItemRow={handleRemoveConsumeItemRow}
        handleConsumeItem={handleConsumeItem}
        showConsumeInventoryCustomParamsPopup={showConsumeInventoryCustomParamsPopup}
        consumeInventoryCustomParams={consumeInventoryCustomParams}
        handleSubmitConsumeItem={handleSubmitConsumeItem}
        setShowConsumeInventoryCustomParamsPopup={setShowConsumeInventoryCustomParamsPopup}
        handleAddEquipItemRow={handleAddEquipItemRow}
        handleEquipItemInputChange={handleEquipItemInputChange}
        handleRemoveEquipItemRow={handleRemoveEquipItemRow}
        handleEquipItem={handleEquipItem}
        showEquipInventoryCustomParamsPopup={showEquipInventoryCustomParamsPopup}
        equipInventoryCustomParams={equipInventoryCustomParams}
        handleSubmitEquipItem={handleSubmitEquipItem}
        setShowEquipInventoryCustomParamsPopup={setShowEquipInventoryCustomParamsPopup}
        inventoryStatus={inventoryStatus}
        resetInventoryState={resetInventoryState}
        />
      )}












{showMatchPopup && (
  <div className="overlay">
    <div className="popup">
      <div className="popup-header">
        <button
          onClick={() => {
            setShowMatchPopup(false);
            setSelectedPlayers([]); // Reset selected players
            setMasterPlayer(''); // Reset master player
            setSelectedMatch(''); // Reset selected match
            setMatchSessionId(''); // Reset match session ID
            setShowMatchCustomParamsPopup(false); // Close custom params popup
            setMatchCustomParams({}); // Clear custom parameters
            setMatchStatus(''); // Clear status
            resetDropdown(); // Reset the dropdown
            triggerMatchPopup()
          }}
          className="close-button"
        >
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
        </button>
        <h1>Match Simulator</h1>
      </div>
      <div className="breadcrumbs">
  <span onClick={() => changeMatchStage('match')} className={`breadcrumb ${matchPopupStage === 'match' ? 'active' : ''}`}>Setup Simulation</span>
  <span className="breadcrumb-divider">{' » '}</span>
  <span onClick={() => changeMatchStage('create')} className={`breadcrumb ${matchPopupStage === 'create' ? 'active' : ''}`}>Create Match</span>
  <span className="breadcrumb-divider">{' » '}</span>
  <span onClick={() => changeMatchStage('start')} className={`breadcrumb ${matchPopupStage === 'start' ? 'active' : ''}`}>Start Match</span>
  <span className="breadcrumb-divider">{' » '}</span>
  <span onClick={() => changeMatchStage('end')} className={`breadcrumb ${matchPopupStage === 'end' ? 'active' : ''}`}>End Match</span>
  <span className="breadcrumb-divider">{' » '}</span>
  <span onClick={() => changeMatchStage('results')} className={`breadcrumb ${matchPopupStage === 'results' ? 'active' : ''}`}>Match Results</span>
</div>

      <div className="popup-content">
        {matchPopupStage === 'match' && (
          <div>
            <h2>Initiate Match Simulation</h2>
            <h3>Select Match</h3>
            <div className="match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleMatchSelectDropdownVisibility}
  >
    {selectedMatch ? matches.find(match => match.id === selectedMatch)?.name : 'Select a match'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {matchSelectDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {matches.map((match) => (
        <li
          key={match.id}
          className="match-select-dropdown-option"
          onClick={() => handleMatchSelect(match.id)}
        >
          {match.name}
        </li>
      ))}
    </ul>
  )}
</div>

           
            <h3>Select Players</h3>
            <table className="new-select-table">
  <thead>
    <tr>
      <th>
        <input
          type="checkbox"
          checked={selectAllMatchPlayers}
          onChange={(e) => {
            setSelectAllMatchPlayers(e.target.checked);
            setSelectedPlayers(e.target.checked ? players.map(player => player.id) : []);
          }}
        />
      </th>
      <th>Player</th>
      <th>Master Player</th>
    </tr>
  </thead>
  <tbody>
    {players.map(player => (
      <tr key={player.uuid}>
        <td>
          <input
            type="checkbox"
            value={player.id}
            checked={selectedPlayers.includes(player.id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedPlayers([...selectedPlayers, player.id]);
              } else {
                setSelectedPlayers(selectedPlayers.filter(id => id !== player.id));
                setSelectAllMatchPlayers(false);
              }
            }}
          />
        </td>
        <td>{player.email || player.customId || player.username}</td>
        <td>
        <div className="switch-wrapper">
          <label className="switch">
            <input
              type="checkbox"
              disabled={!selectedPlayers.includes(player.id)}
              checked={masterPlayer === player.id}
              onChange={(e) => setMasterPlayer(e.target.checked ? player.id : '')}
            />
            <span className="slider round"></span>
          </label>
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>
           <button onClick={() => changeMatchStage('create')} disabled={!selectedMatch || selectedPlayers.length === 0 || !masterPlayer}>Begin Match Simulation</button>
          </div>
        )}
        {matchPopupStage === 'create' && (
          <div>
            <h2>Create Match Session</h2>
            <h3>Add custom parameters if needed and create the session.</h3>
            {selectedPlayers.map(playerId => (
              <div key={playerId}>
                <h4>Custom Params for Player {playerId}</h4>
                {matchCustomParams[playerId] && matchCustomParams[playerId].map((param, index) => (
                  <div key={index} className="custom-param">
                    <input
                      type="text"
                      placeholder="Param Name"
                      value={param.name}
                      onChange={(e) => handleCustomParamChange('match', playerId, index, 'name', e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Value"
                      value={param.value}
                      onChange={(e) => handleCustomParamChange('match', playerId, index, 'value', e.target.value)}
                    />
                <div className="param-select-dropdown">
  <div
    className="param-select-dropdown-header"
    onClick={() => toggleParamDropdownVisibility(playerId, index)}
  >
    {param.type.charAt(0).toUpperCase() + param.type.slice(1)}
    <span className="param-select-dropdown-arrow">&#9662;</span>
  </div>
  {paramDropdownVisibility[playerId]?.[index] && (
    <ul className="param-select-dropdown-list">
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect('match', playerId, index, 'string')}
      >
        String
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect('match', playerId, index, 'number')}
      >
        Number
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect('match', playerId, index, 'boolean')}
      >
        Boolean
      </li>
    </ul>
  )}
</div>


                    <button onClick={() => handleRemoveCustomParam('match', playerId, index)} className="remove-param">
                      -
                    </button>
                  </div>
                ))}
                <button onClick={() => handleAddCustomParam('match', playerId)} className="add-param">
                  + Add Custom Param
                </button>
              </div>
            ))}
        <button className="loading-button" onClick={handleSubmitCreateMatchSession} disabled={isCreatingMatch}>
  {isCreatingMatch && <div className="button-spinner"></div>}
  {isCreatingMatch ? 'Creating...' : 'Create Match Session'}
</button>
{isCreateMatchSuccess && (
      <button onClick={() => changeMatchStage('start')}>Proceed to Next</button>
    )}
            {matchStatus && <p>{matchStatus}</p>}
          </div>
        )}
        {matchPopupStage === 'start' && (
          <div>
          <h2>Start Match Session</h2>
            <h3>Add custom parameters if needed and start the session.</h3>
            {selectedPlayers.map(playerId => (
              <div key={playerId}>
                <h4>Custom Params for Player {playerId}</h4>
                {matchCustomParams[playerId] && matchCustomParams[playerId].map((param, index) => (
                  <div key={index} className="custom-param">
                    <input
                      type="text"
                      placeholder="Param Name"
                      value={param.name}
                      onChange={(e) => handleCustomParamChange('match', playerId, index, 'name', e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Value"
                      value={param.value}
                      onChange={(e) => handleCustomParamChange('match', playerId, index, 'value', e.target.value)}
                    />
             <div className="param-select-dropdown">
  <div
    className="param-select-dropdown-header"
    onClick={() => toggleParamDropdownVisibility(playerId, index)}
  >
    {param.type.charAt(0).toUpperCase() + param.type.slice(1)}
    <span className="param-select-dropdown-arrow">&#9662;</span>
  </div>
  {paramDropdownVisibility[playerId]?.[index] && (
    <ul className="param-select-dropdown-list">
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect('match', playerId, index, 'string')}
      >
        String
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect('match', playerId, index, 'number')}
      >
        Number
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect('match', playerId, index, 'boolean')}
      >
        Boolean
      </li>
    </ul>
  )}
</div>


                    <button onClick={() => handleRemoveCustomParam('match', playerId, index)} className="remove-param">
                      -
                    </button>
                  </div>
                ))}
                <button onClick={() => handleAddCustomParam('match', playerId)} className="add-param">
                  + Add Custom Param
                </button>
              </div>
            ))}
          <button className="loading-button" onClick={handleSubmitStartMatchSession} disabled={!matchSessionId || isStartingMatch}>
  {isStartingMatch && <div className="button-spinner"></div>}
  {isStartingMatch ? 'Starting...' : 'Start Match Session'}
</button>
{isStartMatchSuccess && (
      <button onClick={() => changeMatchStage('end')}>Proceed to Next</button>
    )}

            {matchStatus && <p>{matchStatus}</p>}

          </div>
        )}
        {matchPopupStage === 'end' && (
          <div>
           <h2>End Match Session</h2>
            <h3>Generate outcomes and end the session. Add custom parameters as needed.</h3>
            <button onClick={() => generateOutcomes(false)}>Generate Match Outcomes</button>
            <label>
              Min Outcome:
              <input
                type="number"
                value={minOutcome}
                onChange={(e) => setMinOutcome(Number(e.target.value))}
              />
            </label>
            <label>
              Max Outcome:
              <input
                type="number"
                value={maxOutcome}
                onChange={(e) => setMaxOutcome(Number(e.target.value))}
              />
            </label>
            <div>
              <label>
                <input
                  type="radio"
                  value="unique"
                  checked={outcomeMode === 'unique'}
                  onChange={(e) => setOutcomeMode(e.target.value)}
                />
                Unique Outcomes
              </label>
              <label>
                <input
                  type="radio"
                  value="ties"
                  checked={outcomeMode === 'ties'}
                  onChange={(e) => setOutcomeMode(e.target.value)}
                />
                Include Ties
              </label>
            </div>
            {outcomeMode === 'ties' && (
              <>
                <label>
                  Tie All:
                  <input
                    type="checkbox"
                    checked={tieAll}
                    onChange={(e) => setTieAll(e.target.checked)}
                  />
                </label>
                {!tieAll && (
                  <label>
                    Number of Tied Players:
                    <input
                      type="number"
                      value={numTied}
                      onChange={(e) => setNumTied(Number(e.target.value))}
                      max={selectedPlayers.length}
                    />
                  </label>
                )}
              </>
            )}
            {selectedPlayers.map(playerId => (
              <div key={playerId}>
                <label>
                  Player ID: {playerId}
                  <input
                    type="number"
                    placeholder="Outcome"
                    value={outcomes[playerId] || ''}
                    onChange={(e) => setOutcomes({ ...outcomes, [playerId]: Number(e.target.value) })}
                  />
                </label>
                <h4>Custom Params for Player {playerId}</h4>
                {matchCustomParams[playerId] && matchCustomParams[playerId].map((param, index) => (
                  <div key={index} className="custom-param">
                    <input
                      type="text"
                      placeholder="Param Name"
                      value={param.name}
                      onChange={(e) => handleCustomParamChange('match', playerId, index, 'name', e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Value"
                      value={param.value}
                      onChange={(e) => handleCustomParamChange('match', playerId, index, 'value', e.target.value)}
                    />
                <div className="param-select-dropdown">
  <div
    className="param-select-dropdown-header"
    onClick={() => toggleParamDropdownVisibility(playerId, index)}
  >
    {param.type.charAt(0).toUpperCase() + param.type.slice(1)}
    <span className="param-select-dropdown-arrow">&#9662;</span>
  </div>
  {paramDropdownVisibility[playerId]?.[index] && (
    <ul className="param-select-dropdown-list">
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect('match', playerId, index, 'string')}
      >
        String
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect('match', playerId, index, 'number')}
      >
        Number
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect('match', playerId, index, 'boolean')}
      >
        Boolean
      </li>
    </ul>
  )}
</div>

                    <button onClick={() => handleRemoveCustomParam('match', playerId, index)} className="remove-param">
                      -
                    </button>
                  </div>
                ))}
                <button onClick={() => handleAddCustomParam('match', playerId)} className="add-param">
                  + Add Custom Param
                </button>
              </div>
            ))}
          <button className="loading-button" onClick={handleSubmitEndMatchSession} disabled={isEndingMatch}>
      {isEndingMatch && <div className="button-spinner"></div>}
      {isEndingMatch ? 'Ending...' : 'End Match Session'}
    </button>
    {isEndMatchSuccess && (
      <button onClick={handleFetchMatchResults}>Proceed to Next</button>
    )}
    {matchStatus && <p>{matchStatus}</p>}
  </div>
)}

{matchPopupStage === 'results' && (
  <div className="results-container">
    {matchResults.length > 0 && showConfetti && <ReactConfetti width={windowSize.width} height={windowSize.height} />}
    <h2>Match Results</h2>
    {matchResults.length === 0 ? (
      <div className="no-results-container">
        <img src="/assets/lb.svg" alt="No Results" className="no-results-image" />
        <p className="no-results-text">No match results available. Please generate results by running a simulation.</p>
      </div>
    ) : (
    <table className="results-table">
      <thead>
        <tr>
          <th>Rank</th>
          <th>Player</th>
          <th>Player ID</th>
          <th>Score</th>
        </tr>
        </thead>
    <tbody>
      {matchResults.map(result =>
        result.userInfo.map((user, index) => {
          const player = players.find(p => p.id === user.id);
          return (
            <tr key={user.id} className={`rank-${index + 1}`}>
              <td>
                {index === 0 ? (
                  <span className="rank-icon">&#x1F451;</span> // Crown icon
                ) : (
                  index + 1
                )}
              </td>
                <td>{player ? (player.email || player.customId || player.username) : user.id}</td>
                <td>{user.id}</td>
                <td>{user.score}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    )}
  </div>
)}
      </div>
    </div>
  </div>
)}

      




{showCompetitionPopup && (
  <div className="overlay">
    <div className="popup">
      <div className="popup-header">
        <button
          onClick={() => {
            setShowCompetitionPopup(false);
            setSelectedPlayers([]); // Reset selected players
            setSelectedEntries([]); // Reset selected entries
            setCompetitionCustomParams({}); // Reset custom parameters
            setSelectedCompetition(''); // Reset selected competition
            setMasterPlayer(''); // Reset master player
            setOutcomes({}); // Reset outcomes
            setCompetitionSessionId(''); // Reset competition session ID
            setShowEndCompetitionPopup(false); // Close end competition popup
            setCompetitionStatus(''); // Clear status
            setEntries([]);
            resetDropdown(); // Reset the dropdown
            triggerCompetitionPopup()
          }}
          className="close-button"
        >
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
        </button>
        <h1>Competition (Match) Simulator</h1>
      </div>
      <div className="breadcrumbs">
        <span onClick={() => setCompetitionPopupStage('competition')} className={`breadcrumb ${competitionPopupStage === 'competition' ? 'active' : ''}`}>Setup Simulation</span>
        <span className="breadcrumb-divider">{' » '}</span>
        <span onClick={() => setCompetitionPopupStage('entries')} className={`breadcrumb ${competitionPopupStage === 'entries' ? 'active' : ''}`}>Select Entries</span>
        <span className="breadcrumb-divider">{' » '}</span>
        <span onClick={() => setCompetitionPopupStage('start')} className={`breadcrumb ${competitionPopupStage === 'start' ? 'active' : ''}`}>Start Match</span>
        <span className="breadcrumb-divider">{' » '}</span>
        <span onClick={() => setCompetitionPopupStage('end')} className={`breadcrumb ${competitionPopupStage === 'end' ? 'active' : ''}`}>End Match</span>
        <span className="breadcrumb-divider">{' » '}</span>
  <span onClick={() => setCompetitionPopupStage('results')} className={`breadcrumb ${competitionPopupStage === 'results' ? 'active' : ''}`}>Results</span>
</div>
      <div className="popup-content">
        {competitionPopupStage === 'competition' && (
          <div>
            <h2>Initiate Competition Simulation</h2>
            <h3>Select a Match-Based Competition</h3>
            <div className="competition-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleCompetitionSelectDropdownVisibility}
  >
    {selectedCompetition 
      ? competitions.find(comp => comp.id === selectedCompetition)?.name
      : 'Select a competition'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {competitionSelectDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {competitions.map((comp) => (
        <li
          key={comp.id}
          className="match-select-dropdown-option"
          onClick={() => handleCompetitionSelect(comp.id)}
        >
          {comp.name} {comp.match && comp.match.name ? `(${comp.match.name})` : '(Custom)'}
        </li>
      ))}
    </ul>
  )}
</div>

            <div>
              <h3>Select Player</h3>
              <table className="new-select-table">
  <thead>
    <tr>
      <th>
        <input
          type="checkbox"
          checked={selectAllCompetitionPlayers}
          onChange={(e) => {
            setSelectAllCompetitionPlayers(e.target.checked);
            setSelectedPlayers(e.target.checked ? players.map(player => player.id) : []);
          }}
        />
      </th>
      <th>Player</th>
    </tr>
  </thead>
  <tbody>
    {players.map(player => (
      <tr key={player.uuid}>
        <td>
          <input
            type="checkbox"
            value={player.id}
            checked={selectedPlayers.includes(player.id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedPlayers([...selectedPlayers, player.id]);
              } else {
                setSelectedPlayers(selectedPlayers.filter(id => id !== player.id));
                setSelectAllCompetitionPlayers(false);
              }
            }}
          />
        </td>
        <td>{player.email || player.customId || player.username}</td>
      </tr>
    ))}
  </tbody>
</table>
       </div>
       <button
              className="loading-button"
              onClick={async () => {
                await handleEnterCompetition();
                handleNextCompetitionStage('entries');
              }}
              disabled={!selectedCompetition || selectedPlayers.length === 0 || isEnteringCompetition}
            >
              {isEnteringCompetition && <div className="button-spinner"></div>}
              {isEnteringCompetition ? 'Entering...' : 'Enter Competition'}
            </button>
            {dirtcubeAuthenticated && (
              <>
            <div className="or-divider">or</div>
            <div className="batch-update-container">
              <label>Input Batch Size & Enter Competition (Only for Specter Devs)</label>
              <input
                type="number"
                value={enterCompetitionBatchSize}
                onChange={(e) => setEnterCompetitionBatchSize(Number(e.target.value))}
                min="1"
                max={selectedPlayers.length}
                placeholder="Batch Size"
              />
              <button
                className="loading-button"
                onClick={async () => {
                  await handleEnterCompetitionBatch();
                  handleNextCompetitionStage('entries');
                }}
                disabled={!selectedCompetition || selectedPlayers.length === 0 || isEnteringCompetitionBatch}
              >
                {isEnteringCompetitionBatch && <div className="button-spinner"></div>}
                {isEnteringCompetitionBatch ? 'Entering in Batches...' : 'Enter Competition in Batches'}
                </button>
                </div>
              </>
            )}
            {enterCompetitionBatchUpdateProgress && <p>{enterCompetitionBatchUpdateProgress}</p>}
            {enterCompetitionBatchUpdateStatusMessages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </div>
        )}
        {competitionPopupStage === 'entries' && (
          <div>
            {entries.length > 0 && (
              <div className="entries-table">
                 <h2>Competition Entries</h2>
            <h3>Select the entries with which you want to start the match session for this competition</h3>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAllEntries}
                          onChange={(e) => {
                            setSelectAllEntries(e.target.checked);
                            setSelectedEntries(e.target.checked ? entries.map(entry => entry.entryId) : []);
                          }}
                        />
                      </th>
                      <th>Entry ID</th>
                      <th>Player</th>
                      <th>Competition Name</th>
                      <th>Competition Instance ID</th>
                      <th>Check Attempts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entries
                      .filter(entry => {
                        const competition = competitions.find(comp => comp.id === selectedCompetition);
                        return competition && entry.competitionName === competition.name;
                      })
                      .map((entry, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              value={entry.entryId}
                              checked={selectedEntries.includes(entry.entryId)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedEntries([...selectedEntries, entry.entryId]);
                                } else {
                                  setSelectedEntries(selectedEntries.filter(id => id !== entry.entryId));
                                  setSelectAllEntries(false);
                                }
                              }}
                            />
                          </td>
                          <td>{entry.entryId}</td>
                          <td>
                            {players.find(player => player.id === entry.playerId)?.email ||
                            players.find(player => player.id === entry.playerId)?.customId ||
                            players.find(player => player.id === entry.playerId)?.username}
                          </td>
                          <td>{entry.competitionName}</td>
                          <td>{entry.competitionInstanceId}</td>
                          <td>
                    {attemptsLeft[entry.entryId] !== undefined ? (
                      attemptsLeft[entry.entryId]
                    ) : (
                      <button onClick={() => handleCheckAttempts(entry.entryId)}>Check Attempts</button>
                    )}
                  </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            <button onClick={() => handleNextCompetitionStage('start')} disabled={selectedEntries.length === 0}>Proceed to Next</button>
          </div>
        )}
        {competitionPopupStage === 'start' && (
          <div>
            <h2>Start Match (Competition)</h2>
            <h3>Add custom parameters if needed and start the session.</h3>
            {selectedEntries.map(entryId => (
              <div key={entryId}>
                <h4>Custom Params for Entry {entryId}</h4>
                {competitionCustomParams[entryId] && competitionCustomParams[entryId].map((param, index) => (
                  <div key={index} className="custom-param">
                    <input
                      type="text"
                      placeholder="Param Name"
                      value={param.name}
                      onChange={(e) => handleCustomParamChange('competition', entryId, index, 'name', e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Value"
                      value={param.value}
                      onChange={(e) => handleCustomParamChange('competition', entryId, index, 'value', e.target.value)}
                    />
                <div className="param-select-dropdown">
  <div
    className="param-select-dropdown-header"
    onClick={() => toggleCompetitionParamDropdownVisibility(entryId, index)}
  >
    {param.type.charAt(0).toUpperCase() + param.type.slice(1)}
    <span className="param-select-dropdown-arrow">&#9662;</span>
  </div>
  {competitionParamDropdownVisibility[entryId]?.[index] && (
    <ul className="param-select-dropdown-list">
      <li
        className="param-select-dropdown-option"
        onClick={() => handleCompetitionParamSelect('competition', entryId, index, 'string')}
      >
        String
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleCompetitionParamSelect('competition', entryId, index, 'number')}
      >
        Number
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleCompetitionParamSelect('competition', entryId, index, 'boolean')}
      >
        Boolean
      </li>
    </ul>
  )}
</div>

                    <button onClick={() => handleRemoveCustomParam('competition', entryId, index)} className="remove-param">
                      -
                    </button>
                  </div>
                ))}
                <button onClick={() => handleAddCustomParam('competition', entryId)} className="add-param">
                  + Add Custom Param
                </button>
              </div>
            ))}
            <div className="master-player-container">
            <label>
              Master Player:
              </label>
              <div className="master-player-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleMasterPlayerDropdownVisibility}
  >
    {masterPlayer 
      ? players.find(player => player.id === masterPlayer)?.email || players.find(player => player.id === masterPlayer)?.customId || players.find(player => player.id === masterPlayer)?.username
      : 'Select a master player'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {masterPlayerDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {players.map((player) => (
        <li
          key={player.id}
          className="match-select-dropdown-option"
          onClick={() => handleMasterPlayerSelect(player.id)}
        >
          {player.email || player.customId || player.username}
        </li>
      ))}
    </ul>
  )}
</div>
</div>

         
            <button className="loading-button" onClick={handleSubmitStartCompetition} disabled={isStartingCompetition || !masterPlayer}>
              {isStartingCompetition && <div className="button-spinner"></div>}
              {isStartingCompetition ? 'Starting...' : 'Start Match (Competition)'}
            </button>
            {isStartCompetitionSuccess && (
              <button onClick={() => handleNextCompetitionStage('end')}>Proceed to Next</button>
            )}
          </div>
        )}
        {competitionPopupStage === 'end' && (
          <div>
            <h2>End Match (Competition)</h2>
            <h3>Generate outcomes and end the session. Add custom parameters as needed.</h3>
            <button onClick={() => generateOutcomes(true)}>Generate Outcomes</button>
            <label>
              Min Outcome:
              <input
                type="number"
                value={minOutcome}
                onChange={(e) => setMinOutcome(Number(e.target.value))}
              />
            </label>
            <label>
              Max Outcome:
              <input
                type="number"
                value={maxOutcome}
                onChange={(e) => setMaxOutcome(Number(e.target.value))}
              />
            </label>
            <div>
              <label>
                <input
                  type="radio"
                  value="unique"
                  checked={outcomeMode === 'unique'}
                  onChange={(e) => setOutcomeMode(e.target.value)}
                />
                Unique Outcomes
              </label>
              <label>
                <input
                  type="radio"
                  value="ties"
                  checked={outcomeMode === 'ties'}
                  onChange={(e) => setOutcomeMode(e.target.value)}
                />
                Include Ties
              </label>
            </div>
            {outcomeMode === 'ties' && (
              <>
                <label>
                  Tie All:
                  <input
                    type="checkbox"
                    checked={tieAll}
                    onChange={(e) => setTieAll(e.target.checked)}
                  />
                </label>
                {!tieAll && (
                  <label>
                    Number of Tied Players:
                    <input
                      type="number"
                      value={numTied}
                      onChange={(e) => setNumTied(Number(e.target.value))}
                      max={selectedEntries.length}
                    />
                  </label>
                )}
              </>
            )}
            {selectedEntries.map(entryId => (
              <div key={entryId}>
                <label>
                  Entry ID: {entryId}
                  <input
                    type="number"
                    placeholder="Outcome"
                    value={outcomes[entryId] || ''}
                    onChange={(e) => setOutcomes({ ...outcomes, [entryId]: Number(e.target.value) })}
                  />
                </label>
                <h4>Custom Params for Entry {entryId}</h4>
                {competitionCustomParams[entryId] && competitionCustomParams[entryId].map((param, index) => (
                  <div key={index} className="custom-param">
                    <input
                      type="text"
                      placeholder="Param Name"
                      value={param.name}
                      onChange={(e) => handleCustomParamChange('competition', entryId, index, 'name', e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Value"
                      value={param.value}
                      onChange={(e) => handleCustomParamChange('competition', entryId, index, 'value', e.target.value)}
                    />
                  <div className="param-select-dropdown">
  <div
    className="param-select-dropdown-header"
    onClick={() => toggleCompetitionParamDropdownVisibility(entryId, index)}
  >
    {param.type.charAt(0).toUpperCase() + param.type.slice(1)}
    <span className="param-select-dropdown-arrow">&#9662;</span>
  </div>
  {competitionParamDropdownVisibility[entryId]?.[index] && (
    <ul className="param-select-dropdown-list">
      <li
        className="param-select-dropdown-option"
        onClick={() => handleCompetitionParamSelect('competition', entryId, index, 'string')}
      >
        String
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleCompetitionParamSelect('competition', entryId, index, 'number')}
      >
        Number
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleCompetitionParamSelect('competition', entryId, index, 'boolean')}
      >
        Boolean
      </li>
    </ul>
  )}
</div>

                    <button onClick={() => handleRemoveCustomParam('competition', entryId, index)} className="remove-param">
                      -
                    </button>
                  </div>
                ))}
                <button onClick={() => handleAddCustomParam('competition', entryId)} className="add-param">
                  + Add Custom Param
                </button>
              </div>
            ))}
          <button className="loading-button" onClick={handleSubmitEndCompetition} disabled={isEndingCompetition}>
              {isEndingCompetition && <div className="button-spinner"></div>}
              {isEndingCompetition ? 'Ending...' : 'End Match (Competition)'}
            </button>
            {isEndCompetitionSuccess && (
  <button onClick={handleFetchCompetitionResults}>Proceed to Next</button>
)}
          </div>
        )}
        {competitionStatus && <p>{competitionStatus}</p>}


{competitionPopupStage === 'results' && (
  <div className="results-container">
   {competitionResults.length > 0 && showConfetti && <ReactConfetti width={windowSize.width} height={windowSize.height} />}
    
    <h2>Results </h2>
    {competitionResults.length > 0 ? (
    <table className="results-table">
      <thead>
        <tr>
          <th>Rank</th>
          <th>Entry ID</th>
          <th>Player</th>
          <th>Player ID</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
      {competitionResults.map(result =>
        result.userInfo.map((user, index) => {
          const player = players.find(p => p.id === user.id);
          return (
            <tr key={user.id} className={`rank-${index + 1}`}>
              <td>
                {index === 0 ? (
                  <span className="rank-icon">&#x1F451;</span> // Crown icon
                ) : (
                  index + 1
                )}
              </td>
                <td>{user.entryId}</td> {/* Display entry ID */}
                <td>{player ? (player.email || player.customId || player.username) : user.id}</td>
                <td>{user.id}</td>
                <td>{user.score}</td>
                </tr>
          );
        })
      )}
    </tbody>
  </table>
) : (
      <div className="no-results">
      <img src="/assets/lb.svg" alt="No Results" className="no-results-image" />

        <p className="no-results-text">No results available. Please generate results by running a simulation.</p>
      </div>
    )}
  </div>
)}
      </div>
    </div>
  </div>
)}


















{showCustomTournamentPopup && (
  <div className="overlay">
    <div className="popup">
      <div className="popup-header">
        <button
          onClick={() => {
            setShowCustomTournamentPopup(false);
            setSelectedCustomTournamentPlayers([]);
            setSelectedCustomTournamentEntries([]);
            setScores({});
            setSelectedTournament('');
            setTournamentStatus('');
            setMinOutcome(0);
            setMaxOutcome(100);
            setOutcomeMode('unique');
            setNumTied(0);
            setTieAll(false);
            setCustomTournamentEntries([]);
            resetDropdown(); // Reset the dropdown
            triggerCustomTournamentPopup()
          }}
          className="close-button"
        >
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
        </button>
        <h1>Custom Tournament Simulator</h1>
      </div>
      <div className="breadcrumbs">
        <span onClick={() => setCustomTournamentPopupStage('setup')} className={`breadcrumb ${customTournamentPopupStage === 'setup' ? 'active' : ''}`}>Setup Simulation</span>
        <span className="breadcrumb-divider">{' » '}</span>
        <span onClick={() => setCustomTournamentPopupStage('entries')} className={`breadcrumb ${customTournamentPopupStage === 'entries' ? 'active' : ''}`}>Select Entries</span>
        <span className="breadcrumb-divider">{' » '}</span>
        <span onClick={() => setCustomTournamentPopupStage('scores')} className={`breadcrumb ${customTournamentPopupStage === 'scores' ? 'active' : ''}`}>Submit Scores</span>
      </div>
      <div className="popup-content">
        {customTournamentPopupStage === 'setup' && (
          <>
          <h2>Initiate Custom Tournament Simulation</h2>
            <h3>Select a Custom Tournament</h3>
          
            <div className="tournament-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleTournamentDropdownVisibility}
  >
    {selectedTournament 
      ? customTournaments.find(tournament => tournament.id === selectedTournament)?.name
      : 'Select a tournament'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {tournamentDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {customTournaments.map((tournament) => (
        <li
          key={tournament.id}
          className="match-select-dropdown-option"
          onClick={() => handleTournamentSelect(tournament.id)}
        >
          {tournament.name}
        </li>
      ))}
    </ul>
  )}
</div>

         
            <div>
           
          <h3>Select Players</h3>
<table className="new-select-table">
  <thead>
    <tr>
      <th>
        <input
          type="checkbox"
          checked={selectAllCustomTournamentPlayers}
          onChange={(e) => {
            setSelectAllCustomTournamentPlayers(e.target.checked);
            setSelectedCustomTournamentPlayers(e.target.checked ? players.map(player => player.id) : []);
          }}
        />
      </th>
      <th>Player</th>
    </tr>
  </thead>
  <tbody>
    {players.map(player => (
      <tr key={player.uuid}>
        <td>
          <input
            type="checkbox"
            value={player.id}
            checked={selectedCustomTournamentPlayers.includes(player.id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedCustomTournamentPlayers([...selectedCustomTournamentPlayers, player.id]);
              } else {
                setSelectedCustomTournamentPlayers(selectedCustomTournamentPlayers.filter(id => id !== player.id));
                setSelectAllCustomTournamentPlayers(false);
              }
            }}
          />
        </td>
        <td>{player.email || player.customId || player.username}</td>
      </tr>
    ))}
  </tbody>
</table>

           
            </div>
            <div className="then-divider"></div>
            <button 
              className="loading-button" 
              onClick={async () => {
                await handleEnterCustomCompetition();
                handleNextCustomTournamentStage('entries');
              }} 
              disabled={!selectedTournament || selectedCustomTournamentPlayers.length === 0 || isEnteringCustomCompetition}
            >
              {isEnteringCustomCompetition && <div className="button-spinner"></div>}
              {isEnteringCustomCompetition ? 'Entering...' : 'Enter Custom Tournament'}
            </button>
            {dirtcubeAuthenticated && (
              <>
<div className="or-divider">or</div>
<div className="batch-update-container">
  <label>Input Batch Size & Enter Custom Tournament (Only for Specter Devs)</label>
  <input
    type="number"
    value={enterCustomCompetitionBatchSize}
    onChange={(e) => setEnterCustomCompetitionBatchSize(Number(e.target.value))}
    min="1"
    max={selectedCustomTournamentPlayers.length}
    placeholder="Batch Size"
  />
  <button 
                className="loading-button" 
                onClick={async () => {
                  await handleEnterCustomCompetitionBatch(enterCustomCompetitionBatchSize);
                  handleNextCustomTournamentStage('entries');
                }} 
                disabled={!selectedTournament || selectedCustomTournamentPlayers.length === 0 || isEnteringCustomCompetitionBatch}
              >
                {isEnteringCustomCompetitionBatch && <div className="button-spinner"></div>}
                {isEnteringCustomCompetitionBatch ? 'Entering in Batches...' : 'Batch Enter Custom Tournament'}
                </button>
                </div>
              </>
            )}
<div className="status-messages">
  <p>{enterCompetitionBatchUpdateProgress}</p>
  {enterCompetitionBatchUpdateStatusMessages.map((message, index) => (
    <p key={index}>{message}</p>
  ))}
</div>

          </>
        )}

        {customTournamentPopupStage === 'entries' && (
          <>
            {customTournamentEntries.length > 0 && (
              <div className="entries-table">
         <h2>Custom Tournament Entries</h2>
            <h3>Select the entries with which you want to submit scores for this custom tournament</h3>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAllCustomTournamentEntries}
                          onChange={(e) => {
                            setSelectAllCustomTournamentEntries(e.target.checked);
                            setSelectedCustomTournamentEntries(e.target.checked ? customTournamentEntries.map(entry => entry.entryId) : []);
                          }}
                        />
                      </th>
                      <th>Entry ID</th>
                      <th>Player</th>
                      <th>Competition Name</th>
                      <th>Competition Instance ID</th>
                      <th>Check Attempts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customTournamentEntries.map((entry, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            value={entry.entryId}
                            checked={selectedCustomTournamentEntries.includes(entry.entryId)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedCustomTournamentEntries([...selectedCustomTournamentEntries, entry.entryId]);
                              } else {
                                setSelectedCustomTournamentEntries(selectedCustomTournamentEntries.filter(id => id !== entry.entryId));
                                setSelectAllCustomTournamentEntries(false);
                              }
                            }}
                          />
                        </td>
                        <td>{entry.entryId}</td>
                        <td>
                          {players.find(player => player.id === entry.playerId)?.email ||
                            players.find(player => player.id === entry.playerId)?.customId ||
                            players.find(player => player.id === entry.playerId)?.username}
                        </td>
                        <td>{entry.competitionName}</td>
                        <td>{entry.competitionInstanceId}</td>
                        <td>
  {tournamentAttemptsLeft[entry.entryId] !== undefined ? (
    <span>{tournamentAttemptsLeft[entry.entryId]}</span>
  ) : (
    <button onClick={() => handleCheckAttemptsForCustomTournament(entry.entryId)}>
      Check Attempts
    </button>
  )}
</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <button onClick={() => setCustomTournamentPopupStage('scores')} disabled={selectedCustomTournamentEntries.length === 0}>
             Proceed to Next
            </button>
          </>
        )}

        {customTournamentPopupStage === 'scores' && (
          <>
              <h2>Submit Scores</h2>
              <h3>Generate scores and submit to end the simulation.</h3>
            <label>
              Min Outcome:
              <input
                type="number"
                value={minOutcome}
                onChange={(e) => setMinOutcome(Number(e.target.value))}
              />
            </label>
            <label>
              Max Outcome:
              <input
                type="number"
                value={maxOutcome}
                onChange={(e) => setMaxOutcome(Number(e.target.value))}
              />
            </label>
            <div>
              <label>
                <input
                  type="radio"
                  value="unique"
                  checked={outcomeMode === 'unique'}
                  onChange={(e) => setOutcomeMode(e.target.value)}
                />
                Unique Outcomes
              </label>
              <label>
                <input
                  type="radio"
                  value="ties"
                  checked={outcomeMode === 'ties'}
                  onChange={(e) => setOutcomeMode(e.target.value)}
                />
                Include Ties
              </label>
            </div>
            {outcomeMode === 'ties' && (
              <>
                <label>
                  Tie All:
                  <input
                    type="checkbox"
                    checked={tieAll}
                    onChange={(e) => setTieAll(e.target.checked)}
                  />
                </label>
                {!tieAll && (
                  <label>
                    Number of Tied Players:
                    <input
                      type="number"
                      value={numTied}
                      onChange={(e) => setNumTied(Number(e.target.value))}
                      max={selectedCustomTournamentEntries.length}
                    />
                  </label>
                )}
              </>
            )}
            <button onClick={generateOutcomesForCustomTournament}>Generate Outcomes</button>
            <table className="new-select-table">
  <thead>
    <tr>
      <th>Entry ID</th>
      <th>Score</th>
    </tr>
  </thead>
  <tbody>
    {selectedCustomTournamentEntries.map(entryId => (
      <tr key={entryId}>
        <td>{entryId}</td>
        <td>
          <input
            type="number"
            placeholder="Score"
            value={scores[entryId] || ''}
            onChange={(e) => setScores({ ...scores, [entryId]: Number(e.target.value) })}
          />
        </td>
      </tr>
    ))}
  </tbody>
</table>

            <div className="then-divider"></div>
            <button 
              className="loading-button" 
              onClick={handleSubmitScores} 
              disabled={isSubmittingScores}
            >
              {isSubmittingScores && <div className="button-spinner"></div>}
              {isSubmittingScores ? 'Submitting...' : 'Confirm Submit Scores'}
            </button>
            {dirtcubeAuthenticated && (
              <>
            <div className="or-divider">or</div>
            <div className="batch-update-container">
              <label>Input Batch Size & Submit Scores (Only for Specter Devs)</label>
              <input
                type="number"
                value={scoreBatchSize}
                onChange={(e) => setScoreBatchSize(Number(e.target.value))}
                min="1"
                max={selectedCustomTournamentEntries.length}
                placeholder="Batch Size"
              />
              <button 
                className="loading-button" 
                onClick={() => handleSubmitScoresBatch(scoreBatchSize)} 
                disabled={isSubmittingScoresBatch || !selectedTournament || selectedCustomTournamentEntries.length === 0 || !scores || Object.keys(scores).length === 0}
              >
                {isSubmittingScoresBatch && <div className="button-spinner"></div>}
                {isSubmittingScoresBatch ? 'Submitting in Batches...' : 'Batch Submit Scores'}
                </button>
                </div>
              </>
            )}
            <div className="status-messages">
              <p>{scoreBatchUpdateProgress}</p>
              {scoreBatchUpdateStatusMessages.map((message, index) => (
                <p key={index}>{message}</p>
              ))}
            </div>
          </>
        )}
        {tournamentStatus && <p>{tournamentStatus}</p>}
    
      </div>
    </div>
  </div>
)}



{showLeaderboardPopup && (
  <div className="overlay">
    <div className="popup">
      <div className="popup-header">
        <button
          onClick={() => {
            setShowLeaderboardPopup(false);
            setSelectedLeaderboardPlayers([]);
            setSelectedLeaderboard('');
            setPlayerScores({});
            setSuccessMessage('');
            setErrorMessage('');
            resetDropdown();
            triggerLeaderboardPopup();
            setLeaderboardPopupStage('setup'); // Reset stage to setup
          }}
          className="close-button"
        >
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
        </button>
        <h1>Custom Leaderboard Simulator</h1>
      </div>
      <div className="breadcrumbs">
        <span onClick={() => handleNextLeaderboardStage('setup')} className={`breadcrumb ${leaderboardPopupStage === 'setup' ? 'active' : ''}`}>Setup Simulation</span>
        <span className="breadcrumb-divider">{' » '}</span>
        <span onClick={() => handleNextLeaderboardStage('scores')} className={`breadcrumb ${leaderboardPopupStage === 'scores' ? 'active' : ''}`}>Submit Scores</span>
      </div>
      <div className="popup-content">
        {leaderboardPopupStage === 'setup' && (
          <>
                   <h2>Initiate Custom Leaderboard Simulation</h2>
            <h3>Select a Custom Leaderboard</h3>
       
            <div className="leaderboard-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleLeaderboardDropdownVisibility}
  >
    {selectedLeaderboard 
      ? leaderboards.find(lb => lb.id === selectedLeaderboard)?.name + ` (${selectedLeaderboard})`
      : 'Select a leaderboard'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {leaderboardDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {leaderboards.filter(lb => lb.sourceType.name === 'custom').map((lb) => (
        <li
          key={lb.id}
          className="match-select-dropdown-option"
          onClick={() => handleLeaderboardSelect(lb.id)}
        >
          {lb.name} ({lb.id})
        </li>
      ))}
    </ul>
  )}
</div>

              <h3>Select Players</h3>
<table className="new-select-table ">
  <thead>
    <tr>
      <th>
        <input
          type="checkbox"
          checked={selectAllLeaderboardPlayers}
          onChange={(e) => {
            setSelectAllLeaderboardPlayers(e.target.checked);
            setSelectedLeaderboardPlayers(e.target.checked ? players.map(player => player.id) : []);
          }}
        />
      </th>
      <th>Player</th>
    </tr>
  </thead>
  <tbody>
    {players.map(player => (
      <tr key={player.uuid}>
        <td>
          <input
            type="checkbox"
            value={player.id}
            checked={selectedLeaderboardPlayers.includes(player.id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedLeaderboardPlayers([...selectedLeaderboardPlayers, player.id]);
              } else {
                setSelectedLeaderboardPlayers(selectedLeaderboardPlayers.filter(id => id !== player.id));
                setSelectAllLeaderboardPlayers(false);
              }
            }}
          />
        </td>
        <td>{player.email || player.customId || player.username}</td>
      </tr>
    ))}
  </tbody>
</table>

            <button
              onClick={() => handleNextLeaderboardStage('scores')}
              disabled={selectedLeaderboardPlayers.length === 0}
            >
              Proceed to Next Step
            </button>
          </>
        )}

        {leaderboardPopupStage === 'scores' && (
          <>
            <div className="selected-players">
            <h2>Submit Scores</h2>
              <h3>Generate scores and submit to end the simulation.</h3>
              <label>
                Min Outcome:
                <input
                  type="number"
                  value={minOutcome}
                  onChange={(e) => setMinOutcome(Number(e.target.value))}
                />
              </label>
              <label>
                Max Outcome:
                <input
                  type="number"
                  value={maxOutcome}
                  onChange={(e) => setMaxOutcome(Number(e.target.value))}
                />
              </label>
              <div>
                <label>
                  <input
                    type="radio"
                    value="unique"
                    checked={outcomeMode === 'unique'}
                    onChange={(e) => setOutcomeMode(e.target.value)}
                  />
                  Unique Outcomes
                </label>
                <label>
                  <input
                    type="radio"
                    value="ties"
                    checked={outcomeMode === 'ties'}
                    onChange={(e) => setOutcomeMode(e.target.value)}
                  />
                  Include Ties
                </label>
              </div>
              {outcomeMode === 'ties' && (
                <>
                  <label>
                    Tie All:
                    <input
                      type="checkbox"
                      checked={tieAll}
                      onChange={(e) => setTieAll(e.target.checked)}
                    />
                  </label>
                  {!tieAll && (
                    <label>
                      Number of Tied Players:
                      <input
                        type="number"
                        value={numTied}
                        onChange={(e) => setNumTied(Number(e.target.value))}
                        max={selectedLeaderboardPlayers.length}
                      />
                    </label>
                  )}
                </>
              )}
              <button onClick={generateLeaderboardOutcomes} disabled={selectedLeaderboardPlayers.length === 0}>Generate Outcomes</button>
              <table className="new-select-table">
    <thead>
      <tr>
        <th>Player</th>
        <th>Score</th>
      </tr>
    </thead>
    <tbody>
      {selectedLeaderboardPlayers.map(playerId => (
        <tr key={playerId}>
          <td>{players.find(p => p.id === playerId).email ||
            players.find(p => p.id === playerId).customId ||
            players.find(p => p.id === playerId).username}</td>
          <td>
            <input
              type="number"
              placeholder="Score"
              value={playerScores[playerId] || ''}
              onChange={(e) => setPlayerScores({ ...playerScores, [playerId]: Number(e.target.value) })}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
           
            <div className="then-divider"></div>
            <button
              className="loading-button"
              onClick={handlePostScoreToLeaderboard}
              disabled={isPostingScore || !selectedLeaderboard || selectedLeaderboardPlayers.length === 0}
            >
              {isPostingScore && <div className="button-spinner"></div>}
              {isPostingScore ? 'Posting...' : 'Post Scores to Leaderboard'}
            </button>
            {dirtcubeAuthenticated && (
              <>
            <div className="or-divider">or</div>
            <div className="batch-update-container">
              <label>Input Batch Size & Submit Scores (Only for Specter Devs)</label>
              <input
                type="number"
                value={leaderboardBatchSize}
                onChange={(e) => setLeaderboardBatchSize(Number(e.target.value))}
                min="1"
                max={selectedLeaderboardPlayers.length}
                placeholder="Batch Size"
              />
               <button
                className="loading-button"
                onClick={() => handlePostScoreToLeaderboardBatch(leaderboardBatchSize)}
                disabled={isPostingScoreBatch || !selectedLeaderboard || selectedLeaderboardPlayers.length === 0 || !playerScores || Object.keys(playerScores).length === 0}
              >
                {isPostingScoreBatch && <div className="button-spinner"></div>}
                {isPostingScoreBatch ? 'Posting in Batches...' : 'Batch Submit Scores'}
                </button>
                </div>
              </>
            )}

            <div className="status-messages">
              <p>{leaderboardBatchUpdateProgress}</p>
              {leaderboardBatchUpdateStatusMessages.map((message, index) => (
                <p key={index}>{message}</p>
              ))}
            </div>
          </>
        )}

        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  </div>
)}




   {showUpdateWalletPopup && (
        <div className="overlay">
          <div className="popup">
            <div className="popup-header">
            <button 
    onClick={handleCloseUpdateWalletPopup} 
    className="close-button"
  >
    <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
  </button>

  <h1>Bulk Update Wallets</h1>
</div>
<div className="popup-content">
  <h2>Current Wallet Balances</h2>
  <table>
    <thead>
      <tr>
        <th>Player Name</th>
        {currencies.map(currency => (
          <th key={currency.id}>{currency.name}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {players.map(player => (
        <tr key={player.id}>
          <td>{player.email || player.username || player.customId}</td>
          {currencies.map(currency => (
            <td key={currency.id}>
              {updatedWalletBalances[player.id]?.[currency.id] || 0}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
  <h3>Select Currency</h3>
  <div className="currency-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleCurrencyDropdownVisibility}
  >
    {selectedCurrency 
      ? currencies.find(currency => currency.id === selectedCurrency)?.name
      : 'Select a currency'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {currencyDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {currencies.map((currency) => (
        <li
          key={currency.id}
          className="match-select-dropdown-option"
          onClick={() => handleCurrencySelect(currency.id)}
        >
          {currency.name}
        </li>
      ))}
    </ul>
  )}
</div>

  <h3>Enter Amount</h3>
  <input
    type="number"
    value={updateAmount}
    onChange={(e) => setUpdateAmount(Number(e.target.value))}
    placeholder="Amount"
  />
  <h3>Select Operator</h3>
  <div className="operator-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleOperatorDropdownVisibility}
  >
    {selectedOperator.charAt(0).toUpperCase() + selectedOperator.slice(1)}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {operatorDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      <li
        className="match-select-dropdown-option"
        onClick={() => handleOperatorSelect('add')}
      >
        Add
      </li>
      <li
        className="match-select-dropdown-option"
        onClick={() => handleOperatorSelect('subtract')}
      >
        Subtract
      </li>
    </ul>
  )}
</div>

  <h3>Select Players</h3>
  <table className="new-select-table">
    <thead>
      <tr>
        <th>
          <input
            type="checkbox"
            checked={selectAllWalletPlayers}
            onChange={(e) => {
              setSelectAllWalletPlayers(e.target.checked);
              setSelectedWalletPlayers(e.target.checked ? players.map(player => player.id) : []);
            }}
          />
        </th>
        <th>Player</th>
      </tr>
    </thead>
    <tbody>
      {players.map(player => (
        <tr key={player.id}>
          <td>
            <input
              type="checkbox"
              value={player.id}
              checked={selectedWalletPlayers.includes(player.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedWalletPlayers([...selectedWalletPlayers, player.id]);
                } else {
                  setSelectedWalletPlayers(selectedWalletPlayers.filter(id => id !== player.id));
                  setSelectAllWalletPlayers(false);
                }
              }}
            />
          </td>
          <td>{player.email || player.customId || player.username}</td>
        </tr>
      ))}
    </tbody>
  </table>
  <div className="then-divider"></div>
  <button 
    className="loading-button"
    onClick={handleUpdateWallets} 
    disabled={!selectedCurrency || selectedWalletPlayers.length === 0 || !updateAmount || !selectedOperator || isUpdatingWallets}
  >
    {isUpdatingWallets && <div className="button-spinner"></div>}
    {isUpdatingWallets ? 'Updating...' : 'Update Wallets'}
  </button>
  {dirtcubeAuthenticated && (
          <>
  <div className="or-divider">or</div>
  <div className="batch-update-container">
    <label>Input Batch Size & Update All Wallets (Only for Specter Devs)</label>
    <input
      type="number"
      value={batchSize}
      onChange={(e) => setBatchSize(Number(e.target.value))}
      min="1"
      max={selectedWalletPlayers.length}
      placeholder="Batch Size"
      className="batch-size-input"
    />
    <button 
      className="loading-button"
      onClick={() => handleUpdateWalletsBatch(batchSize)} 
      disabled={!selectedCurrency || selectedWalletPlayers.length === 0 || !updateAmount || !selectedOperator || isUpdatingWalletsBatch}
    >
      {isUpdatingWalletsBatch && <div className="button-spinner"></div>}
      {isUpdatingWalletsBatch ? 'Updating in Batches...' : 'Batch Update Wallets'}
      </button>
      
            </div>
          </>
        )}
  <div className="status-messages">
    <p>{walletUpdateProgress}</p>
    {walletUpdateStatusMessages.map((message, index) => (
      <p key={index}>{message}</p>
    ))}
  </div>
</div>
</div>
</div>
)}

{showUpdateProgressPopup && (
  <div className="overlay">
    <div className="popup">
      <div className="popup-header">
        <button onClick={handleCloseUpdateProgressPopup} className="close-button">
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
        </button>
        <h1>Bulk Update Progress</h1>
      </div>
      <div className="popup-content">
        <h2>Current Progress</h2>
        <table>
          <thead>
            <tr>
              <th>Player Name</th>
              {progressMarkers.map(marker => (
                <th key={marker.id}>{marker.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {players.map(player => (
              <tr key={player.id}>
                <td>{player.email || player.username || player.customId}</td>
                {progressMarkers.map(marker => (
                  <td key={marker.id}>
                    {updatedProgress[player.id]?.[marker.id] || 0}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <h3>Select Progress Marker</h3>
        <div className="progress-marker-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleProgressMarkerDropdownVisibility}
  >
    {selectedProgressMarker 
      ? progressMarkers.find(marker => marker.id === selectedProgressMarker)?.name
      : 'Select a marker'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {progressMarkerDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {progressMarkers.map((marker) => (
        <li
          key={marker.id}
          className="match-select-dropdown-option"
          onClick={() => handleProgressMarkerSelect(marker.id)}
        >
          {marker.name}
        </li>
      ))}
    </ul>
  )}
</div>

        <h3>Enter Amount</h3>
        <input
          type="number"
          value={progressAmount}
          onChange={(e) => setProgressAmount(Number(e.target.value))}
          placeholder="Amount"
        />
        <h3>Select Operator</h3>
        <div className="progress-operator-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleProgressOperatorDropdownVisibility}
  >
    {progressOperator.charAt(0).toUpperCase() + progressOperator.slice(1)}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {progressOperatorDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      <li
        className="match-select-dropdown-option"
        onClick={() => handleProgressOperatorSelect('add')}
      >
        Add
      </li>
      <li
        className="match-select-dropdown-option"
        onClick={() => handleProgressOperatorSelect('subtract')}
      >
        Subtract
      </li>
    </ul>
  )}
</div>

        <h3>Select Players</h3>
        <table className="new-select-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAllProgressPlayers}
                  onChange={(e) => {
                    setSelectAllProgressPlayers(e.target.checked);
                    setSelectedProgressPlayers(e.target.checked ? players.map(player => player.id) : []);
                  }}
                />
              </th>
              <th>Player</th>
            </tr>
          </thead>
          <tbody>
            {players.map(player => (
              <tr key={player.id}>
                <td>
                  <input
                    type="checkbox"
                    value={player.id}
                    checked={selectedProgressPlayers.includes(player.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedProgressPlayers([...selectedProgressPlayers, player.id]);
                      } else {
                        setSelectedProgressPlayers(selectedProgressPlayers.filter(id => id !== player.id));
                        setSelectAllProgressPlayers(false);
                      }
                    }}
                  />
                </td>
                <td>
                  {player.email || player.customId || player.username}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="then-divider"></div>
        <button
          className="loading-button"
          onClick={handleUpdateProgress}
          disabled={!selectedProgressMarker || selectedProgressPlayers.length === 0 || !progressAmount || !progressOperator || isUpdatingProgress}
        >
          {isUpdatingProgress && <div className="button-spinner"></div>}
          {isUpdatingProgress ? 'Updating...' : 'Update Progress'}
        </button>

        {dirtcubeAuthenticated && (
          <>
        <div className="or-divider">or</div>
        <div className="batch-update-container">
          <label>Input Batch Size & Update All Progress (Only for Specter Devs)</label>
          <input
            type="number"
            value={progressBatchSize}
            onChange={(e) => setProgressBatchSize(Number(e.target.value))}
            min="1"
            max={selectedProgressPlayers.length}
            placeholder="Batch Size"
          />
          <button
            className="loading-button"
            onClick={() => handleUpdateProgressBatch(progressBatchSize)}
            disabled={!selectedProgressMarker || selectedProgressPlayers.length === 0 || !progressAmount || !progressOperator || isUpdatingProgressBatch}
          >
            {isUpdatingProgressBatch && <div className="button-spinner"></div>}
            {isUpdatingProgressBatch ? 'Batch Updating...' : 'Batch Update Progress'}
            </button>
            </div>
          </>
        )}
        </div>
      </div>
    </div>
 
)}


  
  </div>
  
);
  
}


// function PlayerTab({  dirtcubeAuthenticated, players,memberName,organisationName,selectedAppName, selectedConsumeItems,selectedEquipItems,  isLoading,
//   activeTab,player, projectId,apiKey, fetchProgress, updateMarker, fetchWallet, updateWallet, currencies, customEvents, selectedEvent, eventParams, handleSelectEvent, handleParamChange, handleFireEvent,   isFiringEvent,handleClearFields, successMessage, errorMessage,avatarStyle,handleFetchInventory,
//   inventoryItems,
//   selectedItems,
//   selectedBundles,
//   handleAddInventoryRow,
//   handleRemoveInventoryRow,
//   handleInventoryInputChange,
//   handleAddInventoryItem,
//   showAddInventoryCustomParamsPopup,
//   addInventoryCustomParams,
//   handleCustomParamChange,
//   handleRemoveCustomParam,
//   handleAddCustomParam,
//   handleSubmitAddInventoryItem,
//   setShowAddInventoryCustomParamsPopup,
//   selectedInventoryItems,
//   handleAddConsumeItemRow,
//   handleConsumeItemInputChange,
//   handleRemoveConsumeItemRow,
//   handleConsumeItem,
//   showConsumeInventoryCustomParamsPopup,
//   consumeInventoryCustomParams,
//   handleSubmitConsumeItem,
//   setShowConsumeInventoryCustomParamsPopup,
//   handleAddEquipItemRow,
//   handleEquipItemInputChange,
//   handleRemoveEquipItemRow,
//   handleEquipItem,
//   showEquipInventoryCustomParamsPopup,
//   equipInventoryCustomParams,
//   handleSubmitEquipItem,
//   setShowEquipInventoryCustomParamsPopup,
//   inventoryStatus,
//   resetInventoryState,selectedEnv,devIp }) {
//   const [progressData, setProgressData] = useState([]);
//   const [walletData, setWalletData] = useState([]);
//   const [markerOperation, setMarkerOperation] = useState('add');
//   const [markerAmount, setMarkerAmount] = useState();
//   const [currencyOperation, setCurrencyOperation] = useState('add');
//   const [currencyAmount, setCurrencyAmount] = useState();
//   const [selectedMarker, setSelectedMarker] = useState('');
//   const [selectedCurrency, setSelectedCurrency] = useState('');
//   const [markerOptions, setMarkerOptions] = useState([]);
//   const [currencyOptions, setCurrencyOptions] = useState([]);
//   const [isFetched, setIsFetched] = useState(false);
//   const [markerCustomParams, setMarkerCustomParams] = useState([{ name: '', value: '', type: 'string' }]);
//   const [walletCustomParams, setWalletCustomParams] = useState([{ name: '', value: '', type: 'string' }]);
//   // New state for tasks
//   const [tasks, setTasks] = useState([]);
//   const [selectedTasks, setSelectedTasks] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);
//   const [taskLoading, setTaskLoading] = useState(false);
//   const [taskFetchStatus, setTaskFetchStatus] = useState('');
//   const [rewards, setRewards] = useState([]);
//   const [selectedRewards, setSelectedRewards] = useState([]);
//   const [selectAllRewards, setSelectAllRewards] = useState(false);
//   const [rewardLoading, setRewardLoading] = useState(false);
//   const [rewardFetchStatus, setRewardFetchStatus] = useState('');
//   const serializeRewardKey = (reward) => `${reward.sourceId}-${reward.sourceType}-${reward.instanceId || ''}`;
//   const [groupedRewards, setGroupedRewards] = useState([]);
//   const [rewardSets, setRewardSets] = useState({});
//   const [granting, setGranting] = useState(false);
//   const [grantStatus, setGrantStatus] = useState('');
//   const [grantingSequential, setGrantingSequential] = useState(false);
// const [grantStatusSequential, setGrantStatusSequential] = useState('');
// const [items, setItems] = useState([]);
// const [bundles, setBundles] = useState([]);
//   const playerName = player.email || player.customId || player.username; // Variable for player's identity
//   const [avatarUrl, setAvatarUrl] = useState('');
//   const [avatarLoading, setAvatarLoading] = useState(false);

//   const [showProfilePopup, setShowProfilePopup] = useState(false);
//   const [profileData, setProfileData] = useState({
//     displayName: '',
//     firstName: '',
//     lastName: '',
//   });



//   const [globalLoading, setGlobalLoading] = useState(false);


//   const [showSnakeGame, setShowSnakeGame] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       setGlobalLoading(true);
//       try {
//         await Promise.all([fetchPlayerProgress(), fetchPlayerWallet(), fetchUpdatedProfile(), initializeAvatarForPlayer(player)]);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       } finally {
//         setGlobalLoading(false);
//       }
//     };
  
//     if (player) {
//       fetchData();
//     }
//   }, [player, setGlobalLoading]);
  



//   const [isEditMode, setIsEditMode] = useState(false);
  
//   const [copySuccess, setCopySuccess] = useState(false);

//   const [avatarUrls, setAvatarUrls] = useState({});
//   const [avatarLoadingStatus, setAvatarLoadingStatus] = useState({});



//   const [stores, setStores] = useState([]);
//   const [selectedStore, setSelectedStore] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [storeContent, setStoreContent] = useState([]);
//   const [showCustomPurchasePopup, setShowCustomPurchasePopup] = useState(false);
//   const [currentPrices, setCurrentPrices] = useState([]);
//   const [newPrice, setNewPrice] = useState({ currencyDetails: { id: '' }, price: 0, discount: 0 });
  
//   const [showDefaultPurchasePopup, setShowDefaultPurchasePopup] = useState(false);
//   const [defaultPurchaseContent, setDefaultPurchaseContent] = useState(null);
//   const [purchaseAmount, setPurchaseAmount] = useState(1);
//   const [collectionId, setCollectionId] = useState('');
//   const [stackId, setStackId] = useState('');
//   const [storeId, setStoreId] = useState('');
  

//   const [formData, setFormData] = useState({
//     displayName: '',
//     firstName: '',
//     lastName: ''
//   });

//   useEffect(() => {
//     if (isEditMode) {
//       setFormData({
//         displayName: profileData.displayName,
//         firstName: profileData.firstName,
//         lastName: profileData.lastName
//       });
//     }
//   }, [isEditMode, profileData]);
  


//   const [isSessionLogViewerOpen, setIsSessionLogViewerOpen] = useState(false);
  

//   const handleOpenLogViewer = () => {
//     setIsSessionLogViewerOpen(true);
//   };
  
//   const handleCloseLogViewer = () => {
//     setIsSessionLogViewerOpen(false);
//   };




//   const fetchItems = async (apiKey, selectedEnv, devIp, projectId, token) => {
//     const headers = {
//       'Content-Type': 'application/json',
//       'Api-Key': apiKey,
//       'Authorization': `Bearer ${token}`,
//       'env': selectedEnv,
//       ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//     };
  
//     let allItems = [];
//     let offset = 0;
//     const limit = 10;
//     let hasMoreItems = true;
  
//     try {
//       while (hasMoreItems) {
//         const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-items`, {
//           method: 'POST',
//           headers: headers,
//           body: JSON.stringify({
//             projectId,
//             limit: limit,
//             offset: offset,
//           }),
//         });
  
//         const data = await response.json();
//         if (!response.ok) {
//           throw new Error(data.message || 'Failed to fetch items');
//         }
  
//         allItems = [...allItems, ...data.data.items];
  
//         if (data.data.items.length < limit) {
//           hasMoreItems = false;
//         } else {
//           offset += limit;
//         }
//       }
  
//       return allItems;
//     } catch (error) {
//       console.error('Error fetching items:', error);
//       throw error;
//     }
//   };
  
//   const fetchBundles = async (apiKey, selectedEnv, devIp, projectId, token) => {
//     const headers = {
//       'Content-Type': 'application/json',
//       'Api-Key': apiKey,
//       'Authorization': `Bearer ${token}`,
//       'env': selectedEnv,
//       ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//     };
  
//     let allBundles = [];
//     let offset = 0;
//     const limit = 10;
//     let hasMoreBundles = true;
  
//     try {
//       while (hasMoreBundles) {
//         const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-bundles`, {
//           method: 'POST',
//           headers: headers,
//           body: JSON.stringify({
//             projectId,
//             limit: limit,
//             offset: offset,
//           }),
//         });
  
//         const data = await response.json();
//         if (!response.ok) {
//           throw new Error(data.message || 'Failed to fetch bundles');
//         }
  
//         allBundles = [...allBundles, ...data.data.bundles];
  
//         if (data.data.bundles.length < limit) {
//           hasMoreBundles = false;
//         } else {
//           offset += limit;
//         }
//       }
  
//       return allBundles;
//     } catch (error) {
//       console.error('Error fetching bundles:', error);
//       throw error;
//     }
//   };
  
  
//   // Call fetchItems and fetchBundles where needed, e.g., in event handlers or other effect hooks
//   const handleFetchItems = async () => {
//     try {
//       const items = await fetchItems(apiKey, selectedEnv, devIp, projectId, players[0].token);
//       setItems(items);
//     } catch (error) {
//       console.error("Error fetching items:", error);
//     }
//   };
  
//   const handleFetchBundles = async () => {
//     try {
//       const bundles = await fetchBundles(apiKey, selectedEnv, devIp, projectId, players[0].token);
//       setBundles(bundles);
//     } catch (error) {
//       console.error("Error fetching bundles:", error);
//     }
//   };
  
//   // Call these functions wherever needed in your component




//   const CustomCloseButton = ({ closeToast }) => (
//     <div className="neu-toast-close" onClick={closeToast}>
//       ✖
//     </div>
//   );



//     const fetchStores = async () => {
//       try {
//         const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/get-stores', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             'Api-Key': apiKey,
//             'Authorization': `Bearer ${player.token}`,
//             'env': selectedEnv,
//             ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//           },
//           body: JSON.stringify({projectId}),
//         });
  
//         const data = await response.json();
//         if (data.status === 'success') {
//           setStores(data.data.stores);
//         } else {
//           console.error(data.message);
//         }
//       } catch (error) {
//         console.error('Error fetching stores:', error);
//       }
//     };
  

//   useEffect(() => {
//     if (selectedStore) {
//       const fetchCategories = async () => {
//         try {
//           const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/get-categories', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//               'Api-Key': apiKey,
//               'Authorization': `Bearer ${player.token}`,
//               'env': selectedEnv,
//               ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//             },
//             body: JSON.stringify({
//               storeId: selectedStore.id,
//               limit: 10,
//               offset: 0,
//             }),
//           });
  
//           const data = await response.json();
//           if (data.status === 'success') {
//             setCategories(data.data);
//             setSelectedCategory(data.data[0]?.id || '');
//           } else {
//             console.error(data.message);
//           }
//         } catch (error) {
//           console.error('Error fetching categories:', error);
//         }
//       };
  
//       fetchCategories();
//     } else {
//       // Reset categories and selected category when no store is selected
//       setCategories([]);
//       setSelectedCategory(null);
//     }
//   }, [selectedStore, player, apiKey]);
  
  
//   useEffect(() => {
//     if (selectedStore && selectedCategory) {
//       const fetchStoreContents = async () => {
//         try {
//           setStoreContent([]); // Clear previous store content
//           let allContents = [];
//           let offset = 0;
//           const limit = 10; // Set the limit to the number of items you want to fetch per request
//           let hasMoreContents = true;
  
//           while (hasMoreContents) {
//             const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/get-contents', {
//               method: 'POST',
//               headers: {
//                 'Content-Type': 'application/json',
//                 'Api-Key': apiKey,
//                 'Authorization': `Bearer ${player.token}`,
//                 'env': selectedEnv,
//                 ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//               },
//               body: JSON.stringify({
//                 storeId: selectedStore.id,
//                 categoryId: selectedCategory,
//                 limit: limit,
//                 offset: offset,
//               }),
//             });
  
//             const data = await response.json();
//             if (data.status === 'success') {
//               const items = data.data.items.map(item => ({ ...item, type: 'item' }));
//               const bundles = data.data.bundles.map(bundle => ({ ...bundle, type: 'bundle' }));
//               const combinedContents = [...items, ...bundles];
//               allContents = [...allContents, ...combinedContents];
  
//               if (items.length < limit && bundles.length < limit) {
//                 hasMoreContents = false;
//               } else {
//                 offset += limit;
//               }
//             } else {
//               console.error(data.message);
//               hasMoreContents = false;
//             }
//           }
  
//           setStoreContent(allContents);
//         } catch (error) {
//           console.error('Error fetching store contents:', error);
//         }
//       };
  
//       fetchStoreContents();
//     } else {
//       // Clear store content when no store or category is selected
//       setStoreContent([]);
//     }
//   }, [selectedStore, selectedCategory, player, apiKey]);
  
  
//   const handleCategoryChange = (categoryId) => {
//     setSelectedCategory(categoryId);
//   };
  
//   const handleCustomPurchaseClick = (content) => {
//     setSelectedPurchaseContent(content); // Store the selected item or bundle
//     setCurrentPrices(content.prices);
//     toggleCustomPurchasePopup();
//   };
  
  
  
//   const handleRemovePrice = (index) => {
//     const updatedPrices = [...currentPrices];
//     updatedPrices.splice(index, 1);
//     setCurrentPrices(updatedPrices);
//   };

  
//   // Update the function to open the default purchase popup
//   const handleDefaultPurchaseClick = (content) => {
//     if (content.type) {
//       setDefaultPurchaseContent(content);
//       setStoreId(selectedStore.id); // Set the store ID from the selected store
//       toggleDefaultPurchasePopup();
//     } else {
//       console.error('Content type is not set correctly:', content);
//     }
//   };

//   const [isDefaultPurchaseLoading, setIsDefaultPurchaseLoading] = useState(false);
//   const [isCustomPurchaseLoading, setIsCustomPurchaseLoading] = useState(false);

//   const handleDefaultPurchase = async () => {
//     setIsDefaultPurchaseLoading(true);
//     const toastId = toast.loading('Processing default purchase...', {
//       className: 'neu-toast neu-toast-info',
//       progressClassName: 'neu-toast-progress',
//       closeButton: <CustomCloseButton />
//     });

//     const headers = {
//       'Content-Type': 'application/json',
//       'Api-Key': apiKey,
//       'Authorization': `Bearer ${player.token}`,
//       'env': selectedEnv,
//       ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
// };
  
//     const payload = {
//       items: defaultPurchaseContent?.type === 'item' ? [{
//         id: defaultPurchaseContent.id,
//         amount: purchaseAmount,
//         stackId: stackId,
//         collectionId: collectionId,
//         storeId: storeId  // Include storeId inside each item
//       }] : [],
//       bundles: defaultPurchaseContent?.type === 'bundle' ? [{
//         id: defaultPurchaseContent.id,
//         amount: purchaseAmount,
//         stackId: stackId,
//         collectionId: collectionId,
//         storeId: storeId  // Include storeId inside each bundle
//       }] : []
//     };
  
//     try {
//       const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/default-purchase', {
//         method: 'POST',
//         headers: headers,
//         body: JSON.stringify(payload),
//       });
  
//       const data = await response.json();
//       if (!response.ok) {
//         throw new Error(data.message || 'Failed to make default purchase');
//       }
  
//       if (data.data.items.length > 0) {
//         setStoreContent((prevContent) =>
//           prevContent.map((item) =>
//             item.id === defaultPurchaseContent.id ? { ...item, ...data.data.items[0] } : item
//           )
//         );
//       }
  
//       if (data.data.bundles.length > 0) {
//         setStoreContent((prevContent) =>
//           prevContent.map((bundle) =>
//             bundle.id === defaultPurchaseContent.id ? { ...bundle, ...data.data.bundles[0] } : bundle
//           )
//         );
//       }
  
//       toast.update(toastId, {
//         render: 'Default purchase successful!',
//         type: 'success',
//         isLoading: false,
//         autoClose: 5000,
//         closeButton: <CustomCloseButton />
//       });
  
//       toggleDefaultPurchasePopup(); // Close the popup
//       setPurchaseAmount(1);
//       setCollectionId('');
//       setStackId('');
//       setStoreId(''); // Reset store ID after purchase
//     } catch (error) {
//       toast.update(toastId, {
//         render: `Error: ${error.message}`,
//         type: 'error',
//         isLoading: false,
//         autoClose: 5000,
//         closeButton: <CustomCloseButton />
//       });
//       console.error('Error making default purchase:', error);
//     } finally {
//       setIsDefaultPurchaseLoading(false);
//     }
//   };
  
  


//   useEffect(() => {
//     if (currencies) {
//       setCurrencyOptions(currencies.map(currency => ({ id: currency.id, name: currency.name })));
//     }
//   }, [currencies]);

//   useEffect(() => {
//     setProgressData([]);
//     setWalletData([]);
//     setMarkerOperation('add');
//     setMarkerAmount();
//     setCurrencyOperation('add');
//     setCurrencyAmount();
//     setSelectedMarker('');
//     setSelectedCurrency('');
//     setMarkerOptions([]);
//     setCurrencyOptions(currencies.map(currency => ({ id: currency.id, name: currency.name }))); // Populate options on player change
//     setIsFetched(false);
//     setMarkerCustomParams([]);
//     setWalletCustomParams([]);
//     setTasks([]);
//     setSelectedTasks([]);
//     setSelectAll(false);
//     setTaskLoading(false);
//     setTaskFetchStatus('');
//     setRewards([]);
//     setSelectedRewards([]);
//     setSelectAllRewards(false);
//     setRewardLoading(false);
//     setRewardFetchStatus('');
//     setGroupedRewards([]);
//     setRewardSets({});
//     setGranting(false);
//     setGrantStatus('');
  
//   // Auto-call fetch functions
//   fetchPlayerProgress();
//   fetchPlayerWallet();
// }, [player]);
  

// const generateNames = () => {
//   setFormData({
//     displayName: generateGameyName(),
//     firstName: faker.person.firstName(),
//     lastName: faker.person.lastName(),
//   });
// };


// // Custom dictionaries
// const adjectives = [
//   'Epic', 'Mystic', 'Galactic', 'Shadow', 'Blazing', 'Ancient', 'Frozen', 'Quantum', 'Cyber', 'Arcane', 
//   'Infernal', 'Divine', 'Savage', 'Majestic', 'Silent', 'Swift', 'Raging', 'Thunder', 'Stealth', 'Vicious',
//   'Luminous', 'Abyssal', 'Celestial', 'Dark', 'Eternal', 'Furious', 'Glorious', 'Heroic', 'Immortal', 'Jaded',
//   'Legendary', 'Mythic', 'Noble', 'Omniscient', 'Phantom', 'Radiant', 'Supreme', 'Titanic', 'Unstoppable', 'Venerable'
// ];

// const nouns = [
//   'Phoenix', 'Dragon', 'Ninja', 'Samurai', 'Titan', 'Wizard', 'Viking', 'Sorcerer', 'Warrior', 'Assassin', 
//   'Knight', 'Ranger', 'Guardian', 'Berserker', 'Sentinel', 'Reaper', 'Hunter', 'Paladin', 'Druid', 'Monk',
//   'Gladiator', 'Warlord', 'Champion', 'Mage', 'Priest', 'Rogue', 'Necromancer', 'Shaman', 'Warlock', 'Archer',
//   'Barbarian', 'Conqueror', 'Defender', 'Enchanter', 'Fighter', 'Illusionist', 'Juggernaut', 'Keeper', 'Lancer', 'Mystic'
// ];

// const techTerms = [
//   'Byte', 'Pixel', 'Bit', 'Code', 'Crypto', 'Circuit', 'Quantum', 'Data', 'Nano', 'Cyber',
//   'Matrix', 'AI', 'Bot', 'Chip', 'Drive', 'Frame', 'Hack', 'Kernel', 'Link', 'Node',
//   'Module', 'Packet', 'Protocol', 'Server', 'Stream', 'Synth', 'Terminal', 'Transistor', 'Virtual', 'Wired',
//   'Algorithm', 'Bandwidth', 'Cache', 'Cluster', 'Domain', 'Firmware', 'Gateway', 'Interface', 'Latency', 'Network'
// ];

// const animals = [
//   'Lion', 'Tiger', 'Bear', 'Wolf', 'Eagle', 'Falcon', 'Shark', 'Panther', 'Leopard', 'Jaguar',
//   'Fox', 'Hawk', 'Puma', 'Cheetah', 'Raven', 'Cobra', 'Python', 'Viper', 'Raptor', 'Grizzly'
// ];

// const superheroes = [
//   'Superman', 'Batman', 'Spiderman', 'Ironman', 'Thor', 'Hulk', 'Wolverine', 'Flash', 'Aquaman', 'BlackPanther',
//   'WonderWoman', 'CaptainAmerica', 'DoctorStrange', 'GreenLantern', 'BlackWidow', 'Daredevil', 'Hawkeye', 'SilverSurfer', 'Cyclops', 'Storm'
// ];

// const allNouns = [...nouns, ...animals, ...superheroes];

// // Config for the unique names generator
// const config = {
//   dictionaries: [adjectives, techTerms, allNouns],
//   separator: '-',
//   length: 3,
// };

// const generateGameyName = () => {
//   return uniqueNamesGenerator(config);
// };


// const fetchPlayerProgress = async () => {
//   try {
//     const data = await fetchProgress(player.token, player.uuid);
//     if (data) {
//       setProgressData(data);
//       setMarkerOptions(data.map(marker => ({ id: marker.id, name: marker.name })));
//       setIsFetched(true);
//     } else {
//       setProgressData([]);
//     }
//   } catch (error) {
//     console.error('Error fetching player progress:', error);
//     setProgressData([]);
//   }
// };

// const fetchPlayerWallet = async () => {
//   try {
//     const currencyIds = currencies.map(currency => currency.id);
//     const data = await fetchWallet(player.token, currencyIds);
//     if (data) {
//       setWalletData(data);
//     } else {
//       setWalletData([]);
//     }
//   } catch (error) {
//     console.error('Error fetching player wallet:', error);
//     setWalletData([]);
//   }
// };
// //   const fetchPlayerPendingTasks = async () => {
// //     const headers = {
// //       'Content-Type': 'application/json',
// //       'Api-Key': apiKey,
// //       'Authorization': `Bearer ${player.token}`,
// //       'env': selectedEnv,
// //       ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
// // };

// //     let allTasks = [];
// //     let offset = 0;
// //     const limit = 10; // Set the limit to the number of tasks you want to fetch per request
// //     let hasMoreTasks = true;

// //     try {
// //       setTaskLoading(true); // Set loading state to true
// //       setTaskFetchStatus('Fetching pending tasks...'); // Update the status message

// //       while (hasMoreTasks) {
// //         const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/tasks/get-status', {
// //           method: 'POST',
// //           headers: headers,
// //           body: JSON.stringify({
// //             includeTaskGroupTasks: true,
// //             limit: limit,
// //             offset: offset,
// //           }),
// //         });

// //         const data = await response.json();
// //         if (!response.ok) {
// //           throw new Error(data.message || 'Failed to fetch tasks');
// //         }

// //         const pendingTasks = data.data.filter(task => task.status === 'pending');
// //         allTasks = [...allTasks, ...pendingTasks];

// //         if (data.data.length < limit) {
// //           hasMoreTasks = false; // If fewer tasks are returned than the limit, there are no more tasks
// //         } else {
// //           offset += limit; // Increase the offset for the next batch
// //         }
// //       }

// //       setTasks(allTasks);
// //       setTaskFetchStatus('All tasks fetched successfully'); // Update the status message
// //     } catch (error) {
// //       console.error('Error fetching tasks:', error);
// //       setTaskFetchStatus('Error fetching tasks'); // Update the status message
// //     } finally {
// //       setTaskLoading(false); // Set loading state to false
// //     }
// //   };

// const fetchPlayerPendingTasks = async () => {
//   const headers = {
//     'Content-Type': 'application/json',
//     'Api-Key': apiKey,
//     'Authorization': `Bearer ${player.token}`,
//     'env': selectedEnv,
//     ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//   };

//   let allTasks = [];
//   let offset = 0;
//   const limit = 10; // Set the limit to the number of tasks you want to fetch per request
//   let hasMoreTasks = true;

//   const toastId = toast.loading('Fetching pending tasks...', {
//     className: 'neu-toast neu-toast-info',
//     progressClassName: 'neu-toast-progress',
//    closeButton: <CustomCloseButton /> 
//   });

//   try {
//     setTaskLoading(true); // Set loading state to true
   
//     while (hasMoreTasks) {
//       const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/tasks/get-status', {
//         method: 'POST',
//         headers: headers,
//         body: JSON.stringify({
//           includeTaskGroupTasks: true,
//           limit: limit,
//           offset: offset,
//           status: 'pending', // Filter for pending tasks
//         }),
//       });

//       const data = await response.json();
//       if (!response.ok) {
//         throw new Error(data.message || 'Failed to fetch tasks');
//       }

//       allTasks = [...allTasks, ...data.data];

//       if (data.data.length < limit) {
//         hasMoreTasks = false; // If fewer tasks are returned than the limit, there are no more tasks
//       } else {
//         offset += limit; // Increase the offset for the next batch
//       }
//     }

//     setTasks(allTasks);
    
//     toast.update(toastId, {
//       render: 'All tasks fetched successfully!',
//       type: 'success',
//       isLoading: false,
//       autoClose: 5000
//     });
//   } catch (error) {
//     console.error('Error fetching tasks:', error);
   
//     toast.update(toastId, {
//       render: `Error fetching tasks: ${error.message}`,
//       type: 'error',
//       isLoading: false,
//       autoClose: 5000
//     });
//   } finally {
//     setTaskLoading(false); // Set loading state to false
//   }
// };


//   const handleForceCompleteTasks = async () => {
//     const headers = {
//       'Content-Type': 'application/json',
//       'Api-Key': apiKey,
//       'Authorization': `Bearer ${player.token}`,
//       'env': selectedEnv,
//       ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//     };
  
//     setTaskLoading(true); // Set loading state to true
//     const batchToastId = toast.loading(`Force completing selected tasks: 0/${selectedTasks.length}`, {
//       className: 'neu-toast neu-toast-info',
//       progressClassName: 'neu-toast-progress',
//      closeButton: <CustomCloseButton /> 
//     });
  
//     let completedTasksCount = 0;
  
//     for (const taskId of selectedTasks) {
//       try {
//         const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/tasks/force-complete', {
//           method: 'POST',
//           headers: headers,
//           body: JSON.stringify({
//             taskIds: [taskId],
//           }),
//         });
  
//         const data = await response.json();
//         if (!response.ok) {
//           throw new Error(data.message || 'Failed to force complete tasks');
//         }
  
//         completedTasksCount++;
//         toast.update(batchToastId, {
//           render: `Force completing selected tasks: ${completedTasksCount}/${selectedTasks.length}`,
//           type: 'info',
//           isLoading: true,
//         });
  
//       } catch (error) {
//         console.error(`Error force completing task ${taskId}:`, error);
//         toast.update(batchToastId, {
//           render: `Error force completing task ${taskId}`,
//           type: 'error',
//           isLoading: false,
//           autoClose: 5000
//         });
//         break;
//       }
//     }
  
//     if (completedTasksCount === selectedTasks.length) {
//       toast.update(batchToastId, {
//         render: 'All selected tasks force completed successfully!',
//         type: 'success',
//         isLoading: false,
//         autoClose: 5000
//       });
//     }
  
//     setTaskLoading(false); // Set loading state to false
//     fetchPlayerPendingTasks(); // Refresh the task list after all tasks are completed
//   };
  
//   // Handle selecting tasks
//   const handleSelectTask = (taskId) => {
//     if (selectedTasks.includes(taskId)) {
//       setSelectedTasks(selectedTasks.filter(id => id !== taskId));
//     } else {
//       setSelectedTasks([...selectedTasks, taskId]);
//     }
//   };

//   // Handle selecting all tasks
//   const handleSelectAll = () => {
//     if (selectAll) {
//       setSelectedTasks([]);
//     } else {
//       setSelectedTasks(tasks.map(task => task.id));
//     }
//     setSelectAll(!selectAll);
//   };
//   const fetchRewardBus = async () => {
//     const headers = {
//       'Content-Type': 'application/json',
//       'Api-Key': apiKey,
//       'Authorization': `Bearer ${player.token}`,
//       'env': selectedEnv,
//       ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//     };
  
//     let allRewards = [];
//     let offset = 0;
//     const limit = 10;
//     let hasMoreRewards = true;
  
//     const toastId = toast.loading('Fetching reward bus...', {
//       className: 'neu-toast neu-toast-info',
//       progressClassName: 'neu-toast-progress',
//      closeButton: <CustomCloseButton /> 
//     });
  
//     try {
//       setRewardLoading(true);
  
//       while (hasMoreRewards) {
//         const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/get-history', {
//           method: 'POST',
//           headers: headers,
//           body: JSON.stringify({
//             limit: limit,
//             offset: offset,
//             status: 'pending',
//           }),
//         });
  
//         const data = await response.json();
//         if (!response.ok) {
//           throw new Error(data.message || 'Failed to fetch rewards');
//         }
  
//         allRewards = [
//           ...allRewards,
//           ...data.data.items.map(reward => ({ ...reward, rewardType: 'item' })),
//           ...data.data.bundles.map(reward => ({ ...reward, rewardType: 'bundle' })),
//           ...data.data.currencies.map(reward => ({ ...reward, rewardType: 'currency' })),
//           ...data.data.progressionMarkers.map(reward => ({ ...reward, rewardType: 'progressionMarker' }))
//         ];
  
//         const totalFetchedThisIteration = data.data.items.length + data.data.bundles.length + data.data.currencies.length + data.data.progressionMarkers.length;
//         if (totalFetchedThisIteration < limit) {
//           hasMoreRewards = false;
//         } else {
//           offset += limit;
//         }
  
//         toast.update(toastId, {
//           render: `Fetching reward bus... ${offset} rewards fetched`,
//           type: 'info',
//           isLoading: true
//         });
//       }
  
//       setRewards(allRewards);
//       toast.update(toastId, {
//         render: 'All rewards fetched successfully',
//         type: 'success',
//         isLoading: false,
//         autoClose: 5000
//       });
//       groupRewards(allRewards);
//     } catch (error) {
//       toast.update(toastId, {
//         render: `Error fetching rewards: ${error.message}`,
//         type: 'error',
//         isLoading: false,
//         autoClose: 5000
//       });
//     } finally {
//       setRewardLoading(false);
//     }
//   };
  
// useEffect(() => {
//   if (rewardFetchStatus) {
//     const timer = setTimeout(() => setRewardFetchStatus(''), 3000); // Clear status after 3 seconds
//     return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
//   }
// }, [rewardFetchStatus]);



// // // Fetch Reward Bus
// // const fetchRewardBus = async () => {
// //   const headers = {
// //     'Content-Type': 'application/json',
// //     'Api-Key': apiKey,
// //     'Authorization': `Bearer ${player.token}`,
// //     'env': selectedEnv,
// //     ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
// //   };
  

// //   let allRewards = [];
// //   let offset = 0;
// //   const limit = 10; // Set the limit to the number of rewards you want to fetch per request
// //   let hasMoreRewards = true;

// //   try {
// //     setRewardLoading(true); // Set loading state to true
// //     setRewardFetchStatus('Fetching reward bus...'); // Update the status message

// //     while (hasMoreRewards) {
// //       const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/get-history', {
// //         method: 'POST',
// //         headers: headers,
// //         body: JSON.stringify({
// //           limit: limit,
// //           offset: offset,
         
// //         }),
// //       });

// //       const data = await response.json();
// //       if (!response.ok) {
// //         throw new Error(data.message || 'Failed to fetch rewards');
// //       }

// //       console.log('Raw fetched data:', data.data);

// //       // Add raw results to allRewards
// //       allRewards = [
// //         ...allRewards,
// //         ...data.data.items.map(reward => ({ ...reward, rewardType: 'item' })),
// //         ...data.data.bundles.map(reward => ({ ...reward, rewardType: 'bundle' })),
// //         ...data.data.currencies.map(reward => ({ ...reward, rewardType: 'currency' })),
// //         ...data.data.progressionMarkers.map(reward => ({ ...reward, rewardType: 'progressionMarker' }))
// //       ];


// //       // Check if the total number of items fetched in this iteration is less than the limit
// //       const totalFetchedThisIteration = data.data.items.length + data.data.bundles.length + data.data.currencies.length + data.data.progressionMarkers.length;
// //       if (totalFetchedThisIteration < limit) {
// //         hasMoreRewards = false;
// //       } else {
// //         offset += limit;
// //       }
// //     }

// //     // Filter pending rewards after fetching all possible rewards
// //     const pendingRewards = allRewards.filter(reward => reward.status === 'pending');

// //     console.log('Filtered pending rewards:', pendingRewards);

// //     setRewards(pendingRewards);
// //     setRewardFetchStatus('All rewards fetched successfully'); // Update the status message
// //     groupRewards(pendingRewards); // Group rewards after fetching
// //   } catch (error) {
// //     console.error('Error fetching rewards:', error);
// //     setRewardFetchStatus('Error fetching rewards'); // Update the status message
// //   } finally {
// //     setRewardLoading(false); // Set loading state to false
// //   }
// // };

// const handleSelectReward = (rewardKey) => {
//   if (selectedRewards.includes(rewardKey)) {
//     setSelectedRewards(selectedRewards.filter(key => key !== rewardKey));
//   } else {
//     setSelectedRewards([...selectedRewards, rewardKey]);
//   }
// };

// const handleSelectAllRewards = () => {
//   if (selectAllRewards) {
//     setSelectedRewards([]);
//   } else {
//     setSelectedRewards(Object.keys(groupedRewards)); // Use the serialized key
//   }
//   setSelectAllRewards(!selectAllRewards);
// };

// const groupRewards = (rewards) => {
//   console.log('Grouping rewards:', rewards);

//   const grouped = rewards.reduce((acc, reward) => {
//     const key = serializeRewardKey(reward);
//     if (!acc[key]) {
//       acc[key] = [];
//     }
//     acc[key].push(reward);
//     return acc;
//   }, {});

//   console.log('Grouped rewards:', grouped);

//   let setNumber = 1;
//   const rewardSets = Object.keys(grouped).reduce((acc, key) => {
//     acc[key] = setNumber++;
//     return acc;
//   }, {});

//   console.log('Reward sets:', rewardSets);

//   setGroupedRewards(grouped);
//   setRewardSets(rewardSets);
// };

// const grantRewardsOneByOne = async () => {
//   setGrantingSequential(true);

//   const rewardDetails = selectedRewards.map(key => {
//     if (groupedRewards[key] && groupedRewards[key].length > 0) {
//       const { sourceId, sourceType, instanceId } = groupedRewards[key][0];
//       return { source: { id: sourceId, type: sourceType, instanceId } };
//     }
//     return null;
//   }).filter(detail => detail !== null);

//   const toastId = toast.loading(`Granting rewards: 0/${rewardDetails.length}`, {
//     className: 'neu-toast neu-toast-info',
//     progressClassName: 'neu-toast-progress',
//    closeButton: <CustomCloseButton /> 
//   });

//   try {
//     for (let i = 0; i < rewardDetails.length; i++) {
//       const rewardDetail = rewardDetails[i];
//       const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/grant', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Api-Key': apiKey,
//           'Authorization': `Bearer ${player.token}`,
//           'env': selectedEnv,
//           ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//         },
//         body: JSON.stringify({ rewardDetails: [rewardDetail] }),
//       });

//       const data = await response.json();
//       if (!response.ok) {
//         throw new Error(data.message || 'Failed to grant reward');
//       }

//       toast.update(toastId, {
//         render: `Granting rewards: ${i + 1}/${rewardDetails.length}`,
//         type: 'info',
//         isLoading: true
//       });
//     }

//     toast.update(toastId, {
//       render: 'All rewards granted successfully',
//       type: 'success',
//       isLoading: false,
//       autoClose: 5000
//     });
//     await fetchRewardBus();
//   } catch (error) {
//     toast.update(toastId, {
//       render: `Error: ${error.message}`,
//       type: 'error',
//       isLoading: false,
//       autoClose: 5000
//     });
//   } finally {
//     setGrantingSequential(false);
//   }
// };

// const grantRewards = async () => {
//   setGranting(true);

//   const rewardDetails = selectedRewards.map(key => {
//     if (groupedRewards[key] && groupedRewards[key].length > 0) {
//       const { sourceId, sourceType, instanceId } = groupedRewards[key][0];
//       return { source: { id: sourceId, type: sourceType, instanceId } };
//     }
//     return null;
//   }).filter(detail => detail !== null);

//   const toastId = toast.loading(`Granting rewards: 0/${rewardDetails.length}`, {
//     className: 'neu-toast neu-toast-info',
//     progressClassName: 'neu-toast-progress',
//    closeButton: <CustomCloseButton /> 
//   });

//   try {
//     const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/grant', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Api-Key': apiKey,
//         'Authorization': `Bearer ${player.token}`,
//         'env': selectedEnv,
//         ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//       },
//       body: JSON.stringify({ rewardDetails }),
//     });

//     const data = await response.json();
//     if (!response.ok) {
//       throw new Error(data.message || 'Failed to grant rewards');
//     }

//     toast.update(toastId, {
//       render: 'All rewards granted successfully',
//       type: 'success',
//       isLoading: false,
//       autoClose: 5000
//     });
//     await fetchRewardBus();
//   } catch (error) {
//     toast.update(toastId, {
//       render: `Error: ${error.message}`,
//       type: 'error',
//       isLoading: false,
//       autoClose: 5000
//     });
//   } finally {
//     setGranting(false);
//   }
// };


// useEffect(() => {
//   if (grantStatus) {
//     const timer = setTimeout(() => setGrantStatus(''), 3000); // Clear status after 3 seconds
//     return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
//   }
// }, [grantStatus]);

// useEffect(() => {
//   if (grantStatusSequential) {
//     const timer = setTimeout(() => setGrantStatusSequential(''), 3000); // Clear status after 3 seconds
//     return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
//   }
// }, [grantStatusSequential]);


// const [grantBatchSize, setGrantBatchSize] = useState(0);
// const [grantBatchUpdateStatusMessages, setGrantBatchUpdateStatusMessages] = useState([]);
// const [grantBatchUpdateProgress, setGrantBatchUpdateProgress] = useState('');

// useEffect(() => {
//   setGrantBatchSize(selectedRewards.length);
// }, [selectedRewards]);


// const [batchGranting, setBatchGranting] = useState(false);



// const grantRewardsBatch = async (batchSize) => {
//   setBatchGranting(true);
//   setGrantBatchUpdateStatusMessages([]);
//   setGrantBatchUpdateProgress('');

//   const rewardDetails = selectedRewards.map(key => {
//     if (groupedRewards[key] && groupedRewards[key].length > 0) {
//       const { sourceId, sourceType, instanceId } = groupedRewards[key][0];
//       return { source: { id: sourceId, type: sourceType, instanceId } };
//     }
//     return null;
//   }).filter(detail => detail !== null);

//   const headers = {
//     'Content-Type': 'application/json',
//     'Api-Key': apiKey,
//     'Authorization': `Bearer ${player.token}`,
//     'env': selectedEnv,
//     ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//   };

//   const toastId = toast.loading(`Granting rewards: 0/${rewardDetails.length}`, {
//     className: 'neu-toast neu-toast-info',
//     progressClassName: 'neu-toast-progress',
//    closeButton: <CustomCloseButton /> 
//   });

//   try {
//     let grantedRewardsCount = 0;

//     for (let start = 0; start < rewardDetails.length; start += batchSize) {
//       const batch = rewardDetails.slice(start, start + batchSize);

//       const grantPromises = batch.map(async (rewardDetail) => {
//         const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/grant', {
//           method: 'POST',
//           headers: headers,
//           body: JSON.stringify({ rewardDetails: [rewardDetail] }),
//         });

//         const data = await response.json();
//         if (!response.ok) {
//           throw new Error(data.message || 'Failed to grant reward');
//         }

//         grantedRewardsCount++;

//         toast.update(toastId, {
//           render: `Granting rewards: ${grantedRewardsCount}/${rewardDetails.length}`,
//           type: 'info',
//           isLoading: true
//         });
//       });

//       await Promise.all(grantPromises);
//     }

//     toast.update(toastId, {
//       render: 'All rewards granted successfully',
//       type: 'success',
//       isLoading: false,
//       autoClose: 5000
//     });
//     await fetchRewardBus();
//   } catch (error) {
//     toast.update(toastId, {
//       render: `Error: ${error.message}`,
//       type: 'error',
//       isLoading: false,
//       autoClose: 5000
//     });
//   } finally {
//     setBatchGranting(false);
//   }
// };



//   const formatCustomParams = (params) => {
//     const formattedParams = {};
//     params.forEach(param => {
//       if (param.name && param.value) {
//         const key = param.name; // Ensure the parameter name is treated as a string
//         const value = param.type === 'number' ? Number(param.value) : param.type === 'boolean' ? param.value === 'true' : param.value;
//         formattedParams[`"${key}"`] = value; // Wrap the key in double quotes
//       }
//     });
//     return formattedParams;
//   };

//   const [isUpdatingMarker, setIsUpdatingMarker] = useState(false);

//   const handleUpdateMarker = async () => {
//     const customParams = formatCustomParams(markerCustomParams);
  
//     if (!selectedMarker) {
//       toast.error('Please select a marker', {
//         className: 'neu-toast neu-toast-error',
//         progressClassName: 'neu-toast-progress',
//        closeButton: <CustomCloseButton /> 
//       });
//       return;
//     }
  
//     setIsUpdatingMarker(true);
//     const toastId = toast.loading('Updating marker...', {
//       className: 'neu-toast neu-toast-info',
//       progressClassName: 'neu-toast-progress',
//      closeButton: <CustomCloseButton /> 
//     });
  
//     try {
//       const updatedMarker = await updateMarker(player.token, selectedMarker, markerOperation, markerAmount, customParams);
//       if (updatedMarker) {
//         fetchPlayerProgress(); // Refresh the progress data
//         toast.update(toastId, {
//           render: 'Marker updated successfully!',
//           type: 'success',
//           isLoading: false,
//           autoClose: 5000
//         });
//       } else {
//         throw new Error('Failed to update marker');
//       }
//     } catch (error) {
//       toast.update(toastId, {
//         render: `Error: ${error.message}`,
//         type: 'error',
//         isLoading: false,
//         autoClose: 5000
//       });
//     } finally {
//       setIsUpdatingMarker(false);
//     }
//   };


//   const [isUpdatingWallet, setIsUpdatingWallet] = useState(false);

 
// const handleUpdateWallet = async () => {
//   const customParams = formatCustomParams(walletCustomParams);

//   if (!selectedCurrency) {
//     toast.error('Please select a currency', {
//       className: 'neu-toast neu-toast-error',
//       progressClassName: 'neu-toast-progress',
//      closeButton: <CustomCloseButton /> 
//     });
//     return;
//   }

//   setIsUpdatingWallet(true);
//   const toastId = toast.loading('Updating wallet...', {
//     className: 'neu-toast neu-toast-info',
//     progressClassName: 'neu-toast-progress',
//    closeButton: <CustomCloseButton /> 
//   });

//   try {
//     const updatedWallet = await updateWallet(player.token, selectedCurrency, currencyOperation, currencyAmount, customParams);
//     if (updatedWallet) {
//       fetchPlayerWallet(); // Refresh the wallet data
//       toast.update(toastId, {
//         render: 'Wallet updated successfully!',
//         type: 'success',
//         isLoading: false,
//         autoClose: 5000
//       });
//     } else {
//       throw new Error('Failed to update wallet');
//     }
//   } catch (error) {
//     toast.update(toastId, {
//       render: `Error: ${error.message}`,
//       type: 'error',
//       isLoading: false,
//       autoClose: 5000
//     });
//   } finally {
//     setIsUpdatingWallet(false);
//   }
// };

//   const handleAddMarkerCustomParam = () => {
//     setMarkerCustomParams([...markerCustomParams, { name: '', value: '', type: 'string' }]);
//   };

//   const handleRemoveMarkerCustomParam = (index) => {
//     const newParams = markerCustomParams.filter((_, idx) => idx !== index);
//     setMarkerCustomParams(newParams);
//   };

//   const handleMarkerCustomParamChange = (index, field, value) => {
//     const newParams = [...markerCustomParams];
//     newParams[index][field] = value;
//     setMarkerCustomParams(newParams);
//   };

//   const handleAddWalletCustomParam = () => {
//     setWalletCustomParams([...walletCustomParams, { name: '', value: '', type: 'string' }]);
//   };

//   const handleRemoveWalletCustomParam = (index) => {
//     const newParams = walletCustomParams.filter((_, idx) => idx !== index);
//     setWalletCustomParams(newParams);
//   };

//   const handleWalletCustomParamChange = (index, field, value) => {
//     const newParams = [...walletCustomParams];
//     newParams[index][field] = value;
//     setWalletCustomParams(newParams);
//   };
  
//  // Function to generate Dicebear Avatars URL with the updated endpoint
//  const getAvatarUrl = (name) => {
//   if (avatarStyle === 'None') {
//     return null;
//   }
//   if (avatarStyle === 'GameStarz') {
//     return getRandomGameStarzImage();
//   }
//   return `https://api.dicebear.com/8.x/${avatarStyle}/svg?seed=${encodeURIComponent(name)}`;
// };

//   // Step 2: Convert and Save SVG Image


  
//   const fetchAndConvertAvatar = async (url) => {
//     const response = await fetch(url);
//     const svgText = await response.text();
  
//     // Convert SVG to PNG using Dicebear conversion module
//     const png = toPng(svgText);
//     const pngArrayBuffer = await png.toArrayBuffer();
  
//     // Create an Image element and load the PNG data
//     const blob = new Blob([pngArrayBuffer], { type: 'image/png' });
//     const img = new Image();
//     const urlObject = URL.createObjectURL(blob);
//     img.src = urlObject;
  
//     // Wait for the image to load
//     await new Promise((resolve) => {
//       img.onload = resolve;
//     });
  
//     // Resize the image using Pica
//     const canvas = document.createElement('canvas');
//     const outputCanvas = document.createElement('canvas');
//     canvas.width = img.width;
//     canvas.height = img.height;
//     outputCanvas.width = 256;
//     outputCanvas.height = 256;
//     const ctx = canvas.getContext('2d');
//     ctx.drawImage(img, 0, 0);
  
//     const pica = new Pica();
//     await pica.resize(canvas, outputCanvas);
  
//     // Convert the resized canvas to a Blob
//     const resizedBlob = await pica.toBlob(outputCanvas, 'image/png');
  
//     // Convert the Blob to a File
//     const fileName = `${playerName.replace(/\s+/g, '_')}-icon.png`;
//     const file = new File([resizedBlob], fileName, { type: 'image/png' });
  
//     // Clean up the URL object
//     URL.revokeObjectURL(urlObject);
  
//     return file;
//   };
//   const uploadToS3 = async (file) => {
//     const formData = new FormData();
    
//     // Create a new file object with the prefixed name
//     const prefixedFile = new File([file], `playground-${file.name}`, { type: file.type });
    
//     formData.append('file', prefixedFile);
  
//     const headers = {
//       'Authorization': `Bearer ${player.token}`,
//       'Api-Key': apiKey,
//       'env': selectedEnv,
//       ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//     };
  
//     const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/upload-file', {
//       method: 'POST',
//       headers: headers,
//       body: formData,
//     });
  
//     const data = await response.json();
//     if (data.status !== 'success') {
//       console.error('Upload failed:', data.message);
//       return;
//     }
  
//     return data.data.locationUrl;
//   };
  
  

//   const updatePlayerProfile = async (token, s3Url) => {
//     const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/user/update-profile', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Api-Key': apiKey,
//         'Authorization': `Bearer ${token}`,
//         'env': selectedEnv,
//         ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//       },
//       body: JSON.stringify({ thumbUrl: s3Url }),
//     });
  
//     const data = await response.json();
//     if (data.status !== 'success') {
//       console.error('Profile update failed:', data.message);
//     }
//   };


  
//   // Step 5: Fetch Updated Player Profile
 
//   const fetchUpdatedProfile = async () => {
//     try {
//       const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/user/get-profile', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Api-Key': apiKey,
//           'Authorization': `Bearer ${player.token}`,
//           'env': selectedEnv,
//           ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//         },
//         body: JSON.stringify({}),
//       });

//       const data = await response.json();
//       if (data.status === 'success') {
//         setAvatarUrl(data.data.user.thumbUrl);
//         setProfileData({
//           displayName: data.data.user.displayName || '',
//           firstName: data.data.user.firstName || '',
//           lastName: data.data.user.lastName || '',
//         });
//       } else {
//         throw new Error('Failed to fetch player profile');
//       }
//     } catch (error) {
//       console.error('Error fetching player profile:', error);
//     }
//   };

//   useEffect(() => {
//     if (player) {
//       fetchUpdatedProfile();
//     }
//   }, [player, apiKey, selectedEnv]);


//   useEffect(() => {
//     setFormData({
//       displayName: profileData.displayName || '',
//       firstName: profileData.firstName || '',
//       lastName: profileData.lastName || '',
//     });
//   }, [player, profileData]);
  


  
  
//   const initializeAvatarForPlayer = async (player) => {
//     try {
//       setAvatarLoadingStatus((prevState) => ({ ...prevState, [player.id]: true }));
//       const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/user/get-profile', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Api-Key': apiKey,
//           'Authorization': `Bearer ${player.token}`,
//           'env': selectedEnv,
//           ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
//         },
//         body: JSON.stringify({}),
//       });
  
//       const data = await response.json();
//       if (data.status === 'success') {
//         const user = data.data.user;
  
//         if (user.thumbUrl) {
//           setAvatarUrls((prevState) => ({ ...prevState, [player.id]: user.thumbUrl }));
//         } else {
//           let avatarFile, s3Url;

//           if (avatarStyle === 'None') {
//             return;  // Skip avatar upload and profile update
//           }
  
//           if (avatarStyle === 'GameStarz') {
//             const gameStarzImageUrl = getAvatarUrl(player.email || player.customId || player.username);
//             const response = await fetch(gameStarzImageUrl);
//             const blob = await response.blob();
//             avatarFile = new File([blob], `${playerName.replace(/\s+/g, '_')}-icon.png`, { type: 'image/png' });
//             s3Url = await uploadToS3(avatarFile);
//           } else {
//             const dicebearUrl = getAvatarUrl(player.email || player.customId || player.username);
//             avatarFile = await fetchAndConvertAvatar(dicebearUrl);
//             s3Url = await uploadToS3(avatarFile);
//           }
  
//           await updatePlayerProfile(player.token, s3Url);
//           setAvatarUrls((prevState) => ({ ...prevState, [player.id]: s3Url }));
//         }
//       } else {
//         console.error('Failed to fetch player profile:', data.message);
//       }
//     } catch (error) {
//       console.error('Error initializing avatar:', error);
//     } finally {
//       setAvatarLoadingStatus((prevState) => ({ ...prevState, [player.id]: false }));
//     }
//   };

  

//   const initializeAvatarsForAllPlayers = async () => {
//     for (const player of players) {
//       await initializeAvatarForPlayer(player);
//     }
//   };
  
//   useEffect(() => {
//     initializeAvatarsForAllPlayers();
//   }, [players]);
  

  
//   const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);

//   const updateProfile = async () => {
//     setIsUpdatingProfile(true);
//     const toastId = toast.loading('Updating profile...', {
//       className: 'neu-toast neu-toast-info',
//       progressClassName: 'neu-toast-progress',
//       closeButton: <CustomCloseButton />
//     });
  
//     try {
//       const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/user/update-profile', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Api-Key': apiKey,
//           'Authorization': `Bearer ${player.token}`,
//           'env': selectedEnv,
//           ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//         },
//         body: JSON.stringify({
//           displayName: formData.displayName,
//           firstName: formData.firstName,
//           lastName: formData.lastName,
//         }),
//       });
  
//       const data = await response.json();
//       if (data.status === 'success') {
//         setProfileData({
//           displayName: formData.displayName,
//           firstName: formData.firstName,
//           lastName: formData.lastName,
//         });
//         setIsEditMode(false);
//         toast.update(toastId, {
//           render: 'Profile updated successfully!',
//           type: 'success',
//           isLoading: false,
//           autoClose: 5000,
//           closeButton: <CustomCloseButton />
//         });
//       } else {
//         throw new Error('Failed to update profile');
//       }
//     } catch (error) {
//       toast.update(toastId, {
//         render: `Error: ${error.message}`,
//         type: 'error',
//         isLoading: false,
//         autoClose: 5000,
//         closeButton: <CustomCloseButton />
//       });
//       console.error('Error updating profile:', error);
//     } finally {
//       setIsUpdatingProfile(false);
//     }
//   };
  
//   const copyToClipboard = (text) => {
//     navigator.clipboard.writeText(text).then(() => {
//       setCopySuccess(true);
//       setTimeout(() => setCopySuccess(false), 2000);
//     });
//   };


//   const toggleCustomPurchasePopup = () => {
//     if (!showCustomPurchasePopup) {
//       document.body.classList.add('modal-open');
//     } else {
//       document.body.classList.remove('modal-open');
//     }
//     setShowCustomPurchasePopup(!showCustomPurchasePopup);
//   };
  
//   const toggleDefaultPurchasePopup = () => {
//     if (!showDefaultPurchasePopup) {
//       document.body.classList.add('modal-open');
//     } else {
//       document.body.classList.remove('modal-open');
//     }
//     setShowDefaultPurchasePopup(!showDefaultPurchasePopup);
//   };




// const [showProgressPopup, setShowProgressPopup] = useState(false);
// const [showWalletPopup, setShowWalletPopup] = useState(false);
// const [showTasksPopup, setShowTasksPopup] = useState(false);
// const [showRewardsPopup, setShowRewardsPopup] = useState(false);
// const [showEventsPopup, setShowEventsPopup] = useState(false);

// const [showStoresPopup, setShowStoresPopup] = useState(false);
// const [showInventoryPopup, setShowInventoryPopup] = useState(false);

// const openModal = () => {
//   document.body.classList.add('modal-open');
// };

// const closeModal = () => {
//   document.body.classList.remove('modal-open');
// };

// // Ensure to call these functions in the appropriate places when showing/hiding popups
// useEffect(() => {
//   if (showProfilePopup || showProgressPopup || showWalletPopup || showTasksPopup || showRewardsPopup || showEventsPopup || showStoresPopup || showInventoryPopup) {
//     openModal();
//   } else {
//     closeModal();
//   }

//   // Clean up on unmount
//   return () => closeModal();
// }, [showProfilePopup, showProgressPopup, showWalletPopup, showTasksPopup, showRewardsPopup, showEventsPopup, showStoresPopup, showInventoryPopup]);



// const triggerStoresPopup =async()=>{
//   fetchStores()
// }

// const triggerinventoryPopup =async()=>{
//   handleFetchItems()
//   handleFetchBundles()
// }



//  const actions = [
//     { value: 'update-progress', label: 'Update Progress' },
//     { value: 'update-wallet', label: 'Update Wallet' },
//     { value: 'force-complete-task', label: 'Force Complete Tasks' },
//     { value: 'grant-pending-rewards', label: 'Grant Pending Rewards' },
//     { value: 'fire-custom-events', label: 'Fire Custom Events' },
//     { value: 'manage-stores', label: 'Make a Purchase' },
//     { value: 'manage-inventory', label: 'Manage Inventory' },
//   ];

//   const [showActionsDropdown, setShowActionsDropdown] = useState(false);
//   const [selectedAction, setSelectedAction] = useState('Player Actions');

//   const handleActionSelect = (action) => {
//     setSelectedAction(action.label);
//     setShowActionsDropdown(false);
//     switch (action.value) {
//       case 'update-progress':
//         setShowProgressPopup(true);
//         break;
//       case 'update-wallet':
//         setShowWalletPopup(true);
//         break;
//       case 'force-complete-task':
//         setShowTasksPopup(true);
//         break;
//       case 'grant-pending-rewards':
//         setShowRewardsPopup(true);
//         break;
//       case 'fire-custom-events':
//         setShowEventsPopup(true);
//         break;
//       case 'manage-stores':
//         setShowStoresPopup(true);
//         triggerStoresPopup()
//         break;
//       case 'manage-inventory':
//         setShowInventoryPopup(true);
//         triggerinventoryPopup()
//         break;
//       default:
//         break;
//     }
//   };

//   useEffect(() => {
//     const resetSelectedAction = () => setSelectedAction('Player Actions');
    
//     window.addEventListener('click', resetSelectedAction);
    
//     return () => {
//       window.removeEventListener('click', resetSelectedAction);
//     };
//   }, []);

// const getInitialsIcon = (name) => {
//   return `https://api.dicebear.com/8.x/initials/svg?seed=${encodeURIComponent(name)}`;
// };

// const [progressionSystems, setProgressionSystems] = useState([]);
// const handleFetchProgressionSystems = async () => {
//   try {
//     const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-progression-systems', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${player.token}`,
//         'Api-Key': apiKey,
//         'env': selectedEnv,
//         ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//       },
//       body: JSON.stringify({ projectId }),
//     });

//     const data = await response.json();
//     if (data.status === 'success') {
//       setProgressionSystems(data.data.progressionSystems || []);
//     } else {
//       throw new Error(data.message || 'Failed to fetch progression systems');
//     }
//   } catch (error) {
//     console.error('Error fetching progression systems:', error);
//   }
// };

// useEffect(() => {
//   handleFetchProgressionSystems();
// }, [player.token, projectId]);

// const getProgressionSystemName = (systemId) => {
//   const system = progressionSystems.find((sys) => sys.id === systemId);
//   return system ? system.name : systemId;
// };

// const getProgressionSystemIcon = (systemId) => {
//   const system = progressionSystems.find((sys) => sys.id === systemId);
//   if (system && system.iconUrl) {
//     return system.iconUrl;
//   } else {
//     return `https://api.dicebear.com/8.x/initials/svg?seed=${encodeURIComponent(getProgressionSystemName(systemId))}`;
//   }
// };


// const [selectedPurchaseContent, setSelectedPurchaseContent] = useState(null);

// const handleCustomPurchase = async () => {
//   setIsCustomPurchaseLoading(true);
//   const toastId = toast.loading('Processing custom purchase...', {
//     className: 'neu-toast neu-toast-info',
//     progressClassName: 'neu-toast-progress',
//     closeButton: <CustomCloseButton />
//   });

//   const headers = {
//     'Content-Type': 'application/json',
//     'Api-Key': apiKey,
//     'Authorization': `Bearer ${player.token}`,
//     'env': selectedEnv,
//     ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//   };

//   const payload = {};

//   const formatPrice = (price) => {
//     if (price.realWorldCurrency && price.realWorldCurrency.code) {
//       return {
//         id: selectedPurchaseContent.id,
//         amount: price.amount,
//         price: price.price,
//         collectionId: price.collectionId,
//         stackId: price.stackId,
//         storeId: storeId,
//         realWorldCurrencyId: price.realWorldCurrency.code
//       };
//     } else {
//       return {
//         id: selectedPurchaseContent.id,
//         amount: price.amount,
//         price: price.price,
//         currencyId: price.currencyDetails.id,
//         collectionId: price.collectionId,
//         stackId: price.stackId,
//         storeId: storeId
//       };
//     }
//   };

//   if (selectedPurchaseContent.type === 'item') {
//     payload.items = currentPrices.map(formatPrice);
//   } else if (selectedPurchaseContent.type === 'bundle') {
//     payload.bundles = currentPrices.map(formatPrice);
//   }

//   try {
//     const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/custom-purchase', {
//       method: 'POST',
//       headers: headers,
//       body: JSON.stringify(payload),
//     });

//     const data = await response.json();
//     if (!response.ok) {
//       throw new Error(data.message || 'Failed to make custom purchase');
//     }

//     if (data.data.items.length > 0 || data.data.bundles.length > 0) {
//       setStoreContent((prevContent) =>
//         prevContent.map((content) =>
//           (content.type === 'item' && data.data.items.some(item => item.id === content.id)) || 
//           (content.type === 'bundle' && data.data.bundles.some(bundle => bundle.id === content.id))
//             ? { ...content, ...data.data.items.find(item => item.id === content.id) || data.data.bundles.find(bundle => bundle.id === content.id) }
//             : content
//         )
//       );
//     }
//     toast.update(toastId, {
//       render: 'Custom purchase successful!',
//       type: 'success',
//       isLoading: false,
//       autoClose: 5000,
//       closeButton: <CustomCloseButton />
//     });

//     toggleCustomPurchasePopup(); // Close the popup
//   } catch (error) {
//     toast.update(toastId, {
//       render: `Error: ${error.message}`,
//       type: 'error',
//       isLoading: false,
//       autoClose: 5000,
//       closeButton: <CustomCloseButton />
//     });
//     console.error('Error making custom purchase:', error);
//   } finally {
//     setIsCustomPurchaseLoading(false);
//   }
// };

// const handleAddPrice = () => {
//   setCurrentPrices([
//     ...currentPrices,
//     { currencyDetails: { id: '' }, price: 0, amount: 1, collectionId: '', stackId: '' }
//   ]);
// };


// const [isFullScreen, setIsFullScreen] = useState(false);
// const toggleFullScreen = () => {
//   setIsFullScreen(!isFullScreen);
// };



// const uniqueCurrencies = currencies.map(currency => ({
//   id: currency.id,
//   name: currency.name
// }));

// const uniqueRealWorldCurrencies = [];

// storeContent.forEach(content => {
//   content.prices.forEach(price => {
//     if (price.realWorldCurrency && !uniqueRealWorldCurrencies.some(c => c.code === price.realWorldCurrency.code)) {
//       uniqueRealWorldCurrencies.push({
//         code: price.realWorldCurrency.code,
//         name: `${price.realWorldCurrency.name} (${price.realWorldCurrency.code})`
//       });
//     }
//   });
// });



// const getRandomGameStarzImage = () => {
//   const images = [
//     'gs_pp_1.jpeg',
//     'gs_pp_2.jpeg',
//     'gs_pp_3.jpeg',
//     'gs_pp_4.jpeg',
//     'gs_pp_5.jpeg',
//     'gs_pp_6.jpeg',
//     'gs_pp_7.jpeg',
//     'gs_pp_8.jpeg',
//     'gs_pp_9.jpeg',
//     'gs_pp_10.jpeg',
//     'gs_pp_11.jpeg',
//     'gs_pp_12.jpeg',
//     'gs_pp_13.jpeg',
//     'gs_pp_14.jpeg',
//     'gs_pp_15.jpeg',
//     'gs_pp_16.jpeg',
//     'gs_pp_17.jpeg',
//     'gs_pp_18.jpeg',
//     'gs_pp_19.jpeg',
//     'gs_pp_20.jpeg',
//     'gs_pp_21.jpeg',
//     'gs_pp_22.jpeg',
//     'gs_pp_23.jpeg',
//     'gs_pp_24.jpeg',
//     'gs_pp_25.jpeg',
//     'gs_pp_26.jpeg',
//     'gs_pp_27.jpeg',
//     'gs_pp_28.jpeg',
//     'gs_pp_29.jpeg',
//     'gs_pp_30.jpeg',
//     'gs_pp_31.jpeg',
//     'gs_pp_32.jpeg',
//     'gs_pp_33.jpeg',
//     'gs_pp_34.jpeg',
//     'gs_pp_35.jpeg',
    
    
//     // Add all your GameStarz images here
//   ];
//   const randomIndex = Math.floor(Math.random() * images.length);
//   return `${process.env.PUBLIC_URL}/assets/GameStarz/${images[randomIndex]}`;
// };


// const [storeDropdownVisibility, setStoreDropdownVisibility] = useState(false);

// const toggleStoreDropdownVisibility = () => {
//   setStoreDropdownVisibility(!storeDropdownVisibility);
// };

// const handleStoreSelect = (storeId) => {
//   setSelectedStore(stores.find(store => store.id === storeId));
//   setStoreDropdownVisibility(false);
// };


// const [markerDropdownVisibility, setMarkerDropdownVisibility] = useState(false);

// const toggleMarkerDropdownVisibility = () => {
//   setMarkerDropdownVisibility(!markerDropdownVisibility);
// };

// const handleMarkerSelect = (markerId) => {
//   setSelectedMarker(markerId);
//   setMarkerDropdownVisibility(false);
// };


// const [markerOperationDropdownVisibility, setMarkerOperationDropdownVisibility] = useState(false);

// const toggleMarkerOperationDropdownVisibility = () => {
//   setMarkerOperationDropdownVisibility(!markerOperationDropdownVisibility);
// };

// const handleMarkerOperationSelect = (operation) => {
//   setMarkerOperation(operation);
//   setMarkerOperationDropdownVisibility(false);
// };


// const [currencyDropdownVisibility, setCurrencyDropdownVisibility] = useState(false);

// const toggleCurrencyDropdownVisibility = () => {
//   setCurrencyDropdownVisibility(!currencyDropdownVisibility);
// };

// const handleCurrencySelect = (currencyId) => {
//   setSelectedCurrency(currencyId);
//   setCurrencyDropdownVisibility(false);
// };

// const [currencyOperationDropdownVisibility, setCurrencyOperationDropdownVisibility] = useState(false);

// const toggleCurrencyOperationDropdownVisibility = () => {
//   setCurrencyOperationDropdownVisibility(!currencyOperationDropdownVisibility);
// };

// const handleCurrencyOperationSelect = (operation) => {
//   setCurrencyOperation(operation);
//   setCurrencyOperationDropdownVisibility(false);
// };



// const [paramDropdownVisibility, setParamDropdownVisibility] = useState({});

// const toggleParamDropdownVisibility = (index) => {
//   setParamDropdownVisibility((prevVisibility) => ({
//     ...prevVisibility,
//     [index]: !prevVisibility[index],
//   }));
// };

// const handleParamSelect = (index, value) => {
//   handleMarkerCustomParamChange(index, 'type', value);
//   setParamDropdownVisibility((prevVisibility) => ({
//     ...prevVisibility,
//     [index]: false,
//   }));
// };

// const [walletParamDropdownVisibility, setWalletParamDropdownVisibility] = useState({});

// const toggleWalletParamDropdownVisibility = (index) => {
//   setWalletParamDropdownVisibility((prevVisibility) => ({
//     ...prevVisibility,
//     [index]: !prevVisibility[index],
//   }));
// };

// const handleWalletParamSelect = (index, value) => {
//   handleWalletCustomParamChange(index, 'type', value);
//   setWalletParamDropdownVisibility((prevVisibility) => ({
//     ...prevVisibility,
//     [index]: false,
//   }));
// };



//   return (
//     <div className="player-tab">
// {globalLoading && (
//   <div className="global-loading-overlay">
//     <img src={`${process.env.PUBLIC_URL}/assets/specter_landing.svg`} alt="Loading..." className="loading-logo" />
//   </div>
// )}


    
//     <div className="player-tab">
//         <div className="card">
//       <div className="actions-dropdown-container">
//         <div className="custom-dropdown">
//           <div className="custom-dropdown-header" onClick={() => setShowActionsDropdown(!showActionsDropdown)}>
//             {selectedAction}
//             <span className="custom-dropdown-arrow">&#9662;</span>
//           </div>
//           {showActionsDropdown && (
//             <ul className="custom-dropdown-list">
//               {actions.map((action) => (
//                 <li
//                   key={action.value}
//                   className="custom-dropdown-option"
//                   onClick={() => handleActionSelect(action)}
//                 >
//                   {action.label}
//                 </li>
//               ))}
//             </ul>
//           )}
//         </div>
//       </div>
//       <div className="player-content-container">
//       <div className="player-left-column">
//       <div className="player-overall-card">
//       <h1>Player Profile</h1>
//         <div className="player-card">
//         <div className="player-icon">
//   {avatarLoadingStatus[player.id] ? (
//     <div className="spinner-container">
//       <div className="spinner"></div>
//     </div>
//   ) : (
//     <ProtectedImage apiKey={apiKey} src={avatarUrls[player.id] || getInitialsIcon(player.email || player.customId || player.username)} alt="Player Icon" />
//   )}
// </div>

//           <div className="player-info">
//           <span className="player-name">{ player.email || player.customId || player.username}</span>

//             <div className="player-id">
//               {copySuccess ? (
//                 <span className="copy-success">Copied!</span>
//               ) : (
//                 <button className="copy-button" onClick={() => copyToClipboard(player.id)}>
//                   <img src={`${process.env.PUBLIC_URL}/assets/copy.svg`} alt="Copy" />
//                 </button>
//               )}
//               {player.id}
//             </div>
//           </div>
//         </div>

//     <div className="player-profile-card">
//   <div className="player-profile-card-header">
//     <h4>Player Bio</h4>
//     <button onClick={() => setIsEditMode(!isEditMode)} className={`edit-button ${isEditMode ? 'active' : ''}`}>
//       <img src={`${process.env.PUBLIC_URL}/assets/edit.svg`} alt="Edit" />
//     </button>
//   </div>
//   <div className="player-profile-card-contents">
//     {isEditMode && (
//       <button onClick={generateNames} className="generate-button">Generate</button>
//     )}
//     <div className="profile-field">
//       <label className="profile-label">Display Name</label>
//       <input
//         type="text"
//         value={formData.displayName}
//         onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
//         disabled={!isEditMode}
//       />
//     </div>
//     <div className="profile-field">
//       <label className="profile-label">First Name</label>
//       <input
//         type="text"
//         value={formData.firstName}
//         onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
//         disabled={!isEditMode}
//       />
//     </div>
//     <div className="profile-field">
//       <label className="profile-label">Last Name</label>
//       <input
//         type="text"
//         value={formData.lastName}
//         onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
//         disabled={!isEditMode}
//       />
//     </div>
//     {isEditMode && (
//       <div className="profile-popup-footer">
//         <button onClick={updateProfile} className="update-button" disabled={isUpdatingProfile}>
//           {isUpdatingProfile ? <div className="button-spinner"></div> : 'Update'}
//         </button>
//       </div>
//     )}


//      </div>
//         </div>
//         </div>
//         </div>
//       <div className="player-stats-card">
//       <h1>Player Stats</h1>
//       <h4>Progression Marker Stats</h4>
//       {progressData && progressData.length > 0 ? progressData.map((marker) => (
//   <div key={marker.id} className="progress-marker">
//     <div className="marker-info">
//       <ProtectedImage
//         src={marker.iconUrl || getInitialsIcon(marker.name)}
//         alt={marker.name}
//         className="marker-icon"
//         apiKey={apiKey}
//       />
//       <span>{marker.name}: {marker.progressionMarkerAmount}</span>
//     </div>
//   </div>
// )) : <h5>You have not set up any Progression Markers!</h5>}


//       <h4>Progression System Stats</h4>
//       {progressData && progressData.some(marker => marker.progressInfo && marker.progressInfo.length > 0) ? progressData.map((marker) => (
//   marker.progressInfo.map((system) => (
//     <div key={system.progressionSystemId} className="progress-system">
//       <ProtectedImage
//         src={getProgressionSystemIcon(system.progressionSystemId)}
//         alt={getProgressionSystemName(system.progressionSystemId)}
//         className="system-icon"
//         apiKey={apiKey}
//       />
//       <span>{getProgressionSystemName(system.progressionSystemId)} ({marker.name}):</span>
//       <span className="level-label"> Level {system.currentLevelNo}</span>
//     </div>
//   ))
// )) : <h5>You have not set up any Progression Systems!</h5>}



//       <h4>Player Wallet Stats</h4>
//       {walletData && walletData.length > 0 ? walletData.map((currency) => (
//   <div key={currency.id} className="currency">
//     <ProtectedImage
//       src={currency.iconUrl || getInitialsIcon(currency.name)}
//       alt={currency.name}
//       className="currency-icon"
//       apiKey={apiKey}
//     />
//     <span>{currency.name}: {currency.balance}</span>
//   </div>
// )) : <h5>You have not set up any Currencies!</h5>}


//     </div>
//     </div>

// </div>
      
  

    
  
//       {showProgressPopup && (
//          <div className="overlay">
//         <div className="popup">
//           <div className="popup-header">
//             <button onClick={() => setShowProgressPopup(false)} className="close-button">
//               <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//             </button>
//             <h1>Update Progress</h1>
//           </div>
//           <div className="popup-content">
//           <h3>Player Progression Marker Details</h3>
//           <div className="progress-list">
//           <table className="new-select-table">
//             <thead>
//               <tr>
//                 <th>Marker Name</th>
//                 <th>Marker ID</th>
//                 <th>Marker Balance</th>
//               </tr>
//             </thead>
//             <tbody>
//               {progressData.map((marker) => (
//                 <tr key={marker.id}>
//                   <td>{marker.name}</td>
//                   <td>{marker.id}</td>
//                   <td>{marker.progressionMarkerAmount}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//         <div className="centered-contents">
//         <div className="centered-fields">
//         <label className="profile-label">
//               Marker:

//             </label>
//               <div className="marker-select-dropdown match-select-dropdown">
//   <div
//     className="match-select-dropdown-header"
//     onClick={toggleMarkerDropdownVisibility}
//   >
//     {selectedMarker 
//       ? markerOptions.find(marker => marker.id === selectedMarker)?.name
//       : 'Select a marker'}
//     <span className="match-select-dropdown-arrow">&#9662;</span>
//   </div>
//   {markerDropdownVisibility && (
//     <ul className="match-select-dropdown-list">
//       {markerOptions.map((marker) => (
//         <li
//           key={marker.id}
//           className="match-select-dropdown-option"
//           onClick={() => handleMarkerSelect(marker.id)}
//         >
//           {marker.name}
//         </li>
//       ))}
//     </ul>
//   )}
// </div>
// </div>
// <div className="centered-fields">
// <label className="profile-label">
//               Operation:
//               </label>
//               <div className="marker-operation-select-dropdown match-select-dropdown">
//   <div
//     className="match-select-dropdown-header"
//     onClick={toggleMarkerOperationDropdownVisibility}
//   >
//     {markerOperation.charAt(0).toUpperCase() + markerOperation.slice(1)}
//     <span className="match-select-dropdown-arrow">&#9662;</span>
//   </div>
//   {markerOperationDropdownVisibility && (
//     <ul className="match-select-dropdown-list">
//       <li
//         className="match-select-dropdown-option"
//         onClick={() => handleMarkerOperationSelect('add')}
//       >
//         Add
//       </li>
//       <li
//         className="match-select-dropdown-option"
//         onClick={() => handleMarkerOperationSelect('subtract')}
//       >
//         Subtract
//       </li>
//       <li
//         className="match-select-dropdown-option"
//         onClick={() => handleMarkerOperationSelect('set')}
//       >
//         Set
//       </li>
//     </ul>
//   )}
// </div>
// </div>
// <div className="centered-fields">
// <label className="profile-label">
//               Amount:
//               </label>
//               <input
//                 type="number"
//                 value={markerAmount}
//                 onChange={(e) => setMarkerAmount(Number(e.target.value))}
//               />
//                  </div>
        
//                  </div>
//            <div className="custom-param-marker-add">
//             <button onClick={handleAddMarkerCustomParam}>Add Custom Parameters</button>
//             {markerCustomParams.map((param, index) => (
//               <div key={index} className="custom-param">
//                 <input
//                   type="text"
//                   placeholder="Param Name"
//                   value={param.name}
//                   onChange={(e) => handleMarkerCustomParamChange(index, 'name', e.target.value)}
//                 />
//                 <input
//                   type="text"
//                   placeholder="Value"
//                   value={param.value}
//                   onChange={(e) => handleMarkerCustomParamChange(index, 'value', e.target.value)}
//                 />
//            <div className="param-select-dropdown">
//   <div
//     className="param-select-dropdown-header"
//     onClick={() => toggleParamDropdownVisibility(index)}
//   >
//     {param.type.charAt(0).toUpperCase() + param.type.slice(1)}
//     <span className="param-select-dropdown-arrow">&#9662;</span>
//   </div>
//   {paramDropdownVisibility[index] && (
//     <ul className="param-select-dropdown-list">
//       <li
//         className="param-select-dropdown-option"
//         onClick={() => handleParamSelect(index, 'string')}
//       >
//         String
//       </li>
//       <li
//         className="param-select-dropdown-option"
//         onClick={() => handleParamSelect(index, 'number')}
//       >
//         Number
//       </li>
//       <li
//         className="param-select-dropdown-option"
//         onClick={() => handleParamSelect(index, 'boolean')}
//       >
//         Boolean
//       </li>
//     </ul>
//   )}
// </div>

//                 <button onClick={() => handleRemoveMarkerCustomParam(index)}>-</button>
//               </div>
//             ))}
//               <div className="then-divider"></div>
//               <button className="loading-button" onClick={handleUpdateMarker} disabled={isUpdatingMarker}>
//   {isUpdatingMarker ? <><div className="button-spinner"></div> Updating...</> : 'Update Marker'}
// </button>
//           </div>
//         </div>
//         </div>
//         </div>
//       )}
  
//       {showWalletPopup && (
//          <div className="overlay">
//         <div className="popup">
//           <div className="popup-header">
//             <button onClick={() => setShowWalletPopup(false)} className="close-button">
//               <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//             </button>
//             <h1>Update Wallet</h1>
//           </div>
//           <div className="popup-content">
//           <h3>Player Wallet Details</h3>
//           <div className="wallet-list">
//           <table className="new-select-table">
//             <thead>
//               <tr>
//                 <th>Currency Name</th>
//                 <th>Currency ID</th>
//                 <th>Wallet Balance</th>
//               </tr>
//             </thead>
//             <tbody>
//               {walletData.map((currency) => (
//                 <tr key={currency.id}>
//                   <td>{currency.name}</td>
//                   <td>{currency.id}</td>
//                   <td>{currency.balance}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
    
//         <div className="centered-contents">
//         <div className="centered-fields">
//         <label className="profile-label">
        
//               Currency:
//               </label>
//               <div className="currency-select-dropdown match-select-dropdown">
//   <div
//     className="match-select-dropdown-header"
//     onClick={toggleCurrencyDropdownVisibility}
//   >
//     {selectedCurrency 
//       ? currencyOptions.find(currency => currency.id === selectedCurrency)?.name
//       : 'Select a currency'}
//     <span className="match-select-dropdown-arrow">&#9662;</span>
//   </div>
//   {currencyDropdownVisibility && (
//     <ul className="match-select-dropdown-list">
//       {currencyOptions.map((currency) => (
//         <li
//           key={currency.id}
//           className="match-select-dropdown-option"
//           onClick={() => handleCurrencySelect(currency.id)}
//         >
//           {currency.name}
//         </li>
//       ))}
//     </ul>
//   )}
// </div>

//               </div>
           
//               <div className="centered-fields">
//         <label className="profile-label">
//               Operation:
//               </label>
//               <div className="currency-operation-select-dropdown match-select-dropdown">
//   <div
//     className="match-select-dropdown-header"
//     onClick={toggleCurrencyOperationDropdownVisibility}
//   >
//     {currencyOperation.charAt(0).toUpperCase() + currencyOperation.slice(1)}
//     <span className="match-select-dropdown-arrow">&#9662;</span>
//   </div>
//   {currencyOperationDropdownVisibility && (
//     <ul className="match-select-dropdown-list">
//       <li
//         className="match-select-dropdown-option"
//         onClick={() => handleCurrencyOperationSelect('add')}
//       >
//         Add
//       </li>
//       <li
//         className="match-select-dropdown-option"
//         onClick={() => handleCurrencyOperationSelect('subtract')}
//       >
//         Subtract
//       </li>
//       <li
//         className="match-select-dropdown-option"
//         onClick={() => handleCurrencyOperationSelect('set')}
//       >
//         Set
//       </li>
//     </ul>
//   )}
// </div>

//               </div>
//               <div className="centered-fields">
//         <label className="profile-label">
//               Amount:
//               </label>
//               <input
//                 type="number"
//                 value={currencyAmount}
//                 onChange={(e) => setCurrencyAmount(Number(e.target.value))}
//               />
//                 </div>
//             </div>  
//              </div>
//             <div className="custom-param-marker-add">
//             <button onClick={handleAddWalletCustomParam}>Add Custom Parameters</button>
//             {walletCustomParams.map((param, index) => (
//               <div key={index} className="custom-param">
//                 <input
//                   type="text"
//                   placeholder="Param Name"
//                   value={param.name}
//                   onChange={(e) => handleWalletCustomParamChange(index, 'name', e.target.value)}
//                 />
//                 <input
//                   type="text"
//                   placeholder="Value"
//                   value={param.value}
//                   onChange={(e) => handleWalletCustomParamChange(index, 'value', e.target.value)}
//                 />
//              <div className="param-select-dropdown">
//   <div
//     className="param-select-dropdown-header"
//     onClick={() => toggleWalletParamDropdownVisibility(index)}
//   >
//     {param.type.charAt(0).toUpperCase() + param.type.slice(1)}
//     <span className="param-select-dropdown-arrow">&#9662;</span>
//   </div>
//   {walletParamDropdownVisibility[index] && (
//     <ul className="param-select-dropdown-list">
//       <li
//         className="param-select-dropdown-option"
//         onClick={() => handleWalletParamSelect(index, 'string')}
//       >
//         String
//       </li>
//       <li
//         className="param-select-dropdown-option"
//         onClick={() => handleWalletParamSelect(index, 'number')}
//       >
//         Number
//       </li>
//       <li
//         className="param-select-dropdown-option"
//         onClick={() => handleWalletParamSelect(index, 'boolean')}
//       >
//         Boolean
//       </li>
//     </ul>
//   )}
// </div>

//                 <button onClick={() => handleRemoveWalletCustomParam(index)}>-</button>
//               </div>
              
//             ))}
//               </div>
          
//             <div className="then-divider"></div>
//             <button className="loading-button" onClick={handleUpdateWallet} disabled={isUpdatingWallet}>
//           {isUpdatingWallet ? <><div className="button-spinner"></div> Updating...</> : 'Update Wallet'}
//         </button>
//           </div>
//         </div>
//         </div>
//       )}
  
//       {showTasksPopup && (
//             <div className="overlay">
//         <div className="popup">
//           <div className="popup-header">
//           <button onClick={() => setShowTasksPopup(false)} className="close-button">
//           <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//         </button>
//             <h1>Force Complete Tasks</h1>
//           </div>
//           <div className="popup-content">
//           <button className="loading-button" onClick={fetchPlayerPendingTasks} disabled={taskLoading}>
//           {taskLoading ? <div className="button-spinner"></div> : 'Fetch Player Pending Tasks'}
//           {taskLoading && ' Fetching...'}
//         </button>
//             <p>{taskFetchStatus}</p>
//             {tasks.length > 0 && (
//               <div>
//                 <table>
//                   <thead>
//                     <tr>
//                       <th><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th>
//                       <th>Task ID</th>
//                       <th>Task Name</th>
//                       <th>Task Group ID</th>
//                       <th>Task Group Name</th>
//                       <th>Task Group Type</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {tasks.map(task => (
//                       <tr key={task.id}>
//                         <td><input type="checkbox" checked={selectedTasks.includes(task.id)} onChange={() => handleSelectTask(task.id)} /></td>
//                         <td>{task.id}</td>
//                         <td>{task.name}</td>
//                         <td>{task.taskGroupDetails?.id || ''}</td>
//                         <td>{task.taskGroupDetails?.name || ''}</td>
//                         <td>{task.taskGroupDetails?.taskGroupType || ''}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 {selectedTasks.length > 0 && (
//                   <div>
//                    <button className="loading-button" onClick={handleForceCompleteTasks} disabled={taskLoading}>
//                   {taskLoading ? <div className="button-spinner"></div> : 'Force Complete Task'}
//                   {taskLoading && ' Force Completing...'}
//                 </button>
//                     <p>{taskFetchStatus}</p>
//                   </div>
//                 )}
//               </div>
//             )}
//           </div>
//         </div>
//         </div>
//       )}
  
//       {showRewardsPopup && (
//             <div className="overlay">
//         <div className="popup">
//           <div className="popup-header">
//             <button onClick={() => setShowRewardsPopup(false)} className="close-button">
//               <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//             </button>
//             <h1>Grant Pending Rewards</h1>
//           </div>
//           <div className="popup-content">
//           <button className="loading-button" onClick={fetchRewardBus} disabled={rewardLoading}>
//   {rewardLoading ? <><div className="button-spinner"></div> Fetching...</> : 'Fetch Reward Bus'}
// </button>
//             <p>{rewardFetchStatus}</p>
//             {rewards.length > 0 && (
//               <div>
//                 <div style={{ textAlign: 'center' }}>
//                   <h4>Total Pending Rewards: {rewards.length}</h4>
//                 </div>
//                 <table>
//                   <thead>
//                     <tr>
//                       <th><input type="checkbox" checked={selectAllRewards} onChange={handleSelectAllRewards} /></th>
//                       <th>Reward Set#</th>
//                       <th>ID</th>
//                       <th>Name</th>
//                       <th>Amount</th>
//                       <th>Reward Type</th>
//                       <th>Source Type</th>
//                       <th>Source ID</th>
//                       <th>Instance ID</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {Object.keys(groupedRewards).map((key, index) => {
//                       const rewardsGroup = groupedRewards[key];
//                       const rewardSetNumber = rewardSets[key];
//                       return rewardsGroup.map((reward, idx) => (
//                         <tr key={`${key}-${idx}`}>
//                           {idx === 0 && (
//                             <>
//                               <td rowSpan={rewardsGroup.length}>
//                                 <input
//                                   type="checkbox"
//                                   checked={selectedRewards.includes(key)}
//                                   onChange={() => handleSelectReward(key)}
//                                 />
//                               </td>
//                               <td rowSpan={rewardsGroup.length}>{rewardSetNumber}</td>
//                             </>
//                           )}
//                           <td>{reward.id}</td>
//                           <td>{reward.name}</td>
//                           <td>{reward.amount}</td>
//                           <td>{reward.rewardType}</td>
//                           <td>{reward.sourceType}</td>
//                           <td>{reward.sourceId}</td>
//                           <td>{reward.instanceId || ''}</td>
//                         </tr>
//                       ));
//                     })}
//                   </tbody>
//                 </table>
          
//                 {selectedRewards.length > 0 && (
//               <div style={{ textAlign: 'center', marginTop: '20px' }}>
//                 <div className="then-divider"></div>
//                 <button className="loading-button" onClick={grantRewards} disabled={granting}>
//   {granting ? <><div className="button-spinner"></div> Granting...</> : 'Grant Pending Rewards'}
// </button>
//                 <div className="or-divider">or</div>
//                 <button className="loading-button" onClick={grantRewardsOneByOne} disabled={grantingSequential}>
//   {grantingSequential ? <><div className="button-spinner"></div> Granting...</> : 'Grant Rewards Sequentially'}
// </button>
//                 <div className="or-divider">or</div>
//                 <div className="batch-update-container">
//                   <label>Input Batch Size & Grant Rewards (Only for Specter Devs)</label>
//                   <input
//                     type="number"
//                     value={grantBatchSize}
//                     onChange={(e) => setGrantBatchSize(Number(e.target.value))}
//                     min="1"
//                     max={selectedRewards.length}
//                     placeholder="Batch Size"
//                   />
                
// <button className="loading-button" onClick={() => grantRewardsBatch(grantBatchSize)} disabled={batchGranting}>
//   {batchGranting ? <><div className="button-spinner"></div> Batch Granting...</> : 'Batch Grant Rewards'}
// </button>
// </div>


//                   </div>
//                 )}
//               </div>
//             )}
//           </div>
//         </div>
//         </div>
//       )}
  
//       {showEventsPopup && (
//         <div className="overlay">
//         <div className="popup">
//           <div className="popup-header">
//             <button onClick={() => setShowEventsPopup(false)} className="close-button">
//               <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//             </button>
//             <h1>Fire Custom Events</h1>
//           </div>
//           <div className="popup-content">
//             <label>
//               Custom Event:
//               <select className="custom-select" onChange={(e) => handleSelectEvent(e.target.value)}>
//                 <option value="">Select a custom event</option>
//                 {customEvents.map(event => (
//                   <option key={event.id} value={event.eventId}>
//                     {event.name} ({event.eventId})
//                   </option>
//                 ))}
//               </select>
//             </label>
//             {selectedEvent && (
//               <div className="event-params">
//             <table className="new-select-table">
//                   <thead>
//                     <tr>
//                       <th>Parameter Name</th>
//                       <th>Value</th>
//                       <th>Type</th>
//                       <th>Data Type</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {eventParams.map((param, index) => (
//                       <tr key={index}>
//                         <td>{param.name}</td>
//                         <td>
//                           {param.dataType === 'integer' && (
//                             <input
//                               type="number"
//                               step="1"
//                               value={param.value}
//                               onChange={(e) => handleParamChange(index, e.target.value)}
//                               disabled={!param.editable}
//                             />
//                           )}
//                           {param.dataType === 'float' && (
//                             <input
//                               type="number"
//                               step="any"
//                               value={param.value}
//                               onChange={(e) => handleParamChange(index, e.target.value)}
//                               disabled={!param.editable}
//                             />
//                           )}
//                           {param.dataType === 'boolean' && (
//                             <select className="custom-select"
//                               value={param.value}
//                               onChange={(e) => handleParamChange(index, e.target.value)}
//                               disabled={!param.editable}
//                             >
//                               <option value="">Select a value</option>
//                               <option value="true">True</option>
//                               <option value="false">False</option>
//                             </select>
//                           )}
//                           {param.dataType === 'string' && (
//                             <input
//                               type="text"
//                               value={param.value}
//                               onChange={(e) => handleParamChange(index, e.target.value)}
//                               disabled={!param.editable}
//                             />
//                           )}
//                         </td>
//                         <td>{param.type}</td>
//                         <td>{param.dataType}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 <button onClick={handleClearFields}>Clear Fields</button>
//                 <button className="loading-button" onClick={handleFireEvent} disabled={isFiringEvent}>
//               {isFiringEvent ? <><div className="button-spinner"></div> Firing...</> : 'Fire Event'}
//             </button>
//                 {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
//                 {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
//               </div>
//             )}
//           </div>
//         </div>
//         </div>
//       )}


// {showStoresPopup && (
//   <div className={`popup ${isFullScreen ? 'full-screen' : ''}`}>
//     <div className="popup-header">
//       <button onClick={() => setShowStoresPopup(false)} className="close-button">
//         <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//       </button>
//       <h1>Stores</h1>
//       <button class="fullscreen-container" onClick={toggleFullScreen}>
//   <input type="checkbox" checked={isFullScreen} onChange={toggleFullScreen} />
//   <svg viewBox="0 0 448 512" height="1em" xmlns="http://www.w3.org/2000/svg" class="fullscreen">
//     <path d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"></path>
//   </svg>
//   <svg viewBox="0 0 448 512" height="1em" xmlns="http://www.w3.org/2000/svg" class="exitfullscreen">
//     <path d="M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z"></path>
//   </svg>
// </button>
//     </div>
//     <div className={`popup-content ${isFullScreen ? 'full-screen-content' : ''}`}>
     
//         <div className="store-select-dropdown match-select-dropdown">
//   <div
//     className="match-select-dropdown-header"
//     onClick={toggleStoreDropdownVisibility}
//   >
//     {selectedStore ? selectedStore.name : 'Select a store'}
//     <span className="match-select-dropdown-arrow">&#9662;</span>
//   </div>
//   {storeDropdownVisibility && (
//     <ul className="match-select-dropdown-list">
//       {stores.map((store) => (
//         <li
//           key={store.id}
//           className="match-select-dropdown-option"
//           onClick={() => handleStoreSelect(store.id)}
//         >
//           {store.name}
//         </li>
//       ))}
//     </ul>
//   )}
// </div>

    
//       {selectedStore && (
//         <div className="store-header">
//           <h3>{selectedStore.name}</h3>
//           <div className="store-container neu-morphic">
//             <div className="store-content">
//               <div className="store-categories">
//                 {categories.map((category) => (
//                   <div key={category.id} className={`store-category ${selectedCategory === category.id ? 'active' : ''}`} onClick={() => handleCategoryChange(category.id)}>
//                     {category.name}
//                   </div>
//                 ))}
//               </div>
//               <div className="store-items grid-container">
//                 {storeContent.map((content) => (
//                   <div key={content.id} className="store-item neu-morphic">
//                     <ProtectedImage src={content.iconUrl|| getInitialsIcon(content.name)} alt={content.name} apiKey={apiKey} />
//                     <h5>{content.name}</h5>
//                     <div className="item-prices">
//   {content.prices && content.prices.length > 0 ? (
//     content.prices.map((price, index) => (
//       <div key={index} className="item-price">
//         {price.realWorldCurrency && price.realWorldCurrency.code ? (
//           <span>{price.realWorldCurrency.name} ({price.realWorldCurrency.code}): {price.priceType} {price.price?.toFixed(2)}</span>
//         ) : (
//           <span>{price.currencyDetails?.name} ({price.currencyDetails?.id}): {price.priceType} {price.price?.toFixed(2)}</span>
//         )}
//         {price.discount && <span className="discount">(-{price.discount * 100}%)</span>}
//       </div>
//     ))
//   ) : (
//     <div className="item-price">
//       <span>Free</span>
//     </div>
//   )}
// </div>

//                     <div className="item-actions">
//                       <button className="buy-button" onClick={() => handleDefaultPurchaseClick(content)}>Default Purchase</button>
//                       {content.prices && content.prices.length > 0 && (
//     <button className="custom-purchase-button" onClick={() => handleCustomPurchaseClick(content)}>Custom Purchase</button>
//   )}
                        
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   </div>
// )}



    
   

// {showCustomPurchasePopup && (
//   <div className="overlay">
//     <div className="purchase-popup">
//       <div className="purchase-popup-header">
//         <button className="close-button" onClick={toggleCustomPurchasePopup}>
//           <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//         </button>
//         <h2>Custom Purchase</h2>
//         {selectedPurchaseContent && <h3>{selectedPurchaseContent.name}</h3>} {/* Display item/bundle name */}
//       </div>
//       <div className="purchase-popup-content">
//         <button onClick={handleAddPrice}>Add Price</button> {/* Add Price button on top */}
//         {currentPrices.map((price, index) => (
//   <div key={index} className="custom-param">
//     <select 
//       value={price.currencyDetails?.id || price.realWorldCurrency?.code} 
//       onChange={(e) => {
//         const updatedPrices = [...currentPrices];
//         if (price.realWorldCurrency) {
//           updatedPrices[index].realWorldCurrency.code = e.target.value;
//         } else {
//           updatedPrices[index].currencyDetails.id = e.target.value;
//         }
//         setCurrentPrices(updatedPrices);
//       }}
//     >
//       {uniqueCurrencies.map((currency) => (
//         <option key={currency.id} value={currency.id}>{currency.name}</option>
//       ))}
//       {uniqueRealWorldCurrencies.map((currency) => (
//         <option key={currency.code} value={currency.code}>
//           {currency.name}
//         </option>
//       ))}
//     </select>
//     <input type="number" value={price.price} onChange={(e) => {
//       const updatedPrices = [...currentPrices];
//       updatedPrices[index].price = Number(e.target.value);
//       setCurrentPrices(updatedPrices);
//     }} placeholder="Price" />
//     <input type="number" value={price.amount} onChange={(e) => {
//       const updatedPrices = [...currentPrices];
//       updatedPrices[index].amount = Number(e.target.value);
//       setCurrentPrices(updatedPrices);
//     }} placeholder="Amount" />
//     <input type="text" value={price.collectionId} onChange={(e) => {
//       const updatedPrices = [...currentPrices];
//       updatedPrices[index].collectionId = e.target.value;
//       setCurrentPrices(updatedPrices);
//     }} placeholder="Collection ID" />
//     <input type="text" value={price.stackId} onChange={(e) => {
//       const updatedPrices = [...currentPrices];
//       updatedPrices[index].stackId = e.target.value;
//       setCurrentPrices(updatedPrices);
//     }} placeholder="Stack ID" />
//     <button onClick={() => handleRemovePrice(index)}>-</button>
//   </div>
// ))}


// <button onClick={handleCustomPurchase} disabled={isCustomPurchaseLoading}>
//           {isCustomPurchaseLoading ? <div className="button-spinner"></div> : 'Confirm Custom Purchase'}
//         </button>
//       </div>
//     </div>
//   </div>
// )}

//   {showDefaultPurchasePopup && (
//     <div className="overlay">
//       <div className="purchase-popup">
//         <div className="purchase-popup-header">
//           <button className="close-button" onClick={toggleDefaultPurchasePopup}> 
//             <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//           </button>
//           <h2>Default Purchase</h2>
      
//         </div>
//         <div className="purchase-popup-content">
//           {defaultPurchaseContent.prices.map((price, index) => (
//             <div key={index} className="item-price">
//               <span>{price.currencyDetails?.name} ({price.currencyDetails?.id}): {price.priceType} {Math.round(price.price)}</span>
//               {price.discount && <span className="discount">(-{price.discount * 100}%)</span>}
//             </div>
//           ))}
//           <label>Amount:
//             <input type="number" value={purchaseAmount} onChange={(e) => setPurchaseAmount(Number(e.target.value))} />
//           </label>
//           <div>
//             <label>Store ID:
//               <input type="text" value={storeId} disabled /> 
//             </label>
//           </div>
//           <div>
//             <label>Collection ID:
//               <input type="text" value={collectionId} onChange={(e) => setCollectionId(e.target.value)} />
//             </label>
//           </div>
//           <div>
//             <label>Stack ID:
//               <input type="text" value={stackId} onChange={(e) => setStackId(e.target.value)} />
//             </label>
//           </div>
//           <button onClick={handleDefaultPurchase} disabled={isDefaultPurchaseLoading}>
//           {isDefaultPurchaseLoading ? <div className="button-spinner"></div> : 'Confirm Purchase'}
//         </button>
//         </div>
//       </div>
//     </div>
//   )}

 
//   {showInventoryPopup && (
//     <div className="popup">
//       <div className="popup-header">
//         <button onClick={() => setShowInventoryPopup(false)} className="close-button">
//           <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//         </button>
//         <h1>Manage Inventory</h1>
//       </div>
//       <div className="popup-content">
//         <div className="inventory-sections">
//           <div className="inventory-section">
//           <button onClick={resetInventoryState}>Reset Row States</button>
//           <div className="then-divider"></div>
//             <h3>Add to Inventory</h3>
//             <button onClick={() => handleAddInventoryRow('item')}>Add Item</button>
//             <button onClick={() => handleAddInventoryRow('bundle')}>Add Bundle</button>
//             <div>
//               {selectedItems.map((item, index) => (
//                 <div key={index} className="inventory-row">
//                   <label>
//                     Item:
//                     <select className="custom-select" value={item.id} onChange={(e) => handleInventoryInputChange('item', index, 'id', e.target.value)}>
//                       <option value="">Select an item</option>
//                       {items.map(item => (
//                         <option key={item.id} value={item.id}>
//                           {item.name}
//                         </option>
//                       ))}
//                     </select>
//                   </label>
//                   <label>
//                     Amount:
//                     <input
//                       type="number"
//                       value={item.amount}
//                       onChange={(e) => handleInventoryInputChange('item', index, 'amount', Number(e.target.value))}
//                     />
//                   </label>
//                   <label>
//                     Stack ID:
//                     <input
//                       type="text"
//                       value={item.stackId}
//                       onChange={(e) => handleInventoryInputChange('item', index, 'stackId', e.target.value)}
//                     />
//                   </label>
//                   <label>
//                     Collection ID:
//                     <input
//                       type="text"
//                       value={item.collectionId}
//                       onChange={(e) => handleInventoryInputChange('item', index, 'collectionId', e.target.value)}
//                     />
//                   </label>
//                   <button onClick={() => handleRemoveInventoryRow('item', index)}>-</button>
//                 </div>
//               ))}
//             </div>
//             <div>
//               {selectedBundles.map((bundle, index) => (
//                 <div key={index} className="inventory-row">
//                   <label>
//                     Bundle:
//                     <select className="custom-select" value={bundle.id} onChange={(e) => handleInventoryInputChange('bundle', index, 'id', e.target.value)}>
//                       <option value="">Select a bundle</option>
//                       {bundles.map(bundle => (
//                         <option key={bundle.id} value={bundle.id}>
//                           {bundle.name}
//                         </option>
//                       ))}
//                     </select>
//                   </label>
//                   <label>
//                     Amount:
//                     <input
//                       type="number"
//                       value={bundle.amount}
//                       onChange={(e) => handleInventoryInputChange('bundle', index, 'amount', Number(e.target.value))}
//                     />
//                   </label>
//                   <label>
//                     Stack ID:
//                     <input
//                       type="text"
//                       value={bundle.stackId}
//                       onChange={(e) => handleInventoryInputChange('bundle', index, 'stackId', e.target.value)}
//                     />
//                   </label>
//                   <label>
//                     Collection ID:
//                     <input
//                       type="text"
//                       value={bundle.collectionId}
//                       onChange={(e) => handleInventoryInputChange('bundle', index, 'collectionId', e.target.value)}
//                     />
//                   </label>
//                   <button onClick={() => handleRemoveInventoryRow('bundle', index)}>-</button>
//                 </div>
//               ))}
//             </div>
//             <button onClick={handleAddInventoryItem}>Start Add to Inventory</button>
//           </div>
//           {showAddInventoryCustomParamsPopup && (
//             <div className="custom-params-popup">
//               <h2>Custom Parameters for Add to Inventory</h2>
//               {selectedItems.map(item => (
//                 <div key={item.id} > 
//                   <h4>Custom Params for Item {item.id}</h4>
//                   {addInventoryCustomParams[item.id] && addInventoryCustomParams[item.id].map((param, index) => (
//                     <div key={index} className="custom-param">
//                       <input
//                         type="text"
//                         placeholder="Param Name"
//                         value={param.name}
//                         onChange={(e) => handleCustomParamChange('addInventory', item.id, index, 'name', e.target.value)}
//                       />
//                       <input
//                         type="text"
//                         placeholder="Value"
//                         value={param.value}
//                         onChange={(e) => handleCustomParamChange('addInventory', item.id, index, 'value', e.target.value)}
//                       />
//                       <select className="custom-select" onChange={(e) => handleCustomParamChange('addInventory', item.id, index, 'type', e.target.value)} value={param.type}>
//                         <option value="string">String</option>
//                         <option value="number">Number</option>
//                         <option value="boolean">Boolean</option>
//                       </select>
//                       <button onClick={() => handleRemoveCustomParam('addInventory', item.id, index)} className="remove-param">
//                         -
//                       </button>
//                     </div>
//                   ))}
//                   <button onClick={() => handleAddCustomParam('addInventory', item.id)} className="add-param">
//                     + Add Custom Param
//                   </button>
//                 </div>
//               ))}
//             <button onClick={handleSubmitAddInventoryItem} className="loading-button" disabled={isLoading}>
//                     {isLoading ? <div className="button-spinner"></div> : 'Confirm Add to Inventory'}
//                   </button>
//               <button onClick={() => setShowAddInventoryCustomParamsPopup(false)}>Close</button>
//             </div>
//           )}
//           <div className="inventory-section">
//           <div className="then-divider"></div>
//           <h3>Consume Item</h3>
//                 <button onClick={() => { handleAddConsumeItemRow(); handleFetchInventory(players[activeTab].token); }}>
//                   Add Item to Consume
//                 </button>
//                 <div>
//                   {selectedConsumeItems.map((item, index) => (
//                     <div key={index} className="inventory-row">
//                       <label>
//                         Item:
//                         <select className="custom-select" value={item.instanceId} onChange={(e) => handleConsumeItemInputChange(index, 'instanceId', e.target.value)}>
//                           <option value="">Select an item</option>
//                           {inventoryItems.map(item => (
//                             <option key={item.instanceId} value={item.instanceId}>
//                               {item.name} ({item.instanceId}) [{item.id}]
//                             </option>
//                           ))}
//                         </select>
//                       </label>
//                       <label>
//                         Amount:
//                         <input
//                           type="number"
//                           value={item.amount}
//                           onChange={(e) => handleConsumeItemInputChange(index, 'amount', Number(e.target.value))}
//                         />
//                       </label>
//                       <label>
//                         Stack ID:
//                         <input
//                           type="text"
//                           value={item.stackId}
//                           onChange={(e) => handleConsumeItemInputChange(index, 'stackId', e.target.value)}
//                         />
//                       </label>
//                       <label>
//                         Collection ID:
//                         <input
//                           type="text"
//                           value={item.collectionId}
//                           onChange={(e) => handleConsumeItemInputChange(index, 'collectionId', e.target.value)}
//                         />
//                       </label>
//                       <button onClick={() => handleRemoveConsumeItemRow(index)}>-</button>
//                     </div>
//                   ))}
//                 </div>
//                 <button onClick={handleConsumeItem}>Start Consume Item</button>
//               </div>
//               {showConsumeInventoryCustomParamsPopup && (
//                 <div className="custom-params-popup">
//                   <h2>Custom Parameters for Consume Item</h2>
//                   {selectedConsumeItems.map(item => (
//                     <div key={item.instanceId}>
//                       <h4>Custom Params for Item {item.instanceId}</h4>
//                       {consumeInventoryCustomParams[item.instanceId] && consumeInventoryCustomParams[item.instanceId].map((param, index) => (
//                         <div key={index} className="custom-param">
//                           <input
//                             type="text"
//                             placeholder="Param Name"
//                             value={param.name}
//                             onChange={(e) => handleCustomParamChange('consumeInventory', item.instanceId, index, 'name', e.target.value)}
//                           />
//                           <input
//                             type="text"
//                             placeholder="Value"
//                             value={param.value}
//                             onChange={(e) => handleCustomParamChange('consumeInventory', item.instanceId, index, 'value', e.target.value)}
//                           />
//                           <select className="custom-select" onChange={(e) => handleCustomParamChange('consumeInventory', item.instanceId, index, 'type', e.target.value)} value={param.type}>
//                             <option value="string">String</option>
//                             <option value="number">Number</option>
//                             <option value="boolean">Boolean</option>
//                           </select>
//                           <button onClick={() => handleRemoveCustomParam('consumeInventory', item.instanceId, index)} className="remove-param">
//                             -
//                           </button>
//                         </div>
//                       ))}
//                       <button onClick={() => handleAddCustomParam('consumeInventory', item.instanceId)} className="add-param">
//                         + Add Custom Param
//                       </button>
//                     </div>
//                   ))}
//                   <button onClick={handleSubmitConsumeItem} className="loading-button" disabled={isLoading}>
//                     {isLoading ? <div className="button-spinner"></div> : 'Confirm Consume Item'}
//                   </button>
//                   <button onClick={() => setShowConsumeInventoryCustomParamsPopup(false)}>Close</button>
//                 </div>
//               )}
//               <div className="inventory-section">
//               <div className="then-divider"></div>
//                 <h3>Equip/Unequip Item</h3>
//                 <button onClick={() => { handleAddEquipItemRow(); handleFetchInventory(players[activeTab].token); }}>
//                   Add Item to Equip/Unequip
//                 </button>
//                 <div>
//                   {selectedEquipItems.map((item, index) => (
//                     <div key={index} className="inventory-row">
//                       <label>
//                         Item:
//                         <select className="custom-select" value={item.instanceId} onChange={(e) => handleEquipItemInputChange(index, 'instanceId', e.target.value)}>
//                           <option value="">Select an item</option>
//                           {inventoryItems.map(item => (
//                             <option key={item.instanceId} value={item.instanceId}>
//                               {item.name} ({item.instanceId}) [{item.id}]
//                             </option>
//                           ))}
//                         </select>
//                       </label>
//                       <label>
//                         Stack ID:
//                         <input
//                           type="text"
//                           value={item.stackId}
//                           onChange={(e) => handleEquipItemInputChange(index, 'stackId', e.target.value)}
//                         />
//                       </label>
//                       <label>
//                         Collection ID:
//                         <input
//                           type="text"
//                           value={item.collectionId}
//                           onChange={(e) => handleEquipItemInputChange(index, 'collectionId', e.target.value)}
//                         />
//                       </label>
//                       <label>
//                         Action:
//                         <select className="custom-select" value={item.shouldEquip} onChange={(e) => handleEquipItemInputChange(index, 'shouldEquip', e.target.value === 'true')}>
//                           <option value="true">Equip</option>
//                           <option value="false">Unequip</option>
//                         </select>
//                       </label>
//                       <button onClick={() => handleRemoveEquipItemRow(index)}>-</button>
//                     </div>
//                   ))}
//                 </div>
//                 <button onClick={handleEquipItem}>Start Equip/Unequip Item</button>
//               </div>
//               {showEquipInventoryCustomParamsPopup && (
//                 <div className="custom-params-popup">
//                   <h2>Custom Parameters for Equip/Unequip Item</h2>
//                   {selectedEquipItems.map(item => (
//                     <div key={item.instanceId}>
//                       <h4>Custom Params for Item {item.instanceId}</h4>
//                       {equipInventoryCustomParams[item.instanceId] && equipInventoryCustomParams[item.instanceId].map((param, index) => (
//                         <div key={index} className="custom-param">
//                           <input
//                             type="text"
//                             placeholder="Param Name"
//                             value={param.name}
//                             onChange={(e) => handleCustomParamChange('equipInventory', item.instanceId, index, 'name', e.target.value)}
//                           />
//                           <input
//                             type="text"
//                             placeholder="Value"
//                             value={param.value}
//                             onChange={(e) => handleCustomParamChange('equipInventory', item.instanceId, index, 'value', e.target.value)}
//                           />
//                           <select className="custom-select" onChange={(e) => handleCustomParamChange('equipInventory', item.instanceId, index, 'type', e.target.value)} value={param.type}>
//                             <option value="string">String</option>
//                             <option value="number">Number</option>
//                             <option value="boolean">Boolean</option>
//                           </select>
//                           <button onClick={() => handleRemoveCustomParam('equipInventory', item.instanceId, index)} className="remove-param">
//                             -
//                           </button>
//                         </div>
//                       ))}
//                       <button onClick={() => handleAddCustomParam('equipInventory', item.instanceId)} className="add-param">
//                         + Add Custom Param
//                       </button>
//                     </div>
//                   ))}
//                                    <button onClick={handleSubmitEquipItem} className="loading-button" disabled={isLoading}>
//                     {isLoading ? <div className="button-spinner"></div> : 'Confirm Equip/Unequip Item'}
//                   </button>

//                   <button onClick={() => setShowEquipInventoryCustomParamsPopup(false)}>Close</button>
//                 </div>
//               )}
//             </div>
           
//             {inventoryStatus && <p>{inventoryStatus}</p>}
//           </div>
//         </div>
//       )}








      
//  <button className="view-session-logs-button-fixed" onClick={handleOpenLogViewer}>
//         <img src="/assets/log.svg" alt="View Session Logs Icon" className="log-icon" />
//         <span className="button-text">View Logs</span>
//       </button>
  

//       {isSessionLogViewerOpen && (
//         <SessionLogViewer onClose={handleCloseLogViewer} />
//       )}

//     <button className="view-session-logs-button-game" onClick={() => setShowSnakeGame(true)}> <img src="/assets/game.svg" alt="View Session Logs Icon" className="log-icon" />
//     <span className="button-text">Play a Game</span>
//     </button>
//       {showSnakeGame && (
//         <div className="snake-modal-container">
//           <div className="snake-modal-content">
//           <button
//           onClick={() => {
//             setShowSnakeGame(false)
//           }}
//           className="close-button"
//         >
//           <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
//         </button>
        
//             <SnakesGame />
//           </div>
//         </div>
//       )}
        

// </div>
// </div>
// );
// }


// export default () => (
//   <Router>
//     <GoogleOAuthProvider clientId="8094591956-l1oj9gu9tm35nv03d8u86g20p1dphbta.apps.googleusercontent.com">
//       <App />
      
//     </GoogleOAuthProvider>
//   </Router>
// );





function PlayerTab({  dirtcubeAuthenticated, players,memberName,organisationName,selectedAppName, selectedConsumeItems,selectedEquipItems,  isLoading,
  activeTab,player, projectId,apiKey, fetchProgress, updateMarker, fetchWallet, updateWallet, currencies, customEvents, selectedEvent, eventParams, handleSelectEvent, handleParamChange, handleFireEvent,   isFiringEvent,handleClearFields, successMessage, errorMessage,avatarStyle,handleFetchInventory,
  inventoryItems,
  selectedItems,
  selectedBundles,
  handleAddInventoryRow,
  handleRemoveInventoryRow,
  handleInventoryInputChange,
  handleAddInventoryItem,
  showAddInventoryCustomParamsPopup,
  addInventoryCustomParams,
  handleCustomParamChange,
  handleRemoveCustomParam,
  handleAddCustomParam,
  handleSubmitAddInventoryItem,
  setShowAddInventoryCustomParamsPopup,
  selectedInventoryItems,
  handleAddConsumeItemRow,
  handleConsumeItemInputChange,
  handleRemoveConsumeItemRow,
  handleConsumeItem,
  showConsumeInventoryCustomParamsPopup,
  consumeInventoryCustomParams,
  handleSubmitConsumeItem,
  setShowConsumeInventoryCustomParamsPopup,
  handleAddEquipItemRow,
  handleEquipItemInputChange,
  handleRemoveEquipItemRow,
  handleEquipItem,
  showEquipInventoryCustomParamsPopup,
  equipInventoryCustomParams,
  handleSubmitEquipItem,
  setShowEquipInventoryCustomParamsPopup,
  inventoryStatus,
  resetInventoryState,selectedEnv,devIp }) {
  const [progressData, setProgressData] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [markerOperation, setMarkerOperation] = useState('add');
  const [markerAmount, setMarkerAmount] = useState();
  const [currencyOperation, setCurrencyOperation] = useState('add');
  const [currencyAmount, setCurrencyAmount] = useState();
  const [selectedMarker, setSelectedMarker] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [markerOptions, setMarkerOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [markerCustomParams, setMarkerCustomParams] = useState([{ name: '', value: '', type: 'string' }]);
  const [walletCustomParams, setWalletCustomParams] = useState([{ name: '', value: '', type: 'string' }]);
  // New state for tasks
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [taskLoading, setTaskLoading] = useState(false);
  const [taskFetchStatus, setTaskFetchStatus] = useState('');
  const [rewards, setRewards] = useState([]);
  const [selectedRewards, setSelectedRewards] = useState([]);
  const [selectAllRewards, setSelectAllRewards] = useState(false);
  const [rewardLoading, setRewardLoading] = useState(false);
  const [rewardFetchStatus, setRewardFetchStatus] = useState('');
  const serializeRewardKey = (reward) => `${reward.sourceId}-${reward.sourceType}-${reward.instanceId || ''}`;
  const [groupedRewards, setGroupedRewards] = useState([]);
  const [rewardSets, setRewardSets] = useState({});
  const [granting, setGranting] = useState(false);
  const [grantStatus, setGrantStatus] = useState('');
  const [grantingSequential, setGrantingSequential] = useState(false);
const [grantStatusSequential, setGrantStatusSequential] = useState('');
const [items, setItems] = useState([]);
const [bundles, setBundles] = useState([]);
  const playerName = player.email || player.customId || player.username; // Variable for player's identity
  const [avatarUrl, setAvatarUrl] = useState('');
  const [avatarLoading, setAvatarLoading] = useState(false);

  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [profileData, setProfileData] = useState({
    displayName: '',
    firstName: '',
    lastName: '',
  });



  const [globalLoading, setGlobalLoading] = useState(false);


  const [showSnakeGame, setShowSnakeGame] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setGlobalLoading(true);
      try {
        await Promise.all([fetchPlayerProgress(), fetchPlayerWallet(), fetchUpdatedProfile(), initializeAvatarForPlayer(player)]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setGlobalLoading(false);
      }
    };
  
    if (player) {
      fetchData();
    }
  }, [player, setGlobalLoading]);
  



  const [isEditMode, setIsEditMode] = useState(false);
  
  const [copySuccess, setCopySuccess] = useState(false);

  const [avatarUrls, setAvatarUrls] = useState({});
  const [avatarLoadingStatus, setAvatarLoadingStatus] = useState({});



  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [storeContent, setStoreContent] = useState([]);
  const [showCustomPurchasePopup, setShowCustomPurchasePopup] = useState(false);
  const [currentPrices, setCurrentPrices] = useState([]);
  const [newPrice, setNewPrice] = useState({ currencyDetails: { id: '' }, price: 0, discount: 0 });
  
  const [showDefaultPurchasePopup, setShowDefaultPurchasePopup] = useState(false);
  const [defaultPurchaseContent, setDefaultPurchaseContent] = useState(null);
  const [purchaseAmount, setPurchaseAmount] = useState(1);
  const [collectionId, setCollectionId] = useState('');
  const [stackId, setStackId] = useState('');
  const [storeId, setStoreId] = useState('');
  

  const [formData, setFormData] = useState({
    displayName: '',
    firstName: '',
    lastName: ''
  });

  useEffect(() => {
    if (isEditMode) {
      setFormData({
        displayName: profileData.displayName,
        firstName: profileData.firstName,
        lastName: profileData.lastName
      });
    }
  }, [isEditMode, profileData]);
  


  const [isSessionLogViewerOpen, setIsSessionLogViewerOpen] = useState(false);
  

  const handleOpenLogViewer = () => {
    setIsSessionLogViewerOpen(true);
  };
  
  const handleCloseLogViewer = () => {
    setIsSessionLogViewerOpen(false);
  };




  const fetchItems = async (apiKey, selectedEnv, devIp, projectId, token) => {
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    let allItems = [];
    let offset = 0;
    const limit = 10;
    let hasMoreItems = true;
  
    try {
      while (hasMoreItems) {
        const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-items`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            projectId,
            limit: limit,
            offset: offset,
          }),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch items');
        }
  
        allItems = [...allItems, ...data.data.items];
  
        if (data.data.items.length < limit) {
          hasMoreItems = false;
        } else {
          offset += limit;
        }
      }
  
      return allItems;
    } catch (error) {
      console.error('Error fetching items:', error);
      throw error;
    }
  };
  
  const fetchBundles = async (apiKey, selectedEnv, devIp, projectId, token) => {
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    let allBundles = [];
    let offset = 0;
    const limit = 10;
    let hasMoreBundles = true;
  
    try {
      while (hasMoreBundles) {
        const response = await fetch(`https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-bundles`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            projectId,
            limit: limit,
            offset: offset,
          }),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch bundles');
        }
  
        allBundles = [...allBundles, ...data.data.bundles];
  
        if (data.data.bundles.length < limit) {
          hasMoreBundles = false;
        } else {
          offset += limit;
        }
      }
  
      return allBundles;
    } catch (error) {
      console.error('Error fetching bundles:', error);
      throw error;
    }
  };
  
  
  // Call fetchItems and fetchBundles where needed, e.g., in event handlers or other effect hooks
  const handleFetchItems = async () => {
    try {
      const items = await fetchItems(apiKey, selectedEnv, devIp, projectId, players[0].token);
      setItems(items);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  
  const handleFetchBundles = async () => {
    try {
      const bundles = await fetchBundles(apiKey, selectedEnv, devIp, projectId, players[0].token);
      setBundles(bundles);
    } catch (error) {
      console.error("Error fetching bundles:", error);
    }
  };
  
  // Call these functions wherever needed in your component




  const CustomCloseButton = ({ closeToast }) => (
    <div className="neu-toast-close" onClick={closeToast}>
      ✖
    </div>
  );



    const fetchStores = async () => {
      try {
        const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/get-stores', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Api-Key': apiKey,
            'Authorization': `Bearer ${player.token}`,
            'env': selectedEnv,
            ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
          },
          body: JSON.stringify({projectId}),
        });
  
        const data = await response.json();
        if (data.status === 'success') {
          setStores(data.data.stores);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
    };
  

  useEffect(() => {
    if (selectedStore) {
      const fetchCategories = async () => {
        try {
          const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/get-categories', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Api-Key': apiKey,
              'Authorization': `Bearer ${player.token}`,
              'env': selectedEnv,
              ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
            },
            body: JSON.stringify({
              storeId: selectedStore.id,
              limit: 10,
              offset: 0,
            }),
          });
  
          const data = await response.json();
          if (data.status === 'success') {
            setCategories(data.data);
            setSelectedCategory(data.data[0]?.id || '');
          } else {
            console.error(data.message);
          }
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };
  
      fetchCategories();
    } else {
      // Reset categories and selected category when no store is selected
      setCategories([]);
      setSelectedCategory(null);
    }
  }, [selectedStore, player, apiKey]);
  
  
  useEffect(() => {
    if (selectedStore && selectedCategory) {
      const fetchStoreContents = async () => {
        try {
          setStoreContent([]); // Clear previous store content
          let allContents = [];
          let offset = 0;
          const limit = 10; // Set the limit to the number of items you want to fetch per request
          let hasMoreContents = true;
  
          while (hasMoreContents) {
            const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/get-contents', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Api-Key': apiKey,
                'Authorization': `Bearer ${player.token}`,
                'env': selectedEnv,
                ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
              },
              body: JSON.stringify({
                storeId: selectedStore.id,
                categoryId: selectedCategory,
                limit: limit,
                offset: offset,
              }),
            });
  
            const data = await response.json();
            if (data.status === 'success') {
              const items = data.data.items.map(item => ({ ...item, type: 'item' }));
              const bundles = data.data.bundles.map(bundle => ({ ...bundle, type: 'bundle' }));
              const combinedContents = [...items, ...bundles];
              allContents = [...allContents, ...combinedContents];
  
              if (items.length < limit && bundles.length < limit) {
                hasMoreContents = false;
              } else {
                offset += limit;
              }
            } else {
              console.error(data.message);
              hasMoreContents = false;
            }
          }
  
          setStoreContent(allContents);
        } catch (error) {
          console.error('Error fetching store contents:', error);
        }
      };
  
      fetchStoreContents();
    } else {
      // Clear store content when no store or category is selected
      setStoreContent([]);
    }
  }, [selectedStore, selectedCategory, player, apiKey]);
  
  
  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
  };
  
  const handleCustomPurchaseClick = (content) => {
    setSelectedPurchaseContent(content); // Store the selected item or bundle
    setCurrentPrices(content.prices);
    toggleCustomPurchasePopup();
  };
  
  
  
  const handleRemovePrice = (index) => {
    const updatedPrices = [...currentPrices];
    updatedPrices.splice(index, 1);
    setCurrentPrices(updatedPrices);
  };

  
  // Update the function to open the default purchase popup
  const handleDefaultPurchaseClick = (content) => {
    if (content.type) {
      setDefaultPurchaseContent(content);
      setStoreId(selectedStore.id); // Set the store ID from the selected store
      toggleDefaultPurchasePopup();
    } else {
      console.error('Content type is not set correctly:', content);
    }
  };

  const [isDefaultPurchaseLoading, setIsDefaultPurchaseLoading] = useState(false);
  const [isCustomPurchaseLoading, setIsCustomPurchaseLoading] = useState(false);

  const handleDefaultPurchase = async () => {
    setIsDefaultPurchaseLoading(true);
    const toastId = toast.loading('Processing default purchase...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
      closeButton: <CustomCloseButton />
    });

    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${player.token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
};
  
    const payload = {
      items: defaultPurchaseContent?.type === 'item' ? [{
        id: defaultPurchaseContent.id,
        amount: purchaseAmount,
        stackId: stackId,
        collectionId: collectionId,
        storeId: storeId  // Include storeId inside each item
      }] : [],
      bundles: defaultPurchaseContent?.type === 'bundle' ? [{
        id: defaultPurchaseContent.id,
        amount: purchaseAmount,
        stackId: stackId,
        collectionId: collectionId,
        storeId: storeId  // Include storeId inside each bundle
      }] : []
    };
  
    try {
      const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/default-purchase', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to make default purchase');
      }
  
      if (data.data.items.length > 0) {
        setStoreContent((prevContent) =>
          prevContent.map((item) =>
            item.id === defaultPurchaseContent.id ? { ...item, ...data.data.items[0] } : item
          )
        );
      }
  
      if (data.data.bundles.length > 0) {
        setStoreContent((prevContent) =>
          prevContent.map((bundle) =>
            bundle.id === defaultPurchaseContent.id ? { ...bundle, ...data.data.bundles[0] } : bundle
          )
        );
      }
  
      toast.update(toastId, {
        render: 'Default purchase successful!',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeButton: <CustomCloseButton />
      });
  
      toggleDefaultPurchasePopup(); // Close the popup
      setPurchaseAmount(1);
      setCollectionId('');
      setStackId('');
      setStoreId(''); // Reset store ID after purchase
    } catch (error) {
      toast.update(toastId, {
        render: `Error: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeButton: <CustomCloseButton />
      });
      console.error('Error making default purchase:', error);
    } finally {
      setIsDefaultPurchaseLoading(false);
    }
  };
  
  


  useEffect(() => {
    if (currencies) {
      setCurrencyOptions(currencies.map(currency => ({ id: currency.id, name: currency.name })));
    }
  }, [currencies]);

  useEffect(() => {
    setProgressData([]);
    setWalletData([]);
    setMarkerOperation('add');
    setMarkerAmount();
    setCurrencyOperation('add');
    setCurrencyAmount();
    setSelectedMarker('');
    setSelectedCurrency('');
    setMarkerOptions([]);
    setCurrencyOptions(currencies.map(currency => ({ id: currency.id, name: currency.name }))); // Populate options on player change
    setIsFetched(false);
    setMarkerCustomParams([]);
    setWalletCustomParams([]);
    setTasks([]);
    setSelectedTasks([]);
    setSelectAll(false);
    setTaskLoading(false);
    setTaskFetchStatus('');
    setRewards([]);
    setSelectedRewards([]);
    setSelectAllRewards(false);
    setRewardLoading(false);
    setRewardFetchStatus('');
    setGroupedRewards([]);
    setRewardSets({});
    setGranting(false);
    setGrantStatus('');
  
  // Auto-call fetch functions
  fetchPlayerProgress();
  fetchPlayerWallet();
}, [player]);
  

const generateNames = () => {
  setFormData({
    displayName: generateGameyName(),
    firstName: faker.person.firstName(),
    lastName: faker.person.lastName(),
  });
};


// Custom dictionaries
const adjectives = [
  'Epic', 'Mystic', 'Galactic', 'Shadow', 'Blazing', 'Ancient', 'Frozen', 'Quantum', 'Cyber', 'Arcane', 
  'Infernal', 'Divine', 'Savage', 'Majestic', 'Silent', 'Swift', 'Raging', 'Thunder', 'Stealth', 'Vicious',
  'Luminous', 'Abyssal', 'Celestial', 'Dark', 'Eternal', 'Furious', 'Glorious', 'Heroic', 'Immortal', 'Jaded',
  'Legendary', 'Mythic', 'Noble', 'Omniscient', 'Phantom', 'Radiant', 'Supreme', 'Titanic', 'Unstoppable', 'Venerable'
];

const nouns = [
  'Phoenix', 'Dragon', 'Ninja', 'Samurai', 'Titan', 'Wizard', 'Viking', 'Sorcerer', 'Warrior', 'Assassin', 
  'Knight', 'Ranger', 'Guardian', 'Berserker', 'Sentinel', 'Reaper', 'Hunter', 'Paladin', 'Druid', 'Monk',
  'Gladiator', 'Warlord', 'Champion', 'Mage', 'Priest', 'Rogue', 'Necromancer', 'Shaman', 'Warlock', 'Archer',
  'Barbarian', 'Conqueror', 'Defender', 'Enchanter', 'Fighter', 'Illusionist', 'Juggernaut', 'Keeper', 'Lancer', 'Mystic'
];

const techTerms = [
  'Byte', 'Pixel', 'Bit', 'Code', 'Crypto', 'Circuit', 'Quantum', 'Data', 'Nano', 'Cyber',
  'Matrix', 'AI', 'Bot', 'Chip', 'Drive', 'Frame', 'Hack', 'Kernel', 'Link', 'Node',
  'Module', 'Packet', 'Protocol', 'Server', 'Stream', 'Synth', 'Terminal', 'Transistor', 'Virtual', 'Wired',
  'Algorithm', 'Bandwidth', 'Cache', 'Cluster', 'Domain', 'Firmware', 'Gateway', 'Interface', 'Latency', 'Network'
];

const animals = [
  'Lion', 'Tiger', 'Bear', 'Wolf', 'Eagle', 'Falcon', 'Shark', 'Panther', 'Leopard', 'Jaguar',
  'Fox', 'Hawk', 'Puma', 'Cheetah', 'Raven', 'Cobra', 'Python', 'Viper', 'Raptor', 'Grizzly'
];

const superheroes = [
  'Superman', 'Batman', 'Spiderman', 'Ironman', 'Thor', 'Hulk', 'Wolverine', 'Flash', 'Aquaman', 'BlackPanther',
  'WonderWoman', 'CaptainAmerica', 'DoctorStrange', 'GreenLantern', 'BlackWidow', 'Daredevil', 'Hawkeye', 'SilverSurfer', 'Cyclops', 'Storm'
];

const allNouns = [...nouns, ...animals, ...superheroes];

// Config for the unique names generator
const config = {
  dictionaries: [adjectives, techTerms, allNouns],
  separator: '-',
  length: 3,
};

const generateGameyName = () => {
  return uniqueNamesGenerator(config);
};


const fetchPlayerProgress = async () => {
  try {
    const data = await fetchProgress(player.token, player.uuid);
    if (data) {
      setProgressData(data);
      setMarkerOptions(data.map(marker => ({ id: marker.id, name: marker.name })));
      setIsFetched(true);
    } else {
      setProgressData([]);
    }
  } catch (error) {
    console.error('Error fetching player progress:', error);
    setProgressData([]);
  }
};

const fetchPlayerWallet = async () => {
  try {
    const currencyIds = currencies.map(currency => currency.id);
    const data = await fetchWallet(player.token, currencyIds);
    if (data) {
      setWalletData(data);
    } else {
      setWalletData([]);
    }
  } catch (error) {
    console.error('Error fetching player wallet:', error);
    setWalletData([]);
  }
};
//   const fetchPlayerPendingTasks = async () => {
//     const headers = {
//       'Content-Type': 'application/json',
//       'Api-Key': apiKey,
//       'Authorization': `Bearer ${player.token}`,
//       'env': selectedEnv,
//       ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
// };

//     let allTasks = [];
//     let offset = 0;
//     const limit = 10; // Set the limit to the number of tasks you want to fetch per request
//     let hasMoreTasks = true;

//     try {
//       setTaskLoading(true); // Set loading state to true
//       setTaskFetchStatus('Fetching pending tasks...'); // Update the status message

//       while (hasMoreTasks) {
//         const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/tasks/get-status', {
//           method: 'POST',
//           headers: headers,
//           body: JSON.stringify({
//             includeTaskGroupTasks: true,
//             limit: limit,
//             offset: offset,
//           }),
//         });

//         const data = await response.json();
//         if (!response.ok) {
//           throw new Error(data.message || 'Failed to fetch tasks');
//         }

//         const pendingTasks = data.data.filter(task => task.status === 'pending');
//         allTasks = [...allTasks, ...pendingTasks];

//         if (data.data.length < limit) {
//           hasMoreTasks = false; // If fewer tasks are returned than the limit, there are no more tasks
//         } else {
//           offset += limit; // Increase the offset for the next batch
//         }
//       }

//       setTasks(allTasks);
//       setTaskFetchStatus('All tasks fetched successfully'); // Update the status message
//     } catch (error) {
//       console.error('Error fetching tasks:', error);
//       setTaskFetchStatus('Error fetching tasks'); // Update the status message
//     } finally {
//       setTaskLoading(false); // Set loading state to false
//     }
//   };

const fetchPlayerPendingTasks = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': apiKey,
    'Authorization': `Bearer ${player.token}`,
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  let allTasks = [];
  let offset = 0;
  const limit = 10; // Set the limit to the number of tasks you want to fetch per request
  let hasMoreTasks = true;

  const toastId = toast.loading('Fetching pending tasks...', {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  try {
    setTaskLoading(true); // Set loading state to true
   
    while (hasMoreTasks) {
      const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/tasks/get-status', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          includeTaskGroupTasks: true,
          limit: limit,
          offset: offset,
          status: 'pending', // Filter for pending tasks
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch tasks');
      }

      allTasks = [...allTasks, ...data.data];

      if (data.data.length < limit) {
        hasMoreTasks = false; // If fewer tasks are returned than the limit, there are no more tasks
      } else {
        offset += limit; // Increase the offset for the next batch
      }
    }

    setTasks(allTasks);
    
    toast.update(toastId, {
      render: 'All tasks fetched successfully!',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
  } catch (error) {
    console.error('Error fetching tasks:', error);
   
    toast.update(toastId, {
      render: `Error fetching tasks: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setTaskLoading(false); // Set loading state to false
  }
};


  const handleForceCompleteTasks = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${player.token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    setTaskLoading(true); // Set loading state to true
    const batchToastId = toast.loading(`Force completing selected tasks: 0/${selectedTasks.length}`, {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
  
    let completedTasksCount = 0;
  
    for (const taskId of selectedTasks) {
      try {
        const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/tasks/force-complete', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            taskIds: [taskId],
          }),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to force complete tasks');
        }
  
        completedTasksCount++;
        toast.update(batchToastId, {
          render: `Force completing selected tasks: ${completedTasksCount}/${selectedTasks.length}`,
          type: 'info',
          isLoading: true,
        });
  
      } catch (error) {
        console.error(`Error force completing task ${taskId}:`, error);
        toast.update(batchToastId, {
          render: `Error force completing task ${taskId}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000
        });
        break;
      }
    }
  
    if (completedTasksCount === selectedTasks.length) {
      toast.update(batchToastId, {
        render: 'All selected tasks force completed successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
    }
  
    setTaskLoading(false); // Set loading state to false
    fetchPlayerPendingTasks(); // Refresh the task list after all tasks are completed
  };
  
  // Handle selecting tasks
  const handleSelectTask = (taskId) => {
    if (selectedTasks.includes(taskId)) {
      setSelectedTasks(selectedTasks.filter(id => id !== taskId));
    } else {
      setSelectedTasks([...selectedTasks, taskId]);
    }
  };

  // Handle selecting all tasks
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTasks([]);
    } else {
      setSelectedTasks(tasks.map(task => task.id));
    }
    setSelectAll(!selectAll);
  };
  const fetchRewardBus = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${player.token}`,
      'env': selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
    };
  
    let allRewards = [];
    let offset = 0;
    const limit = 10;
    let hasMoreRewards = true;
  
    const toastId = toast.loading('Fetching reward bus...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
  
    try {
      setRewardLoading(true);
  
      while (hasMoreRewards) {
        const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/get-history', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            limit: limit,
            offset: offset,
            status: 'pending',
          }),
        });
  
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch rewards');
        }
  
        allRewards = [
          ...allRewards,
          ...data.data.items.map(reward => ({ ...reward, rewardType: 'item' })),
          ...data.data.bundles.map(reward => ({ ...reward, rewardType: 'bundle' })),
          ...data.data.currencies.map(reward => ({ ...reward, rewardType: 'currency' })),
          ...data.data.progressionMarkers.map(reward => ({ ...reward, rewardType: 'progressionMarker' }))
        ];
  
        const totalFetchedThisIteration = data.data.items.length + data.data.bundles.length + data.data.currencies.length + data.data.progressionMarkers.length;
        if (totalFetchedThisIteration < limit) {
          hasMoreRewards = false;
        } else {
          offset += limit;
        }
  
        toast.update(toastId, {
          render: `Fetching reward bus... ${offset} rewards fetched`,
          type: 'info',
          isLoading: true
        });
      }
  
      setRewards(allRewards);
      toast.update(toastId, {
        render: 'All rewards fetched successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
      groupRewards(allRewards);
    } catch (error) {
      toast.update(toastId, {
        render: `Error fetching rewards: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
    } finally {
      setRewardLoading(false);
    }
  };
  
useEffect(() => {
  if (rewardFetchStatus) {
    const timer = setTimeout(() => setRewardFetchStatus(''), 3000); // Clear status after 3 seconds
    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }
}, [rewardFetchStatus]);



// // Fetch Reward Bus
// const fetchRewardBus = async () => {
//   const headers = {
//     'Content-Type': 'application/json',
//     'Api-Key': apiKey,
//     'Authorization': `Bearer ${player.token}`,
//     'env': selectedEnv,
//     ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
//   };
  

//   let allRewards = [];
//   let offset = 0;
//   const limit = 10; // Set the limit to the number of rewards you want to fetch per request
//   let hasMoreRewards = true;

//   try {
//     setRewardLoading(true); // Set loading state to true
//     setRewardFetchStatus('Fetching reward bus...'); // Update the status message

//     while (hasMoreRewards) {
//       const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/get-history', {
//         method: 'POST',
//         headers: headers,
//         body: JSON.stringify({
//           limit: limit,
//           offset: offset,
         
//         }),
//       });

//       const data = await response.json();
//       if (!response.ok) {
//         throw new Error(data.message || 'Failed to fetch rewards');
//       }

//       console.log('Raw fetched data:', data.data);

//       // Add raw results to allRewards
//       allRewards = [
//         ...allRewards,
//         ...data.data.items.map(reward => ({ ...reward, rewardType: 'item' })),
//         ...data.data.bundles.map(reward => ({ ...reward, rewardType: 'bundle' })),
//         ...data.data.currencies.map(reward => ({ ...reward, rewardType: 'currency' })),
//         ...data.data.progressionMarkers.map(reward => ({ ...reward, rewardType: 'progressionMarker' }))
//       ];


//       // Check if the total number of items fetched in this iteration is less than the limit
//       const totalFetchedThisIteration = data.data.items.length + data.data.bundles.length + data.data.currencies.length + data.data.progressionMarkers.length;
//       if (totalFetchedThisIteration < limit) {
//         hasMoreRewards = false;
//       } else {
//         offset += limit;
//       }
//     }

//     // Filter pending rewards after fetching all possible rewards
//     const pendingRewards = allRewards.filter(reward => reward.status === 'pending');

//     console.log('Filtered pending rewards:', pendingRewards);

//     setRewards(pendingRewards);
//     setRewardFetchStatus('All rewards fetched successfully'); // Update the status message
//     groupRewards(pendingRewards); // Group rewards after fetching
//   } catch (error) {
//     console.error('Error fetching rewards:', error);
//     setRewardFetchStatus('Error fetching rewards'); // Update the status message
//   } finally {
//     setRewardLoading(false); // Set loading state to false
//   }
// };

const handleSelectReward = (rewardKey) => {
  if (selectedRewards.includes(rewardKey)) {
    setSelectedRewards(selectedRewards.filter(key => key !== rewardKey));
  } else {
    setSelectedRewards([...selectedRewards, rewardKey]);
  }
};

const handleSelectAllRewards = () => {
  if (selectAllRewards) {
    setSelectedRewards([]);
  } else {
    setSelectedRewards(Object.keys(groupedRewards)); // Use the serialized key
  }
  setSelectAllRewards(!selectAllRewards);
};

const groupRewards = (rewards) => {
  console.log('Grouping rewards:', rewards);

  const grouped = rewards.reduce((acc, reward) => {
    const key = serializeRewardKey(reward);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(reward);
    return acc;
  }, {});

  console.log('Grouped rewards:', grouped);

  let setNumber = 1;
  const rewardSets = Object.keys(grouped).reduce((acc, key) => {
    acc[key] = setNumber++;
    return acc;
  }, {});

  console.log('Reward sets:', rewardSets);

  setGroupedRewards(grouped);
  setRewardSets(rewardSets);
};

const grantRewardsOneByOne = async () => {
  setGrantingSequential(true);

  const rewardDetails = selectedRewards.map(key => {
    if (groupedRewards[key] && groupedRewards[key].length > 0) {
      const { sourceId, sourceType, instanceId } = groupedRewards[key][0];
      return { source: { id: sourceId, type: sourceType, instanceId } };
    }
    return null;
  }).filter(detail => detail !== null);

  const toastId = toast.loading(`Granting rewards: 0/${rewardDetails.length}`, {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  try {
    for (let i = 0; i < rewardDetails.length; i++) {
      const rewardDetail = rewardDetails[i];
      const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/grant', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Key': apiKey,
          'Authorization': `Bearer ${player.token}`,
          'env': selectedEnv,
          ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
        },
        body: JSON.stringify({ rewardDetails: [rewardDetail] }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to grant reward');
      }

      toast.update(toastId, {
        render: `Granting rewards: ${i + 1}/${rewardDetails.length}`,
        type: 'info',
        isLoading: true
      });
    }

    toast.update(toastId, {
      render: 'All rewards granted successfully',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
    await fetchRewardBus();
  } catch (error) {
    toast.update(toastId, {
      render: `Error: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setGrantingSequential(false);
  }
};

const grantRewards = async () => {
  setGranting(true);

  const rewardDetails = selectedRewards.map(key => {
    if (groupedRewards[key] && groupedRewards[key].length > 0) {
      const { sourceId, sourceType, instanceId } = groupedRewards[key][0];
      return { source: { id: sourceId, type: sourceType, instanceId } };
    }
    return null;
  }).filter(detail => detail !== null);

  const toastId = toast.loading(`Granting rewards: 0/${rewardDetails.length}`, {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  try {
    const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/grant', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Api-Key': apiKey,
        'Authorization': `Bearer ${player.token}`,
        'env': selectedEnv,
        ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
      },
      body: JSON.stringify({ rewardDetails }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to grant rewards');
    }

    toast.update(toastId, {
      render: 'All rewards granted successfully',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
    await fetchRewardBus();
  } catch (error) {
    toast.update(toastId, {
      render: `Error: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setGranting(false);
  }
};


useEffect(() => {
  if (grantStatus) {
    const timer = setTimeout(() => setGrantStatus(''), 3000); // Clear status after 3 seconds
    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }
}, [grantStatus]);

useEffect(() => {
  if (grantStatusSequential) {
    const timer = setTimeout(() => setGrantStatusSequential(''), 3000); // Clear status after 3 seconds
    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }
}, [grantStatusSequential]);


const [grantBatchSize, setGrantBatchSize] = useState(0);
const [grantBatchUpdateStatusMessages, setGrantBatchUpdateStatusMessages] = useState([]);
const [grantBatchUpdateProgress, setGrantBatchUpdateProgress] = useState('');

useEffect(() => {
  setGrantBatchSize(selectedRewards.length);
}, [selectedRewards]);


const [batchGranting, setBatchGranting] = useState(false);



const grantRewardsBatch = async (batchSize) => {
  setBatchGranting(true);
  setGrantBatchUpdateStatusMessages([]);
  setGrantBatchUpdateProgress('');

  const rewardDetails = selectedRewards.map(key => {
    if (groupedRewards[key] && groupedRewards[key].length > 0) {
      const { sourceId, sourceType, instanceId } = groupedRewards[key][0];
      return { source: { id: sourceId, type: sourceType, instanceId } };
    }
    return null;
  }).filter(detail => detail !== null);

  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': apiKey,
    'Authorization': `Bearer ${player.token}`,
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  const toastId = toast.loading(`Granting rewards: 0/${rewardDetails.length}`, {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  try {
    let grantedRewardsCount = 0;

    for (let start = 0; start < rewardDetails.length; start += batchSize) {
      const batch = rewardDetails.slice(start, start + batchSize);

      const grantPromises = batch.map(async (rewardDetail) => {
        const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/rewards/grant', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ rewardDetails: [rewardDetail] }),
        });

        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to grant reward');
        }

        grantedRewardsCount++;

        toast.update(toastId, {
          render: `Granting rewards: ${grantedRewardsCount}/${rewardDetails.length}`,
          type: 'info',
          isLoading: true
        });
      });

      await Promise.all(grantPromises);
    }

    toast.update(toastId, {
      render: 'All rewards granted successfully',
      type: 'success',
      isLoading: false,
      autoClose: 5000
    });
    await fetchRewardBus();
  } catch (error) {
    toast.update(toastId, {
      render: `Error: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setBatchGranting(false);
  }
};



  const formatCustomParams = (params) => {
    const formattedParams = {};
    params.forEach(param => {
      if (param.name && param.value) {
        const key = param.name; // Ensure the parameter name is treated as a string
        const value = param.type === 'number' ? Number(param.value) : param.type === 'boolean' ? param.value === 'true' : param.value;
        formattedParams[`"${key}"`] = value; // Wrap the key in double quotes
      }
    });
    return formattedParams;
  };

  const [isUpdatingMarker, setIsUpdatingMarker] = useState(false);

  const handleUpdateMarker = async () => {
    const customParams = formatCustomParams(markerCustomParams);
  
    if (!selectedMarker) {
      toast.error('Please select a marker', {
        className: 'neu-toast neu-toast-error',
        progressClassName: 'neu-toast-progress',
       closeButton: <CustomCloseButton /> 
      });
      return;
    }
  
    setIsUpdatingMarker(true);
    const toastId = toast.loading('Updating marker...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
  
    try {
      const updatedMarker = await updateMarker(player.token, selectedMarker, markerOperation, markerAmount, customParams);
      if (updatedMarker) {
        fetchPlayerProgress(); // Refresh the progress data
        toast.update(toastId, {
          render: 'Marker updated successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 5000
        });
      } else {
        throw new Error('Failed to update marker');
      }
    } catch (error) {
      toast.update(toastId, {
        render: `Error: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
    } finally {
      setIsUpdatingMarker(false);
    }
  };


  const [isUpdatingWallet, setIsUpdatingWallet] = useState(false);

 
const handleUpdateWallet = async () => {
  const customParams = formatCustomParams(walletCustomParams);

  if (!selectedCurrency) {
    toast.error('Please select a currency', {
      className: 'neu-toast neu-toast-error',
      progressClassName: 'neu-toast-progress',
     closeButton: <CustomCloseButton /> 
    });
    return;
  }

  setIsUpdatingWallet(true);
  const toastId = toast.loading('Updating wallet...', {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
   closeButton: <CustomCloseButton /> 
  });

  try {
    const updatedWallet = await updateWallet(player.token, selectedCurrency, currencyOperation, currencyAmount, customParams);
    if (updatedWallet) {
      fetchPlayerWallet(); // Refresh the wallet data
      toast.update(toastId, {
        render: 'Wallet updated successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 5000
      });
    } else {
      throw new Error('Failed to update wallet');
    }
  } catch (error) {
    toast.update(toastId, {
      render: `Error: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setIsUpdatingWallet(false);
  }
};

  const handleAddMarkerCustomParam = () => {
    setMarkerCustomParams([...markerCustomParams, { name: '', value: '', type: 'string' }]);
  };

  const handleRemoveMarkerCustomParam = (index) => {
    const newParams = markerCustomParams.filter((_, idx) => idx !== index);
    setMarkerCustomParams(newParams);
  };

  const handleMarkerCustomParamChange = (index, field, value) => {
    const newParams = [...markerCustomParams];
    newParams[index][field] = value;
    setMarkerCustomParams(newParams);
  };

  const handleAddWalletCustomParam = () => {
    setWalletCustomParams([...walletCustomParams, { name: '', value: '', type: 'string' }]);
  };

  const handleRemoveWalletCustomParam = (index) => {
    const newParams = walletCustomParams.filter((_, idx) => idx !== index);
    setWalletCustomParams(newParams);
  };

  const handleWalletCustomParamChange = (index, field, value) => {
    const newParams = [...walletCustomParams];
    newParams[index][field] = value;
    setWalletCustomParams(newParams);
  };
  
 // Function to generate Dicebear Avatars URL with the updated endpoint
 const getAvatarUrl = (name) => {
  if (avatarStyle === 'None') {
    return null;
  }
  if (avatarStyle === 'GameStarz') {
    return getRandomGameStarzImage();
  }
  return `https://api.dicebear.com/8.x/${avatarStyle}/svg?seed=${encodeURIComponent(name)}`;
};

  // Step 2: Convert and Save SVG Image


  
  const fetchAndConvertAvatar = async (url) => {
    const response = await fetch(url);
    const svgText = await response.text();
  
    // Convert SVG to PNG using Dicebear conversion module
    const png = toPng(svgText);
    const pngArrayBuffer = await png.toArrayBuffer();
  
    // Create an Image element and load the PNG data
    const blob = new Blob([pngArrayBuffer], { type: 'image/png' });
    const img = new Image();
    const urlObject = URL.createObjectURL(blob);
    img.src = urlObject;
  
    // Wait for the image to load
    await new Promise((resolve) => {
      img.onload = resolve;
    });
  
    // Resize the image using Pica
    const canvas = document.createElement('canvas');
    const outputCanvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    outputCanvas.width = 256;
    outputCanvas.height = 256;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
  
    const pica = new Pica();
    await pica.resize(canvas, outputCanvas);
  
    // Convert the resized canvas to a Blob
    const resizedBlob = await pica.toBlob(outputCanvas, 'image/png');
  
    // Convert the Blob to a File
    const fileName = `${playerName.replace(/\s+/g, '_')}-icon.png`;
    const file = new File([resizedBlob], fileName, { type: 'image/png' });
  
    // Clean up the URL object
    URL.revokeObjectURL(urlObject);
  
    return file;
  };

  // const uploadToS3 = async (file,token) => {
  //   const formData = new FormData();
    
  //   // Create a new file object with the prefixed name
  //   const prefixedFile = new File([file], `playground-${file.name}`, { type: file.type });
    
  //   formData.append('file', prefixedFile);
  
  //   const headers = {
  //     'Authorization': `Bearer ${token}`,
  //     'Api-Key': apiKey,
  //     'env': selectedEnv,
  //     ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  //   };
  
  //   const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/upload-file', {
  //     method: 'POST',
  //     headers: headers,
  //     body: formData,
  //   });
  
  //   const data = await response.json();
  //   if (data.status !== 'success') {
  //     console.error('Upload failed:', data.message);
  //     return;
  //   }
  
  //   return data.data.locationUrl;
  // };
  
  

  const updatePlayerProfile = async (token, s3Url) => {
    const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/user/update-profile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Api-Key': apiKey,
        'Authorization': `Bearer ${token}`,
        'env': selectedEnv,
        ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
      },
      body: JSON.stringify({ thumbUrl: s3Url }),
    });
  
    const data = await response.json();
    if (data.status !== 'success') {
      console.error('Profile update failed:', data.message);
    }
  };


  
  // Step 5: Fetch Updated Player Profile
 
  const fetchUpdatedProfile = async () => {
    try {
      const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/user/get-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Key': apiKey,
          'Authorization': `Bearer ${player.token}`,
          'env': selectedEnv,
          ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
        },
        body: JSON.stringify({}),
      });

      const data = await response.json();
      if (data.status === 'success') {
        setAvatarUrl(data.data.user.thumbUrl);
        setProfileData({
          displayName: data.data.user.displayName || '',
          firstName: data.data.user.firstName || '',
          lastName: data.data.user.lastName || '',
        });
      } else {
        throw new Error('Failed to fetch player profile');
      }
    } catch (error) {
      console.error('Error fetching player profile:', error);
    }
  };

  useEffect(() => {
    if (player) {
      fetchUpdatedProfile();
    }
  }, [player, apiKey, selectedEnv]);


  useEffect(() => {
    setFormData({
      displayName: profileData.displayName || '',
      firstName: profileData.firstName || '',
      lastName: profileData.lastName || '',
    });
  }, [player, profileData]);
  

// Use the token passed in (do not rely on any global token variable)
const uploadToS3 = async (file, token) => {
  const formData = new FormData();
  const prefixedFile = new File([file], `playground-${file.name}`, { type: file.type });
  formData.append('file', prefixedFile);

  const headers = {
    'Authorization': `Bearer ${token}`, // Use the token passed as argument
    'Api-Key': apiKey,
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
  };

  const response = await fetch(
    'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/upload-file',
    {
      method: 'POST',
      headers,
      body: formData,
    }
  );
  const data = await response.json();
  if (data.status !== 'success') {
    console.error('Upload failed:', data.message);
    return;
  }
  return data.data.locationUrl;
};

const initializeAvatarForPlayer = async (player) => {
  try {
    // Mark the current player's avatar as loading
    setAvatarLoadingStatus((prev) => ({ ...prev, [player.id]: true }));

    // Fetch the player's profile
    const profileResponse = await fetch(
      'https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/user/get-profile',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Key': apiKey,
          'Authorization': `Bearer ${player.token}`, // Use this player's token
          'env': selectedEnv,
          ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
        },
        body: JSON.stringify({}),
      }
    );
    const profileData = await profileResponse.json();
    if (profileData.status === 'success') {
      const user = profileData.data.user;
      if (user.thumbUrl) {
        // Avatar already exists for this player
        setAvatarUrls((prev) => ({ ...prev, [player.id]: user.thumbUrl }));
      } else {
        let avatarFile, s3Url;
        if (avatarStyle === 'None') return; // Do nothing if avatar style is "None"

        // Create a unique name for the avatar
        const nameForAvatar = player.email || player.customId || player.username;
        if (avatarStyle === 'GameStarz') {
          const gameStarzImageUrl = getAvatarUrl(nameForAvatar);
          const res = await fetch(gameStarzImageUrl);
          const blob = await res.blob();
          avatarFile = new File(
            [blob],
            `${nameForAvatar.replace(/\s+/g, '_')}-icon.png`,
            { type: 'image/png' }
          );
          // IMPORTANT: Pass this player's token to uploadToS3
          s3Url = await uploadToS3(avatarFile, player.token);
        } else {
          const dicebearUrl = getAvatarUrl(nameForAvatar);
          avatarFile = await fetchAndConvertAvatar(dicebearUrl);
          s3Url = await uploadToS3(avatarFile, player.token);
        }
        // Update the player's profile with the new thumbnail URL
        await updatePlayerProfile(player.token, s3Url);
        setAvatarUrls((prev) => ({ ...prev, [player.id]: s3Url }));
      }
    } else {
      console.error('Failed to fetch player profile:', profileData.message);
    }
  } catch (error) {
    console.error('Error initializing avatar:', error);
  } finally {
    setAvatarLoadingStatus((prev) => ({ ...prev, [player.id]: false }));
  }
};

// Loop through all players so that each avatar upload uses its own bearer token.
const initializeAvatarsForAllPlayers = async () => {
  for (const p of players) {
    // Each call uses p.token
    await initializeAvatarForPlayer(p);
  }
};

useEffect(() => {
  if (players && players.length > 0) {
    initializeAvatarsForAllPlayers();
  }
}, [players]);

  
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);

  const updateProfile = async () => {
    setIsUpdatingProfile(true);
    const toastId = toast.loading('Updating profile...', {
      className: 'neu-toast neu-toast-info',
      progressClassName: 'neu-toast-progress',
      closeButton: <CustomCloseButton />
    });
  
    try {
      const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/user/update-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Key': apiKey,
          'Authorization': `Bearer ${player.token}`,
          'env': selectedEnv,
          ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
        },
        body: JSON.stringify({
          displayName: formData.displayName,
          firstName: formData.firstName,
          lastName: formData.lastName,
        }),
      });
  
      const data = await response.json();
      if (data.status === 'success') {
        setProfileData({
          displayName: formData.displayName,
          firstName: formData.firstName,
          lastName: formData.lastName,
        });
        setIsEditMode(false);
        toast.update(toastId, {
          render: 'Profile updated successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          closeButton: <CustomCloseButton />
        });
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      toast.update(toastId, {
        render: `Error: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeButton: <CustomCloseButton />
      });
      console.error('Error updating profile:', error);
    } finally {
      setIsUpdatingProfile(false);
    }
  };
  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };


  const toggleCustomPurchasePopup = () => {
    if (!showCustomPurchasePopup) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    setShowCustomPurchasePopup(!showCustomPurchasePopup);
  };
  
  const toggleDefaultPurchasePopup = () => {
    if (!showDefaultPurchasePopup) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    setShowDefaultPurchasePopup(!showDefaultPurchasePopup);
  };




const [showProgressPopup, setShowProgressPopup] = useState(false);
const [showWalletPopup, setShowWalletPopup] = useState(false);
const [showTasksPopup, setShowTasksPopup] = useState(false);
const [showRewardsPopup, setShowRewardsPopup] = useState(false);
const [showEventsPopup, setShowEventsPopup] = useState(false);

const [showStoresPopup, setShowStoresPopup] = useState(false);
const [showInventoryPopup, setShowInventoryPopup] = useState(false);

const openModal = () => {
  document.body.classList.add('modal-open');
};

const closeModal = () => {
  document.body.classList.remove('modal-open');
};

// Ensure to call these functions in the appropriate places when showing/hiding popups
useEffect(() => {
  if (showProfilePopup || showProgressPopup || showWalletPopup || showTasksPopup || showRewardsPopup || showEventsPopup || showStoresPopup || showInventoryPopup) {
    openModal();
  } else {
    closeModal();
  }

  // Clean up on unmount
  return () => closeModal();
}, [showProfilePopup, showProgressPopup, showWalletPopup, showTasksPopup, showRewardsPopup, showEventsPopup, showStoresPopup, showInventoryPopup]);



const triggerStoresPopup =async()=>{
  fetchStores()
}

const triggerinventoryPopup =async()=>{
  handleFetchItems()
  handleFetchBundles()
}



 const actions = [
    { value: 'update-progress', label: 'Update Progress' },
    { value: 'update-wallet', label: 'Update Wallet' },
    { value: 'force-complete-task', label: 'Force Complete Tasks' },
    { value: 'grant-pending-rewards', label: 'Grant Pending Rewards' },
    { value: 'fire-custom-events', label: 'Fire Custom Events' },
    { value: 'manage-stores', label: 'Make a Purchase' },
    { value: 'manage-inventory', label: 'Manage Inventory' },
  ];

  const [showActionsDropdown, setShowActionsDropdown] = useState(false);
  const [selectedAction, setSelectedAction] = useState('Player Actions');

  const handleActionSelect = (action) => {
    setSelectedAction(action.label);
    setShowActionsDropdown(false);
    switch (action.value) {
      case 'update-progress':
        setShowProgressPopup(true);
        break;
      case 'update-wallet':
        setShowWalletPopup(true);
        break;
      case 'force-complete-task':
        setShowTasksPopup(true);
        break;
      case 'grant-pending-rewards':
        setShowRewardsPopup(true);
        break;
      case 'fire-custom-events':
        setShowEventsPopup(true);
        break;
      case 'manage-stores':
        setShowStoresPopup(true);
        triggerStoresPopup()
        break;
      case 'manage-inventory':
        setShowInventoryPopup(true);
        triggerinventoryPopup()
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const resetSelectedAction = () => setSelectedAction('Player Actions');
    
    window.addEventListener('click', resetSelectedAction);
    
    return () => {
      window.removeEventListener('click', resetSelectedAction);
    };
  }, []);

const getInitialsIcon = (name) => {
  return `https://api.dicebear.com/8.x/initials/svg?seed=${encodeURIComponent(name)}`;
};

const [progressionSystems, setProgressionSystems] = useState([]);
const handleFetchProgressionSystems = async () => {
  try {
    const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/app/get-progression-systems', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${player.token}`,
        'Api-Key': apiKey,
        'env': selectedEnv,
        ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
      },
      body: JSON.stringify({ projectId }),
    });

    const data = await response.json();
    if (data.status === 'success') {
      setProgressionSystems(data.data.progressionSystems || []);
    } else {
      throw new Error(data.message || 'Failed to fetch progression systems');
    }
  } catch (error) {
    console.error('Error fetching progression systems:', error);
  }
};

useEffect(() => {
  handleFetchProgressionSystems();
}, [player.token, projectId]);

const getProgressionSystemName = (systemId) => {
  const system = progressionSystems.find((sys) => sys.id === systemId);
  return system ? system.name : systemId;
};

const getProgressionSystemIcon = (systemId) => {
  const system = progressionSystems.find((sys) => sys.id === systemId);
  if (system && system.iconUrl) {
    return system.iconUrl;
  } else {
    return `https://api.dicebear.com/8.x/initials/svg?seed=${encodeURIComponent(getProgressionSystemName(systemId))}`;
  }
};


const [selectedPurchaseContent, setSelectedPurchaseContent] = useState(null);

const handleCustomPurchase = async () => {
  setIsCustomPurchaseLoading(true);
  const toastId = toast.loading('Processing custom purchase...', {
    className: 'neu-toast neu-toast-info',
    progressClassName: 'neu-toast-progress',
    closeButton: <CustomCloseButton />
  });

  const headers = {
    'Content-Type': 'application/json',
    'Api-Key': apiKey,
    'Authorization': `Bearer ${player.token}`,
    'env': selectedEnv,
    ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') })
  };

  const payload = {};

  const formatPrice = (price) => {
    if (price.realWorldCurrency && price.realWorldCurrency.code) {
      return {
        id: selectedPurchaseContent.id,
        amount: price.amount,
        price: price.price,
        collectionId: price.collectionId,
        stackId: price.stackId,
        storeId: storeId,
        realWorldCurrencyId: price.realWorldCurrency.code
      };
    } else {
      return {
        id: selectedPurchaseContent.id,
        amount: price.amount,
        price: price.price,
        currencyId: price.currencyDetails.id,
        collectionId: price.collectionId,
        stackId: price.stackId,
        storeId: storeId
      };
    }
  };

  if (selectedPurchaseContent.type === 'item') {
    payload.items = currentPrices.map(formatPrice);
  } else if (selectedPurchaseContent.type === 'bundle') {
    payload.bundles = currentPrices.map(formatPrice);
  }

  try {
    const response = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api-client/v1/client/stores/custom-purchase', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to make custom purchase');
    }

    if (data.data.items.length > 0 || data.data.bundles.length > 0) {
      setStoreContent((prevContent) =>
        prevContent.map((content) =>
          (content.type === 'item' && data.data.items.some(item => item.id === content.id)) || 
          (content.type === 'bundle' && data.data.bundles.some(bundle => bundle.id === content.id))
            ? { ...content, ...data.data.items.find(item => item.id === content.id) || data.data.bundles.find(bundle => bundle.id === content.id) }
            : content
        )
      );
    }
    toast.update(toastId, {
      render: 'Custom purchase successful!',
      type: 'success',
      isLoading: false,
      autoClose: 5000,
      closeButton: <CustomCloseButton />
    });

    toggleCustomPurchasePopup(); // Close the popup
  } catch (error) {
    toast.update(toastId, {
      render: `Error: ${error.message}`,
      type: 'error',
      isLoading: false,
      autoClose: 5000,
      closeButton: <CustomCloseButton />
    });
    console.error('Error making custom purchase:', error);
  } finally {
    setIsCustomPurchaseLoading(false);
  }
};

const handleAddPrice = () => {
  setCurrentPrices([
    ...currentPrices,
    { currencyDetails: { id: '' }, price: 0, amount: 1, collectionId: '', stackId: '' }
  ]);
};


const [isFullScreen, setIsFullScreen] = useState(false);
const toggleFullScreen = () => {
  setIsFullScreen(!isFullScreen);
};



const uniqueCurrencies = currencies.map(currency => ({
  id: currency.id,
  name: currency.name
}));

const uniqueRealWorldCurrencies = [];

storeContent.forEach(content => {
  content.prices.forEach(price => {
    if (price.realWorldCurrency && !uniqueRealWorldCurrencies.some(c => c.code === price.realWorldCurrency.code)) {
      uniqueRealWorldCurrencies.push({
        code: price.realWorldCurrency.code,
        name: `${price.realWorldCurrency.name} (${price.realWorldCurrency.code})`
      });
    }
  });
});



const getRandomGameStarzImage = () => {
  const images = [
    'gs_pp_1.jpeg',
    'gs_pp_2.jpeg',
    'gs_pp_3.jpeg',
    'gs_pp_4.jpeg',
    'gs_pp_5.jpeg',
    'gs_pp_6.jpeg',
    'gs_pp_7.jpeg',
    'gs_pp_8.jpeg',
    'gs_pp_9.jpeg',
    'gs_pp_10.jpeg',
    'gs_pp_11.jpeg',
    'gs_pp_12.jpeg',
    'gs_pp_13.jpeg',
    'gs_pp_14.jpeg',
    'gs_pp_15.jpeg',
    'gs_pp_16.jpeg',
    'gs_pp_17.jpeg',
    'gs_pp_18.jpeg',
    'gs_pp_19.jpeg',
    'gs_pp_20.jpeg',
    'gs_pp_21.jpeg',
    'gs_pp_22.jpeg',
    'gs_pp_23.jpeg',
    'gs_pp_24.jpeg',
    'gs_pp_25.jpeg',
    'gs_pp_26.jpeg',
    'gs_pp_27.jpeg',
    'gs_pp_28.jpeg',
    'gs_pp_29.jpeg',
    'gs_pp_30.jpeg',
    'gs_pp_31.jpeg',
    'gs_pp_32.jpeg',
    'gs_pp_33.jpeg',
    'gs_pp_34.jpeg',
    'gs_pp_35.jpeg',
    
    
    // Add all your GameStarz images here
  ];
  const randomIndex = Math.floor(Math.random() * images.length);
  return `${process.env.PUBLIC_URL}/assets/GameStarz/${images[randomIndex]}`;
};


const [storeDropdownVisibility, setStoreDropdownVisibility] = useState(false);

const toggleStoreDropdownVisibility = () => {
  setStoreDropdownVisibility(!storeDropdownVisibility);
};

const handleStoreSelect = (storeId) => {
  setSelectedStore(stores.find(store => store.id === storeId));
  setStoreDropdownVisibility(false);
};


const [markerDropdownVisibility, setMarkerDropdownVisibility] = useState(false);

const toggleMarkerDropdownVisibility = () => {
  setMarkerDropdownVisibility(!markerDropdownVisibility);
};

const handleMarkerSelect = (markerId) => {
  setSelectedMarker(markerId);
  setMarkerDropdownVisibility(false);
};


const [markerOperationDropdownVisibility, setMarkerOperationDropdownVisibility] = useState(false);

const toggleMarkerOperationDropdownVisibility = () => {
  setMarkerOperationDropdownVisibility(!markerOperationDropdownVisibility);
};

const handleMarkerOperationSelect = (operation) => {
  setMarkerOperation(operation);
  setMarkerOperationDropdownVisibility(false);
};


const [currencyDropdownVisibility, setCurrencyDropdownVisibility] = useState(false);

const toggleCurrencyDropdownVisibility = () => {
  setCurrencyDropdownVisibility(!currencyDropdownVisibility);
};

const handleCurrencySelect = (currencyId) => {
  setSelectedCurrency(currencyId);
  setCurrencyDropdownVisibility(false);
};

const [currencyOperationDropdownVisibility, setCurrencyOperationDropdownVisibility] = useState(false);

const toggleCurrencyOperationDropdownVisibility = () => {
  setCurrencyOperationDropdownVisibility(!currencyOperationDropdownVisibility);
};

const handleCurrencyOperationSelect = (operation) => {
  setCurrencyOperation(operation);
  setCurrencyOperationDropdownVisibility(false);
};



const [paramDropdownVisibility, setParamDropdownVisibility] = useState({});

const toggleParamDropdownVisibility = (index) => {
  setParamDropdownVisibility((prevVisibility) => ({
    ...prevVisibility,
    [index]: !prevVisibility[index],
  }));
};

const handleParamSelect = (index, value) => {
  handleMarkerCustomParamChange(index, 'type', value);
  setParamDropdownVisibility((prevVisibility) => ({
    ...prevVisibility,
    [index]: false,
  }));
};

const [walletParamDropdownVisibility, setWalletParamDropdownVisibility] = useState({});

const toggleWalletParamDropdownVisibility = (index) => {
  setWalletParamDropdownVisibility((prevVisibility) => ({
    ...prevVisibility,
    [index]: !prevVisibility[index],
  }));
};

const handleWalletParamSelect = (index, value) => {
  handleWalletCustomParamChange(index, 'type', value);
  setWalletParamDropdownVisibility((prevVisibility) => ({
    ...prevVisibility,
    [index]: false,
  }));
};



  return (
    <div className="player-tab">
{globalLoading && (
  <div className="global-loading-overlay">
    <img src={`${process.env.PUBLIC_URL}/assets/specter_landing.svg`} alt="Loading..." className="loading-logo" />
  </div>
)}


    
    <div className="player-tab">
        <div className="card">
      <div className="actions-dropdown-container">
        <div className="custom-dropdown">
          <div className="custom-dropdown-header" onClick={() => setShowActionsDropdown(!showActionsDropdown)}>
            {selectedAction}
            <span className="custom-dropdown-arrow">&#9662;</span>
          </div>
          {showActionsDropdown && (
            <ul className="custom-dropdown-list">
              {actions.map((action) => (
                <li
                  key={action.value}
                  className="custom-dropdown-option"
                  onClick={() => handleActionSelect(action)}
                >
                  {action.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="player-content-container">
      <div className="player-left-column">
      <div className="player-overall-card">
      <h1>Player Profile</h1>
        <div className="player-card">
        <div className="player-icon">
  {avatarLoadingStatus[player.id] ? (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  ) : (
    <ProtectedImage apiKey={apiKey} src={avatarUrls[player.id] || getInitialsIcon(player.email || player.customId || player.username)} alt="Player Icon" />
  )}
</div>

          <div className="player-info">
          <span className="player-name">{ player.email || player.customId || player.username}</span>

            <div className="player-id">
              {copySuccess ? (
                <span className="copy-success">Copied!</span>
              ) : (
                <button className="copy-button" onClick={() => copyToClipboard(player.id)}>
                  <img src={`${process.env.PUBLIC_URL}/assets/copy.svg`} alt="Copy" />
                </button>
              )}
              {player.id}
            </div>
          </div>
        </div>

    <div className="player-profile-card">
  <div className="player-profile-card-header">
    <h4>Player Bio</h4>
    <button onClick={() => setIsEditMode(!isEditMode)} className={`edit-button ${isEditMode ? 'active' : ''}`}>
      <img src={`${process.env.PUBLIC_URL}/assets/edit.svg`} alt="Edit" />
    </button>
  </div>
  <div className="player-profile-card-contents">
    {isEditMode && (
      <button onClick={generateNames} className="generate-button">Generate</button>
    )}
    <div className="profile-field">
      <label className="profile-label">Display Name</label>
      <input
        type="text"
        value={formData.displayName}
        onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
        disabled={!isEditMode}
      />
    </div>
    <div className="profile-field">
      <label className="profile-label">First Name</label>
      <input
        type="text"
        value={formData.firstName}
        onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
        disabled={!isEditMode}
      />
    </div>
    <div className="profile-field">
      <label className="profile-label">Last Name</label>
      <input
        type="text"
        value={formData.lastName}
        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
        disabled={!isEditMode}
      />
    </div>
    {isEditMode && (
      <div className="profile-popup-footer">
        <button onClick={updateProfile} className="update-button" disabled={isUpdatingProfile}>
          {isUpdatingProfile ? <div className="button-spinner"></div> : 'Update'}
        </button>
      </div>
    )}


     </div>
        </div>
        </div>
        </div>
      <div className="player-stats-card">
      <h1>Player Stats</h1>
      <h4>Progression Marker Stats</h4>
      {progressData && progressData.length > 0 ? progressData.map((marker) => (
  <div key={marker.id} className="progress-marker">
    <div className="marker-info">
      <ProtectedImage
        src={marker.iconUrl || getInitialsIcon(marker.name)}
        alt={marker.name}
        className="marker-icon"
        apiKey={apiKey}
      />
      <span>{marker.name}: {marker.progressionMarkerAmount}</span>
    </div>
  </div>
)) : <h5>You have not set up any Progression Markers!</h5>}


      <h4>Progression System Stats</h4>
      {progressData && progressData.some(marker => marker.progressInfo && marker.progressInfo.length > 0) ? progressData.map((marker) => (
  marker.progressInfo.map((system) => (
    <div key={system.progressionSystemId} className="progress-system">
      <ProtectedImage
        src={getProgressionSystemIcon(system.progressionSystemId)}
        alt={getProgressionSystemName(system.progressionSystemId)}
        className="system-icon"
        apiKey={apiKey}
      />
      <span>{getProgressionSystemName(system.progressionSystemId)} ({marker.name}):</span>
      <span className="level-label"> Level {system.currentLevelNo}</span>
    </div>
  ))
)) : <h5>You have not set up any Progression Systems!</h5>}



      <h4>Player Wallet Stats</h4>
      {walletData && walletData.length > 0 ? walletData.map((currency) => (
  <div key={currency.id} className="currency">
    <ProtectedImage
      src={currency.iconUrl || getInitialsIcon(currency.name)}
      alt={currency.name}
      className="currency-icon"
      apiKey={apiKey}
    />
    <span>{currency.name}: {currency.balance}</span>
  </div>
)) : <h5>You have not set up any Currencies!</h5>}


    </div>
    </div>

</div>
      
  

    
  
      {showProgressPopup && (
         <div className="overlay">
        <div className="popup">
          <div className="popup-header">
            <button onClick={() => setShowProgressPopup(false)} className="close-button">
              <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
            </button>
            <h1>Update Progress</h1>
          </div>
          <div className="popup-content">
          <h3>Player Progression Marker Details</h3>
          <div className="progress-list">
          <table className="new-select-table">
            <thead>
              <tr>
                <th>Marker Name</th>
                <th>Marker ID</th>
                <th>Marker Balance</th>
              </tr>
            </thead>
            <tbody>
              {progressData.map((marker) => (
                <tr key={marker.id}>
                  <td>{marker.name}</td>
                  <td>{marker.id}</td>
                  <td>{marker.progressionMarkerAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="centered-contents">
        <div className="centered-fields">
        <label className="profile-label">
              Marker:

            </label>
              <div className="marker-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleMarkerDropdownVisibility}
  >
    {selectedMarker 
      ? markerOptions.find(marker => marker.id === selectedMarker)?.name
      : 'Select a marker'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {markerDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {markerOptions.map((marker) => (
        <li
          key={marker.id}
          className="match-select-dropdown-option"
          onClick={() => handleMarkerSelect(marker.id)}
        >
          {marker.name}
        </li>
      ))}
    </ul>
  )}
</div>
</div>
<div className="centered-fields">
<label className="profile-label">
              Operation:
              </label>
              <div className="marker-operation-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleMarkerOperationDropdownVisibility}
  >
    {markerOperation.charAt(0).toUpperCase() + markerOperation.slice(1)}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {markerOperationDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      <li
        className="match-select-dropdown-option"
        onClick={() => handleMarkerOperationSelect('add')}
      >
        Add
      </li>
      <li
        className="match-select-dropdown-option"
        onClick={() => handleMarkerOperationSelect('subtract')}
      >
        Subtract
      </li>
      <li
        className="match-select-dropdown-option"
        onClick={() => handleMarkerOperationSelect('set')}
      >
        Set
      </li>
    </ul>
  )}
</div>
</div>
<div className="centered-fields">
<label className="profile-label">
              Amount:
              </label>
              <input
                type="number"
                value={markerAmount}
                onChange={(e) => setMarkerAmount(Number(e.target.value))}
              />
                 </div>
        
                 </div>
           <div className="custom-param-marker-add">
            <button onClick={handleAddMarkerCustomParam}>Add Custom Parameters</button>
            {markerCustomParams.map((param, index) => (
              <div key={index} className="custom-param">
                <input
                  type="text"
                  placeholder="Param Name"
                  value={param.name}
                  onChange={(e) => handleMarkerCustomParamChange(index, 'name', e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Value"
                  value={param.value}
                  onChange={(e) => handleMarkerCustomParamChange(index, 'value', e.target.value)}
                />
           <div className="param-select-dropdown">
  <div
    className="param-select-dropdown-header"
    onClick={() => toggleParamDropdownVisibility(index)}
  >
    {param.type.charAt(0).toUpperCase() + param.type.slice(1)}
    <span className="param-select-dropdown-arrow">&#9662;</span>
  </div>
  {paramDropdownVisibility[index] && (
    <ul className="param-select-dropdown-list">
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect(index, 'string')}
      >
        String
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect(index, 'number')}
      >
        Number
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleParamSelect(index, 'boolean')}
      >
        Boolean
      </li>
    </ul>
  )}
</div>

                <button onClick={() => handleRemoveMarkerCustomParam(index)}>-</button>
              </div>
            ))}
              <div className="then-divider"></div>
              <button className="loading-button" onClick={handleUpdateMarker} disabled={isUpdatingMarker}>
  {isUpdatingMarker ? <><div className="button-spinner"></div> Updating...</> : 'Update Marker'}
</button>
          </div>
        </div>
        </div>
        </div>
      )}
  
      {showWalletPopup && (
         <div className="overlay">
        <div className="popup">
          <div className="popup-header">
            <button onClick={() => setShowWalletPopup(false)} className="close-button">
              <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
            </button>
            <h1>Update Wallet</h1>
          </div>
          <div className="popup-content">
          <h3>Player Wallet Details</h3>
          <div className="wallet-list">
          <table className="new-select-table">
            <thead>
              <tr>
                <th>Currency Name</th>
                <th>Currency ID</th>
                <th>Wallet Balance</th>
              </tr>
            </thead>
            <tbody>
              {walletData.map((currency) => (
                <tr key={currency.id}>
                  <td>{currency.name}</td>
                  <td>{currency.id}</td>
                  <td>{currency.balance}</td>
                </tr>
              ))}
            </tbody>
          </table>
    
        <div className="centered-contents">
        <div className="centered-fields">
        <label className="profile-label">
        
              Currency:
              </label>
              <div className="currency-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleCurrencyDropdownVisibility}
  >
    {selectedCurrency 
      ? currencyOptions.find(currency => currency.id === selectedCurrency)?.name
      : 'Select a currency'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {currencyDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {currencyOptions.map((currency) => (
        <li
          key={currency.id}
          className="match-select-dropdown-option"
          onClick={() => handleCurrencySelect(currency.id)}
        >
          {currency.name}
        </li>
      ))}
    </ul>
  )}
</div>

              </div>
           
              <div className="centered-fields">
        <label className="profile-label">
              Operation:
              </label>
              <div className="currency-operation-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleCurrencyOperationDropdownVisibility}
  >
    {currencyOperation.charAt(0).toUpperCase() + currencyOperation.slice(1)}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {currencyOperationDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      <li
        className="match-select-dropdown-option"
        onClick={() => handleCurrencyOperationSelect('add')}
      >
        Add
      </li>
      <li
        className="match-select-dropdown-option"
        onClick={() => handleCurrencyOperationSelect('subtract')}
      >
        Subtract
      </li>
      <li
        className="match-select-dropdown-option"
        onClick={() => handleCurrencyOperationSelect('set')}
      >
        Set
      </li>
    </ul>
  )}
</div>

              </div>
              <div className="centered-fields">
        <label className="profile-label">
              Amount:
              </label>
              <input
                type="number"
                value={currencyAmount}
                onChange={(e) => setCurrencyAmount(Number(e.target.value))}
              />
                </div>
            </div>  
             </div>
            <div className="custom-param-marker-add">
            <button onClick={handleAddWalletCustomParam}>Add Custom Parameters</button>
            {walletCustomParams.map((param, index) => (
              <div key={index} className="custom-param">
                <input
                  type="text"
                  placeholder="Param Name"
                  value={param.name}
                  onChange={(e) => handleWalletCustomParamChange(index, 'name', e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Value"
                  value={param.value}
                  onChange={(e) => handleWalletCustomParamChange(index, 'value', e.target.value)}
                />
             <div className="param-select-dropdown">
  <div
    className="param-select-dropdown-header"
    onClick={() => toggleWalletParamDropdownVisibility(index)}
  >
    {param.type.charAt(0).toUpperCase() + param.type.slice(1)}
    <span className="param-select-dropdown-arrow">&#9662;</span>
  </div>
  {walletParamDropdownVisibility[index] && (
    <ul className="param-select-dropdown-list">
      <li
        className="param-select-dropdown-option"
        onClick={() => handleWalletParamSelect(index, 'string')}
      >
        String
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleWalletParamSelect(index, 'number')}
      >
        Number
      </li>
      <li
        className="param-select-dropdown-option"
        onClick={() => handleWalletParamSelect(index, 'boolean')}
      >
        Boolean
      </li>
    </ul>
  )}
</div>

                <button onClick={() => handleRemoveWalletCustomParam(index)}>-</button>
              </div>
              
            ))}
              </div>
          
            <div className="then-divider"></div>
            <button className="loading-button" onClick={handleUpdateWallet} disabled={isUpdatingWallet}>
          {isUpdatingWallet ? <><div className="button-spinner"></div> Updating...</> : 'Update Wallet'}
        </button>
          </div>
        </div>
        </div>
      )}
  
      {showTasksPopup && (
            <div className="overlay">
        <div className="popup">
          <div className="popup-header">
          <button onClick={() => setShowTasksPopup(false)} className="close-button">
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
        </button>
            <h1>Force Complete Tasks</h1>
          </div>
          <div className="popup-content">
          <button className="loading-button" onClick={fetchPlayerPendingTasks} disabled={taskLoading}>
          {taskLoading ? <div className="button-spinner"></div> : 'Fetch Player Pending Tasks'}
          {taskLoading && ' Fetching...'}
        </button>
            <p>{taskFetchStatus}</p>
            {tasks.length > 0 && (
              <div>
                <table>
                  <thead>
                    <tr>
                      <th><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th>
                      <th>Task ID</th>
                      <th>Task Name</th>
                      <th>Task Group ID</th>
                      <th>Task Group Name</th>
                      <th>Task Group Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks.map(task => (
                      <tr key={task.id}>
                        <td><input type="checkbox" checked={selectedTasks.includes(task.id)} onChange={() => handleSelectTask(task.id)} /></td>
                        <td>{task.id}</td>
                        <td>{task.name}</td>
                        <td>{task.taskGroupDetails?.id || ''}</td>
                        <td>{task.taskGroupDetails?.name || ''}</td>
                        <td>{task.taskGroupDetails?.taskGroupType || ''}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {selectedTasks.length > 0 && (
                  <div>
                   <button className="loading-button" onClick={handleForceCompleteTasks} disabled={taskLoading}>
                  {taskLoading ? <div className="button-spinner"></div> : 'Force Complete Task'}
                  {taskLoading && ' Force Completing...'}
                </button>
                    <p>{taskFetchStatus}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        </div>
      )}
  
      {showRewardsPopup && (
            <div className="overlay">
        <div className="popup">
          <div className="popup-header">
            <button onClick={() => setShowRewardsPopup(false)} className="close-button">
              <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
            </button>
            <h1>Grant Pending Rewards</h1>
          </div>
          <div className="popup-content">
          <button className="loading-button" onClick={fetchRewardBus} disabled={rewardLoading}>
  {rewardLoading ? <><div className="button-spinner"></div> Fetching...</> : 'Fetch Reward Bus'}
</button>
            <p>{rewardFetchStatus}</p>
            {rewards.length > 0 && (
              <div>
                <div style={{ textAlign: 'center' }}>
                  <h4>Total Pending Rewards: {rewards.length}</h4>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th><input type="checkbox" checked={selectAllRewards} onChange={handleSelectAllRewards} /></th>
                      <th>Reward Set#</th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Amount</th>
                      <th>Reward Type</th>
                      <th>Source Type</th>
                      <th>Source ID</th>
                      <th>Instance ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(groupedRewards).map((key, index) => {
                      const rewardsGroup = groupedRewards[key];
                      const rewardSetNumber = rewardSets[key];
                      return rewardsGroup.map((reward, idx) => (
                        <tr key={`${key}-${idx}`}>
                          {idx === 0 && (
                            <>
                              <td rowSpan={rewardsGroup.length}>
                                <input
                                  type="checkbox"
                                  checked={selectedRewards.includes(key)}
                                  onChange={() => handleSelectReward(key)}
                                />
                              </td>
                              <td rowSpan={rewardsGroup.length}>{rewardSetNumber}</td>
                            </>
                          )}
                          <td>{reward.id}</td>
                          <td>{reward.name}</td>
                          <td>{reward.amount}</td>
                          <td>{reward.rewardType}</td>
                          <td>{reward.sourceType}</td>
                          <td>{reward.sourceId}</td>
                          <td>{reward.instanceId || ''}</td>
                        </tr>
                      ));
                    })}
                  </tbody>
                </table>
          
                {selectedRewards.length > 0 && (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <div className="then-divider"></div>
                <button className="loading-button" onClick={grantRewards} disabled={granting}>
  {granting ? <><div className="button-spinner"></div> Granting...</> : 'Grant Pending Rewards'}
</button>
                <div className="or-divider">or</div>
                <button className="loading-button" onClick={grantRewardsOneByOne} disabled={grantingSequential}>
  {grantingSequential ? <><div className="button-spinner"></div> Granting...</> : 'Grant Rewards Sequentially'}
</button>
                <div className="or-divider">or</div>
                <div className="batch-update-container">
                  <label>Input Batch Size & Grant Rewards (Only for Specter Devs)</label>
                  <input
                    type="number"
                    value={grantBatchSize}
                    onChange={(e) => setGrantBatchSize(Number(e.target.value))}
                    min="1"
                    max={selectedRewards.length}
                    placeholder="Batch Size"
                  />
                
<button className="loading-button" onClick={() => grantRewardsBatch(grantBatchSize)} disabled={batchGranting}>
  {batchGranting ? <><div className="button-spinner"></div> Batch Granting...</> : 'Batch Grant Rewards'}
</button>
</div>


                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        </div>
      )}
  
      {showEventsPopup && (
        <div className="overlay">
        <div className="popup">
          <div className="popup-header">
            <button onClick={() => setShowEventsPopup(false)} className="close-button">
              <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
            </button>
            <h1>Fire Custom Events</h1>
          </div>
          <div className="popup-content">
            <label>
              Custom Event:
              <select className="custom-select" onChange={(e) => handleSelectEvent(e.target.value)}>
                <option value="">Select a custom event</option>
                {customEvents.map(event => (
                  <option key={event.id} value={event.eventId}>
                    {event.name} ({event.eventId})
                  </option>
                ))}
              </select>
            </label>
            {selectedEvent && (
              <div className="event-params">
            <table className="new-select-table">
                  <thead>
                    <tr>
                      <th>Parameter Name</th>
                      <th>Value</th>
                      <th>Type</th>
                      <th>Data Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventParams.map((param, index) => (
                      <tr key={index}>
                        <td>{param.name}</td>
                        <td>
                          {param.dataType === 'integer' && (
                            <input
                              type="number"
                              step="1"
                              value={param.value}
                              onChange={(e) => handleParamChange(index, e.target.value)}
                              disabled={!param.editable}
                            />
                          )}
                          {param.dataType === 'float' && (
                            <input
                              type="number"
                              step="any"
                              value={param.value}
                              onChange={(e) => handleParamChange(index, e.target.value)}
                              disabled={!param.editable}
                            />
                          )}
                          {param.dataType === 'boolean' && (
                            <select className="custom-select"
                              value={param.value}
                              onChange={(e) => handleParamChange(index, e.target.value)}
                              disabled={!param.editable}
                            >
                              <option value="">Select a value</option>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          )}
                          {param.dataType === 'string' && (
                            <input
                              type="text"
                              value={param.value}
                              onChange={(e) => handleParamChange(index, e.target.value)}
                              disabled={!param.editable}
                            />
                          )}
                        </td>
                        <td>{param.type}</td>
                        <td>{param.dataType}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button onClick={handleClearFields}>Clear Fields</button>
                <button className="loading-button" onClick={handleFireEvent} disabled={isFiringEvent}>
              {isFiringEvent ? <><div className="button-spinner"></div> Firing...</> : 'Fire Event'}
            </button>
                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              </div>
            )}
          </div>
        </div>
        </div>
      )}


{showStoresPopup && (
  <div className={`popup ${isFullScreen ? 'full-screen' : ''}`}>
    <div className="popup-header">
      <button onClick={() => setShowStoresPopup(false)} className="close-button">
        <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
      </button>
      <h1>Stores</h1>
      <button class="fullscreen-container" onClick={toggleFullScreen}>
  <input type="checkbox" checked={isFullScreen} onChange={toggleFullScreen} />
  <svg viewBox="0 0 448 512" height="1em" xmlns="http://www.w3.org/2000/svg" class="fullscreen">
    <path d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"></path>
  </svg>
  <svg viewBox="0 0 448 512" height="1em" xmlns="http://www.w3.org/2000/svg" class="exitfullscreen">
    <path d="M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z"></path>
  </svg>
</button>
    </div>
    <div className={`popup-content ${isFullScreen ? 'full-screen-content' : ''}`}>
     
        <div className="store-select-dropdown match-select-dropdown">
  <div
    className="match-select-dropdown-header"
    onClick={toggleStoreDropdownVisibility}
  >
    {selectedStore ? selectedStore.name : 'Select a store'}
    <span className="match-select-dropdown-arrow">&#9662;</span>
  </div>
  {storeDropdownVisibility && (
    <ul className="match-select-dropdown-list">
      {stores.map((store) => (
        <li
          key={store.id}
          className="match-select-dropdown-option"
          onClick={() => handleStoreSelect(store.id)}
        >
          {store.name}
        </li>
      ))}
    </ul>
  )}
</div>

    
      {selectedStore && (
        <div className="store-header">
          <h3>{selectedStore.name}</h3>
          <div className="store-container neu-morphic">
            <div className="store-content">
              <div className="store-categories">
                {categories.map((category) => (
                  <div key={category.id} className={`store-category ${selectedCategory === category.id ? 'active' : ''}`} onClick={() => handleCategoryChange(category.id)}>
                    {category.name}
                  </div>
                ))}
              </div>
              <div className="store-items grid-container">
                {storeContent.map((content) => (
                  <div key={content.id} className="store-item neu-morphic">
                    <ProtectedImage src={content.iconUrl|| getInitialsIcon(content.name)} alt={content.name} apiKey={apiKey} />
                    <h5>{content.name}</h5>
                    <div className="item-prices">
  {content.prices && content.prices.length > 0 ? (
    content.prices.map((price, index) => (
      <div key={index} className="item-price">
        {price.realWorldCurrency && price.realWorldCurrency.code ? (
          <span>{price.realWorldCurrency.name} ({price.realWorldCurrency.code}): {price.priceType} {price.price?.toFixed(2)}</span>
        ) : (
          <span>{price.currencyDetails?.name} ({price.currencyDetails?.id}): {price.priceType} {price.price?.toFixed(2)}</span>
        )}
        {price.discount && <span className="discount">(-{price.discount * 100}%)</span>}
      </div>
    ))
  ) : (
    <div className="item-price">
      <span>Free</span>
    </div>
  )}
</div>

                    <div className="item-actions">
                      <button className="buy-button" onClick={() => handleDefaultPurchaseClick(content)}>Default Purchase</button>
                      {content.prices && content.prices.length > 0 && (
    <button className="custom-purchase-button" onClick={() => handleCustomPurchaseClick(content)}>Custom Purchase</button>
  )}
                        
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
)}



    
   

{showCustomPurchasePopup && (
  <div className="overlay">
    <div className="purchase-popup">
      <div className="purchase-popup-header">
        <button className="close-button" onClick={toggleCustomPurchasePopup}>
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
        </button>
        <h2>Custom Purchase</h2>
        {selectedPurchaseContent && <h3>{selectedPurchaseContent.name}</h3>} {/* Display item/bundle name */}
      </div>
      <div className="purchase-popup-content">
        <button onClick={handleAddPrice}>Add Price</button> {/* Add Price button on top */}
        {currentPrices.map((price, index) => (
  <div key={index} className="custom-param">
    <select 
      value={price.currencyDetails?.id || price.realWorldCurrency?.code} 
      onChange={(e) => {
        const updatedPrices = [...currentPrices];
        if (price.realWorldCurrency) {
          updatedPrices[index].realWorldCurrency.code = e.target.value;
        } else {
          updatedPrices[index].currencyDetails.id = e.target.value;
        }
        setCurrentPrices(updatedPrices);
      }}
    >
      {uniqueCurrencies.map((currency) => (
        <option key={currency.id} value={currency.id}>{currency.name}</option>
      ))}
      {uniqueRealWorldCurrencies.map((currency) => (
        <option key={currency.code} value={currency.code}>
          {currency.name}
        </option>
      ))}
    </select>
    <input type="number" value={price.price} onChange={(e) => {
      const updatedPrices = [...currentPrices];
      updatedPrices[index].price = Number(e.target.value);
      setCurrentPrices(updatedPrices);
    }} placeholder="Price" />
    <input type="number" value={price.amount} onChange={(e) => {
      const updatedPrices = [...currentPrices];
      updatedPrices[index].amount = Number(e.target.value);
      setCurrentPrices(updatedPrices);
    }} placeholder="Amount" />
    <input type="text" value={price.collectionId} onChange={(e) => {
      const updatedPrices = [...currentPrices];
      updatedPrices[index].collectionId = e.target.value;
      setCurrentPrices(updatedPrices);
    }} placeholder="Collection ID" />
    <input type="text" value={price.stackId} onChange={(e) => {
      const updatedPrices = [...currentPrices];
      updatedPrices[index].stackId = e.target.value;
      setCurrentPrices(updatedPrices);
    }} placeholder="Stack ID" />
    <button onClick={() => handleRemovePrice(index)}>-</button>
  </div>
))}


<button onClick={handleCustomPurchase} disabled={isCustomPurchaseLoading}>
          {isCustomPurchaseLoading ? <div className="button-spinner"></div> : 'Confirm Custom Purchase'}
        </button>
      </div>
    </div>
  </div>
)}

  {showDefaultPurchasePopup && (
    <div className="overlay">
      <div className="purchase-popup">
        <div className="purchase-popup-header">
          <button className="close-button" onClick={toggleDefaultPurchasePopup}> 
            <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
          </button>
          <h2>Default Purchase</h2>
      
        </div>
        <div className="purchase-popup-content">
          {defaultPurchaseContent.prices.map((price, index) => (
            <div key={index} className="item-price">
              <span>{price.currencyDetails?.name} ({price.currencyDetails?.id}): {price.priceType} {Math.round(price.price)}</span>
              {price.discount && <span className="discount">(-{price.discount * 100}%)</span>}
            </div>
          ))}
          <label>Amount:
            <input type="number" value={purchaseAmount} onChange={(e) => setPurchaseAmount(Number(e.target.value))} />
          </label>
          <div>
            <label>Store ID:
              <input type="text" value={storeId} disabled /> 
            </label>
          </div>
          <div>
            <label>Collection ID:
              <input type="text" value={collectionId} onChange={(e) => setCollectionId(e.target.value)} />
            </label>
          </div>
          <div>
            <label>Stack ID:
              <input type="text" value={stackId} onChange={(e) => setStackId(e.target.value)} />
            </label>
          </div>
          <button onClick={handleDefaultPurchase} disabled={isDefaultPurchaseLoading}>
          {isDefaultPurchaseLoading ? <div className="button-spinner"></div> : 'Confirm Purchase'}
        </button>
        </div>
      </div>
    </div>
  )}

 
  {showInventoryPopup && (
    <div className="popup">
      <div className="popup-header">
        <button onClick={() => setShowInventoryPopup(false)} className="close-button">
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
        </button>
        <h1>Manage Inventory</h1>
      </div>
      <div className="popup-content">
        <div className="inventory-sections">
          <div className="inventory-section">
          <button onClick={resetInventoryState}>Reset Row States</button>
          <div className="then-divider"></div>
            <h3>Add to Inventory</h3>
            <button onClick={() => handleAddInventoryRow('item')}>Add Item</button>
            <button onClick={() => handleAddInventoryRow('bundle')}>Add Bundle</button>
            <div>
              {selectedItems.map((item, index) => (
                <div key={index} className="inventory-row">
                  <label>
                    Item:
                    <select className="custom-select" value={item.id} onChange={(e) => handleInventoryInputChange('item', index, 'id', e.target.value)}>
                      <option value="">Select an item</option>
                      {items.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Amount:
                    <input
                      type="number"
                      value={item.amount}
                      onChange={(e) => handleInventoryInputChange('item', index, 'amount', Number(e.target.value))}
                    />
                  </label>
                  <label>
                    Stack ID:
                    <input
                      type="text"
                      value={item.stackId}
                      onChange={(e) => handleInventoryInputChange('item', index, 'stackId', e.target.value)}
                    />
                  </label>
                  <label>
                    Collection ID:
                    <input
                      type="text"
                      value={item.collectionId}
                      onChange={(e) => handleInventoryInputChange('item', index, 'collectionId', e.target.value)}
                    />
                  </label>
                  <button onClick={() => handleRemoveInventoryRow('item', index)}>-</button>
                </div>
              ))}
            </div>
            <div>
              {selectedBundles.map((bundle, index) => (
                <div key={index} className="inventory-row">
                  <label>
                    Bundle:
                    <select className="custom-select" value={bundle.id} onChange={(e) => handleInventoryInputChange('bundle', index, 'id', e.target.value)}>
                      <option value="">Select a bundle</option>
                      {bundles.map(bundle => (
                        <option key={bundle.id} value={bundle.id}>
                          {bundle.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Amount:
                    <input
                      type="number"
                      value={bundle.amount}
                      onChange={(e) => handleInventoryInputChange('bundle', index, 'amount', Number(e.target.value))}
                    />
                  </label>
                  <label>
                    Stack ID:
                    <input
                      type="text"
                      value={bundle.stackId}
                      onChange={(e) => handleInventoryInputChange('bundle', index, 'stackId', e.target.value)}
                    />
                  </label>
                  <label>
                    Collection ID:
                    <input
                      type="text"
                      value={bundle.collectionId}
                      onChange={(e) => handleInventoryInputChange('bundle', index, 'collectionId', e.target.value)}
                    />
                  </label>
                  <button onClick={() => handleRemoveInventoryRow('bundle', index)}>-</button>
                </div>
              ))}
            </div>
            <button onClick={handleAddInventoryItem}>Start Add to Inventory</button>
          </div>
          {showAddInventoryCustomParamsPopup && (
            <div className="custom-params-popup">
              <h2>Custom Parameters for Add to Inventory</h2>
              {selectedItems.map(item => (
                <div key={item.id} > 
                  <h4>Custom Params for Item {item.id}</h4>
                  {addInventoryCustomParams[item.id] && addInventoryCustomParams[item.id].map((param, index) => (
                    <div key={index} className="custom-param">
                      <input
                        type="text"
                        placeholder="Param Name"
                        value={param.name}
                        onChange={(e) => handleCustomParamChange('addInventory', item.id, index, 'name', e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Value"
                        value={param.value}
                        onChange={(e) => handleCustomParamChange('addInventory', item.id, index, 'value', e.target.value)}
                      />
                      <select className="custom-select" onChange={(e) => handleCustomParamChange('addInventory', item.id, index, 'type', e.target.value)} value={param.type}>
                        <option value="string">String</option>
                        <option value="number">Number</option>
                        <option value="boolean">Boolean</option>
                      </select>
                      <button onClick={() => handleRemoveCustomParam('addInventory', item.id, index)} className="remove-param">
                        -
                      </button>
                    </div>
                  ))}
                  <button onClick={() => handleAddCustomParam('addInventory', item.id)} className="add-param">
                    + Add Custom Param
                  </button>
                </div>
              ))}
            <button onClick={handleSubmitAddInventoryItem} className="loading-button" disabled={isLoading}>
                    {isLoading ? <div className="button-spinner"></div> : 'Confirm Add to Inventory'}
                  </button>
              <button onClick={() => setShowAddInventoryCustomParamsPopup(false)}>Close</button>
            </div>
          )}
          <div className="inventory-section">
          <div className="then-divider"></div>
          <h3>Consume Item</h3>
                <button onClick={() => { handleAddConsumeItemRow(); handleFetchInventory(players[activeTab].token); }}>
                  Add Item to Consume
                </button>
                <div>
                  {selectedConsumeItems.map((item, index) => (
                    <div key={index} className="inventory-row">
                      <label>
                        Item:
                        <select className="custom-select" value={item.instanceId} onChange={(e) => handleConsumeItemInputChange(index, 'instanceId', e.target.value)}>
                          <option value="">Select an item</option>
                          {inventoryItems.map(item => (
                            <option key={item.instanceId} value={item.instanceId}>
                              {item.name} ({item.instanceId}) [{item.id}]
                            </option>
                          ))}
                        </select>
                      </label>
                      <label>
                        Amount:
                        <input
                          type="number"
                          value={item.amount}
                          onChange={(e) => handleConsumeItemInputChange(index, 'amount', Number(e.target.value))}
                        />
                      </label>
                      <label>
                        Stack ID:
                        <input
                          type="text"
                          value={item.stackId}
                          onChange={(e) => handleConsumeItemInputChange(index, 'stackId', e.target.value)}
                        />
                      </label>
                      <label>
                        Collection ID:
                        <input
                          type="text"
                          value={item.collectionId}
                          onChange={(e) => handleConsumeItemInputChange(index, 'collectionId', e.target.value)}
                        />
                      </label>
                      <button onClick={() => handleRemoveConsumeItemRow(index)}>-</button>
                    </div>
                  ))}
                </div>
                <button onClick={handleConsumeItem}>Start Consume Item</button>
              </div>
              {showConsumeInventoryCustomParamsPopup && (
                <div className="custom-params-popup">
                  <h2>Custom Parameters for Consume Item</h2>
                  {selectedConsumeItems.map(item => (
                    <div key={item.instanceId}>
                      <h4>Custom Params for Item {item.instanceId}</h4>
                      {consumeInventoryCustomParams[item.instanceId] && consumeInventoryCustomParams[item.instanceId].map((param, index) => (
                        <div key={index} className="custom-param">
                          <input
                            type="text"
                            placeholder="Param Name"
                            value={param.name}
                            onChange={(e) => handleCustomParamChange('consumeInventory', item.instanceId, index, 'name', e.target.value)}
                          />
                          <input
                            type="text"
                            placeholder="Value"
                            value={param.value}
                            onChange={(e) => handleCustomParamChange('consumeInventory', item.instanceId, index, 'value', e.target.value)}
                          />
                          <select className="custom-select" onChange={(e) => handleCustomParamChange('consumeInventory', item.instanceId, index, 'type', e.target.value)} value={param.type}>
                            <option value="string">String</option>
                            <option value="number">Number</option>
                            <option value="boolean">Boolean</option>
                          </select>
                          <button onClick={() => handleRemoveCustomParam('consumeInventory', item.instanceId, index)} className="remove-param">
                            -
                          </button>
                        </div>
                      ))}
                      <button onClick={() => handleAddCustomParam('consumeInventory', item.instanceId)} className="add-param">
                        + Add Custom Param
                      </button>
                    </div>
                  ))}
                  <button onClick={handleSubmitConsumeItem} className="loading-button" disabled={isLoading}>
                    {isLoading ? <div className="button-spinner"></div> : 'Confirm Consume Item'}
                  </button>
                  <button onClick={() => setShowConsumeInventoryCustomParamsPopup(false)}>Close</button>
                </div>
              )}
              <div className="inventory-section">
              <div className="then-divider"></div>
                <h3>Equip/Unequip Item</h3>
                <button onClick={() => { handleAddEquipItemRow(); handleFetchInventory(players[activeTab].token); }}>
                  Add Item to Equip/Unequip
                </button>
                <div>
                  {selectedEquipItems.map((item, index) => (
                    <div key={index} className="inventory-row">
                      <label>
                        Item:
                        <select className="custom-select" value={item.instanceId} onChange={(e) => handleEquipItemInputChange(index, 'instanceId', e.target.value)}>
                          <option value="">Select an item</option>
                          {inventoryItems.map(item => (
                            <option key={item.instanceId} value={item.instanceId}>
                              {item.name} ({item.instanceId}) [{item.id}]
                            </option>
                          ))}
                        </select>
                      </label>
                      <label>
                        Stack ID:
                        <input
                          type="text"
                          value={item.stackId}
                          onChange={(e) => handleEquipItemInputChange(index, 'stackId', e.target.value)}
                        />
                      </label>
                      <label>
                        Collection ID:
                        <input
                          type="text"
                          value={item.collectionId}
                          onChange={(e) => handleEquipItemInputChange(index, 'collectionId', e.target.value)}
                        />
                      </label>
                      <label>
                        Action:
                        <select className="custom-select" value={item.shouldEquip} onChange={(e) => handleEquipItemInputChange(index, 'shouldEquip', e.target.value === 'true')}>
                          <option value="true">Equip</option>
                          <option value="false">Unequip</option>
                        </select>
                      </label>
                      <button onClick={() => handleRemoveEquipItemRow(index)}>-</button>
                    </div>
                  ))}
                </div>
                <button onClick={handleEquipItem}>Start Equip/Unequip Item</button>
              </div>
              {showEquipInventoryCustomParamsPopup && (
                <div className="custom-params-popup">
                  <h2>Custom Parameters for Equip/Unequip Item</h2>
                  {selectedEquipItems.map(item => (
                    <div key={item.instanceId}>
                      <h4>Custom Params for Item {item.instanceId}</h4>
                      {equipInventoryCustomParams[item.instanceId] && equipInventoryCustomParams[item.instanceId].map((param, index) => (
                        <div key={index} className="custom-param">
                          <input
                            type="text"
                            placeholder="Param Name"
                            value={param.name}
                            onChange={(e) => handleCustomParamChange('equipInventory', item.instanceId, index, 'name', e.target.value)}
                          />
                          <input
                            type="text"
                            placeholder="Value"
                            value={param.value}
                            onChange={(e) => handleCustomParamChange('equipInventory', item.instanceId, index, 'value', e.target.value)}
                          />
                          <select className="custom-select" onChange={(e) => handleCustomParamChange('equipInventory', item.instanceId, index, 'type', e.target.value)} value={param.type}>
                            <option value="string">String</option>
                            <option value="number">Number</option>
                            <option value="boolean">Boolean</option>
                          </select>
                          <button onClick={() => handleRemoveCustomParam('equipInventory', item.instanceId, index)} className="remove-param">
                            -
                          </button>
                        </div>
                      ))}
                      <button onClick={() => handleAddCustomParam('equipInventory', item.instanceId)} className="add-param">
                        + Add Custom Param
                      </button>
                    </div>
                  ))}
                                   <button onClick={handleSubmitEquipItem} className="loading-button" disabled={isLoading}>
                    {isLoading ? <div className="button-spinner"></div> : 'Confirm Equip/Unequip Item'}
                  </button>

                  <button onClick={() => setShowEquipInventoryCustomParamsPopup(false)}>Close</button>
                </div>
              )}
            </div>
           
            {inventoryStatus && <p>{inventoryStatus}</p>}
          </div>
        </div>
      )}






z
      
 <button className="view-session-logs-button-fixed" onClick={handleOpenLogViewer}>
        <img src="/assets/log.svg" alt="View Session Logs Icon" className="log-icon" />
        <span className="button-text">View Logs</span>
      </button>
  

      {isSessionLogViewerOpen && (
        <SessionLogViewer onClose={handleCloseLogViewer} />
      )}

    <button className="view-session-logs-button-game" onClick={() => setShowSnakeGame(true)}> <img src="/assets/game.svg" alt="View Session Logs Icon" className="log-icon" />
    <span className="button-text">Play a Game</span>
    </button>
      {showSnakeGame && (
        <div className="snake-modal-container">
          <div className="snake-modal-content">
          <button
          onClick={() => {
            setShowSnakeGame(false)
          }}
          className="close-button"
        >
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="Close" className="icon" />
        </button>
        
            <SnakesGame />
          </div>
        </div>
      )}
        

</div>
</div>
);
}


export default () => (
  <Router>
    <GoogleOAuthProvider clientId="8094591956-l1oj9gu9tm35nv03d8u86g20p1dphbta.apps.googleusercontent.com">
      <App />
      
    </GoogleOAuthProvider>
  </Router>
);

